import Axios from 'axios';

import { logOut } from 'store/auth/auth.actions';

import { useAppDispatch } from '../hooks/useAppSelector';
import { getToken } from './HelperFunctions';

const http = Axios.create();

http.interceptors.request.use(
  async (config) => {
    const accessToken = getToken();
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/**
 * intercepts any response and checks the response from our api for a 401 status in the response.
 * ie. the token has now expired and is no longer valid, or no valid token was sent.
 * If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err) {
      return Promise.reject({
        message: err.response.data.message,
      });
    }
    if (err.response.status === 401) {
      useAppDispatch()(logOut());
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(err);
  },
);

export default http;
