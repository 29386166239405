import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

export interface SelectItem {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface MultiSelectProps {
  name: string;
  label: string;
  items: SelectItem[];
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  hasSelectAllOption?: boolean;
}

export const MultiSelectWrapper = ({
  name,
  label,
  items,
  value,
  onChange,
  disabled,
  hasSelectAllOption,
}: MultiSelectProps) => {
  const handleRenderSelectedValue = (selected: string[]) =>
    selected.length
      ? items
          .filter((item) =>
            selected.some((selectItem: string) => item.value === selectItem),
          )
          .map((item) => item.label)
          .join(', ')
      : '';

  const handleChangeSelect = (event: SelectChangeEvent<string[]>) => {
    if (event.target.value.includes('all')) {
      return;
    }

    onChange(event.target.value as string[]);
  };

  const handleSelectAll = () => {
    if (value.length === items.length) {
      onChange([]);
    } else {
      onChange(items.map((item) => item.value as string));
    }
  };

  return (
    <FormControl>
      <InputLabel id={`form-multiselect${name}`}>{label}</InputLabel>
      <Select
        labelId={`form-multiselect${name}`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          autoFocus: false,
        }}
        multiple={true}
        disabled={disabled}
        value={value}
        input={<OutlinedInput label={label} />}
        renderValue={handleRenderSelectedValue}
        onChange={(e) => handleChangeSelect(e)}
      >
        {hasSelectAllOption && (
          <MenuItem value="all" onClick={() => handleSelectAll()}>
            <Checkbox
              indeterminate={value.length > 0 && value.length < items.length}
              checked={value.length === items.length}
            />
            <ListItemText primary="Select All" />
          </MenuItem>
        )}
        {items.map((selectItem: SelectItem) => (
          <MenuItem
            disabled={selectItem.disabled}
            key={selectItem.value}
            value={selectItem.value}
          >
            <Checkbox checked={value.includes(selectItem.value as string)} />
            <ListItemText primary={selectItem.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
