import { ChangeEvent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components';
import { TextInput } from 'components/Inputs/TextInput/TextInput';

import { updateBuildingWorkingHoursById } from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import { IWorkingHours } from 'types';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  mainInfo: {
    display: 'flex',
  },
  content: {
    width: '100%',
    marginTop: '82px',
  },
  editContent: {
    width: '100%',
    maxWidth: '367px',

    '& .MuiFormControl-root': {
      marginBottom: '42px',
    },
  },
  cardBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    width: '100%',
  },
});

interface BuildingWorkingHoursProps {
  workingHours?: IWorkingHours;
  validationFormState?: UseFormReturn;
  canUpdate?: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const BuildingWorkingHours = ({
  workingHours,
  canUpdate = false,
  validationFormState,
  handleChange,
}: BuildingWorkingHoursProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { currentBuilding, loading } = useSelector(
    (state: RootState) => state.buildings,
  );

  useEffect(() => {
    setEditedData({ ...currentBuilding?.working_hours });
  }, [currentBuilding]);

  const [editedData, setEditedData] = useState<IWorkingHours>(
    currentBuilding && {
      monday: currentBuilding?.working_hours?.monday,
      tuesday: currentBuilding?.working_hours?.tuesday,
      wednesday: currentBuilding?.working_hours?.wednesday,
      thursday: currentBuilding?.working_hours?.thursday,
      friday: currentBuilding?.working_hours?.friday,
      saturday: currentBuilding?.working_hours?.saturday,
      sunday: currentBuilding?.working_hours?.sunday,
    },
  );

  useEffect(() => {
    if (editedData) {
      validationFormState?.setValue('monday', editedData.monday);
      validationFormState?.setValue('tuesday', editedData.tuesday);
      validationFormState?.setValue('wednesday', editedData.wednesday);
      validationFormState?.setValue('thursday', editedData.thursday);
      validationFormState?.setValue('friday', editedData.friday);
      validationFormState?.setValue('saturday', editedData.saturday);
      validationFormState?.setValue('sunday', editedData.sunday);
    }
  }, [editedData]);

  const handleChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });

    if (handleChange) {
      handleChange(e);
    }
  };

  const handleSaveChanges = () => {
    dispatch(
      updateBuildingWorkingHoursById({
        workingHours: { working_hours: editedData },
        building_id: currentBuilding?.id,
        working_hours_id: currentBuilding?.working_hours?.id,
      }),
    );
  };

  if (loading) {
    return (
      <Box textAlign={'center'} mt={2}>
        Loading...
      </Box>
    );
  }

  return (
    <Box className={classes.content}>
      <Box display={'flex'} justifyContent={'center'}>
        <Box className={classes.editContent}>
          <TextInput
            name={'monday'}
            value={editedData?.monday}
            label={'Monday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'tuesday'}
            value={editedData?.tuesday}
            label={'Tuesday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'wednesday'}
            value={editedData?.wednesday}
            label={'Wednesday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'thursday'}
            value={editedData?.thursday}
            label={'Thursday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'friday'}
            value={editedData?.friday}
            label={'Friday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'saturday'}
            value={editedData?.saturday}
            label={'Saturday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          <TextInput
            name={'sunday'}
            value={editedData?.sunday}
            label={'Sunday'}
            color={'black'}
            handleChange={handleChangeLocal}
          />
          {canUpdate && (
            <CustomButton
              title={'Save'}
              color={'primary'}
              variant={'buttonMedium'}
              handleClick={validationFormState?.handleSubmit(handleSaveChanges)}
              additionalClass={classes.saveButton}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
