import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';

import { store } from 'store';
import { eliteTheme } from 'theme/theme';

import EliteApp from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const persistor = persistStore(store);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/*@ts-ignore*/}
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={eliteTheme}>
          <BrowserRouter>
            <Suspense fallback={<LoadingScreen text="Loading ..." />}>
              <SnackbarProvider
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                maxSnack={3}
              >
                <EliteApp />
              </SnackbarProvider>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
