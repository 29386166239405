import { BaseTabConfig } from '../../../../components/BaseTabs/BaseTabs';
import { ServiceSpecialist } from '../../../../store/service-specialists/service-specialists.types';
import { ScheduleList } from '../../../Schedule/List/ScheduleList';
import { SCHEDULE_PAGES } from '../../../Schedule/List/ScheduleList.constants';
import { SpecialistServices } from './Services/SpecialistServices';
import { SpecialistInfo } from './SpecialistInfo/SpecialistInfo';

export enum SPECIALIST_TABS {
  INFO = 'info',
  SERVICES = 'services',
  SERVICES_SCHEDULE = 'services_schedule',
}

export const getSpecialistTabsConfig = (
  specialist: ServiceSpecialist,
): BaseTabConfig[] => [
  {
    value: SPECIALIST_TABS.INFO,
    label: 'Info',
    component: () => <SpecialistInfo />,
  },
  {
    value: SPECIALIST_TABS.SERVICES,
    label: 'Services',
    component: () => (
      <SpecialistServices
        specialistName={`${specialist?.first_name} ${specialist?.last_name}`}
      />
    ),
  },
  {
    value: SPECIALIST_TABS.SERVICES_SCHEDULE,
    label: 'Services schedule',
    component: () => (
      <ScheduleList
        schedulePageType={SCHEDULE_PAGES.SPECIALIST}
        id={specialist.id}
      />
    ),
  },
];
