import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField, Typography } from '@mui/material';

import { normalizePhone } from '../../../utils/HelperFunctions';
import * as S from './PhoneNumberInput.styled';

interface BaseInputProps {
  name: string;
  label: string;
}

export const PhoneNumberInput = ({ name, label }: BaseInputProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            label={label}
            {...field}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              field.onChange(e);
              setValue(name, normalizePhone(e.target.value));
            }}
          />
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
      <S.CountryCode>+1</S.CountryCode>
    </S.Container>
  );
};
