import { addWeeks, format, subWeeks } from 'date-fns';

import { GetTimeSlotsParams } from 'store/time-slots/time-slots.actions';

import PreviousIcon from '../../../../assets/icons/chevron-left.svg';
import NextIcon from '../../../../assets/icons/chevron-right.svg';
import { DATE_FORMAT_WEEK_FILTER } from '../../../../utils/constants';
import * as S from './WeekFilter.styled';

interface WeekFilterProps {
  handleFiltersChange: (params: GetTimeSlotsParams) => void;
  params: GetTimeSlotsParams;
  isLoading: boolean;
}

export const WeekFilter = ({
  handleFiltersChange,
  isLoading,
  params,
}: WeekFilterProps) => {
  const handleClickPrevious = () => {
    handleFiltersChange({
      ...params,
      start_date: subWeeks(params.start_date as Date, 1),
      end_date: subWeeks(params.end_date as Date, 1),
    });
  };
  const handleClickNext = () => {
    handleFiltersChange({
      ...params,
      start_date: addWeeks(params.start_date as Date, 1),
      end_date: addWeeks(params.end_date as Date, 1),
    });
  };

  return (
    <S.Container>
      <S.Control disabled={isLoading} onClick={handleClickPrevious}>
        <S.ControlImage src={PreviousIcon} alt="" />
      </S.Control>
      <S.DateRange>
        {`${format(
          params.start_date as Date,
          DATE_FORMAT_WEEK_FILTER,
        )} - ${format(params.end_date as Date, DATE_FORMAT_WEEK_FILTER)}`}
      </S.DateRange>
      <S.Control disabled={isLoading} onClick={handleClickNext}>
        <S.ControlImage src={NextIcon} alt="" />
      </S.Control>
    </S.Container>
  );
};
