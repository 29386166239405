import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const FilterWrapper = styled.div<{ isFiltersOpened: boolean }>`
  max-height: ${(props) => (props.isFiltersOpened ? '500px' : 0)};
  overflow: hidden;
  display: flex;
`;

export const FiltersSwitcher = styled.div`
  margin-right: 30px;
`;
