import { ReactNode } from 'react';

import { Box, Chip, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Close from 'assets/icons/cross.svg';

const useStyles = makeStyles({
  mainContent: {
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    borderRadius: '20px',
    boxSizing: 'border-box',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      margin: '10px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#999999',
      borderRadius: '4px',
    },
  },
  small: {},
  medium: {
    width: '600px',
    // height: '100%',
    maxHeight: '80%',
  },
  large: {
    width: '800px',
    height: '100%',
    maxHeight: '80%',
  },
  fixedBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
    borderBottom: '1px solid #E0E0E0',
  },
  mainTitle: {},
  addedContent: {},
  closeButton: {
    cursor: 'pointer',
  },
  chip: { margin: '0 1rem' },
  red: {},
  blue: { color: '#ffffff', backgroundColor: '#2196F3!important' },
  green: { color: 'white!important', backgroundColor: 'green!important' },
  darkblue: {},
});

interface CustomModalProps {
  title: string | JSX.Element;
  children: ReactNode;
  open: boolean;
  titleBadge?: string;
  colorBadge?: 'red' | 'blue' | 'green' | 'darkblue';
  sizeModal?: 'small' | 'medium' | 'large';
  closeModal: () => void;
}

const CustomModal = ({
  title,
  children,
  open,
  sizeModal,
  titleBadge,
  colorBadge,
  closeModal,
}: CustomModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classNames(classes.mainContent, {
          [classes.small]: sizeModal === 'small',
          [classes.medium]: sizeModal === 'medium',
          [classes.large]: sizeModal === 'large',
        })}
        p={5}
      >
        <Box className={classes.fixedBlock}>
          <Typography variant={'h2'} className={classes.mainTitle}>
            {title}
            {titleBadge && (
              <Chip
                label={titleBadge}
                className={classNames(classes.chip, {
                  [classes.red]: colorBadge === 'red',
                  [classes.blue]: colorBadge === 'blue',
                  [classes.green]: colorBadge === 'green',
                  [classes.darkblue]: colorBadge === 'darkblue',
                })}
              />
            )}
          </Typography>
          <img
            src={Close}
            alt="close"
            width={24}
            height={24}
            onClick={() => closeModal()}
            className={classes.closeButton}
          />
        </Box>
        <Box mt={5}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
