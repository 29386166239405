import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Logout } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Ava from 'assets/s_avatar.png';

import LogOutModal from 'components/LogOutModal/LogOutModal';
import { Notifications } from 'components/Notifications/Notifications';

import { PERMISSIONS, Roles } from 'constants/Roles';
import { logOut } from 'store/auth/auth.actions';
import { AppDispatch } from 'store/index';
import { RootState } from 'store/rootReducer';

import { useHasPermissions } from '../../hooks/useHasPermissions';

const useStyles = makeStyles((theme: any) => ({
  navigation: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    height: '5rem',
    borderTopLeftRadius: '1.875rem',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    objectFit: 'cover',
  },
}));

export const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [userTitle, setUserTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  const open = Boolean(anchorEl);

  const auth = useSelector((state: RootState) => state.auth);
  const refresh: string | undefined = auth?.user?.refresh;

  const canUpdateAccountInfo = useHasPermissions(
    PERMISSIONS.CAN_UPDATE_ACCOUNT_INFO,
  );
  const canSeeNotifications = useHasPermissions(
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
  );

  useEffect(() => {
    for (let i = 0; i < Roles.length; i++) {
      if (Roles[i].value === auth?.user?.user_role) {
        setUserRole(Roles[i].label);
      }
    }
    setUserTitle(auth?.user?.first_name + ' ' + auth?.user?.last_name);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setIsLogoutModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsLogoutModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToAccountSettings = () => {
    navigate('/account/');
  };

  const logout = () => {
    dispatch(logOut(refresh));
  };

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate('/signin');
    }
  });

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.navigation}>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              textAlign: 'center',
              color: 'black',
              marginRight: '2rem',
            }}
          >
            <Box>{canSeeNotifications && <Notifications />}</Box>

            <MenuItem onClick={handleClick}>
              <Tooltip title={userTitle}>
                <IconButton
                  sx={{ p: '1rem' }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <img
                    className={classes.avatar}
                    src={
                      auth?.user?.account_icon ? auth?.user?.account_icon : Ava
                    }
                    alt=""
                  />
                </IconButton>
              </Tooltip>
              <Grid container={true} spacing={0.5}>
                <Grid item={true} xs={12}>
                  <Typography variant="subtitle1">{userTitle}</Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant="body1">{userRole}</Typography>
                </Grid>
              </Grid>
              <KeyboardArrowDownIcon />
            </MenuItem>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {canUpdateAccountInfo && (
              <MenuItem onClick={goToAccountSettings}>
                <Avatar /> Account Management
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <LogOutModal
        closeModal={handleCloseModal}
        handleLogOut={logout}
        isOpen={isLogoutModalOpen}
      />
    </>
  );
};
