import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import { createPackage, deletePackage, getPackages } from './packages.actions';
import { Package, PackagesStateType } from './packages.types';

export const initialState: PackagesStateType = Object.freeze({
  packages: [],
  error: null,
  status: STORE_STATUSES.IDLE,
  loading: false,
});

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackages.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(
      getPackages.fulfilled,
      (state, action: PayloadAction<Package[]>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.packages = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(getPackages.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(createPackage.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(createPackage.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(createPackage.rejected, (state, action: any) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deletePackage.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(deletePackage.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(deletePackage.rejected, (state, action: any) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {} = packagesSlice.actions;

export default packagesSlice.reducer;
