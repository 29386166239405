import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    top: '55px',
  },
  menuItem: {
    padding: 0,
    '&.MuiMenuItem-root': {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },
  itemBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  selectedItem: {
    backgroundColor: '#F3E9CB',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  indeterminateColor: {
    color: '#f50057',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CheckmarkInputWithSelectAllProps {
  value: string[];
  label: string;
  name: string;
  selector?: string;
  itemSecondValue?: string;
  disabled?: boolean;
  color?: 'white' | 'black';
  items: any[];
  selectedId: string[];
  itemValue?: string;
  handleChange: (e: string[]) => void;
}

export const CheckmarkInputWithSelectAll = ({
  value,
  label,
  name,
  selector = ' ',
  disabled = false,
  color = 'white',
  items,
  selectedId,
  itemValue = 'id',
  itemSecondValue = 'name',
  handleChange,
}: CheckmarkInputWithSelectAllProps) => {
  const classes = useStyles();
  const isAllSelected =
    selectedId.length > 0 && selectedId.length === items.length;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const onChange = (event: SelectChangeEvent<typeof items>) => {
    const {
      target: { value: valueTarget },
    } = event;

    if (valueTarget[valueTarget.length - 1] === 'all') {
      handleChange(
        selectedId.length === items.length
          ? []
          : items.map((item: any) => item?.id),
      );
      return;
    }
    handleChange(valueTarget as string[]);
  };

  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        render={({ field }: any) => (
          <FormControl
            fullWidth={true}
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #000000 inset',
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{
                color: `${
                  disabled
                    ? '#999999'
                    : color === 'white'
                    ? '#ffffff'
                    : '#000000'
                }`,
              }}
            >
              {label}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              disabled={disabled}
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
              }}
              multiple={true}
              value={value}
              name={name}
              onChange={onChange}
              input={<OutlinedInput label={label} />}
              renderValue={(selected: any) => {
                let result = '';
                selected.map((itemId: any, index: number) => {
                  const item = items.find(
                    (itemTwo: any) => itemTwo?.id === itemId,
                  );
                  result += `${item?.code}${selector}${item?.name}${
                    selected.length - 1 !== index ? ', ' : ''
                  }`;
                });
                return result;
              }}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : '',
                }}
                className={classes.menuItem}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedId.length > 0 && selectedId.length < items.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {items?.map((item: any) => (
                <MenuItem
                  key={item.id}
                  value={`${item[itemValue]}`}
                  onClick={() => item.id}
                  className={classes.menuItem}
                >
                  <Box
                    className={classNames(classes.itemBox, {
                      [classes.selectedItem]: selectedId.includes(item.id),
                    })}
                  >
                    <Checkbox checked={selectedId.includes(item.id)} />
                    <ListItemText
                      primary={
                        <Box>
                          {item['code']}
                          {selector}
                          {item[itemSecondValue]}
                          <Typography hidden={true}>{item.id}</Typography>
                        </Box>
                      }
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
