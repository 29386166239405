import styled from '@emotion/styled/macro';

import { pickerButtonOverride } from '../../../../styles/common.styled';

export const Container = styled.div`
  padding-top: 8px;
  padding-bottom: 22px;
  display: flex;
`;

export const FilterItem = styled.div`
  margin-right: 20px;
  width: 140px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const FilterItemYearMonth = styled.div`
  margin-right: 20px;
  width: 200px;

  ${pickerButtonOverride};
`;
