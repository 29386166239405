import { areIntervalsOverlapping, isBefore, isEqual } from 'date-fns';

import { Interval, SelectedInterval } from './IntervalsSelector.types';

export const isBeforeOrEqual = (interval: Interval): boolean => {
  return (
    isBefore(interval.end_time as Date, interval.start_time as Date) ||
    isEqual(interval.end_time as Date, interval.start_time as Date)
  );
};

export const areOverlapping = (
  selectedIntervals: SelectedInterval[],
  interval: Interval,
): boolean => {
  return selectedIntervals.some((selectedInterval: SelectedInterval) =>
    areIntervalsOverlapping(
      {
        start: selectedInterval.start_time,
        end: selectedInterval.end_time,
      },
      {
        start: interval.start_time as Date,
        end: interval.end_time as Date,
      },
    ),
  );
};
