import React from 'react';

export enum GRID_HEADER_ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ColumnType<T> {
  label: string;
  widthPercentage: number;
  align: GRID_HEADER_ALIGNMENTS;
  headerComponent: (column: ColumnType<T>) => React.ReactElement;
  cellComponent: (
    column: ColumnType<T>,
    entity: T,
    actionsComponent?: React.ReactElement,
    rowIndex?: number,
    noBottomBorder?: boolean,
  ) => React.ReactElement;
}
