import styled from '@emotion/styled/macro';

export const Container = styled.div`
  .MuiTabs-root {
    .MuiTabsList-root.MuiTabsList-horizontal {
      border-bottom: 1px solid ${(props: any) => props.theme.palette.grey[300]};

      .MuiTab-root {
        font-size: 16px;
        font-family: 'GraphikMedium', sans-serif;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        padding: 12px 24px;
        border: none;
        margin-bottom: -1px;
        text-transform: uppercase;
        color: ${(props: any) => props.theme.palette.text.disabled};

        &.Mui-selected {
          border-bottom: 2px solid
            ${(props: any) => props.theme.palette.primary.light};
          color: ${(props: any) => props.theme.palette.text.primary};
        }
      }
    }
  }

  .MuiTabPanel-root {
    margin-top: 36px;
  }
`;
