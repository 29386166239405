import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints, main } from 'config';

import { Invite } from 'types/index';
import http from 'utils/http';

export const getCanCurrentUserInviteRoles = createAsyncThunk(
  'people/canCurrentUserInviteRoles',
  async (_: void, thunkAPI: any) => {
    try {
      const url = endpoints.auth.canCurrentUserRolesInvite;
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const sendInvite = createAsyncThunk(
  'people/sendInvite',
  async (inviteData: Invite, thunkAPI: any) => {
    try {
      const url = endpoints.auth.sendInvite;
      const response = await http.post(url, inviteData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllPeople = createAsyncThunk(
  'people/getAllPeople',
  async (
    {
      role,
      limit,
      activePage,
      first_name = null,
      last_name = null,
      email = null,
    }: any,
    thunkAPI: any,
  ) => {
    try {
      const firstName = first_name ? `&first_name=${first_name}` : ``;
      const lastName = last_name ? `&last_name=${last_name}` : ``;
      const mail = email ? `&email=${email}` : ``;
      const url =
        `${endpoints.people.getAllPeople}?role=${role}&page_size=${limit}` +
        `&page=${activePage}&ordering=user__last_name${firstName}${lastName}${mail}`;

      const response = await http.get(url, {});
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getResidentNote = createAsyncThunk(
  'people/getResidentNote',
  async (id: string, thunkAPI: any) => {
    try {
      const url = endpoints.people.residentNoteById(id);
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateResidentNote = createAsyncThunk(
  'people/updateResidentNote',
  async ({ id, admin_note }: any, thunkAPI: any) => {
    try {
      const url = endpoints.people.residentNoteById(id);
      const response = await http.patch(url, { admin_note });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const changeStatus = createAsyncThunk(
  'people/changeStatus',
  async ({ id, status }: any, thunkAPI: any) => {
    try {
      const url = endpoints.people.changeStatus(id, status);
      const response = await http.put(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'people/deleteUser',
  async ({ id }: any, thunkAPI: any) => {
    try {
      const url = endpoints.people.deleteUser(id);
      const response = await http.delete(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getAllServiceSpecialists = createAsyncThunk(
  'people/getAllServiceSpecialists',
  async (
    {
      limit = 20,
      activePage = 1,
      isEmpty,
      service,
      company_name = '',
      first_name = '',
      last_name = '',
    }: any,
    thunkAPI: any,
  ) => {
    // TODO
    try {
      const url =
        `${main.serviceSpecialists.root}?${
          !!limit ? `&page_size=${limit}&page=${activePage}` : ''
        }` +
        `${isEmpty === true || isEmpty === false ? `&empty=${isEmpty}` : ''}` +
        `${service ? `&service=${service}` : ''}` +
        `${first_name ? `&first_name_like=${first_name}` : ''}${
          last_name ? `&last_name_like=${last_name}` : ''
        }${company_name ? `&company_name=${company_name}` : ''}`;
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
