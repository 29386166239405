import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import { SelectItem } from '../MultiSelectWrapper/MultiSelectWrapper';

interface SelectWrapperProps {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  items: SelectItem[];
  isItemDisabled?: (item: SelectItem) => boolean;
  disabled?: boolean;
}

export const SelectWrapper = ({
  name,
  label,
  value,
  onChange,
  items,
  isItemDisabled,
  disabled,
}: SelectWrapperProps) => {
  return (
    <FormControl>
      <InputLabel id={`form-select-${name}`}>{label}</InputLabel>
      <Select
        labelId="form-select-building"
        input={<OutlinedInput label={label} />}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      >
        {items.map((selectItem: SelectItem) => (
          <MenuItem
            disabled={isItemDisabled && isItemDisabled(selectItem)}
            key={selectItem.value}
            value={selectItem.value}
          >
            {selectItem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
