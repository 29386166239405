import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { BaseError } from '../store.constants';
import { ContactsList, GetContactsParams } from './contacts.types';

export const getContacts = createAsyncThunk<
  ContactsList,
  GetContactsParams,
  { rejectValue: BaseError }
>('contacts/getContacts', async (params: GetContactsParams) => {
  const response = await http.get(endpoints.auth.getContacts, {
    params: {
      ...params,
    },
  });

  return {
    list: response.data,
    params,
  };
});
