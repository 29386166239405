import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { Box, Grid, Typography } from '@mui/material';

import Send from 'assets/icons/send.svg';

import { CustomButton } from 'components/CustomButton/CustomButton';
import CustomModal from 'components/CustomModal/CustomModal';
import { YesNoModal } from 'components/CustomModal/YesNoModal/YesNoModal';
import { CustomPagination } from 'components/CustomPagination/CustomPagination';
import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper';
import { InvitationModal } from 'components/index';
import { Toast } from 'components/Toast/Toast';
import { useHasPermissions } from 'hooks/useHasPermissions';

import { PERMISSIONS } from 'constants/Roles';
import { getBuildings } from 'store/buildings/buildings.actions';
import { AppDispatch } from 'store/index';
import {
  changeStatus,
  deleteUser,
  getAllPeople,
  getResidentNote,
  updateResidentNote,
} from 'store/people/people.actions';
import { showMessage } from 'store/people/people.slice';
import { RootState } from 'store/rootReducer';
import { LocationManagementState } from 'types';
import { MessageInfo } from 'types/index';

import { DetailsBuildingManagerModal } from './components/DetailsBuildingManagerModal/DetailsBuildingManagerModal';
import { EditNoteModalContent } from './components/EditNoteModal/EditNoteModal';
import { ListBuildingManager } from './ListBuildingManager/ListBuildingManager';
import { ListConcierge } from './ListConcierge/ListConcierge';
import { ListMembers } from './ListMembers/ListMembers';
import { ListSuperAdmin } from './ListSuperAdmin/ListSuperAdmin';
import { ServiceSpecialistList } from './ServiceSpecialist/List/ServiceSpecialistList';

const StartingPage = [
  { role: 'owner', tab: 'super_admin' },
  { role: 'super_admin', tab: 'super_admin' },
  { role: 'building_manager', tab: 'service_specialist' },
  { role: 'concierge', tab: 'resident' },
];

export const LIMIT = 15;

const PeopleManagement = () => {
  const canSeeSuperAdmin = useHasPermissions(PERMISSIONS.CAN_SEE_SUPER_ADMIN);
  const canSeeBuildingManager = useHasPermissions(
    PERMISSIONS.CAN_SEE_BUILDING_MANAGER,
  );
  const canSeeConcierge = useHasPermissions(PERMISSIONS.CAN_SEE_CONCIERGE);
  const canSeeMembers = useHasPermissions(PERMISSIONS.CAN_SEE_MEMBERS);
  const canAddUser = useHasPermissions(PERMISSIONS.CAN_ADD_USER);
  const canSeeServiceSpecialists = useHasPermissions(
    PERMISSIONS.CAN_SEE_SERVICE_SPECIALIST,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { message, status, loading, isMessageShow } = useSelector(
    (state: RootState) => state.people,
  );
  const { allUsers, count, notes } = useSelector(
    (state: RootState) => state.people,
  );
  const auth = useSelector((state: RootState) => state.auth);
  const [toastMessage, setToastMessage] = useState<string | JSX.Element>('');
  const [toastStyle, setToastStyle] = useState<MessageInfo>('info');
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | number | null>('');

  const statePanel = location.state as LocationManagementState;
  const [action, setAction] = useState<
    'activate' | 'deactivate' | 'delete' | null
  >(null);

  const [isBuildingManagerModalOpen, setIsBuildingManagerModalOpen] =
    useState<boolean>(false);
  const [isYesNoModalOpen, setIsYesNoModalOpen] = useState<boolean>(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState<boolean>(false);
  const [isInvitationModalOpen, setIsInvitationModalOpen] =
    useState<boolean>(false);
  const [titleDeleteModal, setTitleDeleteModal] = useState<string>('');
  const [questionDeleteModal, setQuestionDeleteModal] = useState<string>('');
  const [userNotes, setUserNotes] = useState<string | null>(null);

  const [searchQuery, setSearchQuery] = useState<any | null>(null);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [activePage, setActivePage] = useState<number>(0);
  const [user, setUser] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [showPagination, setShowPagination] = useState<boolean>(true);

  useEffect(() => {
    if (isMessageShow) {
      setToastMessage(message);
      setIsToastOpen(true);
      status === 'rejected' ? setToastStyle('error') : setToastStyle('success');
      dispatch(showMessage());
    }
  }, [message, loading]);

  useEffect(() => {
    if (!isMessageShow && isToastOpen) {
      setTimeout(() => {
        setToastMessage('');
        setIsToastOpen(false);
      }, 5000);
    }
  }, [isMessageShow, isToastOpen]);

  useEffect(() => {
    const firstTab = StartingPage.filter((elem: any) => {
      return elem.role === auth?.user?.user_role;
    });
    setActiveTab(firstTab[0]?.tab);
    setActivePage(1);

    if (statePanel?.activeManagementTab) {
      setActiveTab(statePanel?.activeManagementTab);
      navigate('', { state: { activeManagementTab: firstTab[0].tab } });
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'service_specialist') {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
    setIsSearchResult(false);
  }, [activeTab]);

  useEffect(() => {
    if (isSearchResult) {
      dispatch(
        getAllPeople({
          role: activeTab,
          limit: LIMIT,
          activePage,
          first_name: searchQuery?.first_name,
          last_name: searchQuery?.last_name,
          email: searchQuery?.email,
        }),
      );
    } else if (activePage > 0 && activeTab !== 'service_specialist') {
      dispatch(
        getAllPeople({
          role: activeTab,
          limit: LIMIT,
          activePage,
        }),
      );
    }
  }, [activePage]);

  const handleChange = (
    event: SyntheticEvent<Element, Event> | null,
    tab: string | number | null,
  ) => {
    setActiveTab(tab);
    setActivePage(1);
    if (tab !== 'service_specialist') {
      dispatch(getAllPeople({ role: tab, limit: LIMIT, activePage: 1 }));
    }
  };

  const handlePageActive = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setActivePage(value);
  };
  //  ==================== search ======================

  const handleSearch = (data: any) => {
    setIsSearchResult(true);
    setSearchQuery(data);
    dispatch(
      getAllPeople({
        role: activeTab,
        limit: LIMIT,
        activePage: 1,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      }),
    );
  };

  //  ==================== action for users ======================
  const handleOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setSelectedUserId(id);
    setCurrentId(id);
    const selectedUser = allUsers.filter((resident: any) => {
      return resident?.user_id === id;
    });
    if (selectedUser[0]) {
      setUser(selectedUser[0]);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (user_id: string) => {
    setAnchorEl(null);
    setCurrentId(null);
  };

  const handleActivate = () => {
    setAnchorEl(null);
    setCurrentId(null);
    setAction('activate');
    setIsYesNoModalOpen(true);
    setTitleDeleteModal('Activate user');
    setQuestionDeleteModal('Do you want to activate user?');
  };

  const handleDeactivate = () => {
    setAnchorEl(null);
    setCurrentId(null);
    setAction('deactivate');
    setIsYesNoModalOpen(true);
    setTitleDeleteModal('Deactivate user');
    setQuestionDeleteModal('Do you want to deactivate user?');
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setCurrentId(null);
    setAction('delete');
    setIsYesNoModalOpen(true);
    setTitleDeleteModal('Delete user');
    setQuestionDeleteModal('Do you want to delete user?');
  };

  const closeModal = () => {
    setIsYesNoModalOpen(false);
  };

  const handleDetailsModal = () => {
    setAnchorEl(null);
    setCurrentId(null);
    if (activeTab === 'building_manager') {
      setIsBuildingManagerModalOpen(true);
    }
  };

  const closeDetailModal = () => {
    setAnchorEl(null);
    setCurrentId(null);
    if (activeTab === 'building_manager') {
      setIsBuildingManagerModalOpen(false);
    }
  };

  const handleEditNote = () => {
    setAnchorEl(null);
    setCurrentId(null);
    setIsNotesModalOpen(true);
    if (activeTab === 'resident') {
      const { resident_id } = user;
      dispatch(getResidentNote(resident_id));
    }
    setUserNotes('');
  };

  const saveResidentNotes = async (note: string) => {
    setIsNotesModalOpen(false);
    if (activeTab === 'resident') {
      await dispatch(
        updateResidentNote({ id: user.resident_id, admin_note: note }),
      );
    }
    await dispatch(getAllPeople({ role: user.role, limit: LIMIT, activePage }));
  };

  const closeEditNoteModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleOpenInvitationModal = () => {
    // TODO update endpoint on backend for response all building without pagination
    dispatch(getBuildings({ page_size: 1000, page: 1 }));
    setIsToastOpen(false);
    setIsInvitationModalOpen(true);
  };

  const handleCloseInvitationModal = () => {
    setIsInvitationModalOpen(false);
    dispatch(getAllPeople({ role: activeTab, limit: LIMIT, activePage }));
  };

  const handleButtonYes = async () => {
    if (action === 'delete') {
      await dispatch(deleteUser({ id: selectedUserId }));
    } else {
      await dispatch(changeStatus({ id: selectedUserId, status: action }));
    }
    closeModal();
    await dispatch(getAllPeople({ role: user.role, limit: LIMIT, activePage }));
  };

  return (
    <>
      <DashboardWrapper>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={9}>
            <Typography variant={'h2'} mb={4}>
              People management
            </Typography>
          </Grid>
          {canAddUser && (
            <Grid item={true} xs={3}>
              <CustomButton
                handleClick={handleOpenInvitationModal}
                title={'Send invitation'}
                variant={'buttonMedium'}
                color={'primary'}
                afterIcon={Send}
              />
            </Grid>
          )}
        </Grid>

        <Tabs value={activeTab} onChange={handleChange}>
          <TabsList>
            {canSeeSuperAdmin && <Tab value={'super_admin'}>Super Admin</Tab>}
            {canSeeBuildingManager && (
              <Tab value={'building_manager'}>Building manager</Tab>
            )}

            {canSeeServiceSpecialists && (
              <Tab value={'service_specialist'}>Service Specialist</Tab>
            )}
            {canSeeConcierge && <Tab value={'concierge'}>Concierge</Tab>}
            {canSeeMembers && <Tab value={'resident'}>Member</Tab>}
          </TabsList>

          {loading && (
            <Box textAlign={'center'} mt={2} mb={2}>
              Loading...
            </Box>
          )}

          {!loading && (
            <>
              <TabPanel value={'super_admin'}>
                <ListSuperAdmin
                  isSearchResult={isSearchResult}
                  searchQuery={searchQuery}
                  superAdmins={allUsers}
                  anchorEl={anchorEl}
                  currentId={currentId}
                  handleSearch={handleSearch}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                  handleDelete={handleDelete}
                />
              </TabPanel>
              <TabPanel value={'service_specialist'}>
                <ServiceSpecialistList />
              </TabPanel>
              <TabPanel value={'building_manager'}>
                <ListBuildingManager
                  isSearchResult={isSearchResult}
                  searchQuery={searchQuery}
                  buildingManagers={allUsers}
                  anchorEl={anchorEl}
                  currentId={currentId}
                  handleSearch={handleSearch}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                  handleDelete={handleDelete}
                  handleDetailsModal={handleDetailsModal}
                />
              </TabPanel>
              <TabPanel value={'concierge'}>
                <ListConcierge
                  isSearchResult={isSearchResult}
                  searchQuery={searchQuery}
                  concierges={allUsers}
                  anchorEl={anchorEl}
                  currentId={currentId}
                  handleSearch={handleSearch}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                  handleDelete={handleDelete}
                />
              </TabPanel>
              <TabPanel value={'resident'}>
                <ListMembers
                  isSearchResult={isSearchResult}
                  searchQuery={searchQuery}
                  residents={allUsers}
                  anchorEl={anchorEl}
                  currentId={currentId}
                  handleSearch={handleSearch}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                  handleDelete={handleDelete}
                  handleEditNote={handleEditNote}
                />
              </TabPanel>
            </>
          )}
        </Tabs>

        {showPagination && !loading && (
          <CustomPagination
            pages={Math.ceil(+count / LIMIT)}
            handleChange={handlePageActive}
            activePage={activePage}
          />
        )}
      </DashboardWrapper>

      <YesNoModal
        isOpen={isYesNoModalOpen}
        title={titleDeleteModal}
        question={questionDeleteModal}
        closeModal={() => closeModal()}
        handleAction={() => handleButtonYes()}
      />

      <CustomModal
        title="Details"
        open={isBuildingManagerModalOpen}
        sizeModal={'medium'}
        closeModal={closeDetailModal}
      >
        <DetailsBuildingManagerModal userInfo={user} />
      </CustomModal>

      <CustomModal
        title={userNotes && userNotes.length > 0 ? 'Edit note' : 'Add note'}
        open={isNotesModalOpen}
        closeModal={closeEditNoteModal}
      >
        <EditNoteModalContent
          notes={notes}
          saveNotes={saveResidentNotes}
          closeModal={closeEditNoteModal}
        />
      </CustomModal>

      <InvitationModal
        closeModal={handleCloseInvitationModal}
        isOpen={isInvitationModalOpen}
      />

      <Toast
        duration={5000}
        isOpen={isToastOpen}
        message={toastMessage}
        toastStyle={toastStyle}
      />
    </>
  );
};

export default PeopleManagement;
