import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;
  padding-left: 250px;
`;

export const Dashboard = styled.div`
  background-color: #f3f3f3;
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
  min-height: 100vh;
`;

export const DashboardContent = styled.div`
  padding: 40px 50px;
`;

export const Sidebar = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 250px;
  z-index: 100;
`;
