import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import CheckmarkIcon from '../../../../assets/icons/check-mark.svg';
import DeactivatedIcon from '../../../../assets/icons/deactivated.svg';
import DeleteIcon from '../../../../assets/icons/delete.svg';
import {
  ENTITY_CHANGE_STATUSES,
  ENTITY_STATUSES,
} from '../../../../components/EntityStatus/EntityStatus.constants';
import { GridMoreDropdown } from '../../../../components/GridElements/GridMoreDropdown/GridMoreDropdown';
import { PERMISSIONS } from '../../../../constants/Roles';
import { OpenModalParams } from '../../../../hooks/useConfirmationModal';
import { useHasPermissions } from '../../../../hooks/useHasPermissions';
import { theme } from '../../../../theme/theme';

interface ServiceSpecialistActionsProps {
  specialist: ServiceSpecialist;
  handleOpenModal: (params: OpenModalParams) => void;
  deleteSpecialist: (id: string) => void;
  changeSpecialistStatus: (id: string, status: ENTITY_CHANGE_STATUSES) => void;
}

export const ServiceSpecialistActions = ({
  specialist,
  handleOpenModal,
  deleteSpecialist,
  changeSpecialistStatus,
}: ServiceSpecialistActionsProps): React.ReactElement => {
  const navigate = useNavigate();
  const canDeleteSpecialist = useHasPermissions(
    PERMISSIONS.CAN_DELETE_SERVICE_SPECIALIST,
  );

  const actions = [
    {
      label: '. . . Details',
      color: theme.palette.statuses.pending,
      callback: () => navigate(`/management/specialist/${specialist.id}`),
    },
    ...(specialist.status === ENTITY_STATUSES.ACTIVE
      ? [
          {
            label: 'Deactivate',
            icon: DeactivatedIcon,
            color: theme.palette.statuses.pending,
            callback: () => {
              if (handleOpenModal) {
                handleOpenModal({
                  title: 'Deactivate specialist',
                  question: 'Do you want to deactivate service specialist?',
                  callback: () =>
                    changeSpecialistStatus(
                      specialist.user_id,
                      ENTITY_CHANGE_STATUSES.DEACTIVATE,
                    ),
                });
              }
            },
          },
        ]
      : []),
    ...(specialist.status === ENTITY_STATUSES.DEACTIVATED
      ? [
          {
            label: 'Activate',
            icon: CheckmarkIcon,
            color: theme.palette.statuses.active,
            callback: () => {
              if (handleOpenModal) {
                handleOpenModal({
                  title: 'Activate specialist',
                  question: 'Do you want to activate service specialist?',
                  callback: () =>
                    changeSpecialistStatus(
                      specialist.user_id,
                      ENTITY_CHANGE_STATUSES.ACTIVATE,
                    ),
                });
              }
            },
          },
        ]
      : []),
    ...(canDeleteSpecialist
      ? [
          {
            label: 'Delete',
            icon: DeleteIcon,
            color: theme.palette.error.main,
            callback: () => {
              if (handleOpenModal) {
                handleOpenModal({
                  title: 'Delete service specialist',
                  question: 'Do you want to delete service specialist?',
                  callback: () => deleteSpecialist(specialist.user_id),
                });
              }
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <GridMoreDropdown items={actions} />
    </>
  );
};
