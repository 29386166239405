export enum ROLE {
  OWNER = 'OWNER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  BUILDING_MANAGER = 'BUILDING_MANAGER',
  CONCIERGE = 'CONCIERGE',
  SITE_MANAGER = 'SITE_MANAGER',
  SERVICE_SPECIALIST = 'SERVICE_SPECIALIST',
}

export const Roles = [
  {
    value: 'owner',
    label: 'Owner',
  },
  {
    value: 'super_admin',
    label: 'Super Admin',
  },
  {
    value: 'building_manager',
    label: 'Building Manager',
  },
  {
    value: 'concierge',
    label: 'Concierge',
  },
  {
    value: 'service_specialist',
    label: 'Service Specialist',
  },
];

export const ROLE_NAMES = {
  owner: 'Owner',
  super_admin: 'Super Admin',
  building_manager: 'Building Manager',
  concierge: 'Concierge',
  service_specialist: 'Service Specialist',
  site_manager: 'Site Manager',
};

export const PERMISSIONS = {
  // permissions for sidebar and header
  CAN_SEE_SIDEBAR_MANAGEMENT: 11,
  CAN_SEE_SIDEBAR_BOOKING_REQUESTS: 12,
  CAN_SEE_SIDEBAR_BILLING: 13,
  CAN_SEE_SIDEBAR_INFO: 14,
  CAN_UPDATE_ACCOUNT_INFO: 15,
  CAN_SEE_NOTIFICATIONS: 16,
  CAN_SEE_SIDEBAR_SCHEDULE: 17,
  CAN_SEE_SIDEBAR_MEMBERS: 19,
  CAN_SEE_SIDEBAR_SERVICES: 20,
  CAN_SEE_SIDEBAR_EVENTS: 21,
  CAN_SEE_SIDEBAR_CONTACTS: 22,

  // permissions for people management
  CAN_SEE_SUPER_ADMIN: 1101,
  CAN_SEE_BUILDING_MANAGER: 1102,
  CAN_SEE_CONCIERGE: 1105,
  CAN_SEE_MEMBERS: 1106,

  CAN_DELETE_SERVICE_SPECIALIST: 1107,
  CAN_SEE_SERVICE_SPECIALIST: 1108,

  CAN_ADD_SUPER_ADMINS: 1110,
  CAN_MANAGE_SUPER_ADMINS: 1111,
  CAN_ADD_BUILDING_MANAGER: 1112,
  CAN_MANAGE_BUILDING_MANAGER: 1113,
  CAN_ADD_CONCIERGE: 1114,
  CAN_MANAGE_CONCIERGE: 1115,
  CAN_ADD_SITE_MANAGER: 1118,
  CAN_MANAGE_SITE_MANAGER: 1119,
  CAN_ADD_SPECIALIST: 1120,
  CAN_MANAGE_SPECIALIST: 1121,
  CAN_ADD_USER: 1122,
  CAN_ADD_SPLIT: 1123,

  // permissions for service booking
  CAN_SEE_SERVICE_REQUEST: 1201,
  CAN_SEE_EVENT_REQUEST: 1202,
  CAN_SEE_CONCIERGE_REQUEST: 1203,
  CAN_SEE_SERVICES: 1204,
  CAN_SEE_EVENTS: 1205,

  CAN_MAKE_EVENT_BOOKING: 1209,
  CAN_MAKE_SERVICE_BOOKING: 1210,
  CAN_SEE_OWNER_SERVICES: 1211,
  CAN_CREATE_SERVICE: 1213,
  CAN_DELETE_SERVICE: 1214,
  CAN_EDIT_SERVICE: 1215,
  CAN_ACTIVATE_DEACTIVATE_SERVICE: 1216,
  CAN_CREATE_EVENT: 1217,
  CAN_USE_EVENT_ACTIONS: 1218,

  CAN_CHANGE_SPECIALIST_ON_RESCHEDULE: 1219,

  // permissions for transactions
  CAN_SEE_TRANSACTIONS: 1301,
  CAN_SEE_PAYROLLS: 1302,
  CAN_SEE_REFUNDS: 1303,
  CAN_SEE_SPECIALIST_PAYROLL: 1304,

  CAN_SEE_PAYROLL_INFO: 1310,
  CAN_EXPORT_CSV: 1311,
  CAN_FILTER_BY_BUILDING: 1312,

  // permissions for info posts
  CAN_CREATE_BUILDING: 1410,
  CAN_MANAGE_BUILDING: 1411,
  CAN_SEE_ON_DEMAND_VIDEO: 1412,

  CAN_USE_PACKAGE_ACTIONS: 1501,
};

export const getPermissions = {
  [ROLE.OWNER]: [
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
    PERMISSIONS.CAN_SEE_SIDEBAR_MANAGEMENT,
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,
    PERMISSIONS.CAN_SEE_SIDEBAR_BILLING,
    PERMISSIONS.CAN_SEE_SIDEBAR_INFO,
    PERMISSIONS.CAN_SEE_SIDEBAR_SERVICES,
    PERMISSIONS.CAN_SEE_SIDEBAR_EVENTS,

    PERMISSIONS.CAN_SEE_SUPER_ADMIN,
    PERMISSIONS.CAN_SEE_BUILDING_MANAGER,
    PERMISSIONS.CAN_SEE_CONCIERGE,
    PERMISSIONS.CAN_SEE_MEMBERS,
    PERMISSIONS.CAN_DELETE_SERVICE_SPECIALIST,
    PERMISSIONS.CAN_SEE_SERVICE_SPECIALIST,

    PERMISSIONS.CAN_ADD_SUPER_ADMINS,
    PERMISSIONS.CAN_MANAGE_SUPER_ADMINS,
    PERMISSIONS.CAN_CREATE_BUILDING,
    PERMISSIONS.CAN_MANAGE_BUILDING,
    PERMISSIONS.CAN_ADD_USER,
    PERMISSIONS.CAN_ADD_SPLIT,
    PERMISSIONS.CAN_ADD_SPECIALIST,

    PERMISSIONS.CAN_SEE_SERVICE_REQUEST,
    PERMISSIONS.CAN_SEE_EVENT_REQUEST,
    PERMISSIONS.CAN_SEE_CONCIERGE_REQUEST,
    PERMISSIONS.CAN_SEE_SERVICES,
    PERMISSIONS.CAN_SEE_EVENTS,

    PERMISSIONS.CAN_MAKE_SERVICE_BOOKING,
    PERMISSIONS.CAN_MAKE_EVENT_BOOKING,
    PERMISSIONS.CAN_SEE_OWNER_SERVICES,
    PERMISSIONS.CAN_CREATE_SERVICE,
    PERMISSIONS.CAN_DELETE_SERVICE,
    PERMISSIONS.CAN_EDIT_SERVICE,
    PERMISSIONS.CAN_ACTIVATE_DEACTIVATE_SERVICE,
    PERMISSIONS.CAN_CREATE_EVENT,
    PERMISSIONS.CAN_USE_EVENT_ACTIONS,
    PERMISSIONS.CAN_CHANGE_SPECIALIST_ON_RESCHEDULE,

    PERMISSIONS.CAN_SEE_TRANSACTIONS,
    PERMISSIONS.CAN_SEE_PAYROLLS,
    PERMISSIONS.CAN_SEE_REFUNDS,
    PERMISSIONS.CAN_EXPORT_CSV,

    PERMISSIONS.CAN_SEE_ON_DEMAND_VIDEO,
    PERMISSIONS.CAN_UPDATE_ACCOUNT_INFO,
    PERMISSIONS.CAN_FILTER_BY_BUILDING,

    PERMISSIONS.CAN_USE_PACKAGE_ACTIONS,
  ],
  [ROLE.SUPER_ADMIN]: [
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
    PERMISSIONS.CAN_SEE_SIDEBAR_MANAGEMENT,
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,
    PERMISSIONS.CAN_SEE_SIDEBAR_BILLING,
    PERMISSIONS.CAN_SEE_SIDEBAR_INFO,
    PERMISSIONS.CAN_SEE_SIDEBAR_SERVICES,
    PERMISSIONS.CAN_SEE_SIDEBAR_EVENTS,

    PERMISSIONS.CAN_SEE_SUPER_ADMIN,
    PERMISSIONS.CAN_SEE_BUILDING_MANAGER,
    PERMISSIONS.CAN_SEE_CONCIERGE,
    PERMISSIONS.CAN_SEE_MEMBERS,
    PERMISSIONS.CAN_DELETE_SERVICE_SPECIALIST,
    PERMISSIONS.CAN_SEE_SERVICE_SPECIALIST,

    PERMISSIONS.CAN_ADD_SUPER_ADMINS,
    PERMISSIONS.CAN_MANAGE_SUPER_ADMINS,
    PERMISSIONS.CAN_CREATE_BUILDING,
    PERMISSIONS.CAN_MANAGE_BUILDING,
    PERMISSIONS.CAN_ADD_USER,
    PERMISSIONS.CAN_ADD_SPLIT,
    PERMISSIONS.CAN_ADD_SPECIALIST,

    PERMISSIONS.CAN_SEE_SERVICE_REQUEST,
    PERMISSIONS.CAN_SEE_EVENT_REQUEST,
    PERMISSIONS.CAN_SEE_CONCIERGE_REQUEST,
    PERMISSIONS.CAN_SEE_SERVICES,
    PERMISSIONS.CAN_SEE_EVENTS,

    PERMISSIONS.CAN_MAKE_SERVICE_BOOKING,
    PERMISSIONS.CAN_MAKE_EVENT_BOOKING,
    PERMISSIONS.CAN_SEE_OWNER_SERVICES,
    PERMISSIONS.CAN_CREATE_SERVICE,
    PERMISSIONS.CAN_DELETE_SERVICE,
    PERMISSIONS.CAN_EDIT_SERVICE,
    PERMISSIONS.CAN_ACTIVATE_DEACTIVATE_SERVICE,
    PERMISSIONS.CAN_CREATE_EVENT,
    PERMISSIONS.CAN_USE_EVENT_ACTIONS,
    PERMISSIONS.CAN_CHANGE_SPECIALIST_ON_RESCHEDULE,

    PERMISSIONS.CAN_SEE_TRANSACTIONS,
    PERMISSIONS.CAN_SEE_PAYROLLS,
    PERMISSIONS.CAN_SEE_REFUNDS,
    PERMISSIONS.CAN_EXPORT_CSV,

    PERMISSIONS.CAN_SEE_ON_DEMAND_VIDEO,
    PERMISSIONS.CAN_UPDATE_ACCOUNT_INFO,
    PERMISSIONS.CAN_FILTER_BY_BUILDING,

    PERMISSIONS.CAN_USE_PACKAGE_ACTIONS,
  ],
  [ROLE.BUILDING_MANAGER]: [
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
    PERMISSIONS.CAN_SEE_SIDEBAR_MANAGEMENT,
    PERMISSIONS.CAN_SEE_SIDEBAR_BILLING,
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,
    PERMISSIONS.CAN_SEE_SIDEBAR_INFO,
    PERMISSIONS.CAN_SEE_SIDEBAR_SERVICES,
    PERMISSIONS.CAN_SEE_SIDEBAR_EVENTS,

    PERMISSIONS.CAN_SEE_SERVICE_SPECIALIST,
    PERMISSIONS.CAN_SEE_CONCIERGE,
    PERMISSIONS.CAN_SEE_MEMBERS,
    PERMISSIONS.CAN_ADD_USER,
    PERMISSIONS.CAN_MANAGE_BUILDING,
    PERMISSIONS.CAN_MANAGE_BUILDING_MANAGER,

    PERMISSIONS.CAN_MAKE_SERVICE_BOOKING,
    PERMISSIONS.CAN_MAKE_EVENT_BOOKING,
    PERMISSIONS.CAN_CREATE_SERVICE,
    PERMISSIONS.CAN_DELETE_SERVICE,
    PERMISSIONS.CAN_EDIT_SERVICE,
    PERMISSIONS.CAN_ACTIVATE_DEACTIVATE_SERVICE,
    PERMISSIONS.CAN_CREATE_EVENT,
    PERMISSIONS.CAN_USE_EVENT_ACTIONS,
    PERMISSIONS.CAN_CHANGE_SPECIALIST_ON_RESCHEDULE,

    PERMISSIONS.CAN_SEE_SERVICE_REQUEST,
    PERMISSIONS.CAN_SEE_EVENT_REQUEST,
    PERMISSIONS.CAN_SEE_EVENTS,
    PERMISSIONS.CAN_SEE_SERVICES,

    PERMISSIONS.CAN_SEE_TRANSACTIONS,
    PERMISSIONS.CAN_SEE_PAYROLLS,
    PERMISSIONS.CAN_SEE_REFUNDS,

    PERMISSIONS.CAN_CREATE_BUILDING,
    PERMISSIONS.CAN_MAKE_SERVICE_BOOKING,
    PERMISSIONS.CAN_UPDATE_ACCOUNT_INFO,
  ],
  [ROLE.CONCIERGE]: [
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
    PERMISSIONS.CAN_SEE_SIDEBAR_MANAGEMENT,
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,

    PERMISSIONS.CAN_SEE_MEMBERS,

    PERMISSIONS.CAN_SEE_CONCIERGE_REQUEST,
  ],
  [ROLE.SERVICE_SPECIALIST]: [
    PERMISSIONS.CAN_SEE_NOTIFICATIONS,
    PERMISSIONS.CAN_SEE_SIDEBAR_SCHEDULE,
    PERMISSIONS.CAN_SEE_SIDEBAR_MEMBERS,
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,
    PERMISSIONS.CAN_SEE_SIDEBAR_BILLING,
    PERMISSIONS.CAN_SEE_SIDEBAR_EVENTS,
    PERMISSIONS.CAN_SEE_SIDEBAR_SERVICES,
    PERMISSIONS.CAN_SEE_SIDEBAR_CONTACTS,
    PERMISSIONS.CAN_SEE_SERVICE_REQUEST,
    PERMISSIONS.CAN_SEE_EVENT_REQUEST,
    PERMISSIONS.CAN_UPDATE_ACCOUNT_INFO,
    PERMISSIONS.CAN_SEE_TRANSACTIONS,
    PERMISSIONS.CAN_SEE_SPECIALIST_PAYROLL,
    PERMISSIONS.CAN_SEE_REFUNDS,
  ],
};
