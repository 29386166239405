import * as yup from 'yup';

export const packageEditorSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('Package Name is required!')
    .min(3, 'Package Name must be at least 3 characters')
    .max(40, 'Package Name must not exceed 40 characters')
    .matches(/.*\S.*/, 'Package Name must not be only spaces'),
  price: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().nullable().required('Package price required!');
    }

    return yup
      .number()
      .nullable()
      .typeError('Price has to be numbers only')
      .required('Package price required!')
      .min(0, 'Package price must be more than or equal to 0')
      .max(100000, 'Package price must be less than or equal to 100000')
      .test(
        'maxDigitsAfterDecimal',
        'Package price should be with max 2 decimal places',
        (number: any) => /^\d+(\.\d{1,2})?$/.test(number),
      );
  }),
  sessions_amount: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().nullable().required('Sessions number is required!');
    }

    return yup
      .number()
      .nullable()
      .typeError('Sessions number has to be numbers only')
      .required('Sessions number required!')
      .min(1, 'Sessions number must be more than or equal to 1')
      .max(999, 'Sessions number must be less than or equal to 999')
      .integer('Sessions number has to be integer number.');
  }),
});
