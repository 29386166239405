import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import messages from 'constants/messages';
import { IAccountInfo } from 'types';
import http from 'utils/http';

export const getVerificationCode = createAsyncThunk(
  'account/getValidationCode',
  async (email: string, thunkAPI: any) => {
    try {
      const url = endpoints.auth.sendVerificationCode;
      const response = await http.post(url, email);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

interface SendToVerifyCodeProps {
  email: string;
  code: string;
}

export const sendToVerifyCode = createAsyncThunk(
  'account/sendToVerifyCode',
  async ({ code, email }: SendToVerifyCodeProps, thunkAPI: any) => {
    try {
      const url = endpoints.auth.sendToVerifyCode;
      const response = await http.post(url, {
        verification_code: code,
        new_email: email,
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

interface SendToAcceptEmailProps {
  email: string;
  code: string;
}

export const sendToAcceptEmail = createAsyncThunk(
  'account/sendToAcceptEmail',
  async ({ code, email }: SendToAcceptEmailProps, thunkAPI: any) => {
    try {
      const url = endpoints.auth.sendToAcceptEmail;
      const response = await http.post(url, {
        verification_code: code,
        new_email: email,
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

interface SendToChangePasswordProps {
  oldPassword: string;
  newPassword: string;
}

export const sendToChangePassword = createAsyncThunk(
  'account/sendToChangePassword',
  async (
    { oldPassword, newPassword }: SendToChangePasswordProps,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.auth.sendToChangePassword;
      const response = await http.post(url, {
        old_password: oldPassword,
        new_password: newPassword,
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

export const updateAccountInfo = createAsyncThunk(
  'account/updateAccountInfo',
  async (
    { bio_info, first_name, last_name, position, phone_number }: IAccountInfo,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.auth.updateAccountInfo;
      const response = await http.put(
        url,
        {
          bio_info,
          first_name,
          last_name,
          position,
          phone_number,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

export const updateAccountImage = createAsyncThunk(
  'account/updateAccountImage',
  async (account_icon: FormData, thunkAPI: any) => {
    try {
      const url = endpoints.auth.updateAccountInfo;
      const response = await http.put(url, account_icon, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

export const deleteAccount = createAsyncThunk(
  'account/deleteAccount',
  async (id: string, thunkAPI: any) => {
    try {
      const url = endpoints.auth.deleteAccount(id);
      const response = await http.delete(url);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);
