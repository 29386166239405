import * as yup from 'yup';

export const SpecialistEditorSchema = yup.object({
  first_name: yup
    .string()
    .required('First name is required!')
    .min(3, 'First name must be at least 3 characters')
    .max(40, 'First name must not exceed 40 characters'),
  last_name: yup
    .string()
    .required('Last Name is required!')
    .min(3, 'Last Name must be at least 3 characters')
    .max(40, 'Last Name must not exceed 40 characters'),
  bio_info: yup
    .string()
    .required('Bio info is required!')
    .min(3, 'Bio info must be at least 3 characters')
    .max(2000, 'Bio info must not exceed 2000 characters'),
  profession: yup
    .string()
    .required('Position is required!')
    .min(3, 'Position must be at least 3 characters')
    .max(40, 'Position must not exceed 40 characters'),
  email: yup
    .string()
    .required('Email is required please!')
    .email('Please enter a valid email format!'),
  phone_number: yup
    .string()
    .required('Phone number is required!')
    .length(10 + 2, 'Phone number must be 10 characters'),
  building_codes: yup
    .array()
    .required()
    .min(1, 'At least one building should be chosen'),
});
