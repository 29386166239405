import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email format!')
    .required('Email is required please!'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required!'),
});

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email format!')
    .required('Email is required please!'),
});

export const changePasswordSchema = yup.object({
  current_password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(40, 'Password must not exceed 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12})/,
      'Must contain at least 8 characters, one uppercase, one lowercase and one number',
    )
    .required('Password is required please!'),
  new_password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must not exceed 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12})/,
      'Must contain at least 8 characters, one uppercase, one lowercase and one number',
    )
    .required('Password is required please!'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Password is required!'),
});

export const confirmPasswordSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must not exceed 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12})/,
      'Must contain at least 8 characters, one uppercase, one lowercase and one number',
    )
    .required('Password is required please!'),
  confirm_password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must not exceed 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12})/,
      'Must contain at least 8 characters, one uppercase, one lowercase and one number',
    )
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password is required!'),
});

export const getPersonalInfoSchema = (invitationStep: number) => {
  return yup.object({
    position: yup
      .string()
      .required('Position is required!')
      .min(3, 'Position must be at least 3 characters')
      .max(40, 'Position must not exceed 40 characters')
      .matches(/^(?!\s+$)/, 'Position must not contain only spaces'),
    phone_number: yup
      .string()
      .min(12, 'Phone Number must be at least 10 characters')
      .required('Phone number is required!'),
    bio_info: yup.string().when('position', {
      is: () => invitationStep === 3,
      then: yup
        .string()
        .required('Bio info is required!')
        .max(2000, 'Bio info must not exceed 2000 characters'),
    }),
  });
};

export const invitationSchema = yup.object({
  first_name: yup.string().required('First name is required!'),
  last_name: yup.string().required('Last name is required!'),
  email: yup
    .string()
    .email('Please enter a valid email format!')
    .required('Email is required please!'),
  role: yup.string().required('Role is required!'),
  code: yup
    .array()
    .nullable()
    .when('role', {
      is: (roleValue: string) =>
        Boolean(roleValue) &&
        !['site_manager', 'super_admin'].includes(roleValue),
      then: () =>
        yup
          .array()
          .nullable()
          .min(1, 'Building is required!')
          .required('Building is required!'),
      otherwise: () => yup.array().nullable(),
    }),
  building_id: yup
    .string()
    .nullable()
    .when('role', {
      is: (roleValue: string) => roleValue === 'site_manager',
      then: () => yup.string().nullable().required('Building is required!'),
      otherwise: () => yup.string().nullable(),
    }),
  service_provider: yup
    .string()
    .nullable()
    .when(['role', 'building_id'], {
      is: (roleValue: string, buildingValue: string) =>
        roleValue === 'site_manager' && Boolean(buildingValue),
      then: () =>
        yup.string().nullable().required('Service Provider is required!'),
      otherwise: () => yup.string().nullable(),
    }),
  owner_note: yup.string().required('Owner Note is required!'),
});

export const invitationBuildingManagerSchema = yup.object({
  first_name: yup.string().required('First name is required!'),
  last_name: yup.string().required('Last name is required!'),
  email: yup
    .string()
    .email('Please enter a valid email format!')
    .required('Email is required please!'),
});

export const createBuildingMainInfoSchema = yup.object({
  building_name: yup
    .string()
    .min(3, 'Building name must have min 3 symbols!')
    .max(40, 'Building name max length 40 symbols!')
    .required('Building name is required!'),
  building_address: yup.string().required('Building address is required'),
  building_description: yup
    .string()
    .required('Building description is required'),
  phone_number: yup
    .string()
    .min(12, 'Phone Number must be at least 10 characters')
    .max(12, 'Phone Number must be at least 10 characters')
    .required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter a valid email format!')
    .required('Email is required!'),
});

export const createBuildingWorkingHoursSchema = yup.object({
  monday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Monday is required!'),
  tuesday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Tuesday is required!'),
  wednesday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Wednesday is required'),
  thursday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Thursday is required'),
  friday: yup.string().max(20, 'Max 20 symbols').required('Friday is required'),
  saturday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Saturday is required'),
  sunday: yup
    .string()
    .max(20, 'Max 20 symbols')
    .required('Sunday is required!'),
});

export const createBuildingRulesSchema = yup.object({
  title: yup.string().required('Title is required!'),
  description: yup.string().required('Description is required'),
});

export const SearchSchema = yup.object({
  last_name: yup.string(),
  first_name: yup.string(),
  email: yup.string(),
});

export const createBlogPostSchema = yup.object({
  title: yup.string().required('Title is required!'),
  description: yup.string().required('Description is required'),
});

export const infoBlogPostSchema = yup.object({
  title: yup.string().required('Title is required!'),
  description: yup.string().required('Description is required'),
});

export const updateAccountInfoSchema = yup.object({
  first_name: yup
    .string()
    .required('First name is required!')
    .min(3, 'First name must be at least 3 characters')
    .max(40, 'First name must not exceed 40 characters'),
  last_name: yup
    .string()
    .required('Last Name is required!')
    .min(3, 'Last Name must be at least 3 characters')
    .max(40, 'Last Name must not exceed 40 characters'),
  bio_info: yup
    .string()
    .required('Bio info is required!')
    .min(3, 'Bio info must be at least 3 characters')
    .max(2000, 'Bio info must not exceed 2000 characters'),
  phone_number: yup
    .string()
    .min(12, 'Phone Number must be at least 10 characters')
    .max(12, 'Phone Number must be at least 10 characters')
    .required('Phone number is required!'),
  position: yup
    .string()
    .required('Position is required!')
    .min(3, 'Last Name must be at least 3 characters')
    .max(40, 'Last Name must not exceed 40 characters'),
});

export const updateAccountInfoByBuildingManagerSchema = yup.object({
  first_name: yup.string().required('First name is required!'),
  last_name: yup.string().required('Last name is required!'),
  phone_number: yup
    .string()
    .min(12, 'Phone Number must be at least 10 characters')
    .required('Phone number is required!'),
  bio_info: yup.string().max(200),
});

export const updateAccountInfoAdminsSchema = yup.object({
  first_name: yup.string().required('First name is required!'),
  last_name: yup.string().required('Last name is required!'),
});
