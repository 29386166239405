import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
`;

export const UploadSection = styled.div`
  margin-right: 50px;
`;

export const Picture = styled.img`
  width: 355px;
  border-radius: 8px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  padding-top: 70px;
`;

export const FormItem = styled.div`
  margin-bottom: 40px;

  .MuiAlert-root {
    margin-top: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  padding-top: 70px;
  margin-left: 33px;

  button {
    margin-right: 10px;
  }
`;
