/* tslint:disable typedef */
import { createSlice } from '@reduxjs/toolkit';

import { BuildingManagerState } from 'types';

import { addManagerToBuilding } from './building-managers.actions';

export const initialState: BuildingManagerState = Object.freeze({
  currentManager: {},
  loading: false,
  error: null,
  status: 'idle',
});

const buildingManagerSlice = createSlice({
  name: 'buildingManager',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addManagerToBuilding.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(addManagerToBuilding.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(addManagerToBuilding.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });
    // ----------------------------------------------------------------
  },
});

// The reducer
export default buildingManagerSlice.reducer;
