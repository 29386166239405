import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import {
  acceptEventRequest,
  declineEventRequest,
  getEventRequests,
} from './eventsRequests.actions';
import { EventRequestsList, EventRequestsState } from './eventsRequests.types';

const EVENTS_REQUESTS_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

export const initialState: EventRequestsState = Object.freeze({
  eventRequests: EVENTS_REQUESTS_LIST_DEFAULT,
  currentEventRequest: null,
  error: '',
  loading: false,
  status: STORE_STATUSES.IDLE,
});

export const eventRequestsSlice = createSlice({
  name: 'eventRequests',
  initialState,
  reducers: {
    setCurrentEventRequest: (state, action) => {
      state.currentEventRequest = action.payload;
    },
    clearEventRequest: (state) => {
      state.currentEventRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventRequests.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(
      getEventRequests.fulfilled,
      (state, action: PayloadAction<EventRequestsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.eventRequests = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(getEventRequests.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(acceptEventRequest.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(acceptEventRequest.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(acceptEventRequest.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(declineEventRequest.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(declineEventRequest.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(declineEventRequest.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });
  },
});

export const { setCurrentEventRequest, clearEventRequest } =
  eventRequestsSlice.actions;
export default eventRequestsSlice.reducer;
