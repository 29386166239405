import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components/index';

import { FormSchemaAddRecording } from 'constants/schemas/events';
import { IAddRecordingForm } from 'store/events/events.types';
import { Event } from 'store/events/events.types';

import CustomModal from '../CustomModal/CustomModal';
import { TextInput } from '../Inputs/TextInput/TextInput';

const useStyles = makeStyles({
  sendButton: {
    marginRight: '16px',
  },
  modalTitleLabel: {
    background: '#2196F3',
    padding: '0px 5px',
    borderRadius: '15px',
    fontSize: '12px',
    color: '#fff',
    minWidth: '40px',
    textAlign: 'center',
    marginLeft: '10px',
  },
  modalTitle: {
    display: 'flex',
  },
});

interface InvitationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  saveEvent: (values: any) => Promise<void>;
  event: Event;
}

export const AddRecordingEventModal = ({
  isOpen,
  closeModal,
  event,
  saveEvent,
}: InvitationModalProps) => {
  const classes = useStyles();
  const [form, setForm] = useState<IAddRecordingForm>({ link: '' });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchemaAddRecording),
  });

  const onSubmit = () => {
    saveEvent({
      link: form.link,
    });
    closeModal();
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent,
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setForm({
      link: event?.link || '',
    });
  }, []);

  const modalTitle = <Box className={classes.modalTitle}>Add Recording</Box>;

  return (
    <CustomModal title={modalTitle} open={isOpen} closeModal={closeModal}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '400px',
          overflowY: 'auto',
          padding: '0 15px',
        }}
      >
        <FormProvider {...methods}>
          <Box mb={3}>
            <br />
            <TextInput
              name={'link'}
              value={form?.link}
              label={'Link'}
              color={'black'}
              handleChange={handleChange}
            />
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
            <CustomButton
              title="Save"
              variant={'buttonMedium'}
              additionalClass={classes.sendButton}
              handleClick={methods.handleSubmit(onSubmit)}
            />
            <CustomButton
              title={'Cancel'}
              color={'gray'}
              variant={'buttonMedium'}
              handleClick={closeModal}
            />
          </Box>
        </FormProvider>
      </Box>
    </CustomModal>
  );
};
