// tslint:disable:no-console
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { firebaseApp } from 'services/firebase';

import { vapidKeyPublic } from 'constants/fcm-config';
import { EliteRoute } from 'routes/Router';
import { updateAccessToken } from 'store/auth/auth.actions';
import { loginFCN } from 'store/fcm/fcm.actions';
import { addNotifications, setToken } from 'store/fcm/fcm.slice';
import { AppDispatch } from 'store/index';
import { RootState } from 'store/rootReducer';

const messaging = getMessaging(firebaseApp);

function EliteApp() {
  // 60000 = 60 second = 1min
  // 840000 = 14 minutes
  // 3600000 = 60 minutes = 1h
  const TIME = 3600000;
  const dispatch = useDispatch<AppDispatch>();
  const { timeRefresh, user } = useSelector((state: RootState) => state.auth);
  const refreshToken = user ? user.refresh : '';

  const [currentToken, setCurrentToken] = useState<string | null>(null);

  useEffect(() => {
    getCurrentToken();
  }, []);

  useEffect(() => {
    if (currentToken) {
      dispatch(setToken(currentToken));
      setFCMDevice();
    }
  }, [currentToken, user]);

  onMessage(messaging, (payload: any) => {
    console.log('test = ', payload);
    dispatch(addNotifications(payload.data));
  });

  const setFCMDevice = () => {
    if (user) {
      dispatch(
        loginFCN({
          name: `${user?.first_name} ${user?.last_name}`,
          active: true,
          // @ts-ignore: Unreachable code error
          user_id: `${user.user_id}`,
          registration_id: currentToken,
          type: 'web',
        }),
      );
    }
  };

  const getCurrentToken = () => {
    getToken(messaging, { vapidKey: vapidKeyPublic })
      .then((token: string) => {
        if (token) {
          // console.log('current token for client: ', token);
          setCurrentToken(token);

          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            'No registration token available. Request permission to generate one.',
          );
          // setCurrentToken(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err: any) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  };

  // =====================================================================

  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      const currTime = new Date().getTime();
      setCurrentTime(currTime);
    }, 600000);

    if (timeRefresh) {
      const diff = Math.abs(
        currentTime - Date.parse(new Date(timeRefresh as Date).toISOString()),
      );

      if (diff > TIME) {
        dispatch(updateAccessToken(refreshToken as string));
      }
    }
  }, [currentTime]);

  return (
    <>
      <EliteRoute />
    </>
  );
}

export default EliteApp;
