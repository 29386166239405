import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Plus } from 'assets/icons/Plus/Plus';

import { CustomButton } from 'components';
import { DeletePackageModal } from 'components/DeletePackageModal/DeletePackageModal';

import { deletePackage } from 'store/packages/packages.actions';
import { RootState } from 'store/rootReducer';

import CustomModal from '../../../components/CustomModal/CustomModal';
import { OverlayLoader } from '../../../components/Loaders/OverlayLoader';
import { PERMISSIONS } from '../../../constants/Roles';
import { useAppDispatch } from '../../../hooks/useAppSelector';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { Package } from '../../../store/packages/packages.types';
import { PackageEditor } from '../Editor/PackageEditor';
import { PackageCard } from '../PackageCard/PackageCard';

export const PackageList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { packages, loading } = useSelector(
    (state: RootState) => state.packages,
  );
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const dispatch = useAppDispatch();
  const { id: service_id } = useParams();
  const canUsePackagesAction = useHasPermissions(
    PERMISSIONS.CAN_USE_PACKAGE_ACTIONS,
  );

  const deletePackages = () => {
    dispatch(deletePackage({ service_id: service_id as string, id: deleteId }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Package was deleted.', { variant: 'success' });
      });
    setIsDeleteModalOpened(false);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpened(false);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpened(false);
  };

  const openModal = (id: string) => {
    setIsDeleteModalOpened(true);
    setDeleteId(id);
  };

  return (
    <Fragment>
      {loading && <OverlayLoader />}
      {canUsePackagesAction && (
        <CustomButton
          title="Add new package"
          variant="buttonMedium"
          color="primary"
          beforeIcon={<Plus color="black" />}
          handleClick={() => setIsCreateModalOpened(true)}
        />
      )}
      <Grid container={true} spacing={3} mt={3}>
        {Boolean(packages.length) &&
          packages.map((item: Package) => (
            <PackageCard
              key={item.id}
              package={item}
              openDeleteModal={openModal}
            />
          ))}
      </Grid>
      <CustomModal
        title="Create a package"
        open={isCreateModalOpened}
        closeModal={handleCloseCreateModal}
      >
        <PackageEditor closeModal={handleCloseCreateModal} />
      </CustomModal>
      <DeletePackageModal
        closeModal={handleCloseDeleteModal}
        isOpen={isDeleteModalOpened}
        deletePackages={deletePackages}
      />
    </Fragment>
  );
};
