import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '../../../CustomButton/CustomButton';

const useStyles = makeStyles({
  logoutButton: {
    marginRight: '16px',
  },
});

interface LogOutModalContent {
  closeModal: () => void;
  logOut: () => void;
}

export const LogOutModalContent = ({
  closeModal,
  logOut,
}: LogOutModalContent) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={'body1'}>
        Are you sure you would like to log out? You will need to sign in again
        then.
      </Typography>
      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={'Log out'}
          variant={'buttonMedium'}
          additionalClass={classes.logoutButton}
          handleClick={logOut}
        />
        <CustomButton
          title={'Cancel'}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={closeModal}
        />
      </Box>
    </>
  );
};
