import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '../CustomButton/CustomButton';
import CustomModal from '../CustomModal/CustomModal';

const useStyles = makeStyles({
  sendButton: {
    marginRight: '16px',
  },
});

interface CreatePackageModalProps {
  isOpen: boolean;
  closeModal: () => void;
  deletePackages: () => void;
}

export const DeletePackageModal = ({
  isOpen,
  closeModal,
  deletePackages,
}: CreatePackageModalProps) => {
  const classes = useStyles();

  return (
    <CustomModal
      title={'Delete a package'}
      open={isOpen}
      closeModal={closeModal}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant={'body1'}>
          Are you sure you want to delete this package from service?
        </Typography>
      </Box>

      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={'Delete'}
          variant={'buttonMedium'}
          additionalClass={classes.sendButton}
          handleClick={deletePackages}
        />
        <CustomButton
          title={'Cancel'}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={closeModal}
        />
      </Box>
    </CustomModal>
  );
};
