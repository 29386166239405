import { useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getMonth, getYear } from 'date-fns';

import { FiltersParamsTypes } from '../GridFilters.types';

interface YearMonthFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const YearMonthFilter = ({ onFiltersChange }: YearMonthFilterProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'month']}
        label="Year and Month"
        minDate={new Date('2022-02-01')}
        maxDate={new Date('2032-01-01')}
        value={selectedDate}
        onChange={(value: Date | null) => {
          setSelectedDate(value);
          onFiltersChange({
            year: getYear(value as Date),
            month: getMonth(value as Date) + 1,
          });
        }}
      />
    </LocalizationProvider>
  );
};
