import NoItemIcon from 'assets/no-items.svg';

import * as S from './NoItems.styled';

interface NoItemsProps {
  text: string;
  subText?: string;
}

export const NoItems = ({ text, subText }: NoItemsProps) => {
  return (
    <S.Container>
      <img src={NoItemIcon} alt="" />
      <S.Text>{text}</S.Text>
      <S.SubText>{subText}</S.SubText>
    </S.Container>
  );
};
