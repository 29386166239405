import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const TopButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > button {
    margin-left: 15px;
  }
`;
