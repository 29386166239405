import { ReactNode } from 'react';

import { AppHeader, AppSidebar } from 'layouts';

import * as S from './DashboardWrapper.styled';

interface DashboardWrapperProps {
  children: ReactNode;
}

export const DashboardWrapper = ({ children }: DashboardWrapperProps) => {
  return (
    <S.Container className="root-container">
      <S.Sidebar>
        <AppSidebar />
      </S.Sidebar>
      <S.Dashboard>
        <AppHeader />
        <S.DashboardContent>{children}</S.DashboardContent>
      </S.Dashboard>
    </S.Container>
  );
};
