import styled from '@emotion/styled/macro';

export const LoaderSpinner = styled.div<{ size: number; color: string }>`
  width: ${(props: any) => props.size}px;
  height: ${(props: any) => props.size}px;
  border: 5px solid ${(props: any) => props.color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  z-index: 11;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
