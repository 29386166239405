import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  EVENT_FORMAT_SELECT_ITEMS,
  EVENT_FORMATS,
} from '../../../../modules/Events/Events.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface EventFormatFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const EventFormatFilter = ({
  onFiltersChange,
}: EventFormatFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="event-format-filter">Format</InputLabel>
      <Select
        labelId="event-format-filter"
        defaultValue={EVENT_FORMATS.ALL}
        label="Format"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            event_format: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        {EVENT_FORMAT_SELECT_ITEMS.map((format) => (
          <MenuItem key={format.value} value={format.value}>
            {format.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
