import styled from '@emotion/styled/macro';

export const Container = styled.div`
  padding-top: 30px;
`;

export const TotalSection = styled.div`
  margin-top: 16px;
  border-radius: 30px;
  background-color: white;
  padding: 16px 24px;
  font-size: 18px;
  font-family: 'GraphikMedium', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalSplits = styled.div`
  display: flex;
  font-size: 16px;
`;

export const TotalSplit = styled.div`
  display: flex;

  &:first-of-type {
    margin-right: 48px;
  }
`;

export const TotalSplitLabel = styled.div`
  margin-right: 12px;
  font-family: 'GraphikRegular', sans-serif;
`;
