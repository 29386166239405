import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;
`;

export const DeletePackage = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const BoxImage = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
`;

export const Name = styled.div`
  font-family: 'RalewaySemibold', sans-serif;
  font-size: 16px;
  margin-bottom: 16px;
  color: ${(props: any) => props.theme.palette.primary.main};
`;

export const SessionsCount = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 14px;
  padding-bottom: 3px;
  color: ${(props: any) => props.theme.palette.text.disabled};
`;

export const Price = styled.div`
  position: absolute;
  bottom: 12px;
  right: 16px;
`;
