import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { getBuildingById } from '../buildings/buildings.actions';

export const addManagerToBuilding = createAsyncThunk(
  'services/addManagerToBuilding',
  async ({ id, buildingId }: any, thunkAPI: any) => {
    try {
      const url = endpoints.buildingManagers.addManagerToBuilding(id);
      const response = await http.post(url, buildingId);
      await thunkAPI.dispatch(getBuildingById(buildingId.building_id));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);
