import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import logo from 'assets/EliteLogo.png';
import { ReactComponent as Contacts } from 'assets/icons/bag-white.svg';
import { ReactComponent as Billing } from 'assets/icons/billing.svg';
import { ReactComponent as BookingRequests } from 'assets/icons/booking-requests.svg';
import { ReactComponent as Event } from 'assets/icons/event.svg';
import { ReactComponent as Posts } from 'assets/icons/info-posts.svg';
import { ReactComponent as People } from 'assets/icons/people.svg';
import { ReactComponent as Service } from 'assets/icons/service.svg';

import { useHasPermissions } from 'hooks/useHasPermissions';

import { PERMISSIONS } from 'constants/Roles';
import { darkTheme } from 'theme/theme';

const useStyles = makeStyles((theme: any) => ({
  menu: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#000000',
  },
  menuItem: {
    margin: '20px!important',
    '& svg': {
      marginRight: '10px',
      stroke: 'white',
    },

    '&.contacts-menu-item': {
      position: 'absolute',
      bottom: '40px',
      width: '100%',
    },
  },
  activeLink: {
    '&:before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      width: '3px',
      height: '100%',
      background: '#d5a419;',
    },
    '& svg': {
      '&.contacts-icon, &.contacts-icon *': {
        fill: 'none',
        stroke: '#d5a419',
      },
      fill: '#d5a419',
      stroke: '#d5a419',
    },
    '& span': {
      color: '#d5a419',
    },
  },
  menuLink: {
    textDecoration: 'none',
    color: '#ffffff',
    padding: '16px 22px',
  },
  logo: {
    margin: '1.5rem',
    width: '120px',
    height: 'auto',
  },
}));

export const AppSidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeLink, setActiveLink] = useState<string>(location.pathname);
  const canSeeSidebarManagement = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_MANAGEMENT,
  );
  const canSeeSidebarBookingRequests = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_BOOKING_REQUESTS,
  );
  const canSeeSidebarBilling = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_BILLING,
  );
  const canSeeSidebarInfo = useHasPermissions(PERMISSIONS.CAN_SEE_SIDEBAR_INFO);

  const canSeeSidebarSchedule = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_SCHEDULE,
  );

  const canSeeSidebarMembers = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_MEMBERS,
  );

  const canSeeSidebarServices = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_SERVICES,
  );

  const canSeeSidebarEvents = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_EVENTS,
  );

  const canSeeContacts = useHasPermissions(
    PERMISSIONS.CAN_SEE_SIDEBAR_CONTACTS,
  );

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box className={classes.menu}>
          <img alt="Elite Logo" className={classes.logo} src={logo} />
          <Paper sx={{ backgroundColor: 'black', height: '100%' }}>
            <MenuList sx={{ backgroundColor: 'black', height: '100%' }}>
              {canSeeSidebarSchedule && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/schedule');
                    navigate('/schedule');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/schedule'),
                  })}
                >
                  <People />
                  <Typography variant={'title1'}>Schedule</Typography>
                </MenuItem>
              )}
              {canSeeSidebarManagement && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/management');
                    navigate('/management');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/management'),
                  })}
                >
                  <People />
                  <Typography variant={'title1'}>People management</Typography>
                </MenuItem>
              )}
              {canSeeSidebarServices && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/services');
                    navigate('/services');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/services'),
                  })}
                >
                  <Service />
                  <Typography variant={'title1'}>Services</Typography>
                </MenuItem>
              )}
              {canSeeSidebarEvents && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/events');
                    navigate('/events');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/events'),
                  })}
                >
                  <Event />
                  <Typography variant={'title1'}>Events</Typography>
                </MenuItem>
              )}
              {canSeeSidebarBookingRequests && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/booking-requests');
                    navigate('/booking-requests');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]:
                      activeLink.includes('/booking-requests'),
                  })}
                >
                  <BookingRequests />
                  <Typography variant={'title1'}>Booking requests</Typography>
                </MenuItem>
              )}
              {canSeeSidebarBilling && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/billings');
                    navigate('/billings');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/billings'),
                  })}
                >
                  <Billing />
                  <Typography variant={'title1'}>Billings</Typography>
                </MenuItem>
              )}
              {canSeeSidebarMembers && (
                <MenuItem
                  onClick={() => {
                    setActiveLink('/members');
                    navigate('/members');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/members'),
                  })}
                >
                  <People />
                  <Typography variant={'title1'}>Members</Typography>
                </MenuItem>
              )}
              {canSeeSidebarInfo && (
                <MenuItem
                  onClick={() => {
                    navigate('/info');
                    setActiveLink('/info');
                  }}
                  className={classNames(classes.menuItem, {
                    [classes.activeLink]: activeLink.includes('/info'),
                  })}
                >
                  <Posts />
                  <Typography variant={'title1'}>Info posts</Typography>
                </MenuItem>
              )}
              {canSeeContacts && (
                <MenuItem
                  onClick={() => {
                    navigate('/contacts');
                    setActiveLink('/contacts');
                  }}
                  className={classNames(
                    classes.menuItem,
                    'contacts-menu-item',
                    {
                      [classes.activeLink]: activeLink.includes('/contacts'),
                    },
                  )}
                >
                  <Contacts className="contacts-icon" />
                  <Typography variant={'title1'}>Contacts</Typography>
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};
