import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const Header = styled.div`
  font-size: 16px;
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 24px;
`;

export const DaysWrapper = styled.div`
  display: flex;
`;

export const Day = styled.button<{ selected: boolean; disabled: boolean }>`
  background-color: ${(props: any) => {
    if (props.selected) {
      return props.theme.palette.warning.light;
    }

    if (props.disabled) {
      return props.theme.palette.action.disabled;
    }

    return 'white';
  }};
  border: 1px solid
    ${(props: any) => {
      if (props.selected) {
        return props.theme.palette.warning.light;
      }

      if (props.disabled) {
        return props.theme.palette.action.disabled;
      }

      return props.theme.palette.action.disabled;
    }};
  text-transform: capitalize;
  width: 64px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 8px;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
`;
