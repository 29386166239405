import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Building } from '../../../../types/buildings';
import { ALL_BUILDINGS_OPTION } from '../FiltersList/FiltersList.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface BuildingFilterProps {
  buildings: Building[];
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const BuildingFilter = ({
  buildings,
  onFiltersChange,
}: BuildingFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="buildings-filter">Buildings</InputLabel>
      <Select
        labelId="buildings-filter"
        defaultValue={ALL_BUILDINGS_OPTION.value}
        label="Building"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            building: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        <MenuItem
          key={ALL_BUILDINGS_OPTION.value}
          value={ALL_BUILDINGS_OPTION.value}
        >
          {ALL_BUILDINGS_OPTION.name}
        </MenuItem>
        {buildings.map(({ id, code, name }: Building) => (
          <MenuItem key={id} value={id}>{`${code} ${name}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
