import styled from '@emotion/styled/macro';

export const Container = styled.div`
  text-align: center;
  padding-top: 150px;
`;

export const Text = styled.div`
  font-size: 20px;
  margin: 17px 0;
  font-family: 'GraphikMedium', sans-serif;
`;

export const SubText = styled.div`
  font-size: 16px;
`;
