import { SelectItem } from '../../../components/MultiSelectWrapper/MultiSelectWrapper';
import { RepeatType } from './AddScheduleForm/AddScheduleForm.types';

export enum TIME_SLOTS_EDITOR_TYPES {
  AVAILABLE_TIME = 'available',
  UNAVAILABLE_TIME = 'unavailable',
}

export const REPEATS_EVERY_OPTIONS: SelectItem[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
].map((item) => ({
  label: item.toString(),
  value: item,
}));

export const REPEATS_TYPES_OPTIONS: SelectItem[] = [RepeatType.WEEK].map(
  (item) => ({
    label: item.charAt(0).toUpperCase() + item.slice(1),
    value: item,
  }),
);
