import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Grid } from '@mui/material';

import { CustomButton } from 'components';
import { TextInput } from 'components/Inputs/TextInput/TextInput';

import { SearchSchema } from 'constants/schema';

interface AdvanceSearchProps {
  searchQuery: any;
  isSearchResult: boolean;
  handleSearch: (data: any) => void;
}

export const AdvanceSearch = ({
  searchQuery,
  isSearchResult,
  handleSearch,
}: AdvanceSearchProps) => {
  const searchValues = {
    last_name: '',
    first_name: '',
    email: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(SearchSchema),
  });

  const [formValues, setFormValues] = useState(searchValues);

  useEffect(() => {
    if (isSearchResult) {
      setFormValues(searchQuery);
    }
  }, [isSearchResult]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <FormProvider {...methods}>
      <Box mt={1}>
        <Grid container={true} mb={3} spacing={1}>
          <Grid item={true} xs={3}>
            <TextInput
              name={'first_name'}
              value={formValues?.first_name}
              label={'First name'}
              color={'black'}
              handleChange={handleInputChange}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextInput
              name={'last_name'}
              value={formValues?.last_name}
              label={'Last name'}
              color={'black'}
              handleChange={handleInputChange}
            />
          </Grid>
          <Grid item={true} xs={2}>
            <CustomButton
              title={'Search'}
              variant={'buttonMedium'}
              handleClick={() => handleSearch(formValues)}
            />
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};
