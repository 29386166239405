import { Event } from 'store/events/events.types';
import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { ResponseBuilding } from '../../../types/buildings';
import { shapeDateTime } from '../../../utils/HelperFunctions';
import { EVENT_FORMATS, EVENT_TYPES } from '../Events.constants';
import { EventEditorValues } from './EventEditor.types';

export const getEventEditorValues = (
  event: Event | undefined,
  isDuplicate: boolean,
): EventEditorValues => {
  const getNameValue = () => {
    if (isDuplicate) {
      return event?.name ? `${event?.name} (COPY)` : '';
    }

    return event?.name || '';
  };

  return {
    name: getNameValue(),
    service_category_id: event?.service_category.id || '',
    description: event?.description || '',
    image: event?.image || '',
    format: event?.format || '',
    link: event?.link || '',
    building_ids: Boolean(
      event?.format === EVENT_FORMATS.ONLINE && event?.buildings.length,
    )
      ? event?.buildings.map((building: ResponseBuilding) => building.id)
      : [],
    building_id: Boolean(
      event?.format === EVENT_FORMATS.OFFLINE && event?.buildings.length,
    )
      ? event?.buildings[0]?.id
      : '',
    capacity: event?.capacity.toString() || '',
    date:
      event?.start_date_time && !isDuplicate
        ? new Date(event?.start_date_time)
        : null,
    start_time:
      event?.start_date_time && !isDuplicate
        ? new Date(event?.start_date_time)
        : null,
    close_registration_time:
      event?.close_registration_date_time && !isDuplicate
        ? new Date(event?.close_registration_date_time)
        : null,
    duration: event?.duration || '',
    type: event?.type || '',
    price: event?.price || '',
    tax: event?.tax || 0,
    specialist_id:
      event?.specialist?.id &&
      !isDuplicate &&
      event?.specialist?.id !== 'elite_amenity'
        ? event?.specialist?.id
        : null,
    split: event?.split || 0,
  } as EventEditorValues;
};

export const sortServiceSpecialistsForSelect = (
  specialists: ServiceSpecialist[],
) => {
  return specialists
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map((specialist: ServiceSpecialist) => ({
      label: `${specialist.first_name} ${specialist.last_name}`,
      value: specialist.id,
    }));
};

export const shapeEventValuesForRequestBody = (
  values: EventEditorValues,
  isDuplicate?: boolean,
  imageUploadedManually?: boolean,
) => {
  return {
    start_date_time: shapeDateTime(
      values.date as Date,
      values.start_time as Date,
    ),
    close_registration_date_time: shapeDateTime(
      values.date as Date,
      values.close_registration_time as Date,
    ),
    building_ids: values.building_id
      ? [values.building_id]
      : values.building_ids,
    capacity: values.capacity,
    description: values.description,
    duration: values.duration,
    format: values.format,
    link: values.link,
    name: values.name,
    service_category_id: values.service_category_id,
    type: values.type,
    specialist_id: values.specialist_id || null,
    ...(values.image && isDuplicate && !imageUploadedManually
      ? {
          image_link: values.image,
        }
      : {}),
    ...(values.price && values.type === EVENT_TYPES.PAID
      ? {
          price: values.price,
        }
      : {}),
    ...(values.split
      ? {
          split: values.split,
        }
      : {}),
    ...(values.tax
      ? {
          tax: values.tax,
        }
      : {}),
  };
};
