import styled from '@emotion/styled/macro';

export const Container = styled.div`
  width: 452px;
  border-radius: 20px;
  background-color: white;
  padding: 42px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
`;

export const Header = styled.div`
  font-size: 24px;
  font-family: 'GraphikMedium', sans-serif;
  border-bottom: 1px solid ${(props: any) => props.theme.palette.grey[300]};
  padding-bottom: 20px;
  position: relative;
`;

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;

export const FormWrapper = styled.div`
  padding: 24px 0 42px;
`;

export const Details = styled.div`
  margin-top: 42px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    flex-basis: calc(50% - 10px);
  }
`;
