import CheckmarkIcon from 'assets/icons/check-mark.svg';
import DeleteIcon from 'assets/icons/delete-grey.svg';

import { SelectItem } from 'components/MultiSelectWrapper/MultiSelectWrapper';

import { LocationServices } from '../../AddScheduleForm.types';
import * as S from './SelectedCombinations.styled';

interface SelectedCombinationsProps {
  list: LocationServices[];
  services: SelectItem[];
  buildings: SelectItem[];
  handleRemoveCombination: (buildingId: string) => void;
}

export const SelectedCombinations = ({
  list,
  services,
  buildings,
  handleRemoveCombination,
}: SelectedCombinationsProps) => {
  const getBuildingName = (selectedBuilding: string) => {
    return buildings.find((building) => building.value === selectedBuilding)
      ?.label;
  };

  const getServicesNames = (selectedServices: string[]) => {
    return selectedServices
      .map(
        (selectedService) =>
          services.find((service) => selectedService === service.value)?.label,
      )
      .join(', ');
  };

  return (
    <S.Container>
      {list.map((item) => (
        <S.SelectedCombination key={item.building}>
          <img src={CheckmarkIcon} alt="" />
          <S.Info>
            <S.Building title={getBuildingName(item.building)}>
              {getBuildingName(item.building)}
            </S.Building>
            <S.Services title={getServicesNames(item.services)}>
              {getServicesNames(item.services)}
            </S.Services>
          </S.Info>
          <S.DeleteButton
            onClick={() => handleRemoveCombination(item.building)}
            type="button"
          >
            <img src={DeleteIcon} alt="" />
          </S.DeleteButton>
        </S.SelectedCombination>
      ))}
    </S.Container>
  );
};
