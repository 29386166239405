import { Category } from 'store/service-specialists/service-specialists.types';

import { ServiceType } from '../store/services/services.types';
import { Building, ResponseBuilding } from '../types/buildings';

export const mapCategoriesForSelect = (categories: Category[]) => {
  return categories.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
};

export const mapServiceTypesForSelect = (serviceTypes: ServiceType[]) => {
  return serviceTypes.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
};

export const mapBuildingsForSelect = (
  buildings: { result: Building[] },
  eventBuildings?: ResponseBuilding[],
) => {
  return buildings.result.map((building: Building) => ({
    label: `${building.code} ${building.name}`,
    value: building.id,
    disabled:
      Boolean(eventBuildings?.length) &&
      eventBuildings?.some(
        (item) => item.id === building.id && item.has_bookings,
      ),
  }));
};
