import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints, main } from 'config';

import messages from 'constants/messages';
import http from 'utils/http';

import { ServiceBasicInfoFormValues } from '../../modules/Services/Editor/ServiceEditor.types';
import { ImageType } from '../../types';
import { BaseError } from '../store.constants';
import {
  BookConciergeServiceData,
  BookServiceData,
  Service,
} from './services.types';

export interface GetServiceListParams {
  page_size?: number;
  page?: number;
  name?: string;
  search?: string;
  category_id?: string;
  service_type_id?: string;
  building_codes?: string;
  building_code?: string;
  building_id?: string;
  isActiveService?: boolean;
  service?: string;
  specialist?: string;
  is_active?: boolean | string;
  specialists_exist?: boolean;
  member_id?: string;
}

export const getServices = createAsyncThunk(
  'services/getServices',
  async (params: GetServiceListParams, thunkAPI) => {
    try {
      const response = await http.get(main.services.root, {
        params: {
          ...params,
          ordering: '-created',
        },
      });
      return {
        list: response.data,
        params,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);

export const createServiceMainInfo = createAsyncThunk(
  'services/createServiceMainInfo',
  async (body: any, thunkAPI: any) => {
    try {
      const url = main.services.root;
      const response = await http.post(url, body);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.message
          ? typeof err?.message === 'string'
            ? [err?.message]
            : err?.message
          : [messages.defaultMessageForError],
      );
    }
  },
);

export const deleteService = createAsyncThunk<
  Service,
  string,
  { rejectValue: BaseError }
>('services/deleteService', (id: string, thunkAPI) => {
  return http.delete(endpoints.services.serviceById(id)).then((response) => {
    return response.data;
  });
});

export const getServiceById = createAsyncThunk<
  Service,
  string,
  { rejectValue: BaseError }
>('services/getServiceById', async (id: string) => {
  const response = await http.get(endpoints.services.serviceById(id));
  return response.data;
});

export const updateOneService = createAsyncThunk<
  Service,
  {
    id: string;
    data: Partial<ServiceBasicInfoFormValues>;
  },
  { rejectValue: BaseError }
>('services/updateOneService', async ({ id, data }, thunkAPI) => {
  const response = await http.put(endpoints.services.serviceById(id), data);
  thunkAPI.dispatch(getServiceById(id));
  return response.data;
});

export const updateServiceStatus = createAsyncThunk<
  Service,
  {
    id: string;
    newState: boolean;
  },
  { rejectValue: BaseError }
>('services/updateServiceStatus', ({ id, newState }, thunkAPI) => {
  return http
    .patch(endpoints.services.updateServiceStatus(id), { state: newState })
    .then((response) => {
      thunkAPI.dispatch(getServiceById(id));
      return response.data;
    });
});

export const uploadServiceImages = createAsyncThunk<
  ImageType[],
  FormData,
  { rejectValue: BaseError }
>('services/uploadServiceImages', (formData, thunkAPI) => {
  return http
    .post(endpoints.services.uploadServiceImages, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      return response.data;
    });
});

export const deleteServiceImage = createAsyncThunk<
  undefined,
  string,
  { rejectValue: BaseError }
>('services/deleteServiceImage', (id) => {
  return http
    .delete(endpoints.services.deleteServiceImage(id), {
      params: {
        id,
      },
    })
    .then((response) => {
      return response.data;
    });
});

export const bookService = createAsyncThunk<
  undefined,
  BookServiceData,
  { rejectValue: BaseError }
>('services/bookService', async (data) => {
  const response = await http.post(main.serviceRequests.root, data);

  return response.data;
});

export const bookConciergeService = createAsyncThunk<
  undefined,
  { serviceId: string; data: BookConciergeServiceData },
  { rejectValue: BaseError }
>('services/bookConciergeService', async ({ serviceId, data }) => {
  const response = await http.post(
    `${main.services.root}${serviceId}/concierge-requests/`,
    data,
  );

  return response.data;
});
