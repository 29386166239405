import { createAsyncThunk } from '@reduxjs/toolkit';
import { main } from 'config';

import http from 'utils/http';

export const getCategories = createAsyncThunk(
  'buildings/getCategories',
  async (_: void, thunkAPI: any) => {
    try {
      const url = main.categories.root;
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in getting buildings');
    }
  },
);
