import ListViewIcon from '../../assets/icons/list-view.svg';
import TileViewIcon from '../../assets/icons/tile-view.svg';
import { SwitcherItem } from './Events.types';

export const DEFAULT_PARAMS = {
  page: 1,
  search: '',
};

export enum EVENT_TYPES {
  FREE = 'free',
  PAID = 'paid',
}

export enum EVENT_STATUSES {
  UPCOMING = 'upcoming',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
}

export enum EVENT_FORMATS {
  ALL = 'all',
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const EVENT_FORMAT_SELECT_ITEMS = [
  {
    label: 'All',
    value: EVENT_FORMATS.ALL,
  },
  {
    label: 'Online',
    value: EVENT_FORMATS.ONLINE,
  },
  {
    label: 'Offline',
    value: EVENT_FORMATS.OFFLINE,
  },
];

export const EVENT_TYPES_SELECT_ITEMS = [
  {
    label: 'Free',
    value: EVENT_TYPES.FREE,
  },
  {
    label: 'Paid',
    value: EVENT_TYPES.PAID,
  },
];

export enum EVENT_LIST_VIEW_TYPES {
  TILE = 'tile',
  LIST = 'list',
}

export const VIEW_SWITCHER_ITEMS: SwitcherItem[] = [
  {
    label: 'List view',
    value: EVENT_LIST_VIEW_TYPES.LIST,
    icon: ListViewIcon,
  },
  {
    label: 'Tile view',
    value: EVENT_LIST_VIEW_TYPES.TILE,
    icon: TileViewIcon,
  },
];
