import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
  padding-top: 64px;
  padding-bottom: 100px;
`;

export const EditSection = styled.div`
  box-sizing: border-box;

  &:first-of-type {
    flex-basis: 45%;
    padding-right: 100px;
  }

  &:last-of-type {
    width: 580px;
  }
`;

export const LeftSectionHeaderHint = styled.div`
  font-size: 12px;
  margin-bottom: 15px;
`;

export const CurrentTimeSlotWrapper = styled.div`
  margin-bottom: 48px;
`;

export const TimeSlotTime = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  font-family: 'GraphikMedium', sans-serif;
`;

export const TimeSlotInfo = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
`;

export const Building = styled.div`
  margin-bottom: 3px;
`;

export const RightFormWrapper = styled.div`
  border-radius: 20px;
  background-color: white;
  padding: 42px;
`;
