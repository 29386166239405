import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as S from './FormDatePicker.styled';

interface FormDatePickerProps {
  name: string;
  label: string;
  minData?: Date;
  disabled?: boolean;
  shouldDisableDate?: (day: Date) => boolean;
  onChange?: (value: Date) => void;
}

export const FormDatePicker = ({
  name,
  label,
  minData,
  disabled,
  shouldDisableDate,
  onChange,
}: FormDatePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              label={label}
              minDate={minData || new Date()}
              disabled={disabled}
              shouldDisableDate={shouldDisableDate}
              slotProps={{
                textField: {
                  onBeforeInput: (e) => {
                    e.preventDefault();
                  },
                },
              }}
              {...field}
              onChange={(value) => {
                if (onChange) {
                  onChange(value as Date);
                }
                field.onChange(value);
              }}
              onError={() => {
                field.onBlur();
              }}
              onAccept={() => {
                field.onBlur();
              }}
            />
          )}
        />
        {errors[name] && (
          <Typography
            variant="helperText"
            color="error"
            className="error-message"
          >
            <>{errors?.[name]?.message}</>
          </Typography>
        )}
      </LocalizationProvider>
    </S.Container>
  );
};
