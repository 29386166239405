import styled from '@emotion/styled/macro';

export const Container = styled.div`
  .more-button {
    border: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
  }
`;

export const MenuItem = styled.div<{ color: string }>`
  color: ${(props: any) => props.color};
  font-weight: 500;
  font-size: 14px;
  margin-top: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuWrapper = styled.div`
  li:first-of-type {
    ${MenuItem} {
      margin-top: 0;
      padding-bottom: 22px;
      border-bottom: 1px solid
        ${(props: any) => props.theme.palette.other.divider};
    }
  }

  li:last-of-type {
    ${MenuItem} {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export const MenuButton = styled.div`
  display: flex;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props: any) => props.theme.palette.statuses.pending};
`;
export const MenuItemIcon = styled.img`
  margin-right: 10px;
`;
