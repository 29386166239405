import { Alert, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '../../CustomButton/CustomButton';
import CustomModal from '../CustomModal';

const useStyles = makeStyles({
  sendButton: {
    marginRight: '16px',
  },
});

interface SaveForLaterModalProps {
  isOpen: boolean;
  title: string;
  question?: string;
  alertText?: string;
  closeModal: () => void;
  handleAction: () => void;
  handlerSecondAction: () => void;
  buttonLabel: string;
  secondButtonLabel: string;
}

export const SaveForLaterModal = ({
  isOpen,
  title,
  question,
  alertText,
  closeModal,
  handleAction,
  handlerSecondAction,
  buttonLabel,
  secondButtonLabel,
}: SaveForLaterModalProps) => {
  const classes = useStyles();

  return (
    <CustomModal title={title} open={isOpen} closeModal={closeModal}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {question && <Typography variant={'body1'}>{question}</Typography>}
        {alertText && (
          <Alert
            sx={{
              marginTop: '15px',
            }}
            severity="warning"
          >
            {alertText}
          </Alert>
        )}
      </Box>

      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={buttonLabel}
          variant={'buttonMedium'}
          additionalClass={classes.sendButton}
          handleClick={handleAction}
        />
        <CustomButton
          title={secondButtonLabel}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={handlerSecondAction}
        />
      </Box>
    </CustomModal>
  );
};
