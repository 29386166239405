import React from 'react';

import { TableCell } from '@mui/material';

import { ColumnType } from '../GridCommonConfigs';

interface HeaderElementType<T> {
  column: ColumnType<T>;
  children: React.ReactNode;
}

export const HeaderElement = <T extends unknown>({
  column,
  children,
}: HeaderElementType<T>): React.ReactElement => {
  return (
    <TableCell
      key={column.label}
      align={column.align}
      style={{
        width: `${column.widthPercentage}%`,
        maxWidth: `${column.widthPercentage}%`,
      }}
    >
      {children}
    </TableCell>
  );
};
