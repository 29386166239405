import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import * as S from './FormCheckboxGroup.styled';

interface CheckboxGroupItem {
  value: string;
  label: string;
}

interface FormCheckboxGroupProps {
  name: string;
  options: CheckboxGroupItem[];
  size?: number;
}

export const FormCheckboxGroup = ({
  name,
  options,
  size = 28,
}: FormCheckboxGroupProps): ReactElement => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container size={size}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <S.GroupWrapper>
            {options.map((option: CheckboxGroupItem) => (
              <S.CheckboxWrapper key={option.value}>
                <FormControlLabel
                  label={option.label}
                  control={
                    <Checkbox
                      checked={field.value.some(
                        (item: string) => option.value === item,
                      )}
                      onChange={(e) => {
                        setValue(
                          name,
                          e.target.checked
                            ? [...field.value, option.value]
                            : [
                                ...field.value.filter(
                                  (item: string) => item !== option.value,
                                ),
                              ],
                        );
                      }}
                    />
                  }
                />
              </S.CheckboxWrapper>
            ))}
          </S.GroupWrapper>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
