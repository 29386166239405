import { format } from 'date-fns';

import {
  MatchingSchedule,
  TimeSlot,
} from '../../../../store/time-slots/time-slots.types';
import { DATE_FORMAT_BE, TIME_FORMAT_BE } from '../../../../utils/constants';
import {
  capitalizeFirstLetter,
  formatParsedTime,
  parseDateStringToData,
  parseTimeStringToData,
} from '../../../../utils/HelperFunctions';
import {
  RepeatEvery,
  RepeatType,
} from '../AddScheduleForm/AddScheduleForm.types';
import {
  EditScheduleRequestValues,
  EditScheduleValues,
} from './EditScheduleForm.types';

export const getEditScheduleValues = (
  currentTimeSlot: TimeSlot,
  isSpecialistPage: boolean,
): EditScheduleValues => {
  return {
    matching_schedules: [],
    ...(isSpecialistPage
      ? { building: currentTimeSlot.buildings[0].id as string }
      : {
          buildings: currentTimeSlot.buildings.map((building) => building.id),
        }),
    ...(isSpecialistPage
      ? { services: currentTimeSlot.services.map((service) => service.id) }
      : {}),
    start_date: parseDateStringToData(currentTimeSlot.start_date),
    end_date: currentTimeSlot.end_date
      ? parseDateStringToData(currentTimeSlot.start_date)
      : null,
    no_end_date: !Boolean(currentTimeSlot.end_date),
    start_time: parseTimeStringToData(currentTimeSlot.start_time),
    end_time: parseTimeStringToData(currentTimeSlot.end_time),
    repeat_every: currentTimeSlot.repeat_every as RepeatEvery,
    repeat_type: currentTimeSlot.repeat_type as RepeatType,
  };
};

export const mapMatchingSchedulesForCheckboxes = (
  matchingSchedules: MatchingSchedule[],
) =>
  matchingSchedules.map((item) => ({
    value: item.id,
    label: `${capitalizeFirstLetter(item.day_of_the_week)} ${formatParsedTime(
      item.start_time,
    )} - ${formatParsedTime(item.end_time)}`,
  }));

export const prepareDataForRequest = (
  {
    matching_schedules,
    buildings,
    building,
    services,
    start_date,
    end_date,
    start_time,
    end_time,
    repeat_type,
    repeat_every,
  }: EditScheduleValues,
  serviceOrSpecialistId: string,
  isSpecialistPage: boolean,
): EditScheduleRequestValues => {
  const shapeServicesBuildings = () => {
    return isSpecialistPage
      ? [
          {
            building: building as string,
            services: services as string[],
          },
        ]
      : (buildings as string[]).map((item) => ({
          building: item,
          services: [serviceOrSpecialistId],
        }));
  };

  return {
    matching_schedules,
    services_buildings: shapeServicesBuildings(),
    start_date: format(start_date, DATE_FORMAT_BE),
    ...(end_date ? { end_date: format(end_date, DATE_FORMAT_BE) } : {}),
    start_time: format(start_time, TIME_FORMAT_BE),
    end_time: format(end_time, TIME_FORMAT_BE),
    repeat_type,
    repeat_every,
  };
};
