/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BuildingsState, IBuilding, IBuildingRules } from 'types/index';

import * as buildings from './buildings.actions';

// import { RequestError } from 'types/errors';

export const initialState: BuildingsState = Object.freeze({
  buildings: {},
  currentBuilding: {},
  loading: false,
  error: null,
  isMessageShow: false,
  message: '',
  status: 'idle',
  videoOnDemand: {
    loading: false,
    data: null,
    error: null,
  },
  currentVideoOnDemand: {
    loading: false,
    data: null,
    error: null,
  },
  videoOnDemandDeleting: {
    loading: false,
    isDeleted: false,
    error: null,
  },
  videoOnDemandList: {
    loading: false,
    data: {
      result: [],
      count: 0,
    },
    error: null,
  },
});

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    showMessage: (state) => {
      state.isMessageShow = false;
    },
    clearBuildingsError: (state) => {
      state.error = null;
    },
    clearCurrentBuilding: (state) => {
      state.currentBuilding = {};
    },
    setCurrentBuilding: (state, action: PayloadAction<IBuilding>) => {
      state.currentBuilding = action.payload;
    },
    clearVideoOnDemand: (state) => {
      state.videoOnDemand = {
        loading: false,
        data: null,
        error: null,
      };
    },
    clearCurrentVideoOnDemand: (state) => {
      state.currentVideoOnDemand = {
        loading: false,
        data: null,
        error: null,
      };
    },
    setCurrentBuildingRule: (state, action: PayloadAction<IBuildingRules>) => {
      state.currentBuilding = {
        ...state.currentBuilding,
        building_rules: [action.payload],
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(buildings.deleteVideoOnDemand.pending, (state) => {
      state.status = 'pending';
      state.videoOnDemandDeleting = {
        loading: true,
        isDeleted: false,
        error: null,
      };
    });

    builder.addCase(
      buildings.deleteVideoOnDemand.fulfilled,
      (state, action) => {
        state.status = 'resolved';
        state.videoOnDemandDeleting = {
          loading: false,
          isDeleted: true,
          error: null,
        };
      },
    );

    builder.addCase(buildings.deleteVideoOnDemand.rejected, (state, action) => {
      state.status = 'rejected';
      state.videoOnDemandDeleting = {
        loading: true,
        isDeleted: false,
        error: action.payload,
      };
    });
    builder.addCase(buildings.getVideoOnDemandById.pending, (state) => {
      state.status = 'pending';
      state.currentVideoOnDemand = {
        loading: true,
        data: null,
        error: null,
      };
    });

    builder.addCase(
      buildings.getVideoOnDemandById.fulfilled,
      (state, action) => {
        state.status = 'resolved';
        state.currentVideoOnDemand = {
          loading: false,
          data: action.payload,
          error: null,
        };
      },
    );

    builder.addCase(
      buildings.getVideoOnDemandById.rejected,
      (state, action) => {
        state.status = 'rejected';
        state.currentVideoOnDemand = {
          loading: false,
          data: null,
          error: action.payload,
        };
      },
    );
    builder.addCase(buildings.getVideoOnDemand.pending, (state) => {
      state.status = 'pending';
      state.videoOnDemandList = {
        loading: true,
        data: {
          result: [],
          count: 0,
        },
        error: null,
      };
    });

    builder.addCase(buildings.getVideoOnDemand.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.videoOnDemandList = {
        loading: false,
        data: action.payload,
        error: null,
      };
    });

    builder.addCase(buildings.getVideoOnDemand.rejected, (state, action) => {
      state.status = 'rejected';
      state.videoOnDemandList = {
        loading: false,
        data: {
          result: [],
          count: 0,
        },
        error: action.payload,
      };
    });
    builder.addCase(buildings.createVideoOnDemand.pending, (state) => {
      state.status = 'pending';
      state.videoOnDemand = {
        loading: true,
        data: null,
        error: null,
      };
    });

    builder.addCase(
      buildings.createVideoOnDemand.fulfilled,
      (state, action) => {
        state.status = 'resolved';
        state.videoOnDemand = {
          loading: false,
          data: action.payload,
          error: null,
        };
      },
    );

    builder.addCase(buildings.createVideoOnDemand.rejected, (state, action) => {
      state.status = 'rejected';
      state.videoOnDemand = {
        loading: false,
        data: null,
        error: action.payload,
      };
    });
    builder.addCase(buildings.updateVideoOnDemand.pending, (state) => {
      state.status = 'pending';
      state.videoOnDemand = {
        loading: true,
        data: null,
        error: null,
      };
    });

    builder.addCase(
      buildings.updateVideoOnDemand.fulfilled,
      (state, action) => {
        state.status = 'resolved';
        state.videoOnDemand = {
          loading: false,
          data: action.payload,
          error: null,
        };
      },
    );

    builder.addCase(buildings.updateVideoOnDemand.rejected, (state, action) => {
      state.status = 'rejected';
      state.videoOnDemand = {
        loading: false,
        data: null,
        error: action.payload,
      };
    });

    // ----------------------------------------------------------------

    builder.addCase(buildings.getBuildings.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.buildings = [];
    });

    builder.addCase(buildings.getBuildings.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.buildings = action.payload;
      state.error = null;
    });

    builder.addCase(buildings.getBuildings.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(buildings.getBuildingById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.getBuildingById.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = action.payload;
        state.error = null;
      },
    );

    builder.addCase(buildings.getBuildingById.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(buildings.createBuilding.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.createBuilding.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
        state.isMessageShow = true;
        state.message = 'New building was successfully added';
        state.currentBuilding = action.payload;
      },
    );

    builder.addCase(
      buildings.createBuilding.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.createBuildingWorkingHours.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.createBuildingWorkingHours.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = action.payload;
      },
    );

    builder.addCase(
      buildings.createBuildingWorkingHours.rejected,
      (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(
      buildings.updateBuildingWorkingHoursById.pending,
      (state) => {
        state.status = 'pending';
        state.loading = true;
      },
    );

    builder.addCase(
      buildings.updateBuildingWorkingHoursById.fulfilled,
      (state, action) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = {
          ...state.currentBuilding,
          working_hours: action.payload,
        };
      },
    );

    builder.addCase(
      buildings.updateBuildingWorkingHoursById.rejected,
      (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.createBuildingRules.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.createBuildingRules.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = action.payload;
      },
    );

    builder.addCase(buildings.createBuildingRules.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload ? action.payload : 'Something wrong';
    });

    // ----------------------------------------------------------------

    builder.addCase(buildings.updateBuildingRulesById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.updateBuildingRulesById.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
      },
    );

    builder.addCase(
      buildings.updateBuildingRulesById.rejected,
      (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.updateBuildingPost.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(buildings.updateBuildingPost.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'Building was updated';
    });

    builder.addCase(
      buildings.updateBuildingPost.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.imageBuildingUploader.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.imageBuildingUploader.fulfilled,
      (state, action: PayloadAction<IBuildingRules>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = {
          ...state.currentBuilding,
          building_rules: [action.payload],
        };
      },
    );

    builder.addCase(
      buildings.imageBuildingUploader.rejected,
      (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.payload ? action.payload : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.updateBuildingById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      buildings.updateBuildingById.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentBuilding = action.payload;
        state.isMessageShow = true;
        state.message = 'Building was updated';
      },
    );

    builder.addCase(
      buildings.updateBuildingById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.buildings = [];
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(buildings.deleteBuildingById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(buildings.deleteBuildingById.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'Building was deleted';
    });

    builder.addCase(
      buildings.deleteBuildingById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
  },
});

// actions from slice
export const {
  showMessage,
  clearBuildingsError,
  clearCurrentBuilding,
  setCurrentBuilding,
  setCurrentBuildingRule,
  clearVideoOnDemand,
  clearCurrentVideoOnDemand,
} = buildingsSlice.actions;

// The reducer
export default buildingsSlice.reducer;
