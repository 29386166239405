import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 calc(50% - 9px);
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  margin-bottom: 16px;
  height: 275px;

  &:nth-of-type(odd) {
    margin-right: 16px;
  }
`;

export const LeftSection = styled.div`
  flex: 0 0 147px;
  margin-right: 22px;

  & > img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    height: 147px;
  }

  & > button {
    margin-bottom: 8px;

    &:first-of-type {
      margin-top: 8px;
    }
  }
`;

export const RightSection = styled.div`
  flex: 1 0 auto;
`;

export const Name = styled.div`
  font-size: 18px;
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const InfoLine = styled.div<{ hasOpacity?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  opacity: ${(props: any) => (props.hasOpacity ? 0.6 : 1)};

  & > span {
    margin-left: 16px;
  }
`;

export const AssignedChip = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 16px;
  height: 24px;
  color: white;
  font-size: 12px;
  font-family: 'GraphikMedium', sans-serif;
  background-color: ${(props: any) => props.theme.palette.success.dark};
  position: absolute;
  bottom: 20px;
  right: 20px;

  & > img {
    margin-right: 8px;
  }
`;
