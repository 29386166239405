import { Alert, Grid, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import classNames from 'classnames';
import { NotificationType } from 'types/notificationTypes';

const useStyles = makeStyles({
  message: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  notification: {},
  readNotification: {
    cursor: 'auto',
  },
});

interface NotificationMessageProps {
  type: NotificationType | any;
  id: string;
  title: string;
  message: string;
  status: string;
  handleChangeStatus: (id: string) => void;
}

export const NotificationMessage = ({
  type,
  id,
  title,
  message,
  status,
  handleChangeStatus,
}: NotificationMessageProps) => {
  const classes = useStyles();

  const handleClose = () => {};

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={11}>
          <Alert
            onClick={handleClose}
            severity={type}
            variant={status !== 'unread' ? 'outlined' : 'standard'}
            sx={{ width: '100%', fontSize: 16, padding: '10px 24px' }}
          >
            <Typography variant={'h4'}>{title}</Typography>
            <Typography variant={'h5'} className={classes.message}>
              {message}
            </Typography>
          </Alert>
        </Grid>
        <Grid item={true} xs={1} mt={2}>
          {status === 'unread' && (
            <Radio
              // checked={selectedValue === 'a'}
              onChange={() => handleChangeStatus(id)}
              value="a"
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
