import styled from '@emotion/styled/macro';

export const Container = styled.div<{ size: number }>`
  .MuiSvgIcon-root {
    font-size: ${(props: any) => `${props.size}px`};
  }

  .MuiFormControlLabel-label {
    margin-top: 2.5px;
    font-size: 16px;
    font-family: 'GraphikMedium', sans-serif;
    white-space: nowrap;
  }
`;

export const GroupWrapper = styled.div`
  margin-top: 32px;
`;

export const CheckboxWrapper = styled.div``;
