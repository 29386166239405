import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { BaseError } from '../store.constants';
import {
  EventRequestsList,
  GetEventRequestsParams,
} from './eventsRequests.types';

export const getEventRequests = createAsyncThunk<
  EventRequestsList,
  GetEventRequestsParams,
  { rejectValue: BaseError }
>('eventRequests/getEventRequests', async (params: GetEventRequestsParams) => {
  const response = await http.get(endpoints.eventRequests.getEventRequests, {
    params,
  });

  return {
    list: response.data,
    params,
  };
});

export const acceptEventRequest = createAsyncThunk(
  'eventRequests/approveEventRequests',
  async (id: string) => {
    const response = await http.post(
      endpoints.eventRequests.approveEventRequests(id),
    );
    return response.data;
  },
);

export const declineEventRequest = createAsyncThunk(
  'eventRequests/declineEventRequests',
  async (id: string) => {
    const response = await http.post(
      endpoints.eventRequests.declineEventRequests(id),
    );
    return response.data;
  },
);
