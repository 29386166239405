import { sub } from 'date-fns';
import * as yup from 'yup';

import { EVENT_FORMATS, EVENT_TYPES } from '../Events.constants';

export const EventEditorSchema = yup.object({
  name: yup
    .string()
    .required('Title is required!')
    .min(3, 'Title must be at least 3 characters')
    .max(40, 'Title must not exceed 40 characters'),
  service_category_id: yup.string().required('Category is required!'),
  description: yup
    .string()
    .required('About is required!')
    .min(3, 'About field must be at least 3 characters')
    .max(2000, 'About field must not exceed 2000 characters'),
  format: yup.string().required('Format is required!'),
  link: yup.string().url('Please enter valid link'),
  building_ids: yup.array().when('format', {
    is: EVENT_FORMATS.ONLINE,
    then: yup
      .array()
      .required()
      .min(1, 'At least one building should be chosen'),
  }),
  building_id: yup.string().when('format', {
    is: EVENT_FORMATS.OFFLINE,
    then: yup.string().required('Building is required!'),
  }),
  capacity: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().required('Capacity is required!');
    }

    return yup
      .number()
      .integer('Capacity has to be integer number.')
      .typeError('Capacity has to be numbers only')
      .min(1, 'Capacity must be more or equal 1')
      .max(2000, 'Capacity must be less or equal 2000');
  }),
  date: yup
    .date()
    .nullable()
    .min(
      sub(new Date(), {
        days: 1,
      }),
      'No past date can be chosen.',
    )
    .required('Event date is required!'),
  start_time: yup.date().nullable().required('Start time is required!'),
  close_registration_time: yup
    .date()
    .nullable()
    .required('Close registration time is required!'),
  duration: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().required('Event duration is required!');
    }

    return yup
      .number()
      .integer('Event duration has to be integer number.')
      .typeError('Event duration has to be numbers only.')
      .moreThan(0, 'Event duration must be more than 0.')
      .max(100, 'Event duration must be less or equal 100');
  }),
  type: yup.string().required('Type is required!'),
  price: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().when('type', {
        is: EVENT_TYPES.PAID,
        then: yup.string().required('Price is required!'),
      });
    }

    return yup.number().when('type', {
      is: EVENT_TYPES.PAID,
      then: yup
        .number()
        .typeError('Price has to be numbers only')
        .required('Price has to be numbers only')
        .min(0, 'Price must be more than or equal to 0')
        .max(100000, 'Price must be more than or equal to 100000')
        .test(
          'maxDigitsAfterDecimal',
          'Price should be with max 2 decimal places',
          (number: any) => /^\d+(\.\d{1,2})?$/.test(number),
        ),
    });
  }),
  tax: yup.lazy(() => {
    return yup
      .number()
      .typeError('Tax has to be numbers only')
      .min(0, 'Tax must be more than or equal to 0')
      .max(100, 'Tax must be less than or equal to 100')
      .test(
        'maxDigitsAfterDecimal',
        'Tax % should be with max 2 decimal places',
        (number: any) => /^\d+(\.\d{1,2})?$/.test(number),
      );
  }),
  split: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().when('specialist_id', {
        is: (val: any) => Boolean(val),
        then: yup.string().required('Split is required!'),
      });
    }

    return yup
      .number()
      .min(0, 'Split must be more than or equal to 0')
      .max(100, 'Split must be less than or equal to 100')
      .test(
        'maxDigitsAfterDecimal',
        'Split % should be with max 2 decimal places',
        (number: any) => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .when('specialist_id', {
        is: (val: any) => Boolean(val),
        then: yup
          .number()
          .typeError('Split has to be numbers only')
          .required('Split is required!'),
      });
  }),
});
