import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { SelectItem } from '../../MultiSelectWrapper/MultiSelectWrapper';
import * as S from './FormSelect.styled';

interface FormSelectProps {
  name: string;
  label: string;
  items: SelectItem[];
  onChange?: (event: SelectChangeEvent) => void;
  disabled?: boolean;
}

export const FormSelect = ({
  name,
  label,
  items,
  onChange,
  disabled,
}: FormSelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl>
            <InputLabel id={`form-select${name}`}>{label}</InputLabel>
            <Select
              labelId={`form-select${name}`}
              input={<OutlinedInput label={label} />}
              disabled={disabled}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              {...field}
              onChange={(event) => {
                if (onChange) {
                  onChange(event);
                }
                field.onChange(event);
              }}
            >
              {items.map((selectItem: SelectItem) => (
                <MenuItem key={selectItem.value} value={selectItem.value}>
                  {selectItem.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
