import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    top: '55px',
  },
});

interface SelectInputProps {
  label: string;
  value: string;
  itemValue?: string;
  selector?: string;
  itemSecondValue?: string;
  returnedValue?: string;
  disabled?: boolean;
  name: string;
  color?: 'white' | 'black';
  handleChange: (e: SelectChangeEvent) => void;
  items: any[] | null;
}

export const SelectInput = ({
  label,
  value,
  itemValue,
  selector = '',
  itemSecondValue,
  name,
  returnedValue = '',
  disabled = false,
  color = 'white',
  handleChange,
  items,
}: SelectInputProps) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }: any) => (
          <FormControl
            defaultValue={''}
            fullWidth={true}
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #000000 inset',
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              disabled={disabled}
              style={{
                color: `${
                  disabled
                    ? '#999999'
                    : color === 'white'
                    ? '#ffffff'
                    : '#000000'
                }`,
              }}
              defaultValue={''}
            >
              {label}
            </InputLabel>
            <Select
              {...field}
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              name={name}
              label={label}
              disabled={disabled}
              onChange={(e: SelectChangeEvent) => {
                field.onChange(e);
                handleChange(e);
              }}
              defaultValue={value}
            >
              {items?.map((item: any, index: number) => (
                <MenuItem
                  key={index}
                  defaultValue={item[returnedValue]}
                  value={item[returnedValue]}
                >
                  {(itemValue ? item[itemValue] : '') +
                    selector +
                    (itemSecondValue ? item[itemSecondValue] : '')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
