import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  BaseSelectItem,
  PROVIDER_FILTER_VALUES,
} from '../GridFilters.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface ProviderFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ProviderFilter = ({ onFiltersChange }: ProviderFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="provider-filter">Provider</InputLabel>
      <Select
        labelId="provider-filter"
        defaultValue={PROVIDER_FILTER_VALUES[0].value}
        label="Provider"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            provider: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        {PROVIDER_FILTER_VALUES.map(({ value, label }: BaseSelectItem) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
