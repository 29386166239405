import { ChangeEvent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components';
import { TextAreaInput } from 'components/Inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'components/Inputs/TextInput/TextInput';
import { SingleImageUploader } from 'components/SingleImageUploader/SingleImageUploader';

import {
  createBuildingRules,
  updateBuildingRulesById,
} from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import { IBuildingRules } from 'types';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  mainInfo: {
    display: 'flex',
  },
  content: {
    width: '100%',
    marginTop: '82px',
  },
  editContent: {
    width: '100%',
    maxWidth: '367px',
  },
  cardBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  textArea: {
    minHeightL: '500px',
  },
  saveButton: {
    width: '100%',
    marginTop: '42px',
  },
});

interface BuildingMainInfoProps {
  canUpdate?: boolean;
  validationFormState?: UseFormReturn;
  notUploadedImage?: string;
  setNotUploadedImage?: (image: string) => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const BuildingRules = ({
  handleChange,
  validationFormState,
  canUpdate = false,
  notUploadedImage,
  setNotUploadedImage,
}: BuildingMainInfoProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentBuilding, loading } = useSelector(
    (state: RootState) => state.buildings,
  );

  useEffect(() => {
    setEditedData({ ...currentBuilding?.building_rules });
  }, [currentBuilding]);

  const [editedData, setEditedData] = useState<IBuildingRules>({
    description: currentBuilding?.building_rules?.description,
    title: currentBuilding?.building_rules?.title,
    image: currentBuilding?.building_rules?.image,
  });

  useEffect(() => {
    if (editedData) {
      validationFormState?.setValue('description', editedData.description);
      validationFormState?.setValue('title', editedData.title);
    }
  }, [editedData]);

  const handleChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });

    if (handleChange) {
      handleChange(e);
    }
  };

  const handleSaveChanges = () => {
    if (currentBuilding?.building_rules?.id) {
      dispatch(
        updateBuildingRulesById({
          buildingRules: editedData,
          building_id: currentBuilding?.id,
          post_id: currentBuilding?.building_rules?.id,
        }),
      );
    } else {
      dispatch(
        createBuildingRules({
          buildingRules: editedData,
          building_id: currentBuilding?.id,
        }),
      );
    }
    navigate('/info');
  };

  if (loading) {
    return (
      <Box textAlign={'center'} mt={2}>
        Loading...
      </Box>
    );
  }

  return (
    <Box className={classes.mainInfo} pb={5}>
      <SingleImageUploader
        notUploadedImage={notUploadedImage}
        setNotUploadedImage={setNotUploadedImage}
        image={editedData?.image}
        withUploadBtn={true}
      />
      <Box className={classes.content}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box className={classes.editContent}>
            <Box mb={5}>
              <TextInput
                color={'black'}
                name={'title'}
                label={'Title'}
                value={editedData.title}
                handleChange={handleChangeLocal}
              />
            </Box>
            <TextAreaInput
              rows={20}
              color={'black'}
              name={'description'}
              label={'Description'}
              handleChange={handleChangeLocal}
              value={editedData.description}
            />
            {canUpdate && (
              <CustomButton
                title={'Save'}
                color={'primary'}
                variant={'buttonMedium'}
                handleClick={validationFormState?.handleSubmit(
                  handleSaveChanges,
                )}
                additionalClass={classes.saveButton}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
