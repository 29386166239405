import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Control = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgba(38, 51, 77, 0.03);
  border: 2px solid ${(props: any) => props.theme.palette.grey.blue97};
  background-color: white;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
`;

export const ControlImage = styled.img``;

export const DateRange = styled.div`
  font-size: 12px;
  font-family: 'GraphikMedium', sans-serif;
  margin: 0 30px;
`;
