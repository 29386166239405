import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import EmptyImage from 'assets/empty-photo.png';

import * as SInfoPage from 'components/InfoPage/InfoPage.styled';

import { Event } from 'store/events/events.types';

import BalloonsIcon from '../../../../assets/icons/balloons.svg';
import Copy from '../../../../assets/icons/copy.svg';
import EditInfo from '../../../../assets/icons/edit.svg';
import ProviderIcon from '../../../../assets/icons/elite.svg';
import HomeIcon from '../../../../assets/icons/home.svg';
import LocationIcon from '../../../../assets/icons/location.svg';
import PriceIcon from '../../../../assets/icons/price.svg';
import SpecialistsIcon from '../../../../assets/icons/specialists.svg';
import StopIcon from '../../../../assets/icons/stop.svg';
import TimeIcon from '../../../../assets/icons/time.svg';
import { CustomButton } from '../../../../components';
import { AddRecordingEventModal } from '../../../../components/AddRecordingEventModal/AddRecordingEventModal';
import GoldLabel from '../../../../components/GoldLabel/GoldLabel';
import { PERMISSIONS } from '../../../../constants/Roles';
import { useHasPermissions } from '../../../../hooks/useHasPermissions';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import { EVENT_STATUSES, EVENT_TYPES } from '../../Events.constants';
import * as S from './EventInfo.styled';

interface EventInfoProps {
  event: Event;
  saveEvent: (values: any) => Promise<void>;
}

export const EventInfo = ({ event, saveEvent }: EventInfoProps) => {
  const navigate = useNavigate();
  const [isAddRecordingModalVisible, setIsAddRecordingModalVisible] =
    useState(false);
  const canUseEventActions = useHasPermissions(
    PERMISSIONS.CAN_USE_EVENT_ACTIONS,
  );

  return (
    <S.Container>
      <S.ImageAndData>
        <S.EventImage src={event.image || EmptyImage} />
        <S.EventData>
          <S.EventActions>
            {event.status === EVENT_STATUSES.FINISHED && (
              <CustomButton
                title="Add a recording"
                variant="buttonMedium"
                color="primary"
                beforeIcon={StopIcon}
                handleClick={() => setIsAddRecordingModalVisible(true)}
              />
            )}
            {event.status === EVENT_STATUSES.UPCOMING && canUseEventActions && (
              <CustomButton
                title="Edit info"
                variant="buttonMedium"
                color="primary"
                afterIcon={EditInfo}
                handleClick={() => navigate(`/events/event-form/${event.id}`)}
              />
            )}
          </S.EventActions>
          <SInfoPage.EntityName>{event.name}</SInfoPage.EntityName>
          {event.specialist && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={ProviderIcon} alt="" />
              <SInfoPage.InfoItemName>{`${event.specialist.first_name} ${event.specialist.last_name}`}</SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {event.service_category && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={BalloonsIcon} alt="" />
              <SInfoPage.InfoItemName>
                {event.service_category.name}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {(event.price || event.price === 0) && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={PriceIcon} alt="" />
              <SInfoPage.InfoItemName>
                <GoldLabel
                  text={
                    event.type === EVENT_TYPES.FREE
                      ? 'FREE'
                      : `${event.price.toString()}$`
                  }
                  variant={'h5'}
                />
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {event.capacity && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={SpecialistsIcon} alt="" />
              <SInfoPage.InfoItemName>{event.capacity}</SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {event.start_date_time && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={TimeIcon} alt="" />
              <SInfoPage.InfoItemName>
                {format(parseISO(event.start_date_time), DATE_TIME_FORMAT)}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {Boolean(event.buildings.length) && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={HomeIcon} alt="" />
              <SInfoPage.InfoItemName>
                {event.buildings
                  .map(
                    (building) =>
                      `${building.building_code} ${building.building_name}`,
                  )
                  .join(', ')}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {event.format && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={LocationIcon} alt="" />
              <SInfoPage.InfoItemName
                isCapitalized={true}
              >{`${event.format}`}</SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {event.link && (
            <S.LinkWrapper>
              <S.EventLink href={event.link} target="_blank" rel="noreferrer">
                {event.link}
              </S.EventLink>
              <CopyToClipboard text={event.link}>
                <img src={Copy} alt="specialists" />
              </CopyToClipboard>
            </S.LinkWrapper>
          )}
        </S.EventData>
      </S.ImageAndData>
      <SInfoPage.Description>
        <SInfoPage.DescriptionHeader>About</SInfoPage.DescriptionHeader>
        {event.description && (
          <SInfoPage.DescriptionInfo>
            {event.description}
          </SInfoPage.DescriptionInfo>
        )}
      </SInfoPage.Description>
      <AddRecordingEventModal
        isOpen={isAddRecordingModalVisible}
        saveEvent={saveEvent}
        event={event}
        closeModal={() => setIsAddRecordingModalVisible(false)}
      />
    </S.Container>
  );
};
