import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, TextField, Typography } from '@mui/material';

import { SelectItem } from '../../MultiSelectWrapper/MultiSelectWrapper';
import * as S from './FormAutocomplete.styled';

interface FormMultiSelectProps {
  name: string;
  label: string;
  items: SelectItem[];
  onChange?: (value: SelectItem | null) => void;
  disabled?: boolean;
  getOptionsDisabled?: (Option: SelectItem) => boolean;
}

export const FormAutocomplete = ({
  name,
  label,
  items,
  onChange,
  disabled,
  getOptionsDisabled,
}: FormMultiSelectProps) => {
  const { control } = useFormContext();

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, onChange: onFieldChange, value: fieldValue, onBlur },
          fieldState: { error },
        }) => {
          return (
            <Fragment>
              <Autocomplete
                disablePortal={true}
                options={items}
                getOptionLabel={(option) => option.label}
                disabled={disabled}
                value={
                  fieldValue
                    ? items.find((item) => item.value === fieldValue) ?? null
                    : null
                }
                renderInput={(params) => (
                  <TextField {...params} label={label} inputRef={ref} />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionDisabled={getOptionsDisabled || undefined}
                onBlur={() => onBlur()}
                onChange={(_, option) => {
                  if (onChange) {
                    onChange(option);
                  }
                  onFieldChange(option ? option?.value : null);
                }}
              />
              {error && (
                <Typography
                  variant="helperText"
                  color="error"
                  className="error-message"
                >
                  <>{error?.message}</>
                </Typography>
              )}
            </Fragment>
          );
        }}
      />
    </S.Container>
  );
};
