import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DashboardWrapper } from 'components';

import { BackButton } from '../../../components/BackButton/BackButton';
import { PayrollDetails } from './PayrollDetails';

export const PayrollDetailsWrapper = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const specialistName = new URLSearchParams(location.search).get(
    'specialistName',
  );

  const handleClickBack = () => {
    navigate('/billings', {
      state: {
        activeManagementTab: 'payrolls',
      },
    });
  };

  return (
    <DashboardWrapper>
      <BackButton
        name={`Payroll info for ${specialistName}`}
        handleClickBack={handleClickBack}
      />
      {params?.id && <PayrollDetails id={params.id} />}
    </DashboardWrapper>
  );
};
