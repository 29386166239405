import { useSelector } from 'react-redux';

import { DashboardWrapper } from '../../components';
import { RootState } from '../../store/rootReducer';
import { ScheduleList } from '../Schedule/List/ScheduleList';
import { SCHEDULE_PAGES } from '../Schedule/List/ScheduleList.constants';
import * as S from './SpecialistSchedule.styled';

export const SpecialistSchedule = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <DashboardWrapper>
      <S.Header>Schedule</S.Header>
      {user && (
        <ScheduleList
          schedulePageType={SCHEDULE_PAGES.SPECIALIST}
          id={user.specialist_id}
        />
      )}
    </DashboardWrapper>
  );
};
