/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PeopleState } from 'types/index';

import {
  changeStatus,
  deleteUser,
  getAllPeople,
  getAllServiceSpecialists,
  getCanCurrentUserInviteRoles,
  getResidentNote,
  sendInvite,
  updateResidentNote,
} from './people.actions';

export const initialState: PeopleState = Object.freeze({
  allUsers: [],
  specialists: [],
  canUserInviteRoles: [],
  currentSpecialist: null,
  timeslots: null,
  message: '',
  isMessageShow: false,
  notes: '',
  count: 0,
  loading: false,
  error: null,
  status: 'idle',
});

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    showMessage: (state) => {
      state.isMessageShow = false;
      state.error = '';
    },
    clearPeopleError: (state) => {
      state.error = null;
    },
    clearSpecialists: (state) => {
      state.specialists = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sendInvite.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.message = '';
    });

    builder.addCase(sendInvite.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'Invite was successfully sent';
    });

    builder.addCase(sendInvite.rejected, (state) => {
      state.status = 'rejected';
      state.loading = false;
    });

    // ----------------------------------------------------------------

    builder.addCase(getCanCurrentUserInviteRoles.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.message = '';
    });

    builder.addCase(getCanCurrentUserInviteRoles.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.canUserInviteRoles = action.payload.roles;
    });

    builder.addCase(
      getCanCurrentUserInviteRoles.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(getAllPeople.pending, (state) => {
      state.status = 'pending';
      state.message = '';
      state.loading = true;
      state.currentSpecialist = null;
      state.allUsers = [];
    });

    builder.addCase(getAllPeople.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.allUsers = action.payload.result;
      state.count = action.payload.count;
    });

    builder.addCase(
      getAllPeople.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
    // ----------------------------------------------------------------

    builder.addCase(changeStatus.pending, (state) => {
      state.status = 'pending';
      state.message = '';
      state.loading = true;
      state.currentSpecialist = null;
      state.allUsers = [];
    });

    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(
      changeStatus.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
    // ----------------------------------------------------------------
    builder.addCase(deleteUser.pending, (state) => {
      state.status = 'pending';
      state.message = '';
      state.loading = true;
      state.currentSpecialist = null;
      state.allUsers = [];
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'User was successfully deleted';
    });

    builder.addCase(
      deleteUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    builder.addCase(getResidentNote.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.message = '';
      state.notes = '';
    });

    builder.addCase(getResidentNote.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.notes = action.payload.admin_note;
    });

    builder.addCase(
      getResidentNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
    // ----------------------------------------------------------------
    builder.addCase(updateResidentNote.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.message = '';
      state.notes = '';
    });

    builder.addCase(updateResidentNote.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'Note was updated';
      // state.notes = action.payload.admin_note;
    });

    builder.addCase(
      updateResidentNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    builder.addCase(getAllServiceSpecialists.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.count = 0;
      state.currentSpecialist = null;
      state.specialists = [];
    });

    builder.addCase(getAllServiceSpecialists.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.specialists = action.payload.result;
      state.count = action.payload.count;
      state.message = '';
    });

    builder.addCase(
      getAllServiceSpecialists.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
  },
});

// actions from slice
export const { clearPeopleError, clearSpecialists, showMessage } =
  peopleSlice.actions;

// The reducer
export default peopleSlice.reducer;
