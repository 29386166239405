interface PlusProps {
  color: 'black' | 'gold';
}

export const Plus = ({ color }: PlusProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="5"
        y1="10"
        x2="15"
        y2="10"
        stroke={color === 'black' ? '#000000' : '#C09006'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="10"
        y1="15"
        x2="10"
        y2="5"
        stroke={color === 'black' ? '#000000' : '#C09006'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
