import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSnackbar } from 'notistack';

import CrossIcon from 'assets/icons/cross.svg';

import { CustomButton } from 'components';
import { BaseFormInput } from 'components/HookFormWrappers/BaseFormInput/BaseFormInput';
import { useAppDispatch } from 'hooks/useAppSelector';

import {
  assignServiceToSpecialist,
  getSpecialistsServices,
} from 'store/service-specialists/service-specialists.actions';
import { GetServiceListParams } from 'store/services/services.actions';
import { Service } from 'store/services/services.types';

import { SPLIT_MODAL_MODES } from '../useSplitModal';
import { SplitFormSchema } from './SplitForm.schema';
import * as S from './SplitForm.styled';
import { SplitFormValues } from './SplitForm.types';

interface SplitModalProps {
  mode: SPLIT_MODAL_MODES;
  handleCloseSplitModal: () => void;
  specialistName: string;
  specialistId: string;
  service: Service | null;
  params: GetServiceListParams;
}

export const SplitForm = ({
  mode,
  handleCloseSplitModal,
  specialistName,
  specialistId,
  service,
  params,
}: SplitModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(SplitFormSchema),
    values: {
      split: service?.split || 0,
    } as SplitFormValues,
  });

  const handleAssignSpecialist = (
    serviceId: string,
    values: SplitFormValues,
  ) => {
    dispatch(
      assignServiceToSpecialist({
        specialistId,
        serviceId,
        data: values,
      }),
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar(
          `Service ${service?.name} was assigned. Please adjust your schedule accordingly.`,
          {
            variant: 'success',
          },
        );
        dispatch(
          getSpecialistsServices({
            params: { ...params, page: 1 },
            specialistId,
          }),
        );
        handleCloseSplitModal();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      });
  };

  const detailsString = `${specialistName} for ${service?.name}`;

  const handleSubmitForm = (values: SplitFormValues) => {
    handleAssignSpecialist(service?.id as string, values);
  };

  return (
    <S.Container>
      <S.Header>
        {mode === SPLIT_MODAL_MODES.ASSIGN && 'Assign Service'}
        {mode === SPLIT_MODAL_MODES.EDIT && 'Edit Split'}
        <S.Close onClick={handleCloseSplitModal}>
          <img src={CrossIcon} alt="" />
        </S.Close>
      </S.Header>
      <FormProvider {...methods}>
        <S.Details title={detailsString}>{detailsString}</S.Details>
        <S.FormWrapper>
          <BaseFormInput name="split" label="Split %" startAdornment="%" />
        </S.FormWrapper>
        <S.ButtonsWrapper>
          <CustomButton
            title="Cancel"
            color="gray"
            variant="buttonMedium"
            handleClick={handleCloseSplitModal}
          />
          <CustomButton
            title="Save"
            color="primary"
            variant="buttonMedium"
            handleClick={methods.handleSubmit(handleSubmitForm)}
          />
        </S.ButtonsWrapper>
      </FormProvider>
    </S.Container>
  );
};
