import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;
  padding-left: 25px;

  fieldset {
    border-color: black;
  }

  .error-message {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
  }
`;

export const CountryCode = styled.div`
  position: absolute;
  top: 19px;
  left: 1px;
  font-size: 14px;
  font-family: 'GraphikMedium', sans-serif;
`;
