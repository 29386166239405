import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
  width: 300px;
  margin-left: 20px;

  & > .MuiFormControl-root:first-of-type {
    margin-right: 20px;
  }
`;
