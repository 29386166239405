import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import { GHOST_BUILDING_ID } from 'constants/constValue';
import { IBuildingPostCreate, IBuildingRules } from 'types';
import http from 'utils/http';

export interface GetBuildingPosts {
  activePage?: number;
  limit?: number;
  id?: string;
}

export const getBuildingPosts = createAsyncThunk(
  'posts/GetBuildingPosts',
  async ({ limit, activePage, id }: GetBuildingPosts, thunkAPI: any) => {
    try {
      const url = `${endpoints.posts.infoPostsByBuilding(id as string)}${
        !!limit ? `&page_size=${limit}&page=${activePage}` : ''
      }`;
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in getting buildings');
    }
  },
);

interface GetBuildingPostsById {
  building_id?: string;
  post_id: string;
}

export const getBuildingPostsById = createAsyncThunk(
  'posts/getBuildingPostsById',
  async (
    { post_id, building_id = GHOST_BUILDING_ID }: GetBuildingPostsById,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.posts.buildingPostById(building_id, post_id);
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in getting buildings');
    }
  },
);

export const createPost = createAsyncThunk(
  'posts/createPost',
  async ({ data, is_visible_for_all_buildings }: any, thunkAPI: any) => {
    try {
      const url =
        endpoints.posts.createPost() +
        '?is_visible_for_all_buildings=' +
        is_visible_for_all_buildings;
      const response = await http.post(url, data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create post');
    }
  },
);

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async (
    { data, post_id, is_visible_for_all_buildings }: any,
    thunkAPI: any,
  ) => {
    try {
      const url =
        endpoints.posts.updatePost(post_id) +
        '?is_visible_for_all_buildings=' +
        is_visible_for_all_buildings;
      const response = await http.put(url, data);
      // thunkAPI.dispatch(getBuildingPostsById({ building_id, post_id }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in update post');
    }
  },
);

interface ImageBuildingPostUploader {
  image: FormData;
  url: string;
  building_id: string;
  post_id: string;
}

export const uploadImageToPost = createAsyncThunk(
  'posts/uploadImageToPost',
  async (
    { image, url, building_id, post_id }: ImageBuildingPostUploader,
    thunkAPI: any,
  ) => {
    try {
      const response = await http.put(url, image, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      thunkAPI.dispatch(getBuildingPostsById({ building_id, post_id }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in image upload');
    }
  },
);

interface ICreateBuildingPost {
  building_id: string;
  building_post: IBuildingPostCreate;
}

export const createBuildingPost = createAsyncThunk(
  'posts/createBuildingPost',
  async (
    { building_post, building_id }: ICreateBuildingPost,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.posts.infoPostsByBuilding(building_id);
      const response = await http.post(url, building_post);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create post');
    }
  },
);

interface IUpdateBuildingPost {
  building_id: string;
  post_id: string;
  building_post: IBuildingRules;
}

export const updateBuildingPostById = createAsyncThunk(
  'posts/updateBuildingPostById',
  async (
    { building_post, building_id, post_id }: IUpdateBuildingPost,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.posts.buildingPostById(building_id, post_id);
      const response = await http.put(url, building_post);
      thunkAPI.dispatch(getBuildingPostsById({ building_id, post_id }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in update post');
    }
  },
);

interface ImageBuildingPostUploader {
  image: FormData;
  url: string;
  building_id: string;
  post_id: string;
}

export const imageBuildingPostUploader = createAsyncThunk(
  'posts/imageBuildingPostUploader',
  async (
    { image, url, building_id, post_id }: ImageBuildingPostUploader,
    thunkAPI: any,
  ) => {
    try {
      const response = await http.put(url, image, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      thunkAPI.dispatch(getBuildingPostsById({ building_id, post_id }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in image upload');
    }
  },
);

export interface DeleteBuildingPostById {
  post_id: string;
  building_id?: string;
  activePage?: number;
}

export const deleteBuildingPostById = createAsyncThunk(
  'posts/deleteBuildingPostById',
  async (
    {
      building_id = GHOST_BUILDING_ID,
      post_id,
      activePage,
    }: DeleteBuildingPostById,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.posts.buildingPostById(building_id, post_id);
      const response = await http.delete(url);
      thunkAPI.dispatch(
        getBuildingPosts({
          activePage: activePage ? activePage : 1,
          limit: 6,
          id: building_id,
        }),
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);
