import { ChangeEvent } from 'react';

import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { makeStyles } from '@mui/styles';
import { OverridableStringUnion } from '@mui/types';
import classNames from 'classnames';

const useStyles = makeStyles({
  label: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  textBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#D5A419',
  },
});

interface UploadButtonProps {
  title: string;
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  icon?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  additionalClasses?: string;
  multiple?: boolean;
  name?: string;
  disabled?: boolean;
}

export const UploadButton = ({
  title,
  variant = 'subtitle1',
  onChange,
  additionalClasses,
  icon,
  multiple = false,
  name,
  disabled = false,
}: UploadButtonProps) => {
  const classes = useStyles();

  return (
    <label htmlFor="contained-button-file" className={classes.label}>
      <input
        accept="image/*"
        id="contained-button-file"
        type="file"
        className={classes.input}
        onChange={onChange}
        multiple={multiple}
        name={name}
        disabled={disabled}
      />
      <Box className={classNames(classes.textBlock, additionalClasses)}>
        <img src={icon} alt="upload" />
        <Typography variant={variant} component="span" ml={1}>
          {title}
        </Typography>
      </Box>
    </label>
  );
};
