import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    top: '55px',
  },
  menuItem: {
    padding: 0,
    '&.MuiMenuItem-root': {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },
  itemBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  selectedItem: {
    backgroundColor: '#F3E9CB',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CheckmarkInputProps {
  value: string[];
  label: string;
  name: string;
  selector?: string;
  itemSecondValue?: string;
  disabled?: boolean;
  color?: 'white' | 'black';
  items: any[];
  selectedId: string[];
  itemValue?: string;
  handleChange: (e: string[]) => void;
  idReturn?: (id: string) => void;
}

export const CheckmarksInput = ({
  value,
  label,
  name,
  selector = ' ',
  disabled = false,
  color = 'white',
  items,
  selectedId,
  itemValue = 'id',
  itemSecondValue = 'name',
  handleChange,
  idReturn,
}: CheckmarkInputProps) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        render={({ field }: any) => (
          <FormControl
            fullWidth={true}
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #000000 inset',
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{
                color: `${
                  disabled
                    ? '#999999'
                    : color === 'white'
                    ? '#ffffff'
                    : '#000000'
                }`,
              }}
            >
              {label}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              disabled={disabled}
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
              }}
              multiple={true}
              value={value}
              name={name}
              onChange={(event: SelectChangeEvent<typeof items>) => {
                const { target } = event;

                const newValue =
                  typeof target.value === 'string'
                    ? target.value.split(',')
                    : target.value;
                handleChange(newValue);
                field.onChange(newValue);
              }}
              input={<OutlinedInput label={label} />}
              renderValue={(selected: any) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {items?.map((item: any) => (
                <MenuItem
                  key={item.id}
                  value={`${item[itemValue]}${selector}${item[itemSecondValue]}`}
                  onClick={() => idReturn && idReturn(item.id)}
                  className={classes.menuItem}
                >
                  <Box
                    className={classNames(classes.itemBox, {
                      [classes.selectedItem]: selectedId?.includes(item?.id),
                    })}
                  >
                    <Checkbox checked={selectedId?.includes(item?.id)} />
                    <ListItemText
                      primary={
                        <Box>
                          {item[itemValue]}
                          {selector}
                          {item[itemSecondValue]}
                          <Typography hidden={true}>{item.id}</Typography>
                        </Box>
                      }
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
