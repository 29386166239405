import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfWeek, startOfWeek } from 'date-fns';

import { STORE_STATUSES } from '../store.constants';
import {
  addTimeSlots,
  deleteTimeSlot,
  getDaysWithAvailableSlotsList,
  getFreeSlotsByDay,
  getTimeSlotById,
  getTimeSlots,
  updateTimeSlots,
} from './time-slots.actions';
import {
  FreeSlotsList,
  TimeSlotsList,
  TimeSlotsState,
} from './time-slots.types';

const TIME_SLOTS_LIST_DEFAULT_VALUES = {
  list: null,
  params: {
    start_date: startOfWeek(new Date(), {
      weekStartsOn: 1,
    }),
    end_date: endOfWeek(new Date(), {
      weekStartsOn: 1,
    }),
  },
};

const FREE_SLOTS_LIST_DEFAULT = {
  list: null,
  params: {},
};

export const initialState: TimeSlotsState = Object.freeze({
  timeSlotsList: TIME_SLOTS_LIST_DEFAULT_VALUES,
  freeSlotsList: FREE_SLOTS_LIST_DEFAULT,
  daysWithAvailableSlotsList: null,
  currentTimeSlot: null,
  status: STORE_STATUSES.IDLE,
  loading: false,
  error: null,
});

const timeSlotsSlice = createSlice({
  name: 'timeSlots',
  initialState,
  reducers: {
    clearTimeSlotsList: (state) => {
      state.timeSlotsList = {
        ...TIME_SLOTS_LIST_DEFAULT_VALUES,
      };
    },
    clearFreeTimeSlots: (state) => {
      state.freeSlotsList = {
        ...FREE_SLOTS_LIST_DEFAULT,
      };
    },
    clearDaysWithAvailableSlotsList: (state) => {
      state.daysWithAvailableSlotsList = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTimeSlots.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getTimeSlots.fulfilled,
      (state, action: PayloadAction<TimeSlotsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.timeSlotsList = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getTimeSlots.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getFreeSlotsByDay.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getFreeSlotsByDay.fulfilled,
      (state, action: PayloadAction<FreeSlotsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.freeSlotsList = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getFreeSlotsByDay.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getDaysWithAvailableSlotsList.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getDaysWithAvailableSlotsList.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.daysWithAvailableSlotsList = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getDaysWithAvailableSlotsList.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteTimeSlot.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(deleteTimeSlot.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(deleteTimeSlot.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(addTimeSlots.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(addTimeSlots.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(addTimeSlots.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(getTimeSlotById.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getTimeSlotById.fulfilled, (state, action) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.currentTimeSlot = action.payload;
      state.error = false;
    });

    builder.addCase(getTimeSlotById.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(updateTimeSlots.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(updateTimeSlots.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(updateTimeSlots.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  clearTimeSlotsList,
  clearFreeTimeSlots,
  clearDaysWithAvailableSlotsList,
} = timeSlotsSlice.actions;

export default timeSlotsSlice.reducer;
