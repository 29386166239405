import { createAsyncThunk } from '@reduxjs/toolkit';
import { main } from 'config';

import http from 'utils/http';

import { BaseError } from '../store.constants';
import { GetServiceTypesParams, ServiceTypesList } from './service-types.types';

export const getServiceTypes = createAsyncThunk<
  ServiceTypesList,
  GetServiceTypesParams,
  { rejectValue: BaseError }
>('serviceTypes/getServiceTypes', async (params: GetServiceTypesParams) => {
  const response = await http.get(main.serviceTypes.root, {
    params: {
      ...params,
    },
  });

  return {
    list: response.data,
    params,
  };
});
