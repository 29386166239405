import React, { useEffect } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';

import { CustomPagination } from '../../../components/CustomPagination/CustomPagination';
import { ColumnType } from '../../../components/GridElements/GridCommonConfigs';
import { GridFilters } from '../../../components/GridElements/GridFilters/GridFilters';
import { LIMIT } from '../../../components/GridElements/GridFilters/GridFilters.constants';
import { FiltersParamsTypes } from '../../../components/GridElements/GridFilters/GridFilters.types';
import { OverlayLoader } from '../../../components/Loaders/OverlayLoader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppSelector';
import { getPayrollById } from '../../../store/billings/billings.actions';
import { PayrollTransaction } from '../../../store/billings/billings.types';
import { RootState } from '../../../store/rootReducer';
import { TABLE_CONTAINER_STYLES, TABLE_STYLES } from '../../../utils/constants';
import { DEFAULT_PARAMS } from '../Payrolls/Payrolls.constants';
import { PAYROLL_DETAILS_LIST_CONFIG } from './PayrollDetails.config';
import * as S from './PayrollDetails.styled';

interface PayrollDetailsProps {
  id: string;
}

export const PayrollDetails = ({ id }: PayrollDetailsProps) => {
  const dispatch = useAppDispatch();
  const {
    currentPayroll: { list, params },
    loading: isLoading,
  } = useAppSelector((state: RootState) => state.billings);

  useEffect(() => {
    dispatch(
      getPayrollById({
        id,
        params: DEFAULT_PARAMS,
      }),
    );
  }, []);

  const handleSearch = (value: string) => {
    dispatch(
      getPayrollById({
        id,
        params: {
          ...params,
          page: 1,
          search: value,
        },
      }),
    );
  };

  const handleFiltersChange = (filterParams: FiltersParamsTypes) => {
    dispatch(
      getPayrollById({
        id,
        params: {
          ...params,
          ...filterParams,
        },
      }),
    );
  };

  return (
    <S.Container>
      {isLoading && <OverlayLoader />}
      <GridFilters
        handleSearch={handleSearch}
        searchPlaceholder="Search..."
        handleFiltersChange={handleFiltersChange}
        config={{
          yearMonthFilter: true,
        }}
      />
      <TableContainer component={Paper} sx={TABLE_CONTAINER_STYLES}>
        <Table sx={TABLE_STYLES} aria-label="simple table">
          <TableHead>
            <TableRow>
              {PAYROLL_DETAILS_LIST_CONFIG.map(
                (item: ColumnType<PayrollTransaction>) => (
                  <React.Fragment key={item.label}>
                    {item.headerComponent(item)}
                  </React.Fragment>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(list?.result.length) &&
              list?.result?.map((payrollTransaction: PayrollTransaction) => (
                <TableRow hover={true} key={payrollTransaction.id}>
                  {PAYROLL_DETAILS_LIST_CONFIG.map(
                    (item: ColumnType<PayrollTransaction>) => (
                      <React.Fragment key={item.label}>
                        {item.cellComponent(item, payrollTransaction)}
                      </React.Fragment>
                    ),
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <S.TotalSection>
        Total{' '}
        {params.month &&
          params.year &&
          `for ${format(
            new Date(params.year as number, (params.month as number) - 1, 1),
            'MMMM yyyy',
          )}`}
        <S.TotalSplits>
          <S.TotalSplit>
            <S.TotalSplitLabel>Service Specialist Split:</S.TotalSplitLabel>$
            {list?.sum_specialist_profit || 0}
          </S.TotalSplit>
          <S.TotalSplit>
            <S.TotalSplitLabel>Elite Amenity Split:</S.TotalSplitLabel>$
            {list?.sum_amenity_profit || 0}
          </S.TotalSplit>
        </S.TotalSplits>
      </S.TotalSection>
      {Boolean(list?.count) && (
        <CustomPagination
          pages={Math.ceil(list?.count / LIMIT)}
          handleChange={(e: React.ChangeEvent<unknown>, value: number) => {
            dispatch(
              getPayrollById({
                id,
                params: {
                  ...params,
                  page: value,
                },
              }),
            );
          }}
          activePage={params.page as number}
        />
      )}
    </S.Container>
  );
};
