import { Loader } from './Loader';
import * as S from './OverlayLoader.styled';

export const OverlayLoader = () => {
  return (
    <S.Container>
      <Loader size={70} color={'rgba(255, 255, 255, 0.5)'} />
    </S.Container>
  );
};
