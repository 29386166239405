import { ResponseBuilding } from '../../../../../../types/buildings';
import * as S from './BuildingList.styled';

interface BuildingListProps {
  buildings: ResponseBuilding[];
}

export const BuildingList = ({ buildings }: BuildingListProps) => {
  return (
    <S.Container>
      <S.Header>Available buildings</S.Header>
      {buildings.map((item: ResponseBuilding) => (
        <S.Building
          key={item.id}
        >{`${item.building_code} ${item.building_name}`}</S.Building>
      ))}
    </S.Container>
  );
};
