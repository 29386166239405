import { ChangeEvent, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Empty from 'assets/empty-photo.png';
import EmptyUser from 'assets/empty-user.jpg';
import Upload from 'assets/icons/upload-black.svg';

import { encodeInBase64 } from 'utils/HelperFunctions';

import { UploadButton } from '../UploadButton/UploadButton';

const useStyles = makeStyles({
  mainImageBlock: {
    position: 'relative',
  },
  mainImage: {
    borderRadius: '10px',
    width: '376px',
    height: '286px',
    overflow: 'hidden',
    position: 'relative',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  userIcon: {
    width: '218px',
    height: '218px',
    borderRadius: '50%',
  },
  imageListBlock: {
    maxWidth: '376px',
  },
  imageItem: {
    cursor: 'pointer',
    position: 'relative',
    height: '90px',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '10px',
    },
  },
  imageEmpty: {
    cursor: 'default',
  },
  uploadButton: {
    color: '#000000',
    backgroundColor: '#D5A419',
    borderRadius: '26px',
    width: '164px',
    padding: '15px 0',
    marginBottom: '32px',
    display: 'inline-flex',
  },
  deleteBtn: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '4px',
    right: '4px',
    cursor: 'pointer',
  },
  error: {
    position: 'absolute',
    left: 0,
    top: '-20px',
  },
});

interface ImagePickerProps {
  image?: string;
  userIconView?: boolean;
  withUploadBtn: boolean;
  notUploadedImage?: string;
  setNotUploadedImage?: (image: string) => void;
}

const MAX_IMAGE_SIZE = 5000000;

export const SingleImageUploader = ({
  image,
  userIconView,
  withUploadBtn,
  notUploadedImage,
  setNotUploadedImage,
}: ImagePickerProps) => {
  const classes = useStyles();
  const [errorUploaded, setErrorUploaded] = useState('');
  const [currentImage, setCurrentImage] = useState<string>('');

  useEffect(() => {
    if (image) {
      setCurrentImage(image);
    }
  }, [image]);

  useEffect(() => {
    if (notUploadedImage) {
      setCurrentImage(notUploadedImage);
    }
  }, [notUploadedImage]);

  const handleUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const filesUploaded = Array.from(event.target.files);

      if (filesUploaded[0].size > MAX_IMAGE_SIZE) {
        return setErrorUploaded('Max size of image 5mb');
      }

      const fileImage = await encodeInBase64(filesUploaded[0]).then(
        (data: any) => data,
      );

      if (setNotUploadedImage) {
        setNotUploadedImage(fileImage);
      }

      if (image) {
        setCurrentImage(image);
      }
      setErrorUploaded('');
    }
  };

  return (
    <>
      <Box mr={4} className={classes.mainImageBlock}>
        {withUploadBtn && (
          <UploadButton
            title={'Upload photo'}
            onChange={handleUploadImage}
            variant={'buttonMedium'}
            icon={Upload}
            additionalClasses={classes.uploadButton}
          />
        )}
        {errorUploaded && (
          <Box className={classes.error}>
            <Typography variant={'subtitle2'} color={'error'}>
              {errorUploaded}
            </Typography>
          </Box>
        )}
        <Box
          className={classNames(classes.mainImage, {
            [classes.userIcon]: userIconView,
          })}
        >
          <img
            src={currentImage ? currentImage : userIconView ? EmptyUser : Empty}
            alt="building"
          />
        </Box>
      </Box>
    </>
  );
};
