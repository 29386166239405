import { createAsyncThunk } from '@reduxjs/toolkit';
import { main } from 'config';

import http from 'utils/http';

import { BaseError } from '../store.constants';
import { BookEventData, EventList, GetEventListParams } from './events.types';

export const getEventsListForBooking = createAsyncThunk<
  EventList,
  GetEventListParams,
  { rejectValue: BaseError }
>('events/getEventsList', async (params: GetEventListParams) => {
  const response = await http.get(`${main.events.root}booking-list/`, {
    params,
  });

  return {
    list: response.data,
    params,
  };
});

export const bookEvent = createAsyncThunk<
  undefined,
  BookEventData,
  { rejectValue: BaseError }
>('services/bookEvent', async (data) => {
  const response = await http.post(main.eventRequests.root, data);

  return response.data;
});

export const rescheduleEvent = createAsyncThunk<
  undefined,
  { id: string; start_date_time: Date; close_registration_date_time: Date },
  { rejectValue: BaseError }
>(
  'services/rescheduleEvent',
  async ({ id, start_date_time, close_registration_date_time }) => {
    const response = await http.put(`${main.events.root}${id}/reschedule/`, {
      start_date_time,
      close_registration_date_time,
    });

    return response.data;
  },
);
