import React from 'react';

import { Typography } from '@mui/material';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { EntityStatus } from '../../../../components/EntityStatus/EntityStatus';
import { ENTITY_STATUSES } from '../../../../components/EntityStatus/EntityStatus.constants';
import { CellElement } from '../../../../components/GridElements/CellElement/CellElement';
import {
  ColumnType,
  GRID_HEADER_ALIGNMENTS,
} from '../../../../components/GridElements/GridCommonConfigs';
import { HeaderElement } from '../../../../components/GridElements/HeaderElement/HeaderElement';
import { TextWithAvatar } from '../../../../components/GridElements/TextWithAvatar/TextWithAvatar';
import { ResponseBuilding } from '../../../../types/buildings';
import * as S from './ServiceSpecialistList.styled';

export const SERVICE_SPECIALISTS_COLUMNS: ColumnType<ServiceSpecialist>[] = [
  {
    label: 'Service specialist',
    widthPercentage: 27,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      specialist: ServiceSpecialist,
    ) => (
      <CellElement<ServiceSpecialist> column={item}>
        <TextWithAvatar
          imgLink={specialist.account_icon}
          text={`${specialist.first_name} ${specialist.last_name}`}
          navigateLink={`/management/specialist/${specialist.id}`}
        />
      </CellElement>
    ),
  },
  {
    label: 'Building name(s)',
    widthPercentage: 23,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (item: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      specialist: ServiceSpecialist,
    ) => {
      const buildingsString = specialist.buildings
        .map(
          (building: ResponseBuilding) =>
            `${building.building_code} ${building.building_name}`,
        )
        .join(', ');

      return (
        <CellElement<ServiceSpecialist> column={item}>
          <Typography variant={'body2'}>
            <S.BuildingList title={buildingsString}>
              {Boolean(specialist.buildings.length) ? buildingsString : '-'}
            </S.BuildingList>
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Split EA',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (item: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      { split_ea_min, split_ea_max }: ServiceSpecialist,
    ) => {
      const splitString = () => {
        if (!split_ea_min) {
          return '-';
        }
        return split_ea_min === split_ea_max
          ? `${split_ea_min}%`
          : `${split_ea_min}% - ${split_ea_max}%`;
      };

      return (
        <CellElement<ServiceSpecialist> column={item}>
          <Typography variant={'body2'}>{splitString()}</Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Split',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (item: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      { split_min, split_max }: ServiceSpecialist,
    ) => {
      const splitString = () => {
        if (split_min === null) {
          return '-';
        }
        return split_min === split_max
          ? `${split_min}%`
          : `${split_min}% - ${split_max}%`;
      };

      return (
        <CellElement<ServiceSpecialist> column={item}>
          <Typography variant={'body2'}>{splitString()}</Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Status',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (item: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      { status }: ServiceSpecialist,
    ) => (
      <CellElement<ServiceSpecialist> column={item}>
        <EntityStatus type={status as ENTITY_STATUSES} />
      </CellElement>
    ),
  },
  {
    label: 'Actions',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.RIGHT,
    headerComponent: (item: ColumnType<ServiceSpecialist>) => (
      <HeaderElement<ServiceSpecialist> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<ServiceSpecialist>,
      _,
      actionsComponent?: React.ReactElement,
    ) => <CellElement column={item}>{actionsComponent}</CellElement>,
  },
];
