export enum ENTITY_STATUSES {
  ONBOARDING = 'onboarding',
  ACTIVE = 'active',
  ACCEPTED = 'accepted',
  APPROVED = 'approved',
  INACTIVE = 'inactive',
  DEACTIVATED = 'deactivated',
  PENDING = 'pending',
  NEW = 'new',
  ONGOING = 'ongoing',
  UPCOMING = 'upcoming',
  FINISHED = 'finished',
  PAID = 'paid',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  REFUNDED = 'refunded',
}

export enum ENTITY_CHANGE_STATUSES {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}
