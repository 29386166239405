import styled from '@emotion/styled/macro';

export const Container = styled.div`
  max-width: 650px;
  margin-top: 100px;
`;

export const Header = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 18px;
`;

export const Building = styled.div`
  font-family: 'RalewayMedium', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 12px;
  background-color: white;
  border-radius: 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: inline-flex;
`;
