import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ServiceType } from '../../../../store/service-types/service-types.types';
import { ALL_SERVICE_TYPES } from '../FiltersList/FiltersList.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface BuildingFilterProps {
  serviceTypes: ServiceType[];
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ServiceTypeFilter = ({
  serviceTypes,
  onFiltersChange,
}: BuildingFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="service-types-filter">Service Type</InputLabel>
      <Select
        labelId="service-type-filter"
        defaultValue={ALL_SERVICE_TYPES.value}
        label="Service Type"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            service_type: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        <MenuItem key={ALL_SERVICE_TYPES.value} value={ALL_SERVICE_TYPES.value}>
          {ALL_SERVICE_TYPES.name}
        </MenuItem>
        {serviceTypes.map(({ id, name }: ServiceType) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
