import { useState } from 'react';

import { TIME_SLOTS_EDITOR_TYPES } from '../ScheduleForms.constants';

interface UseAddTimeSlotsModalReturn {
  isTimeSlotsModalOpened: boolean;
  timeSlotsEditorType: TIME_SLOTS_EDITOR_TYPES;
  handleOpenTimeSlotsModal: (editorType: TIME_SLOTS_EDITOR_TYPES) => void;
  handleCloseTimeSlotsModal: () => void;
}

export const useAddTimeSlotsModal = (): UseAddTimeSlotsModalReturn => {
  const [isTimeSlotsModalOpened, setIsTimeSlotsModalOpened] = useState(false);
  const [timeSlotsEditorType, setTimeSlotsEditorType] =
    useState<TIME_SLOTS_EDITOR_TYPES>(TIME_SLOTS_EDITOR_TYPES.AVAILABLE_TIME);

  const handleOpenTimeSlotsModal = (editorType: TIME_SLOTS_EDITOR_TYPES) => {
    setIsTimeSlotsModalOpened(true);
    setTimeSlotsEditorType(editorType);
  };

  const handleCloseTimeSlotsModal = () => {
    setIsTimeSlotsModalOpened(false);
  };

  return {
    isTimeSlotsModalOpened,
    timeSlotsEditorType,
    handleOpenTimeSlotsModal,
    handleCloseTimeSlotsModal,
  };
};
