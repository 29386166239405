import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import {
  acceptServiceRequests,
  declineConciergeService,
  declineServiceRequests,
  getConciergeRequests,
  getServiceRequests,
  rescheduleServiceBooking,
  submitConciergeService,
} from './serviceRequests.actions';
import {
  ConciergeRequestsList,
  ServiceRequestsList,
  ServiceRequestsState,
} from './serviceRequests.types';

const REQUESTS_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

export const initialState: ServiceRequestsState = Object.freeze({
  serviceRequests: REQUESTS_LIST_DEFAULT,
  conciergeRequests: REQUESTS_LIST_DEFAULT,
  currentServiceRequest: null,
  currentConciergeRequest: null,
  serviceRequestMembers: [],
  error: '',
  message: '',
  count: 1,
  isMessageShow: false,
  loading: false,
  status: STORE_STATUSES.IDLE,
});

export const serviceRequestsSlice = createSlice({
  name: 'serviceRequests',
  initialState,
  reducers: {
    setCurrentServiceRequest: (state, action) => {
      state.currentServiceRequest = action.payload;
    },
    setCurrentConciergeRequest: (state, action) => {
      state.currentConciergeRequest = action.payload;
    },
    clearServiceRequest: (state) => {
      state.currentServiceRequest = null;
    },
    clearConciergeRequest: (state) => {
      state.currentServiceRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceRequests.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getServiceRequests.fulfilled,
      (state, action: PayloadAction<ServiceRequestsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.serviceRequests = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getServiceRequests.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(acceptServiceRequests.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(acceptServiceRequests.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(acceptServiceRequests.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(declineServiceRequests.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(declineServiceRequests.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(declineServiceRequests.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(rescheduleServiceBooking.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(rescheduleServiceBooking.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(rescheduleServiceBooking.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(getConciergeRequests.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getConciergeRequests.fulfilled,
      (state, action: PayloadAction<ConciergeRequestsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.conciergeRequests = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(getConciergeRequests.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(declineConciergeService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(declineConciergeService.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(declineConciergeService.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(submitConciergeService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(submitConciergeService.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(submitConciergeService.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  setCurrentServiceRequest,
  setCurrentConciergeRequest,
  clearServiceRequest,
  clearConciergeRequest,
} = serviceRequestsSlice.actions;

export default serviceRequestsSlice.reducer;
