import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import CheckMarkIcon from '../../../assets/icons/checkmark-black.svg';
import DeactivateIcon from '../../../assets/icons/deactivated.svg';
import DeleteIcon from '../../../assets/icons/delete-grey.svg';
import { CustomButton, DashboardWrapper } from '../../../components';
import { BackButton } from '../../../components/BackButton/BackButton';
import { BaseTabs } from '../../../components/BaseTabs/BaseTabs';
import { YesNoModal } from '../../../components/CustomModal/YesNoModal/YesNoModal';
import { OverlayLoader } from '../../../components/Loaders/OverlayLoader';
import { PERMISSIONS } from '../../../constants/Roles';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppSelector';
import { useConfirmationModal } from '../../../hooks/useConfirmationModal';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { getBuildings } from '../../../store/buildings/buildings.actions';
import { getPackages } from '../../../store/packages/packages.actions';
import { RootState } from '../../../store/rootReducer';
import {
  deleteService,
  getServiceById,
  updateServiceStatus,
} from '../../../store/services/services.actions';
import { Service } from '../../../store/services/services.types';
import { getServiceDetailsTabsConfig } from './ServiceDetails.constants';
import * as S from './ServiceDetails.styled';

export const ServiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { currentService, loading: isLoading } = useAppSelector(
    (state: RootState) => state.services,
  );
  const canActivateDeactivateService = useHasPermissions(
    PERMISSIONS.CAN_ACTIVATE_DEACTIVATE_SERVICE,
  );
  const canDeleteService = useHasPermissions(PERMISSIONS.CAN_DELETE_SERVICE);

  const navigateToList = () => {
    navigate('/services');
  };

  useEffect(() => {
    dispatch(getServiceById(id as string));
    dispatch(getBuildings({ page_size: 1000, page: 1, service: id }));
    dispatch(getPackages(id as string));
  }, []);

  const handleDeleteService = () => {
    handleOpenModal({
      title: 'Delete service',
      question: 'Do you want to delete service?',
      callback: () =>
        dispatch(deleteService(id as string))
          .unwrap()
          .then((res) => {
            enqueueSnackbar('Service was deleted successfully.', {
              variant: 'success',
            });
            navigateToList();
          })
          .catch((e) => {
            enqueueSnackbar(e.message, {
              variant: 'error',
            });
          }),
    });
  };

  const handleChangeStatus = (newState: boolean) => {
    handleOpenModal({
      title: 'Activate service',
      question: `Do you want to ${
        newState ? 'activate' : 'deactivate'
      } service?`,
      callback: () => {
        dispatch(
          updateServiceStatus({
            id: (currentService as Service).id,
            newState,
          }),
        )
          .unwrap()
          .then(() => {
            enqueueSnackbar(
              `Service was ${
                newState ? 'activated' : 'deactivated'
              } successfully.`,
              {
                variant: 'success',
              },
            );
          })
          .catch((e) => {
            enqueueSnackbar(e.message, {
              variant: 'error',
            });
          });
      },
    });
  };

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      <S.Container>
        {currentService && (
          <Fragment>
            <S.TopButtonsWrapper>
              <BackButton
                name={`${currentService?.name}`}
                handleClickBack={navigateToList}
              />
              <S.ButtonsWrapper>
                {canDeleteService && (
                  <CustomButton
                    title="Delete"
                    variant="buttonMedium"
                    color="primary"
                    beforeIcon={DeleteIcon}
                    handleClick={handleDeleteService}
                  />
                )}
                {canActivateDeactivateService && (
                  <CustomButton
                    title={currentService.is_active ? 'Deactivate' : 'Activate'}
                    variant="buttonMedium"
                    color="secondary"
                    beforeIcon={
                      currentService.is_active ? DeactivateIcon : CheckMarkIcon
                    }
                    handleClick={() =>
                      handleChangeStatus(!(currentService as Service).is_active)
                    }
                  />
                )}
              </S.ButtonsWrapper>
            </S.TopButtonsWrapper>
            {currentService && (
              <BaseTabs
                config={getServiceDetailsTabsConfig(currentService)}
                defaultTab={location.state?.activeTab || undefined}
              />
            )}
          </Fragment>
        )}
      </S.Container>
      <YesNoModal
        isOpen={isConfirmationModalOpen}
        title={modalInfo.title}
        question={modalInfo.question}
        closeModal={handleCloseModal}
        handleAction={() => {
          if (modalInfo.callback) {
            modalInfo.callback();
          }
          handleCloseModal();
        }}
      />
    </DashboardWrapper>
  );
};
