import styled from '@emotion/styled/macro';

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  cursor: pointer;
`;

export const BackIcon = styled.img`
  margin-right: 20px;
`;

export const BackButtonName = styled.div`
  font-family: 'RalewayBold', sans-serif;
  font-size: 24px;
`;
