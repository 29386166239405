import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;

  fieldset {
    border-color: black;
  }

  .error-message {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
  }
`;
