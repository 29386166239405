import styled from '@emotion/styled/macro';

export const Container = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 16px;
`;

export const Info = styled.div`
  margin-left: 24px;
  padding-top: 18px;
`;

export const Text = styled.div`
  font-size: 20px;
  font-family: 'GraphikMedium', sans-serif;
  line-height: 32px;
`;

export const SubText = styled.div`
  font-size: 16px;
  line-height: 28px;
`;
