export const ALL_BUILDINGS_OPTION = {
  name: 'All buildings',
  value: 'all',
};

export const ALL_SERVICES_OPTION = {
  name: 'All services',
  value: 'all',
};

export const ALL_SERVICE_TYPES = {
  name: 'All service types',
  value: 'all',
};
