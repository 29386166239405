import { useNavigate } from 'react-router-dom';

import EmptyPhoto from 'assets/empty-photo.png';
import CheckIcon from 'assets/icons/check16.svg';
import CrossIcon from 'assets/icons/cross.svg';
import EditYellowIcon from 'assets/icons/edit-yellow.svg';
import FitnessIcon from 'assets/icons/fitness16.svg';
import PlusIconYellow from 'assets/icons/plus-yellow.svg';
import PriceIcon from 'assets/icons/price16.svg';
import ClockIcon from 'assets/icons/time16.svg';

import { CustomButton } from 'components';

import { Service } from 'store/services/services.types';

import { shapePrice } from '../../../../../Services/List/ServicesList.helpers';
import { SPLIT_MODAL_MODES } from '../useSplitModal';
import * as S from './ServiceTile.styled';

interface ServiceTileProps {
  service: Service;
  handleOpenSplitModal: (
    modalMode: SPLIT_MODAL_MODES,
    service: Service,
  ) => void;
  handleUnassignSpecialist: (service: Service) => void;
}
export const ServiceTile = ({
  service,
  handleOpenSplitModal,
  handleUnassignSpecialist,
}: ServiceTileProps) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.LeftSection>
        <img src={service.images[0]?.image || EmptyPhoto} alt="" />
        {!service.is_assigned && (
          <CustomButton
            title="Assign Service"
            color="noBackground"
            size="sm"
            variant="buttonMedium"
            beforeIcon={PlusIconYellow}
            handleClick={() =>
              handleOpenSplitModal(SPLIT_MODAL_MODES.ASSIGN, service)
            }
          />
        )}
        {service.is_assigned && (
          <CustomButton
            title="Unassign"
            color="noBackgroundBlack"
            size="sm"
            variant="buttonMedium"
            beforeIcon={CrossIcon}
            handleClick={() => handleUnassignSpecialist(service)}
          />
        )}
        {service.is_assigned && (
          <CustomButton
            title="Edit Split"
            color="noBackground"
            size="sm"
            variant="buttonMedium"
            beforeIcon={EditYellowIcon}
            handleClick={() =>
              handleOpenSplitModal(SPLIT_MODAL_MODES.EDIT, service)
            }
          />
        )}
      </S.LeftSection>
      <S.RightSection>
        <S.Name
          onClick={() => navigate(`/services/${service.id}`)}
          title={service.name}
        >
          {service.name}
        </S.Name>
        <S.InfoLine>
          <img src={FitnessIcon} alt="" />
          <span>{service?.category.name}</span>
        </S.InfoLine>
        <S.InfoLine>
          <img src={CheckIcon} alt="" />
          <span>{service?.service_type.name}</span>
        </S.InfoLine>
        {service?.time_slot_duration_internal && (
          <S.InfoLine>
            <img src={ClockIcon} alt="" />
            <span>
              {`${service?.time_slot_duration_internal}`} hours duration
              (internal)
            </span>
          </S.InfoLine>
        )}
        <S.InfoLine>
          <img src={PriceIcon} alt="" />
          <span>
            {shapePrice(service.price, service.price_from, service.price_to)}
          </span>
        </S.InfoLine>
        <S.InfoLine hasOpacity={!service.is_assigned}>
          <img src={PriceIcon} alt="" />
          <span>Split {`${service.split !== null ? service.split : 0}%`}</span>
        </S.InfoLine>
      </S.RightSection>
      {service.is_assigned && (
        <S.AssignedChip>
          <img src={CheckIcon} alt="" />
          Assigned
        </S.AssignedChip>
      )}
    </S.Container>
  );
};
