import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  BaseSelectItem,
  TRANSACTION_SORT_OPTIONS,
} from '../GridFilters.constants';
import { FiltersParamsTypes } from '../GridFilters.types';

interface TransactionSortProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const TransactionSort = ({ onFiltersChange }: TransactionSortProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="transaction-sort">Sort by</InputLabel>
      <Select
        labelId="transaction-sort"
        defaultValue={TRANSACTION_SORT_OPTIONS[0].value}
        label="Sort by"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            ordering: e.target.value,
            page: 1,
          })
        }
      >
        {TRANSACTION_SORT_OPTIONS.map(({ value, label }: BaseSelectItem) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
