import { useState } from 'react';

interface ConfirmationModalInfo {
  title: string;
  question: string;
  alertText?: string;
  callback: (() => void) | null;
  secondCallback?: (() => void) | null;
  buttonLabel?: string;
  secondButtonLabel?: string;
}

interface UseConfirmationModalType {
  isConfirmationModalOpen: boolean;
  handleOpenModal: (params: OpenModalParams) => void;
  handleCloseModal: () => void;
  modalInfo: ConfirmationModalInfo;
}

export interface OpenModalParams {
  title: string;
  question: string;
  alertText?: string;
  callback: (() => void) | null;
  secondCallback?: (() => void) | null;
  buttonLabel?: string;
  secondButtonLabel?: string;
}

export const useConfirmationModal = (): UseConfirmationModalType => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState<ConfirmationModalInfo>({
    title: '',
    question: '',
    alertText: '',
    callback: null,
    secondCallback: null,
    buttonLabel: '',
    secondButtonLabel: '',
  });

  const handleOpenModal = ({
    title,
    question,
    alertText,
    callback,
    secondCallback,
    buttonLabel,
    secondButtonLabel,
  }: OpenModalParams) => {
    setModalInfo({
      title,
      question,
      alertText,
      callback,
      secondCallback,
      buttonLabel,
      secondButtonLabel,
    });
    setIsConfirmationModalOpen(true);
  };
  const handleCloseModal = () => setIsConfirmationModalOpen(false);

  return {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  };
};
