import { useState } from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endOfDay } from 'date-fns';

import { FiltersParamsTypes } from '../GridFilters.types';
import * as S from './RangeDatePickerFilter.styled';

interface RangeDatePickerFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

interface Range {
  from: Date | null;
  to: Date | null;
}

export const RangeDatePickerFilter = ({
  onFiltersChange,
}: RangeDatePickerFilterProps) => {
  const [range, setRange] = useState<Range>({
    from: null,
    to: null,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <S.Container>
        <DatePicker
          label="Start date"
          defaultValue={range.from}
          onChange={(value) => {
            setRange((prevState) => ({
              ...prevState,
              from: value,
            }));
            onFiltersChange({
              date_from: value as Date,
              page: 1,
            });
          }}
        />
        <DatePicker
          label="End date"
          minDate={range.from}
          defaultValue={range.to}
          onChange={(value) => {
            setRange((prevState) => ({
              ...prevState,
              to: value,
            }));
            onFiltersChange({
              date_to: endOfDay(value as Date),
              page: 1,
            });
          }}
        />
      </S.Container>
    </LocalizationProvider>
  );
};
