import { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { isToday } from 'date-fns';

import { CustomButton } from 'components';

import { Event } from 'store/events/events.types';
import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { SaveForLaterModal } from '../../../components/CustomModal/SaveForLaterModal/SaveForLaterModal';
import { BaseFormInput } from '../../../components/HookFormWrappers/BaseFormInput/BaseFormInput';
import { BaseFormTextArea } from '../../../components/HookFormWrappers/BaseFormTextArea/BaseFormTextArea';
import { FormAutocomplete } from '../../../components/HookFormWrappers/FormAutocomplete/FormAutocomplete';
import { FormDatePicker } from '../../../components/HookFormWrappers/FormDatePicker/FormDatePicker';
import { FormMultiSelect } from '../../../components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { FormSelect } from '../../../components/HookFormWrappers/FormSelect/FormSelect';
import { FormTimePicker } from '../../../components/HookFormWrappers/FormTimePicker/FormTimePicker';
import { useConfirmationModal } from '../../../hooks/useConfirmationModal';
import { RootState } from '../../../store/rootReducer';
import {
  mapBuildingsForSelect,
  mapCategoriesForSelect,
} from '../../../utils/form.helpers';
import {
  EVENT_FORMAT_SELECT_ITEMS,
  EVENT_FORMATS,
  EVENT_TYPES,
  EVENT_TYPES_SELECT_ITEMS,
} from '../Events.constants';
import { ImageState } from '../useEventDetailsData';
import {
  getEventEditorValues,
  shapeEventValuesForRequestBody,
  sortServiceSpecialistsForSelect,
} from './EventEditor.helpers';
import { EventEditorSchema } from './EventEditor.schema';
import * as S from './EventEditor.styled';
import { EventEditorValues } from './EventEditor.types';

interface EventEditorProps {
  event: Event | undefined;
  createEvent: (
    values: Partial<EventEditorValues>,
    activateAfterCreate?: boolean,
  ) => Promise<void>;
  saveEvent: (values: Partial<EventEditorValues>) => Promise<void>;
  eventImage: ImageState;
  serviceSpecialists: ServiceSpecialist[];
  getSpecialistsList: any;
  clearSpecialists: () => void;
  isDuplicate: boolean;
  isEdit: boolean;
}

export const EventEditor = ({
  event,
  createEvent,
  saveEvent,
  eventImage,
  serviceSpecialists,
  getSpecialistsList,
  isDuplicate,
  isEdit,
}: EventEditorProps) => {
  const navigate = useNavigate();
  const { categories } = useSelector((state: RootState) => state.categories);
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EventEditorSchema),
    values: getEventEditorValues(event, isDuplicate),
  });

  const createEventHandler = (
    values: EventEditorValues,
    activateAfterCreate?: boolean,
  ) => {
    return createEvent(
      shapeEventValuesForRequestBody(
        values,
        isDuplicate,
        Boolean(eventImage.imgSrc),
      ),
      activateAfterCreate,
    ).then(() => {
      navigate('/events');
    });
  };

  const handleSubmitForm = (values: EventEditorValues) => {
    if (!isEdit) {
      handleOpenModal({
        title: 'Activate event',
        question: `Do you wish to activate event`,
        callback: () => createEventHandler(values, true),
        secondCallback: () => createEventHandler(values),
        buttonLabel: 'Yes',
        secondButtonLabel: 'Save for later',
      });
    } else {
      saveEvent(shapeEventValuesForRequestBody(values)).then(() => {
        navigate('/events');
      });
    }
  };

  const haveUpcomingBookings = () =>
    event?.buildings.some((item) => item.has_bookings);

  return (
    <FormProvider {...methods}>
      <S.FormWrapper>
        <S.FormItem>
          <BaseFormInput name="name" label="Title" />
        </S.FormItem>
        <S.FormItem>
          {Boolean(categories.length) && (
            <FormSelect
              name="service_category_id"
              label="Category"
              items={mapCategoriesForSelect(categories)}
            />
          )}
        </S.FormItem>
        <S.FormItem>
          <BaseFormTextArea name="description" label="About" />
        </S.FormItem>
        <S.FormItem>
          <FormSelect
            name="format"
            label="Format"
            items={EVENT_FORMAT_SELECT_ITEMS.slice(1)}
            disabled={isEdit && haveUpcomingBookings()}
          />
          {isEdit && haveUpcomingBookings() && (
            <Alert severity="warning">
              There are upcoming bookings for this event, you can’t change the
              event format
            </Alert>
          )}
        </S.FormItem>
        {methods.watch('format') === EVENT_FORMATS.ONLINE && (
          <S.FormItem>
            {Boolean(buildings?.result?.length) && (
              <FormMultiSelect
                name="building_ids"
                label="Buildings"
                items={mapBuildingsForSelect(buildings, event?.buildings)}
                onChange={(e: SelectChangeEvent<string[]>) => {
                  methods.setValue('building_id', '');
                  methods.setValue('specialist_id', null);
                  getSpecialistsList({
                    building: (e.target.value as string[]).join(','),
                    search: '',
                    page: 1,
                  });
                }}
              />
            )}
          </S.FormItem>
        )}
        {methods.watch('format') === EVENT_FORMATS.OFFLINE && (
          <S.FormItem>
            {Boolean(buildings?.result?.length) && (
              <>
                <FormSelect
                  name="building_id"
                  label="Building"
                  items={mapBuildingsForSelect(buildings)}
                  disabled={isEdit && haveUpcomingBookings()}
                  onChange={(e: SelectChangeEvent) => {
                    methods.setValue('building_ids', []);
                    methods.setValue('specialist_id', null);
                    getSpecialistsList({
                      building: e.target.value,
                      search: '',
                      page: 1,
                    });
                  }}
                />
                {isEdit && haveUpcomingBookings() && (
                  <Alert severity="warning">
                    You can't change the building of this event. There are
                    members who have already joined this event in building{' '}
                    {`${event?.buildings[0].building_code} ${event?.buildings[0].building_name}`}
                  </Alert>
                )}
              </>
            )}
          </S.FormItem>
        )}
        <S.FormItem>
          <BaseFormInput name="link" label="Link" />
        </S.FormItem>
        <S.FormItem>
          <BaseFormInput name="capacity" label="Capacity" />
        </S.FormItem>
        {(!event?.is_active || isDuplicate) && (
          <Fragment>
            <S.FormItem>
              <FormDatePicker name="date" label="Event date" />
            </S.FormItem>
            <S.FormItem>
              <FormTimePicker
                name="start_time"
                label="Start"
                disabled={!methods.watch('date')}
                minTime={
                  isToday(methods.watch('date') as Date)
                    ? new Date()
                    : undefined
                }
              />
            </S.FormItem>
            <S.FormItem>
              <FormTimePicker
                name="close_registration_time"
                label="Close registration"
                maxTime={methods.watch('start_time') as Date}
                disabled={!methods.watch('start_time')}
              />
            </S.FormItem>
          </Fragment>
        )}
        <S.FormItem>
          <BaseFormInput
            name="duration"
            label="Event duration"
            startAdornment="hour(s)"
          />
        </S.FormItem>
        <S.FormItem>
          <FormSelect
            name="type"
            label="Type"
            disabled={isEdit && haveUpcomingBookings()}
            items={EVENT_TYPES_SELECT_ITEMS}
          />
        </S.FormItem>
        {methods.watch('type') === EVENT_TYPES.PAID && (
          <>
            <S.FormItem>
              <BaseFormInput name="price" label="Price" startAdornment="$" />
            </S.FormItem>
            <S.FormItem>
              <BaseFormInput name="tax" label="Tax" startAdornment="%" />
            </S.FormItem>
          </>
        )}
        {Boolean(serviceSpecialists.length) && (
          <S.FormItem>
            <FormAutocomplete
              name="specialist_id"
              label="Service Specialist"
              disabled={Boolean(isEdit && haveUpcomingBookings())}
              items={sortServiceSpecialistsForSelect(serviceSpecialists)}
            />
          </S.FormItem>
        )}
        {methods.watch('specialist_id') &&
          methods.watch('type') === EVENT_TYPES.PAID && (
            <S.FormItem>
              <BaseFormInput name="split" label="Split %" startAdornment="%" />
            </S.FormItem>
          )}
      </S.FormWrapper>
      <S.ButtonsWrapper>
        <CustomButton
          title="Save"
          color="primary"
          variant="buttonMedium"
          size="sm"
          handleClick={methods.handleSubmit(handleSubmitForm)}
        />
        <CustomButton
          title="Cancel"
          color="secondary"
          variant="buttonMedium"
          size="sm"
          handleClick={() => navigate('/events')}
        />
      </S.ButtonsWrapper>
      <SaveForLaterModal
        isOpen={isConfirmationModalOpen}
        title={modalInfo.title}
        question={modalInfo.question}
        closeModal={handleCloseModal}
        handleAction={() => {
          if (modalInfo.callback) {
            modalInfo.callback();
          }
          handleCloseModal();
        }}
        handlerSecondAction={() => {
          if (modalInfo.secondCallback) {
            modalInfo.secondCallback();
          }
          handleCloseModal();
        }}
        buttonLabel={modalInfo.buttonLabel as string}
        secondButtonLabel={modalInfo.secondButtonLabel as string}
      />
    </FormProvider>
  );
};
