import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';

import * as S from './BaseTabs.styled';

export interface BaseTabConfig {
  value: string;
  label: string;
  component: () => React.ReactElement;
}

interface BaseTabsProps {
  config: BaseTabConfig[];
  defaultTab?: string;
}

export const BaseTabs = ({ config, defaultTab }: BaseTabsProps) => {
  return (
    <S.Container>
      <Tabs defaultValue={defaultTab || config[0].value}>
        <TabsList>
          {config.map((item: BaseTabConfig) => (
            <Tab key={item.value} value={item.value}>
              {item.label}
            </Tab>
          ))}
        </TabsList>
        {config.map((item: BaseTabConfig) => (
          <TabPanel value={item.value} key={item.value}>
            {item.component()}
          </TabPanel>
        ))}
      </Tabs>
    </S.Container>
  );
};
