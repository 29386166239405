import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { makeStyles } from '@mui/styles';
import { OverridableStringUnion } from '@mui/types';
import classNames from 'classnames';

const useStyles = makeStyles({
  label: {
    width: 'fit-content',
    padding: '6px 16px',
    background: '#D5A419',
    borderRadius: '26px',
  },
});

interface GoldLabelProps {
  text: string;
  additionalClasses?: string;
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}

const GoldLabel = ({
  text,
  additionalClasses,
  variant = 'body2',
}: GoldLabelProps) => {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.label, additionalClasses)}>
      <Typography variant={variant}>{text}</Typography>
    </Box>
  );
};

export default GoldLabel;
