import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import {
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as S from './FormTimePicker.styled';

interface FormTimePickerProps {
  name: string;
  label: string;
  minTime?: Date;
  maxTime?: Date;
  disabled?: boolean;
  minutesStep?: number;
}

export const FormTimePicker = ({
  name,
  label,
  minTime,
  maxTime,
  disabled,
  minutesStep,
}: FormTimePickerProps) => {
  const { control } = useFormContext();

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Fragment>
              <TimePicker
                disabled={disabled}
                minTime={minTime}
                maxTime={maxTime}
                label={label}
                minutesStep={minutesStep}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                {...field}
                onError={() => {
                  field.onBlur();
                }}
                onAccept={() => {
                  field.onBlur();
                }}
              />
              {error && (
                <Typography
                  variant="helperText"
                  color="error"
                  className="error-message"
                >
                  <>{error?.message}</>
                </Typography>
              )}
            </Fragment>
          )}
        />
      </LocalizationProvider>
    </S.Container>
  );
};
