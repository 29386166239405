import * as React from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Avatar } from 'components/Avatar/Avatar';
import { AdvanceSearch } from 'components/Inputs/AdvanceSearch/AdvanceSearch';

import { EntityStatus } from '../../../components/EntityStatus/EntityStatus';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: any) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.primary.main,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStyles = makeStyles({
  status: { color: 'grey' },
  deactivate: {
    color: '#C90303',
  },
  active: {
    color: '#4CAF50',
  },
  managerUser: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface ListBuildingManagersProps {
  searchQuery: any;
  isSearchResult: boolean;
  buildingManagers: any;
  currentId: string | null;
  anchorEl: null | HTMLElement;
  handleOpen: (event: React.MouseEvent<HTMLElement>, id: string) => void;
  handleClose: (user_id: string) => void;
  handleSearch: (data: any) => void;
  handleActivate?: () => void;
  handleDeactivate?: () => void;
  handleDetailsModal?: () => void;
  handleDelete?: () => void;
}

export const ListBuildingManager = ({
  searchQuery,
  isSearchResult,
  buildingManagers,
  currentId,
  anchorEl,
  handleSearch,
  handleOpen,
  handleClose,
  handleActivate,
  handleDeactivate,
  handleDetailsModal,
  handleDelete,
}: ListBuildingManagersProps) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);

  return (
    <>
      <AdvanceSearch
        searchQuery={searchQuery}
        isSearchResult={isSearchResult}
        handleSearch={handleSearch}
      />

      <TableContainer
        component={Paper}
        sx={{ borderRadius: '20px', minHeight: 576 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: '20%',
                }}
              >
                <Typography variant={'tableHeader'}>Name</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>Building name</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>Category</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>Type</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '10%',
                }}
              >
                <Typography variant={'tableHeader'}>Status</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '10%',
                }}
                align="right"
              >
                <Typography variant={'tableHeader'}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buildingManagers?.map(
              (
                {
                  user_id,
                  account_icon,
                  last_name,
                  first_name,
                  building_names,
                  category,
                  type,
                  status,
                  admin_note,
                  id,
                }: any,
                index: number,
              ) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box className={classes.managerUser}>
                      <Avatar img={account_icon} alt="building_manager" />
                      <Typography variant={'body1'}>
                        {first_name} {last_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant={'body1'}>
                      {building_names &&
                        building_names.map((el: any, buildingId: number) => {
                          return (
                            <span key={buildingId}>
                              {buildingId ? ', ' : ''}
                              {el.building_code} {el.building_name}
                            </span>
                          );
                        })}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant={'body1'}>
                      {category &&
                        category.map((el: any, categoryId: number) => {
                          return (
                            <span key={categoryId}>
                              {categoryId ? ', ' : ''}
                              {el}
                            </span>
                          );
                        })}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant={'body1'}>
                      {type &&
                        type.map((el: any, typeId: number) => {
                          return (
                            <span key={typeId}>
                              {typeId ? ', ' : ''}
                              {el}
                            </span>
                          );
                        })}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <EntityStatus type={status} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="text"
                      disableElevation={true}
                      onClick={(event: any) => handleOpen(event, user_id)}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      More
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={currentId === user_id}
                      onClose={() => handleClose(user_id)}
                    >
                      <MenuItem
                        onClick={handleDetailsModal}
                        disableRipple={false}
                      >
                        <InfoIcon />
                        Details
                      </MenuItem>

                      <Divider sx={{ my: 0.5 }} />
                      {status === 'active' ? (
                        <MenuItem
                          onClick={handleDeactivate}
                          disableRipple={false}
                        >
                          <ArchiveIcon />
                          Deactivate
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={handleActivate}
                          disableRipple={false}
                        >
                          <ArchiveIcon />
                          Activate
                        </MenuItem>
                      )}

                      <MenuItem onClick={handleDelete} disableRipple={false}>
                        <MoreHorizIcon />
                        Delete
                      </MenuItem>
                    </StyledMenu>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
