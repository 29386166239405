/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BlogPostsState,
  IBlogPostsList,
  IBuilding,
  IBuildingPost,
} from 'types/index';

import {
  createBuildingPost,
  createPost,
  deleteBuildingPostById,
  getBuildingPosts,
  getBuildingPostsById,
  updateBuildingPostById,
  updatePost,
} from './blog-posts.actions';

// import { RequestError } from 'types/errors';

export const initialState: BlogPostsState = Object.freeze({
  posts: {},
  currentPost: {},
  loading: false,
  error: null,
  isMessageShow: false,
  status: 'idle',
  message: '',
});

const blogPostsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    showMessage: (state) => {
      state.isMessageShow = false;
    },
    clearCurrentPost: (state) => {
      state.currentPost = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBuildingPosts.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      getBuildingPosts.fulfilled,
      (state, action: PayloadAction<IBlogPostsList>) => {
        state.status = 'resolved';
        state.loading = false;
        state.posts = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getBuildingPosts.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(getBuildingPostsById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      getBuildingPostsById.fulfilled,
      (state, action: PayloadAction<IBuildingPost>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentPost = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getBuildingPostsById.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(createPost.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(createPost.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.currentPost = action.payload;
      state.error = null;
      state.isMessageShow = true;
      state.message = 'Post was created';
    });

    builder.addCase(
      createPost.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------
    builder.addCase(updatePost.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      updatePost.fulfilled,
      (state, action: PayloadAction<IBuildingPost>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentPost = action.payload;
        state.error = null;
        state.isMessageShow = true;
        state.message = 'Post was updated';
      },
    );

    builder.addCase(
      updatePost.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(createBuildingPost.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      createBuildingPost.fulfilled,
      (state, action: PayloadAction<IBuilding>) => {
        const { info_posts, id } = action.payload;
        state.status = 'resolved';
        state.loading = false;
        state.currentPost = info_posts
          ? { ...info_posts[0], building: id }
          : action.payload;
        state.error = null;
        state.isMessageShow = true;
        state.message = 'Post was created';
      },
    );

    builder.addCase(
      createBuildingPost.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(updateBuildingPostById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      updateBuildingPostById.fulfilled,
      (state, action: PayloadAction<IBuildingPost>) => {
        state.status = 'resolved';
        state.loading = false;
        state.currentPost = action.payload;
        state.error = null;
        state.isMessageShow = true;
        state.message = 'Post was updated';
      },
    );

    builder.addCase(
      updateBuildingPostById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );

    // ----------------------------------------------------------------

    builder.addCase(deleteBuildingPostById.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(deleteBuildingPostById.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isMessageShow = true;
      state.message = 'Post was deleted';
    });

    builder.addCase(
      deleteBuildingPostById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.loading = false;
        state.isMessageShow = true;
        state.message = action.payload
          ? action.payload.message
          : 'Something wrong';
      },
    );
  },
});

export const { clearCurrentPost, showMessage } = blogPostsSlice.actions;

// The reducer
export default blogPostsSlice.reducer;
