import styled from '@emotion/styled/macro';

import { pickerButtonOverride } from '../../../../../styles/common.styled';

export const Container = styled.div`
  margin-top: 35px;
  margin-bottom: 24px;
`;

export const TimePickersWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${pickerButtonOverride};

  & > div {
    width: 200px;
  }
`;

export const SelectedIntervals = styled.div`
  margin-bottom: 24px;
`;

export const SelectedInterval = styled.div`
  height: 53px;
  display: flex;
  padding: 0 110px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: ${(props: any) => props.theme.palette.grey.greyF3};

  & > span {
    font-size: 18px;
    font-family: 'GraphikMedium', sans-serif;
  }
`;

export const DeleteInterval = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
`;
