import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISpecialistsWithTimeSlots } from 'types';

import { ServiceBasicInfoFormValues } from '../../modules/Services/Editor/ServiceEditor.types';
import { AddSpecialistsStepValues } from '../../modules/Services/Editor/steps/AddSpecialists/AddSpecialistsStep.types';
import { STORE_STATUSES } from '../store.constants';
import {
  bookConciergeService,
  bookService,
  createServiceMainInfo,
  deleteService,
  deleteServiceImage,
  getServiceById,
  getServices,
  updateOneService,
  updateServiceStatus,
  uploadServiceImages,
} from './services.actions';
import { Service, ServiceList, ServicesState } from './services.types';

const SERVICE_BOOKING_DEFAULT = {
  member: null,
  familyMemberId: '',
  service: null,
  slotsForm: null,
  bookFromPackage: null,
  packageToPurchase: null,
};

const SERVICE_LIST_DEFAULT_VALUES = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

export const initialState: ServicesState = Object.freeze({
  servicesList: SERVICE_LIST_DEFAULT_VALUES,
  currentService: null,
  isAuthenticated: false,
  specialistsEdited: false,
  timeSlotsEdited: false,
  serviceSpecialistsTimeSlots: [],
  loading: false,
  error: null,
  message: '',
  isMessageShow: false,
  status: STORE_STATUSES.IDLE,
  serviceTypes: [],
  unsavedServiceValues: null,
  serviceBooking: SERVICE_BOOKING_DEFAULT,
});

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    clearServicesError: (state) => {
      state.error = null;
    },
    clearCurrentService: (state) => {
      state.currentService = null;
    },
    clearServicesList: (state) => {
      state.servicesList = {
        ...SERVICE_LIST_DEFAULT_VALUES,
      };
    },
    setSpecialistEditMode(
      state: ServicesState,
      action: PayloadAction<boolean>,
    ) {
      state.specialistsEdited = action.payload;
    },
    setTimeSlotsEditMode(state: ServicesState, action: PayloadAction<boolean>) {
      state.timeSlotsEdited = action.payload;
    },
    getSpecialistsTimeSlots(
      state: ServicesState,
      action: PayloadAction<ISpecialistsWithTimeSlots[]>,
    ) {
      state.serviceSpecialistsTimeSlots = action.payload;
    },
    updateTimeSlotsStatus(
      state: ServicesState,
      action: PayloadAction<ISpecialistsWithTimeSlots[]>,
    ) {
      state.serviceSpecialistsTimeSlots = action.payload;
    },
    showMessage: (state) => {
      state.isMessageShow = false;
      state.error = '';
    },
    setUnsavedServiceValues: (
      state,
      action: PayloadAction<
        Partial<ServiceBasicInfoFormValues & AddSpecialistsStepValues>
      >,
    ) => {
      state.unsavedServiceValues = action.payload;
    },
    clearUnsavedServiceValues: (state) => {
      state.unsavedServiceValues = null;
    },
    bookingSetMember: (state, action) => {
      state.serviceBooking.member = action.payload;
      state.serviceBooking.familyMemberId = action.payload.member_id;
    },
    bookingSetFamilyMember: (state, action) => {
      state.serviceBooking.familyMemberId = action.payload;
    },
    bookingSetService: (state, action) => {
      state.serviceBooking.service = action.payload;
    },
    setSlotForm: (state, action) => {
      state.serviceBooking.slotsForm = action.payload;
    },
    cleanServiceBooking: (state) => {
      state.serviceBooking = SERVICE_BOOKING_DEFAULT;
    },
    cleanServiceBookingMember: (state) => {
      state.serviceBooking.member = null;
    },
    cleanServiceBookingService: (state) => {
      state.serviceBooking.service = null;
    },
    cleanSlotsForm: (state) => {
      state.serviceBooking.slotsForm = null;
    },
    setBookFromPackage: (state, action) => {
      state.serviceBooking.packageToPurchase = null;
      state.serviceBooking.bookFromPackage = action.payload;
    },
    setPackageToPurchase: (state, action) => {
      state.serviceBooking.bookFromPackage = null;
      state.serviceBooking.packageToPurchase = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getServices.fulfilled,
      (state, action: PayloadAction<ServiceList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.servicesList = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getServices.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
      state.error = false;
    });

    builder.addCase(deleteService.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(deleteService.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = true;
    });

    builder.addCase(createServiceMainInfo.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      createServiceMainInfo.fulfilled,
      (state, action: PayloadAction<Service>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.currentService = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(createServiceMainInfo.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getServiceById.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getServiceById.fulfilled,
      (state, action: PayloadAction<Service>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.currentService = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateOneService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(
      updateOneService.fulfilled,
      (state, action: PayloadAction<Service>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.currentService = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(updateOneService.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateServiceStatus.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(updateServiceStatus.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(updateServiceStatus.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(uploadServiceImages.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(uploadServiceImages.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(uploadServiceImages.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(deleteServiceImage.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(deleteServiceImage.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(deleteServiceImage.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(bookService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(bookService.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(bookService.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(bookConciergeService.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(bookConciergeService.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(bookConciergeService.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });
  },
});

export const {
  clearServicesError,
  clearCurrentService,
  setSpecialistEditMode,
  setTimeSlotsEditMode,
  clearServicesList,
  showMessage,
  setUnsavedServiceValues,
  clearUnsavedServiceValues,
  bookingSetMember,
  bookingSetFamilyMember,
  bookingSetService,
  setSlotForm,
  cleanServiceBooking,
  cleanServiceBookingMember,
  cleanServiceBookingService,
  cleanSlotsForm,
  setBookFromPackage,
  setPackageToPurchase,
} = servicesSlice.actions;

export default servicesSlice.reducer;
