import { CSSProperties } from 'react';

import { createTheme } from '@mui/material/styles';

export const theme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
      light: '#d5a419',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F50057',
      light: '#FF4081',
      dark: '#C51162',
      contrastText: '#EEF0F3',
    },
    error: {
      main: '#C90303',
      light: '#F88078',
      dark: '#B90505',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#D5A419',
      light: '#F3E9CB',
      dark: '#C09006',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      light: '#64B6F7',
      dark: '#0B79D0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF',
    },
    statuses: {
      active: '#3B873E',
      activeBg: 'rgba(76, 175, 80, 0.20)',
      pending: '#3F51B5',
      pendingBg: 'rgba(63, 81, 181, 0.20)',
      onboarding: '#999999',
      inactive: '#C90303',
      inactiveBg: 'rgba(244, 67, 54, 0.20)',
      new: '#2196F3',
      newBg: 'rgba(63, 81, 181, 0.20)',
    },
    other: {
      divider: '#EEE',
    },
    action: {
      disabled: '#B8B8B8',
      disabledBackground: '#F3E9CB',
      active: '#999',
    },
    grey: {
      '300': '#E0E0E0',
      blue97: '#F5F6F7',
      greyF3: '#F3F3F3',
    },
    text: {
      primary: '#000000',
      disabled: 'rgba(12, 43, 89, 0.38)',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '4rem',
      lineHeight: '112px',
      fontFamily: 'RalewayRegular',
      letterSpacing: -1.5,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '32px',
      fontFamily: 'RalewayRegular',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '32px',
      fontFamily: 'GraphikRegular',
      letterSpacing: 0.15,
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: '21px',
      fontFamily: 'GraphikRegular',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '18px',
      fontFamily: 'GraphikRegular',
    },
    mainTitle: {
      fontWeight: 600,
      fontSize: '2.125rem',
      lineHeight: '42px',
      fontFamily: 'RalewayRegular',
      letterSpacing: 0.25,
    },
    title1: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '24px',
      fontFamily: 'RalewayRegular',
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '28px',
      letterSpacing: 0.15,
      fontFamily: 'GraphikRegular',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '22px',
      letterSpacing: 0.1,
      fontFamily: 'GraphikRegular',
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '20px',
      letterSpacing: 0.15,
      fontFamily: 'GraphikRegular',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0.15,
      fontFamily: 'GraphikRegular',
    },
    buttonMedium: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '20px',
      letterSpacing: 0.4,
      fontFamily: 'RalewayRegular',
    },
    chip: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '16px',
      letterSpacing: 0.16,
      fontFamily: 'RalewayRegular',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '20px',
      letterSpacing: 0.4,
      fontFamily: 'GraphikRegular',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '32px',
      letterSpacing: 1,
      fontFamily: 'GraphikRegular',
    },
    inputText: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: 0.15,
      fontFamily: 'GraphikRegular',
    },
    tooltip: {
      fontWeight: 400,
      fontSize: '0.625rem',
      lineHeight: '14px',
      fontFamily: 'GraphikRegular',
    },
    helperText: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '20px',
      letterSpacing: 0.4,
      fontFamily: 'GraphikRegular',
    },
    alertTitle: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: 0.15,
      fontFamily: 'GraphikRegular',
    },
    tableHeader: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '24px',
      letterSpacing: 0.17,
      fontFamily: 'GraphikRegular',
    },
    badgeLabel: {
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '20px',
      letterSpacing: 0.14,
      fontFamily: 'RalewayRegular',
    },
  },
  components: {
    MuiCssBaseline: {},
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
} as const;

const eliteTheme = createTheme(theme);

const darkTheme = createTheme({
  ...eliteTheme,
  palette: {
    mode: 'dark',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          // backgroundColor: 'red',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'yellow',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px solid white',
          borderRadius: '1.625rem',
          padding: '1.0rem 1.5rem',
          width: '100%',
        },
      },
    },
  },
});
declare module '@mui/material/styles' {
  interface TypographyVariants {
    mainTitle: CSSProperties;
    title1: CSSProperties;
    buttonMedium: CSSProperties;
    chip: CSSProperties;
    caption: CSSProperties;
    overline: CSSProperties;
    inputText: CSSProperties;
    tooltip: CSSProperties;
    helperText: CSSProperties;
    alertTitle: CSSProperties;
    tableHeader: CSSProperties;
    badgeLabel: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mainTitle?: CSSProperties;
    title1?: CSSProperties;
    buttonMedium?: CSSProperties;
    chip?: CSSProperties;
    caption?: CSSProperties;
    overline?: CSSProperties;
    inputText?: CSSProperties;
    tooltip?: CSSProperties;
    helperText?: CSSProperties;
    alertTitle?: CSSProperties;
    tableHeader?: CSSProperties;
    badgeLabel?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mainTitle: true;
    title1: true;
    buttonMedium: true;
    chip: true;
    caption: true;
    overline: true;
    inputText: true;
    tooltip: true;
    helperText: true;
    alertTitle: true;
    tableHeader: true;
    badgeLabel: true;
  }
}

export { eliteTheme, darkTheme };
