import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components';
import { EntityStatus } from 'components/EntityStatus/EntityStatus';
import { TextInput } from 'components/Inputs/TextInput/TextInput';

import { SearchSchema } from 'constants/schema';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: any) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.primary.main,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStyles = makeStyles({
  rowItem: {
    borderBottom: '1px solid #E0E0E0',
  },
  status: { color: 'grey' },
  deactivate: {
    color: '#C90303',
  },
  active: {
    color: '#4CAF50',
  },
  searchBtn: {
    marginLeft: '10px',
  },
  notes: { overflowWrap: 'anywhere' },
});

interface ListMembersProps {
  searchQuery: any;
  isSearchResult: boolean;
  residents: any;
  currentId: string | null;
  anchorEl: null | HTMLElement;
  handleOpen: (event: React.MouseEvent<HTMLElement>, id: string) => void;
  handleClose: (user_id: string) => void;
  handleSearch: (data: any) => void;
  handleActivate?: () => void;
  handleDeactivate?: () => void;
  handleDelete?: () => void;
  handleEditNote?: () => void;
}
export const ListMembers = ({
  searchQuery,
  isSearchResult,
  residents,
  currentId,
  anchorEl,
  handleSearch,
  handleOpen,
  handleClose,
  handleActivate,
  handleDeactivate,
  handleDelete,
  handleEditNote,
}: ListMembersProps) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(SearchSchema),
  });

  const searchValues = {
    last_name: '',
    first_name: '',
    email: '',
  };

  const [formValues, setFormValues] = useState(searchValues);
  useEffect(() => {
    if (isSearchResult) {
      setFormValues(searchQuery);
    }
  }, [isSearchResult]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box mt={4}>
          <Grid container={true} mb={3} spacing={1}>
            <Grid item={true} xs={3}>
              <TextInput
                name={'first_name'}
                value={formValues?.first_name}
                label={'First name'}
                color={'black'}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextInput
                name={'last_name'}
                value={formValues?.last_name}
                label={'Last name'}
                color={'black'}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <TextInput
                name={'email'}
                value={formValues?.email}
                label={'Email'}
                color={'black'}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <CustomButton
                title={'Search'}
                additionalClass={classes.searchBtn}
                variant={'buttonMedium'}
                handleClick={() => handleSearch(formValues)}
              />
            </Grid>
          </Grid>
        </Box>
      </FormProvider>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: '20px', minHeight: 576 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: '15%',
                }}
              >
                <Typography variant={'tableHeader'}>Name</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '20%',
                }}
              >
                <Typography variant={'tableHeader'}>Email</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '15%',
                }}
              >
                <Typography variant={'tableHeader'}>Building name</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '10%',
                }}
              >
                <Typography variant={'tableHeader'}>
                  Apartment number
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '10%',
                }}
              >
                <Typography variant={'tableHeader'}>Status</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '20%',
                }}
              >
                <Typography variant={'tableHeader'}>Notes</Typography>
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '10%',
                }}
              >
                <Typography variant={'tableHeader'}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {residents &&
              residents.map(
                (
                  {
                    first_name,
                    last_name,
                    email,
                    status,
                    admin_note,
                    building_names,
                    apartment_number,
                    user_id,
                  }: any,
                  index: number,
                ) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={classes.rowItem}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant={'body1'}>
                        {first_name} {last_name}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant={'body1'} className={classes.notes}>
                        {email}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {building_names.map((elem: any, id: number) => (
                        <Typography key={id} variant={'body1'}>
                          {elem.building_code} {elem.building_name}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant={'badgeLabel'}>
                        {apartment_number}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <EntityStatus type={status} />
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant={'body1'} className={classes.notes}>
                        {admin_note}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? 'demo-customized-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="text"
                        disableElevation={true}
                        onClick={(event: any) => handleOpen(event, user_id)}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        More
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={currentId === user_id}
                        onClose={() => handleClose(user_id)}
                      >
                        {admin_note ? (
                          <MenuItem
                            onClick={handleEditNote}
                            disableRipple={false}
                          >
                            <EditIcon />
                            Edit Note
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={handleEditNote}
                            disableRipple={false}
                          >
                            <EditIcon />
                            Add Note
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        {status === 'active' ? (
                          <MenuItem
                            onClick={handleDeactivate}
                            disableRipple={false}
                          >
                            <ArchiveIcon />
                            Deactivate
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={handleActivate}
                            disableRipple={false}
                          >
                            <ArchiveIcon />
                            Activate
                          </MenuItem>
                        )}

                        <MenuItem onClick={handleDelete} disableRipple={false}>
                          <MoreHorizIcon />
                          Delete
                        </MenuItem>
                      </StyledMenu>
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
