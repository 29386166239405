/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotification, NotificationsState } from 'types/notificationTypes';

import {
  getAllPastNotifications,
  getAllTodayNotifications,
  getUnreadCountNotifications,
  markReadAllNotifications,
  markReadNotificationByID,
} from './notifications.actions';

export const initialState: NotificationsState = Object.freeze({
  status: 'idle',
  loading: false,
  count: 0,
  notifications: [],
  isMessageShow: false,
  message: '',
  today_unread: 0,
  past_unread: 0,
  error: '',
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotificationStore(state) {
      state.notifications = [];
    },
    markReadById: (state, action) => {
      state.notifications.map((notif: any) => {
        if (notif.id === action.payload) {
          notif.status = 'read';
        }
        return notif;
      });
    },
    showMessage: (state) => {
      state.isMessageShow = false;
    },
  },
  extraReducers: (builder) => {
    // ----------------------------------------------------------------
    builder.addCase(getUnreadCountNotifications.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      getUnreadCountNotifications.fulfilled,
      (state, action: any) => {
        state.status = 'resolved';
        state.past_unread = action.payload.past_unread;
        state.today_unread = action.payload.today_unread;
        state.loading = false;
      },
    );

    builder.addCase(getUnreadCountNotifications.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------
    builder.addCase(getAllPastNotifications.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      getAllPastNotifications.fulfilled,
      (state, action: PayloadAction<INotification>) => {
        state.status = 'resolved';
        state.notifications = [
          ...state.notifications,
          ...action.payload.result,
        ];
        state.count = action.payload.count;
        state.loading = false;
      },
    );

    builder.addCase(getAllPastNotifications.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------
    builder.addCase(getAllTodayNotifications.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      getAllTodayNotifications.fulfilled,
      (state, action: PayloadAction<INotification>) => {
        state.status = 'resolved';
        state.notifications = [
          ...state.notifications,
          ...action.payload.result,
        ];
        state.count = action.payload.count;
        state.loading = false;
      },
    );

    builder.addCase(getAllTodayNotifications.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------
    builder.addCase(markReadAllNotifications.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      markReadAllNotifications.fulfilled,
      (state, action: PayloadAction<INotification>) => {
        state.status = 'resolved';
        // state.notifications = action.payload.result;
        // state.count = action.payload.count;
        state.loading = false;
      },
    );
    builder.addCase(markReadAllNotifications.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------
    builder.addCase(markReadNotificationByID.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      markReadNotificationByID.fulfilled,
      (state, action: PayloadAction<INotification>) => {
        state.status = 'resolved';
        // state.notifications = action.payload.result;
        // state.count = action.payload.count;
        state.loading = false;
      },
    );

    builder.addCase(markReadNotificationByID.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------
  },
});

export const { showMessage, markReadById, clearNotificationStore } =
  notificationsSlice.actions;

// The reducer
export default notificationsSlice.reducer;
