import * as React from 'react';

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';

import './GridMoreDropdown.scss';
import * as S from './GridMoreDropdown.styled';

export interface MoreDropdownItem {
  label: string;
  icon?: string;
  color: string;
  callback: () => void;
}

interface GridMoreDropdownProps {
  items: MoreDropdownItem[];
}

export const GridMoreDropdown = ({
  items,
}: GridMoreDropdownProps): React.ReactElement => {
  return (
    <S.Container>
      <Dropdown>
        <MenuButton className="more-button">
          <S.MenuButton>. . . More</S.MenuButton>
        </MenuButton>
        <Menu className="more-button__menu">
          <S.MenuWrapper>
            {items.map((item: MoreDropdownItem) => (
              <MenuItem key={item.label} onClick={item.callback}>
                <S.MenuItem color={item.color}>
                  {item.icon && <S.MenuItemIcon src={item.icon} />}
                  {item.label}
                </S.MenuItem>
              </MenuItem>
            ))}
          </S.MenuWrapper>
        </Menu>
      </Dropdown>
    </S.Container>
  );
};
