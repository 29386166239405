/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthState } from 'types/index';

import {
  updateAccountImage,
  updateAccountInfo,
} from '../account-management/account-management.actions';
import {
  checkTemporaryToken,
  logIn,
  logOut,
  resetPassword,
  restorePassword,
  savePassword,
  signup,
  updateAccessToken,
} from './auth.actions';

// import { RequestError } from 'types/errors';

export const initialState: AuthState = Object.freeze({
  isAuthenticated: false,
  isTemporaryTokenValid: false,
  timeRefresh: null,
  loading: false,
  error: null,
  status: 'idle',
  user: null,
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthError: (state) => {
      state.error = null;
    },
    clearTemporaryToken: (state) => {
      state.isTemporaryTokenValid = false;
    },
    setNewEmail: (state, action: PayloadAction<string>) => {
      state.user = state.user && {
        ...state.user,
        email: action?.payload as string,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.isAuthenticated = false;
    });

    builder.addCase(logIn.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.timeRefresh = new Date();
      state.error = null;
    });

    builder.addCase(logIn.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(logOut.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(logOut.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.user = null;
    });

    builder.addCase(logOut.rejected, (state) => {
      state.status = 'rejected';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.user = null;
    });

    // ----------------------------------------------------------------

    builder.addCase(resetPassword.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.user = null;
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.user = null;
      // state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(restorePassword.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(restorePassword.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
      state.isAuthenticated = false;
      state.timeRefresh = null;
      state.user = null;
    });

    builder.addCase(restorePassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.timeRefresh = null;
      // state.error = action.payload;
    });
    // ----------------------------------------------------------------

    builder.addCase(savePassword.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(savePassword.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(savePassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      // state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase(signup.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(signup.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(signup.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      // state.error = action.payload;
    });
    // ----------------------------------------------------------------

    builder.addCase(updateAccessToken.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(updateAccessToken.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.loading = false;
      state.isAuthenticated = true;
      state.timeRefresh = new Date();
      // TODO: need update
      // tslint:disable  no-non-null-assertion
      state.user!.access = action.payload;
    });

    builder.addCase(updateAccessToken.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.timeRefresh = null;
      state.isAuthenticated = false;
      // state.error = action.payload;
    });
    // ----------------------------------------------------------------

    builder.addCase(checkTemporaryToken.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(checkTemporaryToken.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.isTemporaryTokenValid = true;
      state.loading = false;
    });

    builder.addCase(checkTemporaryToken.rejected, (state, action) => {
      state.status = 'rejected';
      state.isTemporaryTokenValid = false;
      state.loading = false;
      // state.error = action.payload;
    });

    // -------------------------------------------------------------

    builder.addCase(updateAccountInfo.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(updateAccountInfo.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.user = { ...state.user, ...action.payload };
      state.loading = false;
    });

    builder.addCase(updateAccountInfo.rejected, (state, action) => {
      state.status = 'rejected';
      // state.error = action.payload;
      state.loading = false;
    });

    // -------------------------------------------------------------

    builder.addCase(updateAccountImage.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(updateAccountImage.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.user = { ...state.user, ...action.payload };
      state.loading = false;
    });

    builder.addCase(updateAccountImage.rejected, (state, action) => {
      state.status = 'rejected';
      // state.error = action.payload;
      state.loading = false;
    });
  },
});

// actions from slice
export const { clearAuthError, clearTemporaryToken, setNewEmail } =
  authSlice.actions;

// The reducer
export default authSlice.reducer;
