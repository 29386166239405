import * as React from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { EntityStatus } from 'components/EntityStatus/EntityStatus';
import { AdvanceSearch } from 'components/Inputs/AdvanceSearch/AdvanceSearch';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: any) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.primary.main,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStyles = makeStyles({
  rowItem: {
    borderBottom: '1px solid #E0E0E0',
  },
  status: { color: 'grey' },
  deactivate: {
    color: '#C90303',
  },
  active: {
    color: '#4CAF50',
  },
});

interface ListSuperAdminProps {
  searchQuery: any;
  isSearchResult: boolean;
  superAdmins: any;
  currentId: string | null;
  anchorEl: null | HTMLElement;
  handleOpen: (event: React.MouseEvent<HTMLElement>, id: string) => void;
  handleClose: (user_id: string) => void;
  handleSearch: (data: any) => void;
  handleActivate?: () => void;
  handleDeactivate?: () => void;
  handleDelete?: () => void;
  handleEditNote?: () => void;
}

export const ListSuperAdmin = ({
  searchQuery,
  isSearchResult,
  superAdmins,
  currentId,
  anchorEl,
  handleOpen,
  handleClose,
  handleSearch,
  handleActivate,
  handleDeactivate,
  handleDelete,
}: ListSuperAdminProps) => {
  const open = Boolean(anchorEl);
  const classes = useStyles();

  return (
    <>
      <AdvanceSearch
        searchQuery={searchQuery}
        isSearchResult={isSearchResult}
        handleSearch={handleSearch}
      />

      <TableContainer
        component={Paper}
        sx={{ borderRadius: '20px', minHeight: 576 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: '30%',
                }}
              >
                <Typography variant={'tableHeader'}>User</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '35%',
                }}
              >
                <Typography variant={'tableHeader'}>Email</Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: '15%',
                }}
              >
                <Typography variant={'tableHeader'}>Status</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={'tableHeader'}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {superAdmins &&
              superAdmins.map(
                (
                  { first_name, last_name, email, status, note, user_id }: any,
                  index: number,
                ) => (
                  <TableRow key={index} className={classes.rowItem}>
                    <TableCell component="th" scope="row">
                      <Typography variant={'body1'}>
                        {first_name} {last_name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant={'body1'}>{email}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <EntityStatus type={status} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? 'demo-customized-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="text"
                        disableElevation={true}
                        onClick={(event: any) => handleOpen(event, user_id)}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        More
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={currentId === user_id}
                        onClose={() => handleClose(user_id)}
                      >
                        <Divider sx={{ my: 0.5 }} />
                        {status === 'active' ? (
                          <MenuItem
                            onClick={handleDeactivate}
                            disableRipple={false}
                          >
                            <ArchiveIcon />
                            Deactivate
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={handleActivate}
                            disableRipple={false}
                          >
                            <ArchiveIcon />
                            Activate
                          </MenuItem>
                        )}

                        <MenuItem onClick={handleDelete} disableRipple={false}>
                          <MoreHorizIcon />
                          Delete
                        </MenuItem>
                      </StyledMenu>
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
