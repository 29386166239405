// tslint:disable:no-console
import { format, getHours, getMinutes, parse, set } from 'date-fns';

import { sendUserFormDataRegistrationInfo } from '../services/imageUpload/imageUploader';
import { ResponseBuilding } from '../types/buildings';
import {
  DATE_FORMAT_WEEK_FILTER,
  MAX_FILE_SIZE_AVATAR,
  SUPPORTED_FILE_EXTENSIONS,
  TIME_FORMAT_AM_PM,
} from './constants';

export const getToken = () => {
  return localStorage.getItem('token');
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const encodeInBase64 = (file: File) => {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
  });
};

export const decodeFromBase64 = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${mime.split('/')[1]}`, { type: mime });
};

export const sendUserRegistrationInfo = async (userInfo: any) => {
  const formData = new FormData();
  formData.append('position', userInfo.position);
  formData.append('bio_info', userInfo.bio_info);
  formData.append('temporary_token', userInfo.temporary_token);
  formData.append('phone_number', userInfo.phone_number);
  if (userInfo.account_icon) {
    formData.append('account_icon', userInfo.account_icon as File);
  }

  const uploadedData = await sendUserFormDataRegistrationInfo(formData);

  if (typeof uploadedData === 'string') {
    return 'Something wrong';
  } else {
    return uploadedData;
  }
};

export const convertImageInFormData = (image: File) => {
  const formData = new FormData();
  formData.append('filename', image.name);
  formData.append('image', image);

  return formData;
};

export const convertImageInFormDataForAccountIcon = (image: File) => {
  const formData = new FormData();
  formData.append('account_icon', image);

  return formData;
};

export const validateAvatarImage = (image: File) => {
  return (
    image.size <= MAX_FILE_SIZE_AVATAR &&
    SUPPORTED_FILE_EXTENSIONS.some(
      (extension: string) => extension === image.type,
    )
  );
};

export const normalizePhone = (value: string) => {
  if (!value) {
    return value;
  }
  let onlyNums = value;

  // with '-'
  if (onlyNums.length <= 12) {
    onlyNums = value.replace(/[^\d]/g, '');
  }

  // without '-'
  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, onlyNums.length - 3)}-${onlyNums.slice(
      onlyNums.length - 3,
      onlyNums.length,
    )}`;
  }

  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, onlyNums.length - 6)}-${onlyNums.slice(
      onlyNums.length - 6,
      onlyNums.length - 3,
    )}-${onlyNums.slice(onlyNums.length - 3, onlyNums.length)}`;
  }

  if (onlyNums.length === 10) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  }

  return onlyNums;
};

export const downloadBlob = (data: Blob, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const parseTimeStringToData = (timeString: string) =>
  parse(timeString, 'HH:mm:ss', new Date());

export const parseDateStringToData = (dateString: string) =>
  parse(dateString, 'yyyy-MM-dd', new Date());

export const formatParsedTime = (timeString: string) => {
  return format(parseTimeStringToData(timeString), TIME_FORMAT_AM_PM);
};

export const formatParsedDate = (dateString: string) => {
  return format(parseDateStringToData(dateString), DATE_FORMAT_WEEK_FILTER);
};

export const capitalizeFirstLetter = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const shapeDateTime = (date: Date, time: Date) => {
  return set(date, {
    hours: getHours(time),
    minutes: getMinutes(time),
  });
};

export const shapeBuildingsString = (buildings: ResponseBuilding[]) => {
  if (!buildings.length) {
    return '-';
  }

  return buildings
    .map(
      (building: ResponseBuilding) =>
        `${building.building_code} ${building.building_name}`,
    )
    .join(', ');
};
