import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import EmptyBuilding from 'assets/empty.jpg';

const useStyles = makeStyles({
  notfound: {
    display: 'block',
    position: 'relative',
    margin: '200px auto 0',
    textAlign: 'center',
  },
});

export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notfound}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h3">Page don't found</Typography>
      <img src={EmptyBuilding} alt="delete" />
      <Typography variant="h3">
        Go to <a href="/services">Home page</a>
      </Typography>
    </div>
  );
};
