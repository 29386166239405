import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -4px;

  input {
    width: 340px;
    background-color: transparent;
    border-radius: 10px;
    height: 56px;
    border: 1px solid ${(props: any) => props.theme.palette.primary.main};
    padding-left: 50px;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: ${(props: any) => props.theme.palette.action.disabled};
    }
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 19px;
  left: 18px;
`;

export const CrossIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 18px;
  cursor: pointer;
`;
