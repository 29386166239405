import { useEffect, useState } from 'react';

import FilterIcon from '../../../assets/icons/filter.svg';
import { useAppDispatch } from '../../../hooks/useAppSelector';
import { getBuildings } from '../../../store/buildings/buildings.actions';
import { CustomButton } from '../../CustomButton/CustomButton';
import { SearchField } from '../../SearchField/SearchField';
import { FiltersList } from './FiltersList/FiltersList';
import * as S from './GridFilters.styled';
import { AvailableFilters, FiltersParamsTypes } from './GridFilters.types';

interface GridFiltersProps {
  handleSearch?: (value: string) => void;
  searchPlaceholder?: string;
  config?: AvailableFilters;
  handleFiltersChange?: (params: FiltersParamsTypes) => void;
}

export const GridFilters = ({
  handleSearch,
  handleFiltersChange,
  searchPlaceholder,
  config,
}: GridFiltersProps) => {
  const dispatch = useAppDispatch();
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);

  const handleClickSwitcher = () =>
    setIsFiltersOpened((prevState) => !prevState);

  useEffect(() => {
    if (config?.building) {
      dispatch(
        getBuildings({
          page_size: 10000,
        }),
      );
    }
  }, []);

  return (
    <S.Container>
      <S.SearchWrapper>
        <S.FiltersSwitcher>
          {config && handleFiltersChange && (
            <CustomButton
              title={`${isFiltersOpened ? 'Hide' : 'Show'} Filters`}
              variant="buttonMedium"
              color="secondary"
              afterIcon={FilterIcon}
              size="sm"
              handleClick={handleClickSwitcher}
            />
          )}
        </S.FiltersSwitcher>
        {handleSearch && searchPlaceholder && (
          <SearchField
            placeholder={searchPlaceholder}
            handleSearch={handleSearch}
          />
        )}
      </S.SearchWrapper>
      {config && handleFiltersChange && (
        <S.FilterWrapper isFiltersOpened={isFiltersOpened}>
          <FiltersList config={config} onFiltersChange={handleFiltersChange} />
        </S.FilterWrapper>
      )}
    </S.Container>
  );
};
