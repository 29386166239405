import { Controller, useFormContext } from 'react-hook-form';

import { TextField, Typography } from '@mui/material';

import * as S from './BaseFormTextArea.styled';

interface BaseInputProps {
  name: string;
  label: string;
  rows?: number;
}

export const BaseFormTextArea = ({ name, label, rows }: BaseInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            multiline={true}
            rows={rows || 3}
            label={label}
            {...field}
          />
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
