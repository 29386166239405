import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    bottom: '-20px',
  },
});

interface TextAreaInputProps {
  placeholder?: string;
  value: string;
  handleChange: (event: any) => void;
  name: string;
  label: string;
  color?: 'white' | 'black';
  rows?: number;
  disabled?: boolean;
}

export const TextAreaInput = ({
  handleChange,
  placeholder,
  value,
  name,
  label,
  color = 'white',
  disabled = false,
  rows = 3,
}: TextAreaInputProps) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }: any) => (
          <FormControl
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #000000 inset',
              },
            }}
            variant="outlined"
          >
            <InputLabel
              style={{
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              }}
              htmlFor="outlined-adornment-password"
            >
              {label}
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-root': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                    },
                },
              }}
              minRows={rows}
              maxRows={rows}
              multiline={true}
              id="outlined-adornment-password"
              type={'text'}
              {...field}
              value={value}
              name={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                handleChange(e);
              }}
              placeholder={placeholder}
              label={label}
              disabled={disabled}
            />
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
