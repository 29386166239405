import styled from '@emotion/styled';

import { ENTITY_STATUSES } from './EntityStatus.constants';

export const Container = styled.div`
  display: flex;
`;
export const Status = styled.div<{ type: ENTITY_STATUSES }>`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
  border-radius: 12px;
  color: ${(props: any) => {
    if (
      props.type === ENTITY_STATUSES.ACTIVE ||
      props.type === ENTITY_STATUSES.ONGOING ||
      props.type === ENTITY_STATUSES.ACCEPTED ||
      props.type === ENTITY_STATUSES.APPROVED ||
      props.type === ENTITY_STATUSES.PAID
    ) {
      return props.theme.palette.statuses.active;
    }

    if (
      props.type === ENTITY_STATUSES.ONBOARDING ||
      props === ENTITY_STATUSES.PENDING
    ) {
      return props.theme.palette.statuses.pending;
    }

    if (
      props.type === ENTITY_STATUSES.DEACTIVATED ||
      props.type === ENTITY_STATUSES.INACTIVE ||
      props.type === ENTITY_STATUSES.FINISHED ||
      props.type === ENTITY_STATUSES.DECLINED ||
      props.type === ENTITY_STATUSES.FAILED ||
      props.type === ENTITY_STATUSES.CANCELLED ||
      props.type === ENTITY_STATUSES.REFUNDED
    ) {
      return props.theme.palette.statuses.inactive;
    }

    if (
      props.type === ENTITY_STATUSES.NEW ||
      props.type === ENTITY_STATUSES.UPCOMING
    ) {
      return props.theme.palette.statuses.new;
    }
  }};
  background-color: ${(props: any) => {
    if (
      props.type === ENTITY_STATUSES.ACTIVE ||
      props.type === ENTITY_STATUSES.ONGOING ||
      props.type === ENTITY_STATUSES.ACCEPTED ||
      props.type === ENTITY_STATUSES.APPROVED ||
      props.type === ENTITY_STATUSES.PAID
    ) {
      return props.theme.palette.statuses.activeBg;
    }

    if (
      props.type === ENTITY_STATUSES.ONBOARDING ||
      props.type === ENTITY_STATUSES.PENDING
    ) {
      return props.theme.palette.statuses.pendingBg;
    }

    if (
      props.type === ENTITY_STATUSES.DEACTIVATED ||
      props.type === ENTITY_STATUSES.INACTIVE ||
      props.type === ENTITY_STATUSES.FINISHED ||
      props.type === ENTITY_STATUSES.DECLINED ||
      props.type === ENTITY_STATUSES.FAILED ||
      props.type === ENTITY_STATUSES.CANCELLED ||
      props.type === ENTITY_STATUSES.REFUNDED
    ) {
      return props.theme.palette.statuses.inactiveBg;
    }

    if (
      props.type === ENTITY_STATUSES.NEW ||
      props.type === ENTITY_STATUSES.UPCOMING
    ) {
      return props.theme.palette.statuses.newBg;
    }
  }};
`;

export const Icon = styled.img`
  margin-right: 10px;
`;
