import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputAdornment, TextField, Typography } from '@mui/material';

import * as S from './BaseFormInput.styled';

interface BaseInputProps {
  name: string;
  label: string;
  startAdornment?: string;
  disabled?: boolean;
}

export const BaseFormInput = ({
  name,
  label,
  startAdornment,
  disabled = false,
}: BaseInputProps) => {
  const { control } = useFormContext();

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Fragment>
            <TextField
              label={label}
              disabled={disabled}
              InputProps={{
                ...(startAdornment
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          {startAdornment}
                        </InputAdornment>
                      ),
                    }
                  : {}),
              }}
              {...field}
            />
            {error && (
              <Typography
                variant="helperText"
                color="error"
                className="error-message"
              >
                <>{error?.message}</>
              </Typography>
            )}
          </Fragment>
        )}
      />
    </S.Container>
  );
};
