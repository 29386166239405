import { Typography } from '@mui/material';
import { format } from 'date-fns';

import { CellElement } from 'components/GridElements/CellElement/CellElement';
import {
  ColumnType,
  GRID_HEADER_ALIGNMENTS,
} from 'components/GridElements/GridCommonConfigs';
import { HeaderElement } from 'components/GridElements/HeaderElement/HeaderElement';

import { PayrollTransaction } from '../../../store/billings/billings.types';
import { DATE_TIME_FORMAT } from '../../../utils/constants';

export const PAYROLL_DETAILS_LIST_CONFIG: ColumnType<PayrollTransaction>[] = [
  {
    label: 'Payment Time',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            {format(
              new Date(payrollTransaction.payment_time),
              DATE_TIME_FORMAT,
            )}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Booking  time',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            {format(
              new Date(payrollTransaction.booking_time),
              DATE_TIME_FORMAT,
            )}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Member name',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            {payrollTransaction.member_name}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Service name',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            {payrollTransaction.service_name}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'SS split',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            ${payrollTransaction.profit_specialist}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'EA split',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            ${payrollTransaction.profit_ea}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Tax',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>{payrollTransaction.tax}%</Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Payment method',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<PayrollTransaction>) => (
      <HeaderElement<PayrollTransaction> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<PayrollTransaction>,
      payrollTransaction: PayrollTransaction,
    ) => {
      return (
        <CellElement<PayrollTransaction> column={item}>
          <Typography variant={'body2'}>
            {payrollTransaction.payment_method}
          </Typography>
        </CellElement>
      );
    },
  },
];
