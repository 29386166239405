import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const ImagesAndInfo = styled.div`
  display: flex;
`;

export const Info = styled.div`
  margin-left: 32px;
  flex-grow: 1;
  padding-right: 25%;
  position: relative;

  & > button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 60px;
  padding-top: 28px;
  border-top: 1px solid ${(props: any) => props.theme.palette.grey[300]};
`;

export const DescriptionHeader = styled.div`
  font-size: 20px;
  font-family: 'GraphikMedium', sans-serif;
`;

export const Description = styled.div`
  margin-top: 28px;
`;

export const SpecialistsWrapper = styled.div`
  margin-top: 60px;
  padding-top: 28px;
  border-top: 1px solid ${(props: any) => props.theme.palette.grey[300]};
`;

export const SpecialistsList = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SpecialistsListHeader = styled.div`
  font-size: 20px;
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 30px;
`;

export const SpecialistCard = styled.div`
  border-radius: 10px;
  background-color: white;
  font-size: 16px;
  width: 230px;
  height: 72px;
  padding: 12px;
  box-sizing: border-box;
  font-family: 'GraphikMedium', sans-serif;
  margin-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    font-size: 14px;
    margin-top: 10px;
  }
`;
