import NoAssignedIcon from 'assets/emptyImage.svg';

import * as S from './EmptyState.styled';

interface EmptyStateProps {
  text: string;
  subText: string;
}

export const EmptyState = ({ text, subText }: EmptyStateProps) => {
  return (
    <S.Container>
      <img src={NoAssignedIcon} alt="" />
      <S.Info>
        <S.Text>{text}</S.Text>
        <S.SubText>{subText}</S.SubText>
      </S.Info>
    </S.Container>
  );
};
