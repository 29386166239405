// tslint:disable:no-console
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import { AppDispatch } from 'store/index';
import {
  getAllPastNotifications,
  getAllTodayNotifications,
  getUnreadCountNotifications,
  markReadAllNotifications,
  markReadNotificationByID,
} from 'store/notifications/notifications.actions';
import { RootState } from 'store/rootReducer';
import { Notification } from 'types/notificationTypes';

import { NotificationMessage } from './NotificationMessage';

const useStyles = makeStyles({
  menu: {
    '&.MuiPaper-root': {
      width: '100%',
      display: 'block',
    },
  },
  menuItem: {
    width: 'auto',
  },
  readAll: {
    color: '#C09006',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
  },
  readNotification: {
    cursor: 'auto!important',
  },
});

const LIMIT = 10;
const today =
  new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split('.')[0] + 'Z';

export const Notifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = useState<string | number | null>(0);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const { notifications, today_unread, past_unread, count } = useSelector(
    (state: RootState) => state.notifications,
  );

  useEffect(() => {
    dispatch(getUnreadCountNotifications({ date: today }));
    getListOfNotifications(0, 1);
  }, []);

  useEffect(() => {
    setUnreadCount(today_unread + past_unread);
  }, [today_unread, past_unread]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.SyntheticEvent | null,
    tab: number | string | null,
  ) => {
    setActiveTab(tab);
    setPage(1);
    setHasMore(true);
    getListOfNotifications(tab, 1);
  };

  const getListOfNotifications = (
    tab: number | string | null,
    newPage: number,
  ) => {
    if (tab === 0) {
      dispatch(
        getAllTodayNotifications({
          date: today,
          page: newPage,
        }),
      );
    }
    if (tab === 1) {
      dispatch(
        getAllPastNotifications({
          date: today,
          page: newPage,
        }),
      );
    }
  };

  const handleChangeAllStatus = () => {
    dispatch(markReadAllNotifications({ tab: activeTab, date: today }));
  };

  const handleChangeStatus = (id: string) => {
    dispatch(markReadNotificationByID({ id, date: today, tab: activeTab }));
  };

  const uploadMoreNotifications = () => {
    if (page * LIMIT < count) {
      setPage(page + 1);
      getListOfNotifications(activeTab, page + 1);
    } else {
      setHasMore(false);
    }
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show new notifications"
        color="primary"
        onClick={handleClick}
      >
        {unreadCount ? (
          <Badge
            badgeContent={unreadCount && unreadCount > 99 ? '...' : unreadCount}
            color="warning"
          >
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: '650px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={classes.menu}
      >
        <Typography variant="h2" m={2}>
          Notifications
        </Typography>
        <Tabs value={activeTab} onChange={handleChange}>
          <Grid container={true}>
            <Grid item={true} xs={9}>
              <TabsList>
                <Tab value={0}>Today's</Tab>
                <Tab value={1}>Other</Tab>
              </TabsList>
            </Grid>
            <Grid item={true} xs={3} mt={3}>
              <Typography
                onClick={handleChangeAllStatus}
                className={classes.readAll}
              >
                Mark all as read
              </Typography>
            </Grid>
          </Grid>

          <TabPanel value={0}>
            {notifications.length === 0 ? (
              <Typography variant={'h3'} m={3}>
                There are no new notifications
              </Typography>
            ) : (
              <div
                id="scrollableDiv"
                style={{
                  height: 600,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <InfiniteScroll
                  dataLength={notifications.length}
                  next={uploadMoreNotifications}
                  style={{ display: 'flex', flexDirection: 'column' }}
                  hasMore={hasMore}
                  loader={
                    <Typography variant={'h4'} m={3}>
                      Loading..
                    </Typography>
                  }
                  endMessage={
                    <Typography variant={'h4'} m={3}>
                      End
                    </Typography>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {notifications &&
                    notifications.map(
                      ({
                        title,
                        message,
                        message_type,
                        id,
                        status,
                      }: Notification) => {
                        return (
                          <MenuItem
                            key={id}
                            disableRipple={status === 'read'}
                            className={classNames(classes.menuItem, {
                              [classes.readNotification]: status === 'read',
                            })}
                            // onClick={() => handleNotificationClick(id)}
                          >
                            <NotificationMessage
                              type={message_type}
                              title={title}
                              message={message}
                              status={status}
                              id={id}
                              handleChangeStatus={handleChangeStatus}
                            />
                          </MenuItem>
                        );
                      },
                    )}
                </InfiniteScroll>
              </div>
            )}
          </TabPanel>
          <TabPanel value={1}>
            {notifications.length === 0 ? (
              <Typography variant={'h3'} m={3}>
                There are no past notifications
              </Typography>
            ) : (
              <div
                id="scrollableDiv"
                style={{
                  height: 600,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <InfiniteScroll
                  dataLength={notifications.length}
                  next={uploadMoreNotifications}
                  style={{ display: 'flex', flexDirection: 'column' }}
                  hasMore={hasMore}
                  loader={
                    <Typography variant={'h4'} m={3}>
                      Loading..
                    </Typography>
                  }
                  endMessage={
                    <Typography variant={'h4'} m={3}>
                      End
                    </Typography>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {notifications &&
                    notifications.map(
                      ({
                        title,
                        message,
                        message_type,
                        id,
                        status,
                      }: Notification) => {
                        return (
                          <MenuItem
                            key={id}
                            className={classNames(classes.menuItem, {
                              [classes.readNotification]: status === 'read',
                            })}
                            disableRipple={status === 'read'}
                            // onClick={() => handleNotificationClick(id)}
                          >
                            <NotificationMessage
                              type={message_type}
                              title={title}
                              message={message}
                              status={status}
                              id={id}
                              handleChangeStatus={handleChangeStatus}
                            />
                          </MenuItem>
                        );
                      },
                    )}
                </InfiniteScroll>
              </div>
            )}
          </TabPanel>
        </Tabs>
      </Menu>
    </Box>
  );
};
