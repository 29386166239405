import { ChangeEvent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
// ss
// import ReactPhoneInput from 'react-phone-input-material-ui';
import { useSelector } from 'react-redux';

// import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Typography } from '@mui/material';
// import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components';
import { PhoneInput } from 'components/Inputs/PhoneInput/PhoneInput';
import { TextAreaInput } from 'components/Inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'components/Inputs/TextInput/TextInput';
import { SingleImageUploader } from 'components/SingleImageUploader/SingleImageUploader';
import { Switcher } from 'components/Switcher/Switcher';

// import { phoneSchema } from 'constants/schema';
import { updateBuildingById } from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import { IBuilding } from 'types';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  mainInfo: {
    display: 'flex',
  },
  content: {
    width: '100%',
    marginTop: '82px',
  },
  editContent: {
    width: '100%',
    maxWidth: '367px',

    '& :not(:last-child)': {
      '& .MuiFormControl-root': {
        marginBottom: '42px',
      },
      '& .MuiTypography-helperText': {
        bottom: '20px',
      },
    },
  },
  cardBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    width: '100%',
  },
  managerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  changeManagerButton: {
    color: '#C09006',
    cursor: 'pointer',
  },
  managerInfo: {
    backgroundColor: '#FFFFFF',
    padding: '12px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  switcherBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '-30px 0 30px',
  },
});

interface BuildingMainInfoProps {
  canUpdate?: boolean;
  setActiveTab?: (tab: string) => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  validationFormState?: UseFormReturn;
  notUploadedImage?: string;
  setNotUploadedImage?: (image: string) => void;
  handleChangeSwitcher?: () => void;
}

export const BuildingMainInfo = ({
  handleChange,
  validationFormState,
  canUpdate = false,
  notUploadedImage,
  setNotUploadedImage,
  setActiveTab,
  handleChangeSwitcher,
}: BuildingMainInfoProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { currentBuilding, loading } = useSelector(
    (state: RootState) => state.buildings,
  );

  useEffect(() => {
    setEditedData({ ...currentBuilding });
  }, [currentBuilding]);

  const [editedData, setEditedData] = useState<IBuilding>(
    currentBuilding && {
      building_name: currentBuilding?.building_name,
      building_image: currentBuilding?.building_image,
      building_description: currentBuilding?.building_description,
      building_address: currentBuilding?.building_address,
      phone_number: currentBuilding?.phone_number,
      email: currentBuilding?.email,
      building_manager: currentBuilding?.building_manager,
      is_concierge_service_allowed: false,
    },
  );

  useEffect(() => {
    if (editedData) {
      validationFormState?.setValue('building_name', editedData.building_name);
      validationFormState?.setValue(
        'building_image',
        editedData.building_image,
      );
      validationFormState?.setValue(
        'building_description',
        editedData.building_description,
      );
      validationFormState?.setValue(
        'building_address',
        editedData.building_address,
      );
      validationFormState?.setValue('phone_number', editedData.phone_number);
      validationFormState?.setValue('email', editedData.email);
      validationFormState?.setValue(
        'building_manager',
        editedData.building_manager,
      );
    }
  }, [editedData]);

  const checkSwitcher = () => {
    if (currentBuilding?.is_concierge_service_allowed) {
      setEditedData({ ...editedData, is_concierge_service_allowed: true });
    } else {
      setEditedData({ ...editedData, is_concierge_service_allowed: false });
    }
  };

  useEffect(() => {
    checkSwitcher();
  }, []);

  const goToChangeManagerPage = () => {
    if (setActiveTab) {
      setActiveTab('managers');
    }
  };

  const handleChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });

    if (handleChange) {
      handleChange(e);
    }
  };

  const handleSaveChanges = () => {
    dispatch(
      updateBuildingById({ building: editedData, id: currentBuilding?.id }),
    );
  };

  // const onChangePhone = (e: any) => {
  //   console.log(e);
  // };

  // const onChange = (e: any) => {
  //   console.log(e);
  //   // onChangePhone(e);
  // };

  const changeSwitcher = () => {
    if (handleChangeSwitcher) {
      handleChangeSwitcher();
    }

    setEditedData({
      ...editedData,
      is_concierge_service_allowed: !editedData.is_concierge_service_allowed,
    });
  };

  if (loading) {
    return (
      <Box textAlign={'center'} mt={2}>
        Loading...
      </Box>
    );
  }

  return (
    <Box className={classes.mainInfo} pb={5}>
      <SingleImageUploader
        image={editedData.building_image}
        withUploadBtn={true}
        notUploadedImage={notUploadedImage}
        setNotUploadedImage={setNotUploadedImage}
      />
      <Box className={classes.content}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box className={classes.editContent}>
            <TextInput
              name={'building_name'}
              value={editedData.building_name}
              label={'Building name'}
              color={'black'}
              handleChange={handleChangeLocal}
            />
            {currentBuilding?.building_code && (
              <TextInput
                name={'building_code'}
                value={currentBuilding.building_code}
                isDisabled={true}
                label={'Building code'}
                color={'black'}
                handleChange={handleChangeLocal}
              />
            )}
            <TextInput
              name={'building_address'}
              value={editedData.building_address}
              label={'Building address'}
              color={'black'}
              handleChange={handleChangeLocal}
            />
            <TextAreaInput
              label={'Building description'}
              value={editedData.building_description}
              name={'building_description'}
              color={'black'}
              handleChange={handleChangeLocal}
            />
            <PhoneInput
              name={'phone_number'}
              label={'Telephone number'}
              value={editedData.phone_number}
              color={'black'}
              handleChange={handleChangeLocal}
            />
            <TextInput
              label={'Email'}
              name={'email'}
              value={editedData.email}
              color={'black'}
              handleChange={handleChangeLocal}
            />
            <Box className={classes.switcherBlock}>
              <Switcher
                isChecked={editedData.is_concierge_service_allowed}
                onChange={changeSwitcher}
              />
              <Typography ml={1} variant={'buttonMedium'}>
                Concierge services
              </Typography>
            </Box>
            {canUpdate && (
              <Box>
                <Box mb={3}>
                  <Box className={classes.managerTitle}>
                    <Typography variant={'title1'}>Building manager</Typography>
                    <Typography
                      variant={'buttonMedium'}
                      className={classes.changeManagerButton}
                      onClick={goToChangeManagerPage}
                    >
                      Change manager
                    </Typography>
                  </Box>
                  <Box className={classes.managerInfo}>
                    <Typography variant={'title1'}>
                      {editedData?.building_manager
                        ? `${editedData?.building_manager?.first_name} ${editedData?.building_manager?.last_name}`
                        : 'Manager not assigned'}
                    </Typography>
                    <Typography variant={'body1'}>
                      {editedData?.building_manager?.email}
                    </Typography>
                  </Box>
                </Box>
                <CustomButton
                  title={'Save'}
                  color={'primary'}
                  variant={'buttonMedium'}
                  handleClick={validationFormState?.handleSubmit(
                    handleSaveChanges,
                  )}
                  additionalClass={classes.saveButton}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
