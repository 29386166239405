import { createAsyncThunk } from '@reduxjs/toolkit';

import { endpoints } from '../../config';
import http from '../../utils/http';
import { BaseError } from '../store.constants';
import { MembersList } from './members.types';

export interface GetMembersParams {
  page?: number;
  page_size?: number;
  search?: string;
  building?: string;
}

export const getMembers = createAsyncThunk<
  MembersList,
  GetMembersParams,
  { rejectValue: BaseError }
>('timeSlots/getMembers', async (params: GetMembersParams) => {
  const response = await http.get(endpoints.people.getMembers, {
    params,
  });

  return {
    list: response.data,
    params,
  };
});
