import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints, main } from 'config';

import http from 'utils/http';

import { BaseError } from '../store.constants';
import {
  ConciergeRequestsList,
  GetServiceRequestsParams,
  ServiceRequestsList,
} from './serviceRequests.types';

export const getServiceRequests = createAsyncThunk<
  ServiceRequestsList,
  GetServiceRequestsParams,
  { rejectValue: BaseError }
>(
  'serviceRequests/getServiceRequests',
  async (params: GetServiceRequestsParams) => {
    const response = await http.get(
      endpoints.serviceRequests.getServiceRequests,
      {
        params,
      },
    );

    return {
      list: response.data,
      params,
    };
  },
);

export const acceptServiceRequests = createAsyncThunk<
  undefined,
  string,
  { rejectValue: BaseError }
>('serviceRequests/approveServiceRequests', async (id: string) => {
  const response = await http.post(
    endpoints.serviceRequests.approveServiceRequests(id),
  );
  return response.data;
});

export const declineServiceRequests = createAsyncThunk<
  undefined,
  string,
  { rejectValue: BaseError }
>('serviceRequests/declineServiceRequests', async (id: string) => {
  const response = await http.post(
    endpoints.serviceRequests.declineServiceRequests(id),
  );
  return response.data;
});

export const rescheduleServiceBooking = createAsyncThunk<
  undefined,
  { id: string; time_slot_id: string },
  { rejectValue: BaseError }
>('serviceRequests/rescheduleServiceBooking', async ({ id, time_slot_id }) => {
  const response = await http.put(
    `${main.serviceRequests.root}${id}/reschedule/`,
    {
      time_slot_id,
    },
  );

  return response.data;
});

export const getConciergeRequests = createAsyncThunk<
  ConciergeRequestsList,
  GetServiceRequestsParams,
  { rejectValue: BaseError }
>(
  'serviceRequests/getConciergeRequests',
  async (params: GetServiceRequestsParams) => {
    const response = await http.get(
      endpoints.serviceRequests.getConciergeRequests,
      {
        params,
      },
    );

    return {
      list: response.data,
      params,
    };
  },
);

export const submitConciergeService = createAsyncThunk<
  undefined,
  { id: string; price: number; comment: string },
  { rejectValue: BaseError }
>('serviceRequests/submitConciergeService', async ({ id, comment, price }) => {
  const response = await http.put(
    endpoints.serviceRequests.confirmDetailsConciergeService(id),
    {
      price,
      comment,
    },
  );
  return response.data;
});

export const declineConciergeService = createAsyncThunk<
  undefined,
  string,
  { rejectValue: BaseError }
>('serviceRequests/declineConciergeService', async (id) => {
  const response = await http.post(
    endpoints.serviceRequests.declineConciergeServiceRequests(id),
  );
  return response.data;
});
