import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarAndBio = styled.div`
  display: flex;
  position: relative;
`;

export const EditButtonWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 0;
`;

export const AvatarWrapper = styled.div`
  margin-right: 64px;
`;

export const Avatar = styled.img`
  width: 218px;
  height: 218px;
  border-radius: 50%;
  object-fit: cover;
`;

export const SpecialistInfo = styled.div`
  max-width: 320px;
`;

export const Description = styled.div`
  font-family: 'GraphikRegular', sans-serif;
  line-height: 20px;
  margin-bottom: 24px;
  color: black;
`;
