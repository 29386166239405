import { Typography } from '@mui/material';

import { CellElement } from '../../components/GridElements/CellElement/CellElement';
import {
  ColumnType,
  GRID_HEADER_ALIGNMENTS,
} from '../../components/GridElements/GridCommonConfigs';
import { HeaderElement } from '../../components/GridElements/HeaderElement/HeaderElement';
import { TextWithAvatar } from '../../components/GridElements/TextWithAvatar/TextWithAvatar';
import { ROLE_NAMES } from '../../constants/Roles';
import { Contact } from '../../store/contacts/contacts.types';
import { TrancatedString } from '../../styles/common.styled';
import { shapeBuildingsString } from '../../utils/HelperFunctions';

export const CONTACTS_LIST_CONFIG: ColumnType<Contact>[] = [
  {
    label: 'User name',
    widthPercentage: 25,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Contact>) => (
      <HeaderElement<Contact> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Contact>, contact: Contact) => (
      <CellElement<Contact> column={item}>
        <TextWithAvatar
          imgLink={contact.account_icon}
          text={`${contact.first_name} ${contact.last_name}`}
        />
      </CellElement>
    ),
  },
  {
    label: 'Role',
    widthPercentage: 25,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Contact>) => (
      <HeaderElement<Contact> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Contact>, contact: Contact) => {
      return (
        <CellElement<Contact> column={item}>
          <Typography variant={'body2'}>{ROLE_NAMES[contact.role]}</Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Email',
    widthPercentage: 25,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Contact>) => (
      <HeaderElement<Contact> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Contact>, contact: Contact) => {
      return (
        <CellElement<Contact> column={item}>
          <Typography variant={'body2'}>{contact.email}</Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Building name',
    widthPercentage: 25,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Contact>) => (
      <HeaderElement<Contact> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Contact>, contact: Contact) => {
      const buildingsString = shapeBuildingsString(contact.buildings);

      return (
        <CellElement<Contact> column={item}>
          <TrancatedString title={buildingsString}>
            {buildingsString}
          </TrancatedString>
        </CellElement>
      );
    },
  },
];
