/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FcmState } from 'types/fcm';

export const initialState: FcmState = Object.freeze({
  token: null,
  notifications: [],
});

const fcmSlice = createSlice({
  name: 'fcm',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    addNotifications(state, action) {
      state.notifications = [
        ...state.notifications,
        { ...action.payload, id: +new Date() },
      ];
    },
    removeNotifications(state, action) {
      state.notifications = [];
    },
    updateNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});

export const {
  setToken,
  addNotifications,
  removeNotifications,
  updateNotifications,
} = fcmSlice.actions;

export default fcmSlice.reducer;
