import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import {
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addMinutes, format, isToday } from 'date-fns';

import DeleteIcon from 'assets/icons/delete-grey.svg';

import { CustomButton } from '../../../../../components';
import { TIME_FORMAT_AM_PM } from '../../../../../utils/constants';
import { areOverlapping, isBeforeOrEqual } from './IntervalsSelector.helpers';
import * as S from './IntervalsSelector.styled';
import {
  Interval,
  INTERVAL_DEFAULT_VALUE,
  SelectedInterval,
} from './IntervalsSelector.types';

interface IntervalsSelectorProps {
  name: string;
}

export const IntervalsSelector = ({ name }: IntervalsSelectorProps) => {
  const [interval, setInterval] = useState<Interval>(INTERVAL_DEFAULT_VALUE);
  const {
    formState: { errors },
    setError,
    setValue,
    getValues,
    clearErrors,
    watch,
  } = useFormContext();

  const watchedStartDate = watch('start_date');
  const watchedEndDate = watch('end_date');

  const handleAddInterval = () => {
    clearErrors();
    const selectedIntervals = getValues('timeslots');

    if (isBeforeOrEqual(interval)) {
      setError('timeslots', {
        message: 'End time should be later than start time',
      });

      return;
    }

    if (
      selectedIntervals.length > 0 &&
      areOverlapping(selectedIntervals, interval)
    ) {
      setError('timeslots', {
        message: 'Intervals should not overlap',
      });

      return;
    }

    setValue('timeslots', [...getValues('timeslots'), interval]);
    setInterval(INTERVAL_DEFAULT_VALUE);
  };

  const handleDeleteSelectedInterval = (startTimeString: string) => {
    setValue(
      'timeslots',
      getValues('timeslots').filter(
        (item: SelectedInterval) =>
          item.start_time.toTimeString() !== startTimeString,
      ),
    );
  };

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <S.SelectedIntervals>
          {watch('timeslots').map((item: SelectedInterval) => (
            <S.SelectedInterval key={item.start_time?.toTimeString()}>
              <span>{format(item.start_time, TIME_FORMAT_AM_PM)}</span>
              <div>-</div>
              <span>{format(item.end_time, TIME_FORMAT_AM_PM)}</span>
              <S.DeleteInterval
                onClick={() =>
                  handleDeleteSelectedInterval(item.start_time.toTimeString())
                }
              >
                <img src={DeleteIcon} alt="" />
              </S.DeleteInterval>
            </S.SelectedInterval>
          ))}
        </S.SelectedIntervals>
        <S.TimePickersWrapper>
          <TimePicker
            label="Start work"
            minutesStep={15}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            minTime={
              isToday(watchedStartDate) &&
              watchedEndDate &&
              isToday(watchedEndDate)
                ? new Date()
                : undefined
            }
            slotProps={{
              textField: {
                onBeforeInput: (e) => {
                  e.preventDefault();
                },
              },
            }}
            value={interval?.start_time}
            onChange={(newValue) =>
              setInterval((prevState) => ({
                ...prevState,
                start_time: newValue,
              }))
            }
          />
          <TimePicker
            label="End work"
            minutesStep={15}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            slotProps={{
              textField: {
                onBeforeInput: (e) => {
                  e.preventDefault();
                },
              },
            }}
            disabled={!interval.start_time}
            minTime={addMinutes(interval?.start_time as Date, 15)}
            value={interval?.end_time}
            onChange={(newValue) =>
              setInterval((prevState) => ({
                ...prevState,
                end_time: newValue,
              }))
            }
          />
          <CustomButton
            title="Add"
            color="primary"
            size="sm"
            disabled={!interval.start_time || !interval.end_time}
            variant="buttonMedium"
            handleClick={handleAddInterval}
          />
        </S.TimePickersWrapper>
      </LocalizationProvider>
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
