import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as SInfoPage from 'components/InfoPage/InfoPage.styled';

import { Type } from 'store/service-specialists/service-specialists.types';

import DefaultAvatar from '../../../../../assets/empty-user.jpg';
import BagIcon from '../../../../../assets/icons/bag.svg';
import CheckmarkIcon from '../../../../../assets/icons/checkmark-black.svg';
import EditIcon from '../../../../../assets/icons/edit.svg';
import FitnessIcon from '../../../../../assets/icons/fitness.svg';
import MailIcon from '../../../../../assets/icons/mail.svg';
import PhoneIcon from '../../../../../assets/icons/phone.svg';
import UploadIcon from '../../../../../assets/icons/upload-black.svg';
import { CustomButton } from '../../../../../components';
import { OverlayLoader } from '../../../../../components/Loaders/OverlayLoader';
import { UploadButton } from '../../../../../components/UploadButton/UploadButton';
import { useAppDispatch } from '../../../../../hooks/useAppSelector';
import { getBuildings } from '../../../../../store/buildings/buildings.actions';
import { ResponseBuilding } from '../../../../../types/buildings';
import { useSpecialistDetailsData } from '../../useSpecialistDetailsData';
import { BuildingList } from './BuildingsList/BuildingList';
import { SpecialistEditor } from './Editor/SpecialistEditor';
import * as S from './SpecialistInfo.styled';

export const SpecialistInfo = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { specialist, uploadSpecialistAvatar, isLoading, saveSpecialist } =
    useSpecialistDetailsData(id);

  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (specialist?.id && e.target.files?.length) {
      uploadSpecialistAvatar(specialist.id, e.target.files[0]);
    }
  };

  useEffect(() => {
    dispatch(
      getBuildings({
        page_size: 10000,
      }),
    );
  }, []);

  return (
    <S.Container>
      {isLoading && <OverlayLoader />}
      <S.AvatarAndBio>
        {!isEditMode && (
          <S.EditButtonWrapper>
            <CustomButton
              title={'Edit info'}
              color={'primary'}
              variant={'buttonMedium'}
              afterIcon={EditIcon}
              handleClick={() => setIsEditMode(true)}
            />
          </S.EditButtonWrapper>
        )}
        <S.AvatarWrapper>
          <UploadButton
            title="Upload photo"
            onChange={handleUploadAvatar}
            additionalClasses="upload-button"
            variant={'buttonMedium'}
            icon={UploadIcon}
            name="specialist-avatar"
          />
          <S.Avatar src={specialist?.account_icon || DefaultAvatar} />
        </S.AvatarWrapper>
        {!isEditMode && specialist && (
          <S.SpecialistInfo>
            <SInfoPage.EntityName>{`${specialist.first_name} ${specialist.last_name}`}</SInfoPage.EntityName>
            {specialist.bio_info && (
              <S.Description>{`${specialist.bio_info}`}</S.Description>
            )}
            {specialist.profession && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={BagIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {specialist.profession}
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
            {specialist.email && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={MailIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {specialist.email}
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
            {specialist.phone_number && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={PhoneIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {specialist.phone_number}
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
            {Boolean(specialist.categories.length) && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={FitnessIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {specialist.categories[0].name}
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
            {Boolean(specialist.types.length) && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={CheckmarkIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {specialist?.types.map(
                    (type: Type, index: number) =>
                      `${type.name}${
                        index === specialist.types.length - 1 ? '' : ', '
                      }`,
                  )}
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
          </S.SpecialistInfo>
        )}
        {isEditMode && specialist && (
          <SpecialistEditor
            specialist={specialist}
            saveSpecialist={saveSpecialist}
            setIsEditMode={setIsEditMode}
          />
        )}
      </S.AvatarAndBio>
      {Boolean(specialist?.buildings.length) && !isEditMode && (
        <BuildingList buildings={specialist?.buildings as ResponseBuilding[]} />
      )}
    </S.Container>
  );
};
