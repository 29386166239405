import * as yup from 'yup';

export const SplitFormSchema = yup.object({
  split: yup.lazy((value: any) => {
    if (value === '') {
      return yup.string().required('Split is required!');
    }

    return yup
      .number()
      .min(0, 'Split must be more than or equal to 0')
      .max(100, 'Split must be less than or equal to 100')
      .test(
        'maxDigitsAfterDecimal',
        'Split % should be with max 2 decimal places',
        (number: any) => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .typeError('Split has to be numbers only')
      .required('Split is required!');
  }),
});
