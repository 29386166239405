import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import { getServiceTypes } from './service-types.actions';
import { ServiceTypeState } from './service-types.types';

const SERVICE_TYPES_LIST_DEFAULT = {
  list: [],
  params: {},
};

export const initialState: ServiceTypeState = Object.freeze({
  loading: false,
  error: null,
  status: STORE_STATUSES.IDLE,
  serviceTypes: SERVICE_TYPES_LIST_DEFAULT,
});

const serviceTypesSlice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    clearsServiceTypes: (state) => {
      state.serviceTypes = SERVICE_TYPES_LIST_DEFAULT;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getServiceTypes.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getServiceTypes.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.loading = false;
        state.serviceTypes = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getServiceTypes.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearsServiceTypes } = serviceTypesSlice.actions;

export default serviceTypesSlice.reducer;
