import styled from '@emotion/styled/macro';

export const Container = styled.div<{ size: number }>`
  .MuiSvgIcon-root {
    font-size: ${(props: any) => `${props.size}px`};
  }

  .MuiFormControlLabel-label {
    margin-top: 2.5px;
  }
`;
