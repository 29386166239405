export const shapePrice = (
  price: number | undefined,
  priceFrom: number | undefined,
  priceTo: number | undefined,
) => {
  if (
    (price === 0 || price === null) &&
    (priceFrom === 0 || priceFrom === null) &&
    (priceTo === 0 || priceTo === null)
  ) {
    return 'FREE';
  }

  if (price) {
    return `${price}$`;
  }

  return `${priceFrom}$ - ${priceTo}$`;
};

export const shapePackagePrice = (
  packageToPurchasePrice: number | undefined,
  bookFromPackagePrice: number | undefined,
) => {
  if (packageToPurchasePrice) {
    return `${packageToPurchasePrice}$`;
  }

  if (bookFromPackagePrice) {
    return `${bookFromPackagePrice}$`;
  }

  return 'FREE';
};
