import { Switch, switchClasses } from '@mui/base';
import { styled } from '@mui/system';

const gold = {
  500: '#D5A419;',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
};

const Root = styled('span')(
  ({ theme }: any) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 22px;
      top: 3px;
      background-color: #fff;
    }

    .${switchClasses.track} {
      background: ${gold[500]};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);

interface SwitcherProps {
  isChecked: boolean;
  onChange: () => void;
}

export const Switcher = ({ isChecked, onChange }: SwitcherProps) => {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };

  return (
    <Switch
      slots={{
        root: Root,
      }}
      {...label}
      checked={isChecked}
      onChange={onChange}
    />
  );
};
