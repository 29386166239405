import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import { eachDayOfInterval, format } from 'date-fns';

import { DAYS_LIST } from './DaysSelector.constants';
import * as S from './DaysSelector.styled';

interface DaysSelectorProps {
  name: string;
}

export const DaysSelector = ({ name }: DaysSelectorProps) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const getDisabledDaysList = (): string[] => {
    if (watch('no_end_date')) {
      return DAYS_LIST;
    }

    const daysOfInterval = eachDayOfInterval({
      start: watch('start_date'),
      end: watch('end_date'),
    });

    if (daysOfInterval.length >= 7) {
      return DAYS_LIST;
    }

    return daysOfInterval.map((day: Date) => format(day, 'EEEE').toLowerCase());
  };

  const isDayDisabled = (day: string) => {
    return getDisabledDaysList().indexOf(day) < 0;
  };

  return (
    <S.Container>
      <S.Header>Day(s) of the week</S.Header>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          const handleDayClick = (day: string) => {
            const isSelected = value.indexOf(day) > -1;

            if (isSelected) {
              onChange(value.filter((item: string) => item !== day));
              return;
            }

            onChange([...value, day]);
          };

          return (
            <S.DaysWrapper>
              {DAYS_LIST.map((day: string) => (
                <S.Day
                  onClick={() => handleDayClick(day)}
                  selected={value.indexOf(day) > -1}
                  disabled={isDayDisabled(day)}
                  key={day}
                >
                  {day.slice(0, 3)}
                </S.Day>
              ))}
            </S.DaysWrapper>
          );
        }}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
