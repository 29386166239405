import { ENTITY_STATUSES } from '../../../components/EntityStatus/EntityStatus.constants';
import { LIMIT } from '../../../components/GridElements/GridFilters/GridFilters.constants';
import { SelectItem } from '../../../components/MultiSelectWrapper/MultiSelectWrapper';

export const DEFAULT_PARAMS = {
  page: 1,
  page_size: LIMIT,
  search: '',
  ordering: 'newest',
};

export const CONCIERGE_REQUEST_STATUSES_OPTIONS: SelectItem[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'New',
    value: ENTITY_STATUSES.NEW,
  },
  {
    label: 'Pending',
    value: ENTITY_STATUSES.PENDING,
  },
  {
    label: 'Paid',
    value: ENTITY_STATUSES.PAID,
  },
  {
    label: 'Declined',
    value: ENTITY_STATUSES.DECLINED,
  },
  {
    label: 'Cancelled',
    value: ENTITY_STATUSES.CANCELLED,
  },
];
