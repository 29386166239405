import React from 'react';

import { Typography } from '@mui/material';

import AvailableIcon from 'assets/icons/check-mark.svg';
import UnavailableIcon from 'assets/icons/error.svg';

import {
  ColumnType,
  GRID_HEADER_ALIGNMENTS,
} from 'components/GridElements/GridCommonConfigs';

import { TimeSlot } from 'store/time-slots/time-slots.types';

import { CellElement } from '../../../components/GridElements/CellElement/CellElement';
import { HeaderElement } from '../../../components/GridElements/HeaderElement/HeaderElement';
import { Service } from '../../../store/services/services.types';
import { TIME_SLOT_STATUSES } from '../../../store/time-slots/time-slots.constants';
import { ResponseBuilding } from '../../../types/buildings';
import {
  formatParsedDate,
  formatParsedTime,
} from '../../../utils/HelperFunctions';
import * as S from '../../PeopleManagement/ServiceSpecialist/List/ServiceSpecialistList.styled';

export const getScheduleListConfig = (
  isSpecialistPage: boolean,
): ColumnType<TimeSlot>[] => [
  {
    label: 'Day',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      timeSlot: TimeSlot,
      _,
      rowIndex,
      noBottomBorder,
    ) => (
      <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
        <Typography
          sx={{
            textTransform: 'capitalize',
          }}
          variant={'tableHeader'}
        >
          {rowIndex === 0 ? timeSlot.day_of_the_week : ''}
        </Typography>
      </CellElement>
    ),
  },
  {
    label: 'Start Time',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      timeSlot: TimeSlot,
      _,
      __,
      noBottomBorder,
    ) => {
      return (
        <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
          <Typography variant={'body2'}>
            <S.TimeWithStatus>
              {timeSlot.status === TIME_SLOT_STATUSES.AVAILABLE && (
                <img src={AvailableIcon} alt="" />
              )}
              {timeSlot.status === TIME_SLOT_STATUSES.UNAVAILABLE && (
                <img src={UnavailableIcon} alt="" />
              )}
              {formatParsedTime(timeSlot.start_time)}
            </S.TimeWithStatus>
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'End Time',
    widthPercentage: 15,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      timeSlot: TimeSlot,
      _,
      __,
      noBottomBorder,
    ) => {
      return (
        <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
          <Typography variant={'body2'}>
            {' '}
            {formatParsedTime(timeSlot.end_time)}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Location',
    widthPercentage: isSpecialistPage ? 15 : 30,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      timeSlot: TimeSlot,
      _,
      __,
      noBottomBorder,
    ) => {
      const buildingsString = timeSlot.buildings
        .map(
          (building: ResponseBuilding) =>
            `${building.building_code} ${building.building_name}`,
        )
        .join(', ');

      return (
        <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
          <Typography variant={'body2'}>
            <S.BuildingList title={buildingsString}>
              {Boolean(timeSlot.buildings.length) ? buildingsString : '-'}
            </S.BuildingList>
          </Typography>
        </CellElement>
      );
    },
  },
  ...(isSpecialistPage
    ? ([
        {
          label: 'Services',
          widthPercentage: isSpecialistPage ? 15 : 30,
          align: GRID_HEADER_ALIGNMENTS.LEFT,
          headerComponent: (column: ColumnType<TimeSlot>) => (
            <HeaderElement<TimeSlot> column={column}>
              <Typography variant={'tableHeader'}>{column.label}</Typography>
            </HeaderElement>
          ),
          cellComponent: (
            item: ColumnType<TimeSlot>,
            timeSlot: TimeSlot,
            _,
            __,
            noBottomBorder,
          ) => {
            const servicesString = timeSlot.services
              .map((service: Service) => service.name)
              .join(', ');

            return (
              <CellElement<TimeSlot>
                column={item}
                noBottomBorder={noBottomBorder}
              >
                <Typography variant={'body2'}>
                  <S.BuildingList title={servicesString}>
                    {Boolean(timeSlot.buildings.length) ? servicesString : '-'}
                  </S.BuildingList>
                </Typography>
              </CellElement>
            );
          },
        },
      ] as ColumnType<TimeSlot>[])
    : []),
  {
    label: 'Date Range',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      timeSlot: TimeSlot,
      _,
      __,
      noBottomBorder,
    ) => {
      return (
        <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
          <Typography variant={'body2'}>
            {formatParsedDate(timeSlot.start_date)} -{' '}
            {timeSlot.end_date
              ? formatParsedDate(timeSlot.end_date)
              : 'No end date'}
          </Typography>
        </CellElement>
      );
    },
  },
  {
    label: 'Actions',
    widthPercentage: 10,
    align: GRID_HEADER_ALIGNMENTS.RIGHT,
    headerComponent: (item: ColumnType<TimeSlot>) => (
      <HeaderElement<TimeSlot> column={item}>
        <Typography variant={'tableHeader'}>{item.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (
      item: ColumnType<TimeSlot>,
      __,
      actionsComponent?: React.ReactElement,
      _?: number,
      noBottomBorder?: boolean,
    ) => (
      <CellElement<TimeSlot> column={item} noBottomBorder={noBottomBorder}>
        {actionsComponent}
      </CellElement>
    ),
  },
];
