import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { debounce } from '@mui/material';

import CrossIcon from '../../assets/icons/cross.svg';
import SearchIcon from '../../assets/icons/search.svg';
import * as S from './SearchField.styled';

interface SearchFieldProps {
  placeholder: string;
  handleSearch: (value: string) => void;
}

export const SearchField = ({
  placeholder,
  handleSearch,
}: SearchFieldProps) => {
  const initialLoaded = useRef(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const debouncedSearch = debounce((searchedValue: string) => {
      handleSearch(searchedValue);
    }, 600);

    if (initialLoaded.current) {
      debouncedSearch(inputValue);
    }

    return () => {
      debouncedSearch.clear();
    };
  }, [inputValue]);

  return (
    <S.Container>
      <S.SearchIcon src={SearchIcon} alt="" />
      {inputValue && (
        <S.CrossIcon onClick={() => setInputValue('')} src={CrossIcon} alt="" />
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!initialLoaded.current) {
            initialLoaded.current = true;
          }
          setInputValue(e.target.value);
        }}
      />
    </S.Container>
  );
};
