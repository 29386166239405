import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

export const useCurrentUser = () => {
  const auth = useSelector((state: RootState) => state.auth);

  return {
    auth,
  };
};
