export const normalizeFilterValue = (value: string) => {
  return value === 'all' ? '' : value;
};

export const normalizeBooleanFilterValue = (value: string) => {
  if (value === 'all') {
    return '';
  }

  return value === 'active';
};
