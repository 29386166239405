import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';

import { MessageInfo } from 'types';

interface ToastProps {
  isOpen: boolean;
  message: string | JSX.Element;
  duration: number;
  toastStyle: MessageInfo;
}

export const Toast = ({
  isOpen,
  message,
  duration,
  toastStyle,
}: ToastProps) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        action={action}
      >
        <Alert
          onClick={handleClose}
          severity={toastStyle}
          sx={{ width: '100%', fontSize: 16, padding: '10px 18px' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
