import CustomModal from '../CustomModal/CustomModal';
import { LogOutModalContent } from './components/LogOutModalContent/LogOutModalContent';

interface LogOutModalProps {
  isOpen: boolean;
  handleLogOut: () => void;
  closeModal: () => void;
}

const LogOutModal = ({
  handleLogOut,
  isOpen,
  closeModal,
}: LogOutModalProps) => {
  return (
    <CustomModal title={'Log out'} open={isOpen} closeModal={closeModal}>
      <LogOutModalContent closeModal={closeModal} logOut={handleLogOut} />
    </CustomModal>
  );
};

export default LogOutModal;
