import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '../CustomButton/CustomButton';

const useStyles = makeStyles({
  deleteButton: {
    marginRight: '16px',
  },
});

interface DeleteModalContentProps {
  handleDelete: () => void;
  closeModal: () => void;
  text: string | ReactNode;
}

export const DeleteModalContent = ({
  handleDelete,
  closeModal,
  text,
}: DeleteModalContentProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={'body1'}>{text}</Typography>
      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={'Delete'}
          variant={'buttonMedium'}
          additionalClass={classes.deleteButton}
          handleClick={handleDelete}
        />
        <CustomButton
          title={'Cancel'}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={closeModal}
        />
      </Box>
    </>
  );
};
