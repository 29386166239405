import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import more from 'assets/icons/more.svg';

import { Avatar } from 'components/Avatar/Avatar';

import { getAllPeople } from 'store/people/people.actions';
import { RootState } from 'store/rootReducer';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  status: { color: 'grey' },
  deactivate: {
    color: '#C90303',
  },
  active: {
    color: '#4CAF50',
  },
  managerUser: {
    display: 'flex',
    alignItems: 'center',
  },

  rowItem: {
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F7EDD1',
    },
  },
  selected: {
    backgroundColor: '#F7EDD1',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#3F51B5',
  },
  actionIcon: {
    marginRight: '8px',
  },
});

const limit = 15;

interface BuildingManagerListProps {
  activePage: number;
  selectedManager: string;
  handleOpenModal: (id: string) => void;
  handleSelect: (id: string) => void;
}

export const BuildingManagerList = ({
  activePage,
  selectedManager,
  handleOpenModal,
  handleSelect,
}: BuildingManagerListProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { allUsers } = useSelector((state: RootState) => state.people);

  useEffect(() => {
    dispatch(getAllPeople({ role: 'building_manager', limit, activePage }));
  }, [activePage]);

  const setShortText = (text: string) =>
    text === null || text.trim() === ''
      ? 'No Info'
      : text.length > 25
      ? `${text.slice(0, 25)}...`
      : text;

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '20px', minHeight: 576 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: '25%',
                }}
              >
                <Typography variant={'tableHeader'}>Name</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '25%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>
                  Building name(s)
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '25%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>Category</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}
                align="left"
              >
                <Typography variant={'tableHeader'}>Type</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '5%',
                }}
                align="right"
              >
                <Typography variant={'tableHeader'}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers?.map(
              (
                {
                  user_id,
                  account_icon,
                  last_name,
                  first_name,
                  building_names,
                  category,
                  type,
                  status,
                  admin_note,
                  id,
                }: any,
                index: number,
              ) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={classNames(classes.rowItem, {
                    [classes.selected]: user_id === selectedManager,
                  })}
                  onClick={() => handleSelect(user_id)}
                >
                  <TableCell component="th" scope="row">
                    <Box className={classes.managerUser}>
                      <Avatar img={account_icon} alt="building_manager" />
                      <Typography
                        variant={'body1'}
                        ml={4}
                        whiteSpace={'nowrap'}
                      >
                        {setShortText(`${last_name} ${first_name}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {building_names?.length
                        ? setShortText(
                            building_names
                              .map(
                                (el: any) =>
                                  ` ${el.building_code} ${el.building_name}`,
                              )
                              .toString(),
                          )
                        : 'No InfoPosts'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {category?.length
                        ? setShortText(category.map((el: any) => el).toString())
                        : 'No Category'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {type?.length
                        ? setShortText(type.map((el: any) => el).toString())
                        : 'No Type'}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      className={classes.actionButtons}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        handleOpenModal(user_id);
                      }}
                    >
                      <Typography
                        variant={'subtitle2'}
                        className={classes.actionBtn}
                      >
                        <img
                          src={more}
                          alt="more"
                          width={20}
                          height={20}
                          className={classes.actionIcon}
                        />
                        Details
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
