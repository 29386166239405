import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { YesNoModal } from 'components/CustomModal/YesNoModal/YesNoModal';
import { CustomPagination } from 'components/CustomPagination/CustomPagination';
import { ColumnType } from 'components/GridElements/GridCommonConfigs';
import { GridFilters } from 'components/GridElements/GridFilters/GridFilters';
import { FiltersParamsTypes } from 'components/GridElements/GridFilters/GridFilters.types';
import { OverlayLoader } from 'components/Loaders/OverlayLoader';
import { useConfirmationModal } from 'hooks/useConfirmationModal';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';
import { TABLE_CONTAINER_STYLES, TABLE_STYLES } from 'utils/constants';

import { LIMIT } from '../../PeopleManagement';
import { useServiceSpecialistData } from '../useServiceSpecialistData';
import { ServiceSpecialistActions } from './ServiceSpecialistActions';
import { SERVICE_SPECIALISTS_COLUMNS } from './ServiceSpecialistList.config';
import * as S from './ServiceSpecialistList.styled';

export const ServiceSpecialistList = (): React.ReactElement => {
  const {
    serviceSpecialists,
    getSpecialistsList,
    deleteSpecialist,
    changeSpecialistStatus,
    listRequestParams,
    listCount,
    isLoading,
  } = useServiceSpecialistData();
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();

  const handleSpecialistSearch = (value: string) => {
    getSpecialistsList({
      ...listRequestParams,
      search: value,
      page: 1,
    });
  };

  const handleFiltersChange = (params: FiltersParamsTypes) => {
    getSpecialistsList({
      ...listRequestParams,
      ...params,
      page: 1,
    });
  };

  return (
    <S.Container>
      {isLoading && <OverlayLoader />}
      <GridFilters
        handleFiltersChange={handleFiltersChange}
        handleSearch={handleSpecialistSearch}
        searchPlaceholder="Search by service specialist"
        config={{
          building: true,
        }}
      />
      <TableContainer component={Paper} sx={TABLE_CONTAINER_STYLES}>
        <Table sx={TABLE_STYLES} aria-label="simple table">
          <TableHead>
            <TableRow>
              {SERVICE_SPECIALISTS_COLUMNS.map(
                (item: ColumnType<ServiceSpecialist>) => (
                  <React.Fragment key={item.label}>
                    {item.headerComponent(item)}
                  </React.Fragment>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(serviceSpecialists.length) &&
              serviceSpecialists.map((specialist: ServiceSpecialist) => (
                <TableRow hover={true} key={specialist.id}>
                  {SERVICE_SPECIALISTS_COLUMNS.map(
                    (item: ColumnType<ServiceSpecialist>) => (
                      <React.Fragment key={item.label}>
                        {item.label === 'Actions'
                          ? item.cellComponent(
                              item,
                              specialist,
                              <ServiceSpecialistActions
                                specialist={specialist}
                                handleOpenModal={handleOpenModal}
                                deleteSpecialist={deleteSpecialist}
                                changeSpecialistStatus={changeSpecialistStatus}
                              />,
                            )
                          : item.cellComponent(item, specialist)}
                      </React.Fragment>
                    ),
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <YesNoModal
          isOpen={isConfirmationModalOpen}
          title={modalInfo.title}
          question={modalInfo.question}
          closeModal={handleCloseModal}
          handleAction={() => {
            if (modalInfo.callback) {
              modalInfo.callback();
            }
            handleCloseModal();
          }}
        />
      </TableContainer>
      <CustomPagination
        pages={Math.ceil(listCount / LIMIT)}
        handleChange={(e: React.ChangeEvent<unknown>, value: number) => {
          getSpecialistsList({
            ...listRequestParams,
            page: value,
          });
        }}
        activePage={listRequestParams.page}
      />
    </S.Container>
  );
};
