import { ReactElement } from 'react';

import './Loader.scss';
import * as S from './Loader.styled';

interface LoaderProps {
  size?: number;
  color?: string;
}

export const Loader = ({
  size = 24,
  color = '#000',
}: LoaderProps): ReactElement => {
  return <S.LoaderSpinner size={size} color={color} />;
};
