import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { main } from '../../config';
import { AddTimeSlotsRequestValues } from '../../modules/Schedule/Forms/AddScheduleForm/AddScheduleForm.types';
import { EditScheduleRequestValues } from '../../modules/Schedule/Forms/EditScheduleForm/EditScheduleForm.types';
import { DATE_FORMAT_BE } from '../../utils/constants';
import http from '../../utils/http';
import { BaseError } from '../store.constants';
import { FreeSlotsList, TimeSlot, TimeSlotsList } from './time-slots.types';

export interface GetTimeSlotsParams {
  building_id?: string;
  start_date?: Date;
  end_date?: Date;
  service_id?: string;
  status?: string;
  specialist_id?: string;
}

export const getTimeSlots = createAsyncThunk<
  TimeSlotsList,
  GetTimeSlotsParams,
  { rejectValue: BaseError }
>('timeSlots/getTimeSlots', async (params: GetTimeSlotsParams) => {
  const response = await http.get(main.scheduling.root, {
    params: {
      ...params,
      start_date: format(params.start_date as Date, DATE_FORMAT_BE),
      end_date: format(params.end_date as Date, DATE_FORMAT_BE),
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const getTimeSlotById = createAsyncThunk<
  TimeSlot,
  string,
  { rejectValue: BaseError }
>('timeSlots/getTimeSlotById', async (id: string) => {
  const response = await http.get(`${main.scheduling.root}${id}`);

  return response.data;
});

export const addTimeSlots = createAsyncThunk<
  TimeSlot,
  AddTimeSlotsRequestValues,
  { rejectValue: BaseError }
>('timeSlots/addTimeSlots', (formValues: AddTimeSlotsRequestValues) => {
  return http.post(`${main.scheduling.root}`, formValues).then((response) => {
    return response.data;
  });
});

interface UpdateTimeSlotsParams {
  formValues: EditScheduleRequestValues;
  id: string;
}

export const updateTimeSlots = createAsyncThunk<
  TimeSlot,
  UpdateTimeSlotsParams,
  { rejectValue: BaseError }
>('timeSlots/updateTimeSlots', ({ formValues, id }: UpdateTimeSlotsParams) => {
  return http
    .put(`${main.scheduling.root}${id}/`, formValues)
    .then((response) => {
      return response.data;
    });
});

export const deleteTimeSlot = createAsyncThunk<
  TimeSlot,
  string,
  { rejectValue: BaseError }
>('timeSlots/deleteTimeSlot', (id: string) => {
  return http.delete(`${main.scheduling.root}${id}`).then((response) => {
    return response.data;
  });
});

export const getFreeSlotsByDay = createAsyncThunk<
  FreeSlotsList,
  GetTimeSlotsParams,
  { rejectValue: BaseError }
>('timeSlots/getFreeSlotsByDay', async (params: GetTimeSlotsParams) => {
  const response = await http.get(`${main.timeSlots.root}free_slots/`, {
    params: {
      ...params,
      start_date: format(params.start_date as Date, DATE_FORMAT_BE),
      end_date: format(params.end_date as Date, DATE_FORMAT_BE),
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const getDaysWithAvailableSlotsList = createAsyncThunk<
  string[],
  GetTimeSlotsParams,
  { rejectValue: BaseError }
>(
  'timeSlots/getDaysWithAvailableSlotsList',
  async (params: GetTimeSlotsParams) => {
    const response = await http.get(`${main.timeSlots.root}calendar/`, {
      params: {
        ...params,
      },
    });

    return response.data;
  },
);
