import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { endpoints } from 'config';
import { useSnackbar } from 'notistack';

import ArrowBack from 'assets/icons/back-arrow.svg';

import { CustomButton, DashboardWrapper } from 'components';
import { CheckmarkInputWithSelectAll } from 'components/Inputs/CheckmarkInputWithSelectAll/CheckmarkInputWithSelectAll';
import { TextAreaInput } from 'components/Inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'components/Inputs/TextInput/TextInput';
import { SingleImageUploader } from 'components/SingleImageUploader/SingleImageUploader';
import { useHasPermissions } from 'hooks/useHasPermissions';

import { GHOST_BUILDING_ID } from 'constants/constValue';
import { PERMISSIONS } from 'constants/Roles';
import { createBlogPostSchema, infoBlogPostSchema } from 'constants/schema';
import {
  createPost,
  imageBuildingPostUploader,
  updatePost,
} from 'store/blog-posts/blog-posts.actions';
import { clearCurrentPost } from 'store/blog-posts/blog-posts.slice';
import { RootState } from 'store/rootReducer';
import {
  convertImageInFormData,
  decodeFromBase64,
} from 'utils/HelperFunctions';

import { OverlayLoader } from '../../../../components/Loaders/OverlayLoader';
import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  mainInfo: {
    display: 'flex',
  },
  content: {
    width: '100%',
    marginTop: '82px',
  },
  editContent: {
    width: '100%',
    maxWidth: '367px',

    '& .MuiFormControl-root': {
      marginBottom: '42px',
    },
  },
  cardBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  textArea: {
    minHeightL: '500px',
  },
  saveButton: {
    width: '100%',
  },
  mainTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowBack: {
    marginRight: '8px',
  },
});

export const BlogPostDetail = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [notUploadedImage, setNotUploadedImage] = useState('');
  const canCreateBuilding = useHasPermissions(PERMISSIONS.CAN_CREATE_BUILDING);

  const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);

  const { buildings } = useSelector((state: RootState) => state.buildings);
  const { currentPost, loading } = useSelector(
    (state: RootState) => state.posts,
  );

  const [editedData, setEditedData] = useState(
    currentPost && {
      building_ids: currentPost?.building || '',
      description: currentPost?.description || '',
      title: currentPost?.title || '',
      image: currentPost?.image || '',
    },
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: editedData,
    resolver: yupResolver(
      currentPost?.id ? infoBlogPostSchema : createBlogPostSchema,
    ),
  });

  useEffect(() => {
    if (currentPost?.id && notUploadedImage) {
      uploadImage(notUploadedImage);
      setNotUploadedImage('');
    }
  }, [currentPost?.id, notUploadedImage]);

  useEffect(() => {
    if (currentPost?.id) {
      const arrOfBuildingIds = filterSelectedBuilding(currentPost.building_ids);
      setSelectedBuildings(arrOfBuildingIds);
      setEditedData({ ...currentPost, building_ids: arrOfBuildingIds });
    }
  }, [currentPost]);

  useEffect(() => {
    if (editedData) {
      methods?.setValue('building_ids', editedData.building_ids);
      methods?.setValue('description', editedData.description);
      methods?.setValue('title', editedData.title);
    }
  }, [editedData]);

  const filterSelectedBuilding = (arrayIds: any) => {
    const selectedIds: string[] = [];
    buildings.result.forEach((building: any) => {
      arrayIds.forEach((elem: any) => {
        if (building.id === elem.id) {
          selectedIds.push(elem.id);
        }
      });
    });

    return selectedIds;
  };

  const backToBlogPosts = () => {
    dispatch(clearCurrentPost());
    navigate('/info', { state: { activeInfoTab: 'posts' } });
  };

  const uploadImage = async (image: string) => {
    const file = decodeFromBase64(image, new Date().getTime().toString());
    const imageFormData = convertImageInFormData(file);

    if (currentPost?.building_ids) {
      await dispatch(
        imageBuildingPostUploader({
          image: imageFormData,
          post_id: currentPost?.id,
          building_id: GHOST_BUILDING_ID,
          url: endpoints.images.imageBuildingPostUpload(
            GHOST_BUILDING_ID,
            currentPost?.id,
          ),
        }),
      );
    } else {
      await dispatch(
        imageBuildingPostUploader({
          image: imageFormData,
          post_id: currentPost?.id,
          building_id: GHOST_BUILDING_ID,
          url: endpoints.images.imageBuildingPostUpload(
            GHOST_BUILDING_ID,
            currentPost?.id,
          ),
        }),
      );
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent,
  ) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });
  };

  const handleChangeBuildingMulti = (values: any) => {
    setSelectedBuildings(values);
    setEditedData({
      ...editedData,
      building_ids: values,
    });
  };

  const isForAllBuildings = () => {
    return editedData.building_ids.length === buildings.result.length;
  };

  const handleSavePost = () => {
    if (currentPost?.id) {
      dispatch(
        updatePost({
          data: editedData,
          post_id: currentPost?.id,
          is_visible_for_all_buildings: isForAllBuildings(),
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar(`Blog post was updated.`, {
            variant: 'success',
          });
          backToBlogPosts();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, {
            variant: 'error',
          });
        });
    } else {
      dispatch(
        createPost({
          data: {
            ...editedData,
            building_ids: editedData.building_ids,
          },
          is_visible_for_all_buildings: isForAllBuildings(),
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar(`Blog post was created.`, {
            variant: 'success',
          });
          backToBlogPosts();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, {
            variant: 'error',
          });
        });
    }
  };

  return (
    <DashboardWrapper>
      {loading && <OverlayLoader />}
      <Typography
        variant={'h2'}
        className={classes.mainTitle}
        mb={4}
        onClick={backToBlogPosts}
      >
        <img src={ArrowBack} alt="arrow back" className={classes.arrowBack} />
        {currentPost?.id
          ? currentPost?.title
          : editedData.title || 'New blog post'}
      </Typography>
      <Box className={classes.mainInfo} pb={5}>
        <SingleImageUploader
          notUploadedImage={notUploadedImage}
          setNotUploadedImage={setNotUploadedImage}
          image={editedData?.image}
          withUploadBtn={true}
        />
        <Box className={classes.content}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box className={classes.editContent}>
              <FormProvider {...methods}>
                {canCreateBuilding && (
                  <CheckmarkInputWithSelectAll
                    value={selectedBuildings}
                    color={'black'}
                    name={'building_ids'}
                    label={'Building name'}
                    selectedId={selectedBuildings}
                    handleChange={handleChangeBuildingMulti}
                    items={buildings?.result}
                  />
                )}
                <TextInput
                  color={'black'}
                  name={'title'}
                  label={'Title'}
                  value={editedData.title}
                  handleChange={handleChange}
                />
                <TextAreaInput
                  rows={20}
                  color={'black'}
                  name={'description'}
                  label={'Description'}
                  handleChange={handleChange}
                  value={editedData.description}
                />
                <CustomButton
                  title={'Save'}
                  color={'primary'}
                  variant={'buttonMedium'}
                  handleClick={methods.handleSubmit(handleSavePost)}
                  additionalClass={classes.saveButton}
                />
              </FormProvider>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardWrapper>
  );
};
