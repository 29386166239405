import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import { getContacts } from './contacts.actions';
import { ContactsList, ContactsState } from './contacts.types';

const CONTACTS_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

export const initialState: ContactsState = Object.freeze({
  loading: false,
  error: null,
  status: STORE_STATUSES.IDLE,
  contacts: CONTACTS_LIST_DEFAULT,
});

const serviceTypesSlice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getContacts.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getContacts.fulfilled,
      (state, action: PayloadAction<ContactsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.loading = false;
        state.contacts = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getContacts.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default serviceTypesSlice.reducer;
