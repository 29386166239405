import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { PackageEditorValues } from '../../modules/Packages/Editor/PackageEditor.types';
import { BaseError } from '../store.constants';
import { Package } from './packages.types';

export const getPackages = createAsyncThunk<
  Package[],
  string,
  { rejectValue: BaseError }
>('packages/getPackages', async (id: string) => {
  const response = await http.get(
    endpoints.services.servicePackagesById(id as string),
  );
  return response.data;
});

export const createPackage = createAsyncThunk<
  Package,
  { id: string; data: PackageEditorValues },
  { rejectValue: BaseError }
>('packages/createPackage', ({ id, data }, thunkAPI) => {
  return http
    .post(endpoints.services.servicePackagesById(id as string), data)
    .then((res) => {
      thunkAPI.dispatch(getPackages(id));
      return res.data;
    });
});

export const deletePackage = createAsyncThunk<
  unknown,
  { service_id: string; id: string },
  { rejectValue: BaseError }
>('services/deletePackage', async ({ service_id, id }, thunkAPI) => {
  const response = await http.delete(
    endpoints.services.deleteServicePackagesById(service_id, id),
  );
  await thunkAPI.dispatch(getPackages(service_id));
  return response.data;
});
