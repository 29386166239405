import React from 'react';

import { ENTITY_STATUSES } from './EntityStatus.constants';
import * as S from './EntityStatus.styled';

interface EntityStatusProps {
  type: ENTITY_STATUSES;
}

export const EntityStatus = ({
  type,
}: EntityStatusProps): React.ReactElement => {
  return (
    <S.Container>
      <S.Status type={type}>{type}</S.Status>
    </S.Container>
  );
};
