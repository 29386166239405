import styled from '@emotion/styled/macro';

export const FormWrapper = styled.div``;

export const FormItem = styled.div`
  margin-bottom: 24px;

  .MuiAlert-root {
    margin-top: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  padding-top: 10px;
  display: flex;

  button {
    margin-right: 10px;
    flex: 1 1 100%;
  }
`;
