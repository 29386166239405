import ArrowBack from '../../assets/icons/back-arrow.svg';
import * as S from './BackButton.styled';

interface BackButtonProps {
  name: string;
  handleClickBack: () => void;
}

export const BackButton = ({ name, handleClickBack }: BackButtonProps) => {
  return (
    <S.BackButton onClick={handleClickBack}>
      <S.BackIcon src={ArrowBack} />
      <S.BackButtonName>{name}</S.BackButtonName>
    </S.BackButton>
  );
};
