import { Box, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  pagination: {
    '& .MuiPaginationItem-page': {
      fontSize: '0.875rem',
      lineHeight: '22px',
      fontWeight: 'bold',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#D5A419',
    },
  },
});

interface CustomPaginationProps {
  pages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  activePage: number;
}

export const CustomPagination = ({
  pages,
  handleChange,
  activePage,
}: CustomPaginationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.paginationContainer} mt={4}>
      <Pagination
        count={pages}
        page={activePage}
        onChange={handleChange}
        className={classes.pagination}
        size={'large'}
      />
    </Box>
  );
};
