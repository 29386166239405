import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { EVENT_REQUEST_STATUSES_OPTIONS } from '../../../../modules/BookingRequests/EventRequestsList/EventRequestsList.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface EventRequestStatusFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const EventRequestStatusFilter = ({
  onFiltersChange,
}: EventRequestStatusFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="event-format-filter">Status</InputLabel>
      <Select
        labelId="service-request-status-filter"
        defaultValue={EVENT_REQUEST_STATUSES_OPTIONS[0].value as string}
        label="Status"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            status: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        {EVENT_REQUEST_STATUSES_OPTIONS.map((format) => (
          <MenuItem key={format.value} value={format.value}>
            {format.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
