import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 300px;
  background-color: white;
  border-radius: 20px;
  padding: 42px 0 42px 42px;
`;

export const ScrollableContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 42px;
`;

export const Header = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 24px;
  margin-bottom: 24px;
`;

export const Close = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  position: absolute;
  right: 30px;
  top: 35px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 42px;

  button {
    margin-left: 10px;
  }
`;

export const SelectDayWrapper = styled.div`
  margin-bottom: 24px;
`;
