import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import logo from 'assets/EliteLogo.png';
import maniBanner from 'assets/wallpaper.png';

const useStyles = makeStyles((theme: any) => ({
  welcomeBanner: {
    background: `url(${maniBanner})center center/cover no-repeat`,
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    boxShadow: `3px 2px 24px rgba(0, 0, 0, 0.09)`,
    position: 'relative',
    borderRadius: '0 40px 40px 0',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
        // tslint:disable-next-line:max-line-length
        'linear-gradient(180deg,rgba(9, 9, 9, 0.84) 4.17%, rgba(3, 3, 3, 0.68) 32.94%, rgba(2, 2, 2, 0.4) 70.31%, rgba(0, 0, 0, 0.32) 100%)',
    },
  },
  logo: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const AppWelcome = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.welcomeBanner}>
        <img alt="logo" className={classes.logo} src={logo} />
      </Box>
    </>
  );
};
