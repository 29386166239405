import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import UserIcon from '../../../assets/user-icon.svg';
import * as S from './TextWithAvatar.styled';

export interface TextWithAvatarProps {
  imgLink: string | null | undefined;
  text: string;
  navigateLink?: string;
}

export const TextWithAvatar = ({
  imgLink,
  text,
  navigateLink,
}: TextWithAvatarProps) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.UserAvatar src={imgLink || UserIcon} alt="" />
      <Typography
        variant={'body2'}
        {...(navigateLink
          ? {
              onClick: () => navigate(navigateLink),
            }
          : {})}
      >
        {text}
      </Typography>
    </S.Container>
  );
};
