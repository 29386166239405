import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Service } from '../../../../store/services/services.types';
import { ALL_SERVICES_OPTION } from '../FiltersList/FiltersList.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface BuildingFilterProps {
  services: Service[];
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ServiceFilter = ({
  services,
  onFiltersChange,
}: BuildingFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="service-filter">Services</InputLabel>
      <Select
        labelId="service-filter"
        defaultValue={ALL_SERVICES_OPTION.value}
        label="Services"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            service_id: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        <MenuItem
          key={ALL_SERVICES_OPTION.value}
          value={ALL_SERVICES_OPTION.value}
        >
          {ALL_SERVICES_OPTION.name}
        </MenuItem>
        {services.map(({ id, name }: Service) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
