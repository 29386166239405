import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  ACTIVE_INACTIVE_FILTER_VALUES,
  BaseSelectItem,
} from '../GridFilters.constants';
import { normalizeBooleanFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface ActiveInactiveFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ActiveInactiveFilter = ({
  onFiltersChange,
}: ActiveInactiveFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="provider-filter">Status</InputLabel>
      <Select
        labelId="provider-filter"
        defaultValue={ACTIVE_INACTIVE_FILTER_VALUES[0].value}
        label="Status"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            is_active: normalizeBooleanFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        {ACTIVE_INACTIVE_FILTER_VALUES.map(
          ({ value, label }: BaseSelectItem) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
};
