import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardWrapper } from 'components';
import { BackButton } from 'components/BackButton/BackButton';
import { OverlayLoader } from 'components/Loaders/OverlayLoader';
import { useAppDispatch } from 'hooks/useAppSelector';

import { getBuildings } from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import { getTimeSlotById } from 'store/time-slots/time-slots.actions';
import { TIME_SLOT_STATUSES } from 'store/time-slots/time-slots.constants';

import { ROLE } from '../../../../constants/Roles';
import { useIsRole } from '../../../../hooks/useHasPermissions';
import { getServices } from '../../../../store/services/services.actions';
import { SPECIALIST_TABS } from '../../../PeopleManagement/ServiceSpecialist/Details/SpecialistDetails.constants';
import { SERVICE_DETAILS_TABS } from '../../../Services/Details/ServiceDetails.constants';
import { SCHEDULE_PAGES } from '../../List/ScheduleList.constants';
import { EditScheduleForm } from './EditScheduleForm';

interface EditScheduleFormWrapperProps {
  schedulePageType: SCHEDULE_PAGES;
}

export const EditScheduleFormWrapper = ({
  schedulePageType,
}: EditScheduleFormWrapperProps) => {
  const { id, timeSlotId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    currentTimeSlot,
    timeSlotsList: { params },
    loading: isLoading,
  } = useSelector((state: RootState) => state.timeSlots);
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const isSpecialistPage = schedulePageType === SCHEDULE_PAGES.SPECIALIST;
  const isSpecialistRole = useIsRole(ROLE.SERVICE_SPECIALIST);

  useEffect(() => {
    if (timeSlotId) {
      dispatch(getTimeSlotById(timeSlotId))
        .unwrap()
        .then((res) => {
          if (isSpecialistPage) {
            dispatch(
              getServices({
                building_id: res.buildings[0].id,
                specialist: id,
              }),
            );
          }
        });
    }
    dispatch(
      getBuildings({
        page_size: 1000,
        page: 1,
        ...(isSpecialistPage ? { specialist: id } : { service: id }),
      }),
    );
  }, []);

  const handleClickBack = () => {
    if (isSpecialistPage) {
      return isSpecialistRole
        ? navigate(`/schedule`, {})
        : navigate(`/management/specialist/${id}`, {
            state: {
              activeTab: SPECIALIST_TABS.SERVICES_SCHEDULE,
            },
          });
    }

    navigate(`/services/${id}`, {
      state: { activeTab: SERVICE_DETAILS_TABS.SERVICES_SCHEDULE },
    });
  };

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      <BackButton
        name={
          currentTimeSlot?.status === TIME_SLOT_STATUSES.AVAILABLE
            ? 'Edit Working Time'
            : 'Edit Time-off'
        }
        handleClickBack={handleClickBack}
      />
      {currentTimeSlot && buildings?.result?.length && id && (
        <EditScheduleForm
          currentTimeSlot={currentTimeSlot}
          buildings={buildings}
          timeSlotListGetParams={params}
          navigateBack={handleClickBack}
          serviceOrSpecialistId={id}
          isSpecialistPage={isSpecialistPage}
        />
      )}
    </DashboardWrapper>
  );
};
