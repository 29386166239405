import styled from '@emotion/styled/macro';

import { FiltersSwitcher } from '../../../components/GridElements/GridFilters/GridFilters.styled';

export const Container = styled.div`
  margin-top: 20px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${FiltersSwitcher} {
    margin-right: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    flex-shrink: 0;

    &:first-of-type {
      margin: 0 15px;
    }
  }
`;

export const ListWrapper = styled.div`
  margin-top: 30px;
`;
