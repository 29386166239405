import { useState } from 'react';

import { Box, Grid, TextField, Typography } from '@mui/material';

import { CustomButton } from 'components/CustomButton/CustomButton';

interface EditNoteModalContentProps {
  notes: string | null;
  closeModal: () => void;
  saveNotes: (note: string) => void;
}

export const EditNoteModalContent = ({
  notes,
  closeModal,
  saveNotes,
}: EditNoteModalContentProps) => {
  const [textInput, setTextInput] = useState('');
  const handleTextInputChange = (event: any) => {
    setTextInput(event.target.value);
  };

  return (
    <>
      <Box>
        <Grid
          container={true}
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item={true}>
            <Typography variant="h4">
              This note will be visible only for you and your site managers.
            </Typography>
            <TextField
              margin="normal"
              fullWidth={true}
              id="outlined-multiline-static"
              label="Note"
              multiline={true}
              rows={4}
              autoFocus={true}
              defaultValue={notes}
              onChange={handleTextInputChange}
            />
          </Grid>

          <Grid item={true}>
            <CustomButton
              title={'Save'}
              variant={'buttonMedium'}
              handleClick={() => saveNotes(textInput)}
            />
            <CustomButton
              title={'Cancel'}
              color={'gray'}
              variant={'buttonMedium'}
              handleClick={closeModal}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
