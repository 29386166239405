import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { Event } from 'store/events/events.types';

import { endpoints, main } from '../../config';
import { VALIDATE_IMAGE_MESSAGE } from '../../utils/constants';
import {
  convertImageInFormData,
  validateAvatarImage,
} from '../../utils/HelperFunctions';
import http from '../../utils/http';
import { EventEditorValues } from './Editor/EventEditor.types';

export interface ImageState {
  imgSrc: string | null;
  file: File | null;
}

interface UseSpecialistInfoParams {
  event?: Event;
  saveEvent: (values: Partial<EventEditorValues>) => Promise<void>;
  createEvent: (values: Partial<EventEditorValues>) => Promise<void>;
  changeEventStatus: (id: string, data: { state: boolean }) => Promise<void>;
  processEventImage: (file: File) => void;
  eventImage: ImageState;
  isLoading: boolean;
}

export const useEventDetailsData = (
  eventId?: string,
): UseSpecialistInfoParams => {
  const { enqueueSnackbar } = useSnackbar();
  const [eventImage, setEventImage] = useState<ImageState>({
    imgSrc: null,
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState<Event>();

  const getEventById = (id: string) => {
    setIsLoading(true);
    http
      .get(endpoints.events.eventById(id))
      .then((res: AxiosResponse) => {
        setEvent(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeEventStatus = (id: string, data: { state: boolean }) => {
    setIsLoading(true);
    return http
      .patch(endpoints.events.changeEventStatus(id), data)
      .then((res: AxiosResponse) => {
        getEventById(id);
        enqueueSnackbar('Event status was changed successfully.', {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const processEventImage = (file: File) => {
    if (!validateAvatarImage(file)) {
      enqueueSnackbar(VALIDATE_IMAGE_MESSAGE, {
        variant: 'error',
      });

      return;
    }

    setEventImage({
      file,
      imgSrc: URL.createObjectURL(file),
    });
  };

  const uploadEventImage = async (id: string, file: File) => {
    setIsLoading(true);
    const convertedFile = convertImageInFormData(file);

    http
      .post(endpoints.events.uploadEventPhoto(id), convertedFile, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createEvent = (
    values: Partial<EventEditorValues>,
    activateAfterCreate?: boolean,
  ) => {
    setIsLoading(true);
    return http
      .post(main.events.root, values)
      .then((res: AxiosResponse) => {
        if (eventImage.file) {
          uploadEventImage(res.data.id, eventImage.file);
        }

        if (activateAfterCreate) {
          enqueueSnackbar(`Event was created.`, {
            variant: 'success',
          });
          return changeEventStatus(res.data.id, {
            state: true,
          });
        }

        enqueueSnackbar(`Event was created.`, {
          variant: 'success',
        });

        return Promise.resolve();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });

        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveEvent = (values: Partial<EventEditorValues>) => {
    setIsLoading(true);

    return http
      .put(endpoints.events.eventById(eventId as string), values)
      .then(() => {
        if (eventImage.file) {
          uploadEventImage(eventId as string, eventImage.file);
        }

        getEventById(eventId as string);
        enqueueSnackbar(`Event was saved.`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });

        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (eventId) {
      getEventById(eventId);
    }
  }, []);

  return {
    event,
    saveEvent,
    createEvent,
    changeEventStatus,
    processEventImage,
    eventImage,
    isLoading,
  };
};
