import React, { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {},
});

interface PasswordInputProps {
  name?: string;
  withIcon?: boolean;
  label: string;
  password: string;
  color?: 'white' | 'black';
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isPasswordShow?: boolean;
  setShowPassword?: () => void;
}

export const PasswordInput = ({
  name = 'password',
  label,
  withIcon = true,
  password,
  setShowPassword,
  handleChange,
  color = 'white',
  isPasswordShow = false,
}: PasswordInputProps) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }: any) => (
          <FormControl
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${
                  color === 'white' ? '#000000' : '#ffffff'
                } inset`,
              },
            }}
            variant="outlined"
          >
            <InputLabel
              style={{
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              }}
            >
              {label}
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                    },
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${
                    color === 'white' ? '#000000' : '#F3F3F3'
                  } inset`,
                },
              }}
              {...field}
              name={name}
              type={isPasswordShow ? 'text' : 'password'}
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                handleChange(e);
              }}
              endAdornment={
                withIcon && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword && setShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordShow ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }
              label={label}
            />
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
