import React, { useEffect } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DashboardWrapper } from '../../components';
import { CustomPagination } from '../../components/CustomPagination/CustomPagination';
import { ColumnType } from '../../components/GridElements/GridCommonConfigs';
import { LIMIT } from '../../components/GridElements/GridFilters/GridFilters.constants';
import { OverlayLoader } from '../../components/Loaders/OverlayLoader';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppSelector';
import { getContacts } from '../../store/contacts/contacts.actions';
import { Contact } from '../../store/contacts/contacts.types';
import { RootState } from '../../store/rootReducer';
import { TABLE_CONTAINER_STYLES, TABLE_STYLES } from '../../utils/constants';
import * as S from './Contacts.styled';
import { CONTACTS_LIST_CONFIG } from './ContactsList.config';

export const Contacts = () => {
  const dispatch = useAppDispatch();
  const {
    contacts: { params, list },
    loading: isLoading,
  } = useAppSelector((state: RootState) => state.contacts);

  useEffect(() => {
    dispatch(
      getContacts({
        page: 1,
        page_size: LIMIT,
      }),
    );
  }, []);

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      <S.Container>
        <S.Header>Elite Amenity Contacts</S.Header>
        <S.ListWrapper>
          <TableContainer component={Paper} sx={TABLE_CONTAINER_STYLES}>
            <Table sx={TABLE_STYLES} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {CONTACTS_LIST_CONFIG.map((item: ColumnType<Contact>) => (
                    <React.Fragment key={item.label}>
                      {item.headerComponent(item)}
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(list?.result?.length) &&
                  list.result.map((contact: Contact) => (
                    <TableRow hover={true} key={contact.id}>
                      {CONTACTS_LIST_CONFIG.map((item: ColumnType<Contact>) => (
                        <React.Fragment key={item.label}>
                          {item.cellComponent(item, contact)}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </S.ListWrapper>
        {Boolean(list?.count) && (
          <CustomPagination
            pages={Math.ceil(list?.count / LIMIT)}
            handleChange={(e: React.ChangeEvent<unknown>, value: number) => {
              dispatch(
                getContacts({
                  ...params,
                  page: value,
                }),
              );
            }}
            activePage={params.page as number}
          />
        )}
      </S.Container>
    </DashboardWrapper>
  );
};
