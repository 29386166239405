import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { clearNotificationStore, markReadById } from './notifications.slice';

export const getUnreadCountNotifications = createAsyncThunk(
  'notifications/getUnreadCountNotifications',
  async ({ date }: any, thunkAPI: any) => {
    try {
      const url = endpoints.notifications.getUnreadCountNotifications;

      const response = await http.get(url + '?date_time=' + date);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);

export const getAllPastNotifications = createAsyncThunk(
  'notifications/getAllPastNotifications',
  async ({ date, page = 1 }: any, thunkAPI: any) => {
    try {
      const url = endpoints.notifications.getAllPastNotifications;
      if (page === 1) {
        await thunkAPI.dispatch(clearNotificationStore());
      }
      const response = await http.get(
        url + '?date_time=' + date + '&page=' + page,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);

export const getAllTodayNotifications = createAsyncThunk(
  'notifications/getAllTodayNotifications',
  async ({ date, page = 1 }: any, thunkAPI: any) => {
    try {
      const url = endpoints.notifications.getAllTodayNotifications;
      if (page === 1) {
        await thunkAPI.dispatch(clearNotificationStore());
      }
      const response = await http.get(
        url + '?date_time=' + date + '&page=' + page,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);

export const markReadAllNotifications = createAsyncThunk(
  'notifications/markAllRead',
  async ({ tab, date }: any, thunkAPI: any) => {
    try {
      const url = endpoints.notifications.markReadAll;
      const response = await http.post(url + '?status=read');
      if (tab === 0) {
        await thunkAPI.dispatch(getAllTodayNotifications({ date }));
        await thunkAPI.dispatch(getUnreadCountNotifications({ date }));
      } else {
        await thunkAPI.dispatch(getAllPastNotifications({ date }));
        await thunkAPI.dispatch(getUnreadCountNotifications({ date }));
      }
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);

export const markReadNotificationByID = createAsyncThunk(
  'notifications/markReadNotificationByID',
  async ({ date, id, tab }: any, thunkAPI: any) => {
    try {
      const url = endpoints.notifications.markReadById(id) + '?status=read';
      const response = await http.post(url);
      await thunkAPI.dispatch(markReadById(id));
      await thunkAPI.dispatch(getUnreadCountNotifications({ date }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error: Something wrong');
    }
  },
);
