import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import {
  assignServiceToSpecialist,
  getSpecialists,
  getSpecialistsServices,
  unassignServiceFromSpecialist,
} from './service-specialists.actions';
import {
  ServiceSpecialistState,
  SpecialistServicesList,
  SpecialistsList,
} from './service-specialists.types';

const SPECIALIST_SERVICES_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
    assigned_count: 0,
    assigned_count_before_filters: 0,
  },
  params: {},
};

const SPECIALISTS_LIST_DEFAULT = {
  list: {
    result: null,
    count: 0,
  },
  params: {},
};

export const initialState: ServiceSpecialistState = Object.freeze({
  loading: false,
  error: null,
  status: STORE_STATUSES.IDLE,
  specialistServices: SPECIALIST_SERVICES_LIST_DEFAULT,
  specialists: SPECIALISTS_LIST_DEFAULT,
});

const serviceTypesSlice = createSlice({
  name: 'serviceSpecialists',
  initialState,
  reducers: {
    clearSpecialists: (state) => {
      state.specialists = SPECIALISTS_LIST_DEFAULT;
    },
    clearSpecialistsServices: (state) => {
      state.specialistServices = SPECIALIST_SERVICES_LIST_DEFAULT;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSpecialists.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getSpecialists.fulfilled,
      (state, action: PayloadAction<SpecialistsList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.loading = false;
        state.specialists = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getSpecialists.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getSpecialistsServices.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getSpecialistsServices.fulfilled,
      (state, action: PayloadAction<SpecialistServicesList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.loading = false;
        state.specialistServices = action.payload;
        state.error = null;
      },
    );

    builder.addCase(getSpecialistsServices.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(assignServiceToSpecialist.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(assignServiceToSpecialist.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(assignServiceToSpecialist.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(unassignServiceFromSpecialist.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(unassignServiceFromSpecialist.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(unassignServiceFromSpecialist.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });
  },
});

export const { clearSpecialists, clearSpecialistsServices } =
  serviceTypesSlice.actions;

export default serviceTypesSlice.reducer;
