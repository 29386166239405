import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  backgroundLoading: {
    display: 'block',
    margin: '0',
    padding: '0',
    backgroundColor: 'black',
    width: '100%',
    height: '100vh',
  },
  header: {
    color: 'white',
    margin: '0',
    padding: '2rem',
    textAlign: 'center',
  },
}));

interface LoadingScreenProps {
  text: string;
}

export const LoadingScreen = ({ text }: LoadingScreenProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.backgroundLoading}>
        <h2 className={classes.header}>{text}</h2>
      </div>
    </>
  );
};
