import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { endpoints } from 'config';
import { useSnackbar } from 'notistack';

import ArrowBack from 'assets/icons/back-arrow.svg';

import { CustomButton, DashboardWrapper } from 'components';
import CustomModal from 'components/CustomModal/CustomModal';
import { CustomStepper } from 'components/CustomStepper/CustomStepper';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { Toast } from 'components/Toast/Toast';
import { BuildingInvitation } from 'modules/InfoPosts/Buildings/BuildingInvitation/BuildingInvitation';
import { BuildingMainInfo } from 'modules/InfoPosts/Buildings/BuildingMainInfo/BuildingMainInfo';
import { BuildingRules } from 'modules/InfoPosts/Buildings/BuildingRules/BuildingRules';
import { BuildingWorkingHours } from 'modules/InfoPosts/Buildings/BuildingWorkingHours/BuildingWorkingHours';

import {
  createBuildingMainInfoSchema,
  createBuildingRulesSchema,
  createBuildingWorkingHoursSchema,
} from 'constants/schema';
import { addManagerToBuilding } from 'store/building-managers/building-managers.actions';
import {
  createBuilding,
  createBuildingRules,
  createBuildingWorkingHours,
  deleteBuildingById,
  imageBuildingUploader,
  updateBuildingById,
  updateBuildingRulesById,
  updateBuildingWorkingHoursById,
} from 'store/buildings/buildings.actions';
import { showMessage } from 'store/people/people.slice';
import { RootState } from 'store/rootReducer';
import { IBuilding, IBuildingRules, IWorkingHours, MessageInfo } from 'types';
import {
  convertImageInFormData,
  decodeFromBase64,
} from 'utils/HelperFunctions';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  mainTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowBack: {
    marginRight: '8px',
    display: 'inline-block',
  },
  stepButtonsBlock: {
    display: 'flex',
    maxWidth: '370px',
    margin: '87px auto 0',
  },
});

const steps = [
  'Add basic info',
  'Add business hours',
  'Add building rules',
  'Invite building manager',
];

export const BuildingCreate = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastStyle, setToastStyle] = useState<MessageInfo>('info');
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [notUploadedImage, setNotUploadedImage] = useState('');
  const [selectedManager, setSelectedManager] = useState('');
  const [editedData, setEditedData] = useState<IBuilding>({
    building_name: '',
    building_address: '',
    building_image: '',
    building_description: '',
    phone_number: '',
    email: '',
    is_concierge_service_allowed: false,
  });
  const [workingHours, setWorkingHours] = useState<IWorkingHours>({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  });
  const [buildingRules, setBuildingRules] = useState<IBuildingRules>({
    title: '',
    description: '',
    image: '',
  });

  const { currentBuilding, message, status, loading, isMessageShow } =
    useSelector((state: RootState) => state.buildings);

  useEffect(() => {
    if (isMessageShow) {
      setToastMessage(message);
      setIsToastOpen(true);
      status === 'rejected' ? setToastStyle('error') : setToastStyle('success');
      dispatch(showMessage());
    }
  }, [message, loading]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: editedData,
    resolver: yupResolver(
      activeStep === 0
        ? createBuildingMainInfoSchema
        : activeStep === 1
        ? createBuildingWorkingHoursSchema
        : createBuildingRulesSchema,
    ),
  });

  useEffect(() => {
    if (currentBuilding?.id && notUploadedImage) {
      uploadImage(notUploadedImage);
    }

    if (currentBuilding?.building_image && activeStep === 1) {
      setEditedData({
        ...editedData,
        building_image: currentBuilding?.building_image,
      });
    }

    if (currentBuilding?.building_rules?.length && activeStep === 3) {
      setBuildingRules({
        ...buildingRules,
        image: currentBuilding?.building_rules?.image,
      });
    }
  }, [activeStep]);

  const handleNotUploadedImage = (image: string) => {
    setNotUploadedImage(image);
  };

  const uploadImage = (image: string) => {
    const file = decodeFromBase64(image, new Date().getTime().toString());
    const imageFormData = convertImageInFormData(file);

    if (activeStep <= 2) {
      dispatch(
        imageBuildingUploader({
          image: imageFormData,
          url: endpoints.images.imageBuildingUpload(currentBuilding?.id),
          building_id: currentBuilding?.id,
        }),
      );

      setNotUploadedImage('');
    }

    if (currentBuilding?.building_rules?.id) {
      dispatch(
        imageBuildingUploader({
          image: imageFormData,
          url: endpoints.images.imageBuildingPostUpload(
            currentBuilding?.id,
            currentBuilding?.building_rules?.id,
          ),
          building_id: currentBuilding?.id,
        }),
      );
    }
  };

  const backToBuildings = () => {
    setOpenModal(true);
  };

  const handleSelect = (id: string) => {
    setSelectedManager(id);
  };

  const handleNext = (step?: number) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (step) {
      setActiveStep(step);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });
  };

  const changeWorkingHours = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkingHours({ ...workingHours, [e.target.name]: e.target.value });
  };

  const changeBuildingRules = (e: ChangeEvent<HTMLInputElement>) => {
    setBuildingRules({ ...buildingRules, [e.target.name]: e.target.value });
  };

  const handleSubmitMainInfo = () => {
    const { building_image, ...firstStepData } = editedData;

    if (currentBuilding?.id) {
      dispatch(
        updateBuildingById({
          id: currentBuilding?.id,
          building: firstStepData,
        }),
      )
        .unwrap()
        .then(() => {
          handleNext();
        });
    } else {
      dispatch(createBuilding(firstStepData))
        .unwrap()
        .then(() => {
          handleNext();
        })
        .catch((e) => {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        });
    }
  };

  const handleCheckWorkingHours = () => {
    if (currentBuilding?.working_hours?.id) {
      dispatch(
        updateBuildingWorkingHoursById({
          building_id: currentBuilding?.id,
          workingHours: { working_hours: workingHours },
          working_hours_id: currentBuilding?.working_hours?.id,
        }),
      );
    } else {
      dispatch(
        createBuildingWorkingHours({
          workingHours: { working_hours: workingHours },
          building_id: currentBuilding?.id,
        }),
      );
    }

    handleNext();
  };

  const handleCheckBuildingRules = () => {
    const { image, ...buildingRulesList } = buildingRules;

    if (currentBuilding?.building_rules?.id) {
      dispatch(
        updateBuildingRulesById({
          building_id: currentBuilding?.id,
          post_id: currentBuilding?.building_rules?.id,
          buildingRules: buildingRulesList,
        }),
      );
    } else {
      dispatch(
        createBuildingRules({
          building_id: currentBuilding?.id,
          buildingRules: buildingRulesList,
        }),
      );
    }

    handleNext();
  };

  const handleAddBuildingManager = async () => {
    if (selectedManager) {
      dispatch(
        addManagerToBuilding({
          id: selectedManager,
          buildingId: { building_id: currentBuilding?.id },
        }),
      );
    }
    await handleNext();
    await navigate('/info');
  };

  const handleDeleteBuilding = () => {
    if (currentBuilding?.id) {
      dispatch(deleteBuildingById({ id: currentBuilding.id }));
    }

    navigate('/info');
  };

  const handleChangeSwitcher = () => {
    setEditedData({
      ...editedData,
      is_concierge_service_allowed: !editedData.is_concierge_service_allowed,
    });
  };

  const nextStep =
    activeStep === 0
      ? methods.handleSubmit(handleSubmitMainInfo)
      : activeStep === 1
      ? methods.handleSubmit(handleCheckWorkingHours)
      : activeStep === 2
      ? methods.handleSubmit(handleCheckBuildingRules)
      : handleAddBuildingManager;

  const closeModal = () => {
    setOpenModal(false);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  return (
    <DashboardWrapper>
      <Typography
        variant={'h2'}
        className={classes.mainTitle}
        mb={4}
        onClick={backToBuildings}
      >
        <img src={ArrowBack} alt="arrow back" className={classes.arrowBack} />
        Add new building
      </Typography>
      <CustomStepper stepList={steps} activeStep={activeStep} />
      <Box mt={4} sx={{ minHeight: 610 }}>
        <FormProvider {...methods}>
          {activeStep === 0 && (
            <BuildingMainInfo
              notUploadedImage={notUploadedImage}
              setNotUploadedImage={handleNotUploadedImage}
              handleChange={handleChange}
              handleChangeSwitcher={handleChangeSwitcher}
            />
          )}
          {activeStep === 1 && (
            <BuildingWorkingHours
              workingHours={workingHours}
              handleChange={changeWorkingHours}
            />
          )}
          {activeStep === 2 && (
            <BuildingRules
              handleChange={changeBuildingRules}
              notUploadedImage={notUploadedImage}
              setNotUploadedImage={handleNotUploadedImage}
            />
          )}
        </FormProvider>
        {activeStep === 3 && (
          <BuildingInvitation
            selectManager={selectedManager}
            handleSelectManager={handleSelect}
          />
        )}
      </Box>
      <Box className={classes.stepButtonsBlock}>
        <CustomButton
          title={'Back'}
          color={activeStep === 0 ? 'disabled' : 'secondary'}
          handleClick={handleBack}
        />
        <Box sx={{ flex: '1 1 auto' }} />
        <CustomButton
          title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          color={'primary'}
          handleClick={() => nextStep()}
        />
      </Box>
      <CustomModal
        title={'Delete building'}
        open={openModal}
        closeModal={closeModal}
      >
        <DeleteModalContent
          text={'Are you sure you want to delete new building?'}
          closeModal={closeModal}
          handleDelete={handleDeleteBuilding}
        />
      </CustomModal>
      <Toast
        duration={5000}
        isOpen={isToastOpen}
        message={toastMessage}
        toastStyle={toastStyle}
      />
    </DashboardWrapper>
  );
};
