import { getPermissions, ROLE } from 'constants/Roles';

import { useCurrentUser } from './useCurrentUser';

const getUserPermissions = (role_key: any) => {
  return getPermissions[role_key];
};

export const useHasPermissions = (permissionNames: any) => {
  const { auth } = useCurrentUser();

  const name = auth?.user?.user_role?.toUpperCase();

  const permissions = getUserPermissions(name);

  if (!permissions) {
    return false;
  }
  if (typeof permissionNames === 'number') {
    return permissions.includes?.(permissionNames);
  } else if (Array.isArray(permissionNames)) {
    return permissions.some((permissionName: any) =>
      Boolean(permissionNames.includes?.(permissionName)),
    );
  } else {
    return false;
  }
};

export const useIsRole = (role: ROLE) => {
  const { auth } = useCurrentUser();

  const roleName = auth?.user?.user_role?.toUpperCase();

  return roleName === role;
};
