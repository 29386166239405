import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventList, EventState } from 'store/events/events.types';

import { STORE_STATUSES } from '../store.constants';
import {
  bookEvent,
  getEventsListForBooking,
  rescheduleEvent,
} from './events.actions';

const EVENT_BOOKING_DEFAULT = {
  selectedEvent: null,
  selectedMember: null,
  ticketsQuantity: '1',
};

const EVENT_LIST_BOOKING_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {
    page_size: 20,
    page: 1,
  },
};

export const initialState: EventState = Object.freeze({
  eventListBooking: EVENT_LIST_BOOKING_DEFAULT,
  bookingEvent: EVENT_BOOKING_DEFAULT,
  currentEvent: null,
  loading: false,
  error: null,
  status: STORE_STATUSES.IDLE,
});

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    },
    clearEventsList: (state) => {
      state.eventListBooking = EVENT_LIST_BOOKING_DEFAULT;
    },
    clearBookingEvent: (state) => {
      state.bookingEvent = EVENT_BOOKING_DEFAULT;
    },
    bookingSetEvent: (state, action) => {
      state.bookingEvent.selectedEvent = action.payload;
    },
    bookingSetMember: (state, action) => {
      state.bookingEvent.selectedMember = action.payload;
    },
    bookingSetTicketsQuantity: (state, action) => {
      state.bookingEvent.ticketsQuantity = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getEventsListForBooking.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getEventsListForBooking.fulfilled,
      (state, action: PayloadAction<EventList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.eventListBooking = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getEventsListForBooking.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(bookEvent.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(bookEvent.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(bookEvent.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(rescheduleEvent.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });
    builder.addCase(rescheduleEvent.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
    });
    builder.addCase(rescheduleEvent.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });
  },
});

export const {
  setCurrentEvent,
  clearEventsList,
  clearBookingEvent,
  bookingSetEvent,
  bookingSetMember,
  bookingSetTicketsQuantity,
} = eventsSlice.actions;

export default eventsSlice.reducer;
