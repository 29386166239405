/* tslint:disable typedef */
import { createSlice } from '@reduxjs/toolkit';

import { IInvitationInfo } from 'types';

export const initialState: IInvitationInfo = Object.freeze({
  invitationData: {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    building_codes: [],
    owner_note: '',
    service_provider_id: '',
  },
  error: '',
  loading: false,
});

export const invitationInfoSlice = createSlice({
  name: 'invitationInfo',
  initialState,
  reducers: {
    setUserInvitationData(state, action) {
      state.invitationData = action.payload;
    },
    clearUserInvitationData(state) {
      state.invitationData = {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        building_codes: [],
        owner_note: '',
        service_provider_id: '',
      };
    },
  },
});

// The reducer
export default invitationInfoSlice.reducer;
