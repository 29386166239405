import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/rootReducer';
import { ActiveInactiveFilter } from '../AvailableFilters/ActiveInactiveFilter';
import { BuildingFilter } from '../AvailableFilters/BuildingFilter';
import { ConciergeRequestStatusFilter } from '../AvailableFilters/ConciergeRequestStatusFilter';
import { EventFormatFilter } from '../AvailableFilters/EventFormatFilter';
import { EventRequestStatusFilter } from '../AvailableFilters/EventRequestStatusFilter';
import { ProviderFilter } from '../AvailableFilters/ProviderFilter';
import { RangeDatePickerFilter } from '../AvailableFilters/RangeDatePickerFilter';
import { ServiceCategoryAndTypeFilter } from '../AvailableFilters/ServiceCategoryAndTypeFilter';
import { ServiceRequestStatusFilter } from '../AvailableFilters/ServiceRequestStatusFilter';
import { TransactionSort } from '../AvailableFilters/TransactionSort';
import { YearMonthFilter } from '../AvailableFilters/YearMonthFilter';
import { AvailableFilters, FiltersParamsTypes } from '../GridFilters.types';
import * as S from './FiltersList.styled';

interface FiltersListProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
  config: AvailableFilters;
}

export const FiltersList = ({ onFiltersChange, config }: FiltersListProps) => {
  const { buildings } = useSelector((state: RootState) => state.buildings);

  return (
    <S.Container>
      {config.transactionSort && (
        <S.FilterItem>
          <TransactionSort onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.building && buildings?.result?.length && (
        <S.FilterItem>
          <BuildingFilter
            buildings={buildings.result}
            onFiltersChange={onFiltersChange}
          />
        </S.FilterItem>
      )}
      {config.eventFormat && (
        <S.FilterItem>
          <EventFormatFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.provider && (
        <S.FilterItem>
          <ProviderFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.serviceRequestStatus && (
        <S.FilterItem>
          <ServiceRequestStatusFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.eventRequestStatus && (
        <S.FilterItem>
          <EventRequestStatusFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.conciergeRequestStatus && (
        <S.FilterItem>
          <ConciergeRequestStatusFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.activeInactiveFilter && (
        <S.FilterItem>
          <ActiveInactiveFilter onFiltersChange={onFiltersChange} />
        </S.FilterItem>
      )}
      {config.yearMonthFilter && (
        <S.FilterItemYearMonth>
          <YearMonthFilter onFiltersChange={onFiltersChange} />
        </S.FilterItemYearMonth>
      )}
      {config.rangeDate && (
        <RangeDatePickerFilter onFiltersChange={onFiltersChange} />
      )}
      {config.serviceCategoryAndType && (
        <ServiceCategoryAndTypeFilter onFiltersChange={onFiltersChange} />
      )}
    </S.Container>
  );
};
