import { useNavigate, useParams } from 'react-router-dom';

import CheckmarkIcon from 'assets/icons/checkmark-black.svg';
import DeactivatedIcon from 'assets/icons/deactivated.svg';
import DeleteIcon from 'assets/icons/delete-grey.svg';
import DuplicateIcon from 'assets/icons/duplicate.svg';

import { CustomButton, DashboardWrapper } from 'components';

import { Event } from 'store/events/events.types';

import { BackButton } from '../../../components/BackButton/BackButton';
import { YesNoModal } from '../../../components/CustomModal/YesNoModal/YesNoModal';
import { OverlayLoader } from '../../../components/Loaders/OverlayLoader';
import { PERMISSIONS } from '../../../constants/Roles';
import { useConfirmationModal } from '../../../hooks/useConfirmationModal';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { useEventDetailsData } from '../useEventDetailsData';
import { useEventsData } from '../useEventsData';
import * as S from './EventDetails.styled';
import { EventInfo } from './EventInfo/EventInfo';

const EventDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { deleteEvent } = useEventsData(false);
  const { event, saveEvent, changeEventStatus, isLoading } =
    useEventDetailsData(id);
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();
  const canUseEventActions = useHasPermissions(
    PERMISSIONS.CAN_USE_EVENT_ACTIONS,
  );

  const handleClickBack = () => {
    navigate('/events');
  };

  const handleDeleteEvent = () =>
    handleOpenModal({
      title: 'Delete event',
      question: 'Do you want to delete event?',
      callback: () => deleteEvent((event as Event).id, handleClickBack),
    });

  const handleChangeEventStatus = () => {
    handleOpenModal({
      title: event?.is_active ? 'Deactivate event' : 'Activate event',
      question: `Do you want to ${
        event?.is_active ? 'deactivate' : 'activate'
      } event?`,
      callback: () =>
        changeEventStatus((event as Event).id, {
          state: !event?.is_active,
        }),
    });
  };

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      {event && (
        <S.Container>
          <S.TopButtonsWrapper>
            <BackButton name={event?.name} handleClickBack={handleClickBack} />
            {canUseEventActions && (
              <S.ButtonsWrapper>
                <CustomButton
                  title={event.is_active ? 'Deactivate' : 'Activate'}
                  variant="buttonMedium"
                  color="secondary"
                  beforeIcon={event.is_active ? DeactivatedIcon : CheckmarkIcon}
                  handleClick={handleChangeEventStatus}
                />

                <CustomButton
                  title="Delete"
                  variant="buttonMedium"
                  color="secondary"
                  beforeIcon={DeleteIcon}
                  handleClick={handleDeleteEvent}
                />
                <CustomButton
                  title="Duplicate"
                  variant="buttonMedium"
                  color="primary"
                  afterIcon={DuplicateIcon}
                  handleClick={() =>
                    navigate(`/events/event-form/${event.id}/duplicate`)
                  }
                />
              </S.ButtonsWrapper>
            )}
          </S.TopButtonsWrapper>
          <EventInfo event={event} saveEvent={saveEvent} />
        </S.Container>
      )}
      <YesNoModal
        isOpen={isConfirmationModalOpen}
        title={modalInfo.title}
        question={modalInfo.question}
        closeModal={handleCloseModal}
        handleAction={() => {
          if (modalInfo.callback) {
            modalInfo.callback();
          }
          handleCloseModal();
        }}
      />
    </DashboardWrapper>
  );
};

export default EventDetails;
