import { endpoints } from 'config';

import { User } from 'types';
import http from 'utils/http';

export const sendUserFormDataRegistrationInfo = async (
  companyInfo: FormData,
) => {
  try {
    const url = endpoints.auth.signup;
    const response = await http.post<{ user: User }>(url, companyInfo, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return 'Error on save Company info';
  }
};
