import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { NoAccess } from 'modules';

import { ROLE } from 'constants/Roles';
import { RootState } from 'store/rootReducer';

const PrivateRoute = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: ROLE[];
}) => {
  const location = useLocation();

  const { isAuthenticated, user, loading } = useSelector(
    (state: RootState) => state.auth,
  );

  if (loading) {
    return (
      <>
        <LoadingScreen text="Checking authentication ..." />
      </>
    );
  }

  const userHasRequiredRole = Boolean(
    user && roles.includes(user.user_role.toLocaleUpperCase() as ROLE),
  );

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <NoAccess />; // build your won access denied page (sth like 404)
  }

  return children;
};

export default PrivateRoute;
