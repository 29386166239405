import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints, main } from 'config';

import http from 'utils/http';

import { SplitFormValues } from '../../modules/PeopleManagement/ServiceSpecialist/Details/Services/SplitForm/SplitForm.types';
import { BaseError } from '../store.constants';
import {
  GetSpecialistServicesTypesParams,
  GetSpecialistsListParams,
  SpecialistServicesList,
  SpecialistsList,
} from './service-specialists.types';

export const getSpecialists = createAsyncThunk<
  SpecialistsList,
  GetSpecialistsListParams,
  { rejectValue: BaseError }
>(
  'serviceSpecialists/getSpecialists',
  async (params: GetSpecialistsListParams) => {
    const response = await http.get(endpoints.people.getAllSpecialists, {
      params,
    });

    return {
      list: response.data,
      params,
    };
  },
);

export const getSpecialistsServices = createAsyncThunk<
  SpecialistServicesList,
  { params: GetSpecialistServicesTypesParams; specialistId: string },
  { rejectValue: BaseError }
>(
  'serviceSpecialists/getSpecialistServices',
  async ({ params, specialistId }) => {
    const response = await http.get(
      `${main.serviceSpecialists.root}${specialistId}/services/`,
      {
        params,
      },
    );

    return {
      list: response.data,
      params,
    };
  },
);

export const assignServiceToSpecialist = createAsyncThunk<
  undefined,
  { specialistId: string; serviceId: string; data: SplitFormValues },
  { rejectValue: BaseError }
>(
  'serviceSpecialists/assignServiceToSpecialist',
  ({ serviceId, specialistId, data }) => {
    return http
      .post(
        endpoints.services.serviceToSpecialist(specialistId, serviceId),
        data,
      )
      .then((response) => {
        return response.data;
      });
  },
);

export const unassignServiceFromSpecialist = createAsyncThunk<
  undefined,
  { specialistId: string; serviceId: string },
  { rejectValue: BaseError }
>(
  'serviceSpecialists/unassignServiceToSpecialist',
  ({ serviceId, specialistId }) => {
    return http
      .delete(endpoints.services.serviceToSpecialist(specialistId, serviceId))
      .then((response) => {
        return response.data;
      });
  },
);
