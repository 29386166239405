export interface BaseSelectItem {
  label: string;
  value: string;
}

export const LIMIT = 15;

export const ALL_OPTION = {
  label: 'All',
  value: 'all',
};

export const PROVIDER_FILTER_VALUES: BaseSelectItem[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Elite Amenity',
    value: 'elite_amenity',
  },
  {
    label: 'Service Specialist',
    value: 'service_specialist',
  },
];

export const ACTIVE_INACTIVE_FILTER_VALUES: BaseSelectItem[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const TRANSACTION_SORT_OPTIONS: BaseSelectItem[] = [
  {
    label: 'Created',
    value: '-created',
  },
  {
    label: 'Booking Time',
    value: 'earliest_booking_date_time',
  },
  {
    label: 'User',
    value: 'user__first_name',
  },
];

export const DEFAULT_PARAMS = {
  page: 1,
  search: '',
  page_size: LIMIT,
};
