import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { endpoints } from '../../../config';
import { VALIDATE_IMAGE_MESSAGE } from '../../../utils/constants';
import {
  convertImageInFormData,
  validateAvatarImage,
} from '../../../utils/HelperFunctions';
import http from '../../../utils/http';
import { SpecialistEditorValues } from './Details/SpecialistInfo/Editor/SpecialistEditor.types';

interface UseSpecialistInfoParams {
  specialist?: ServiceSpecialist;
  uploadSpecialistAvatar: (id: string, file: File) => void;
  saveSpecialist: (values: SpecialistEditorValues) => Promise<void>;
  isLoading: boolean;
}

export const useSpecialistDetailsData = (
  specialistId?: string,
): UseSpecialistInfoParams => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [specialist, setSpecialist] = useState<ServiceSpecialist>();

  const getSpecialistById = (id: string) => {
    http.get(endpoints.people.specialistById(id)).then((res: AxiosResponse) => {
      setSpecialist(res.data);
    });
  };

  const saveSpecialist = (values: SpecialistEditorValues) => {
    setIsLoading(true);
    return http
      .put(endpoints.people.specialistById(specialistId as string), values)
      .then(() => {
        getSpecialistById(specialistId as string);
        enqueueSnackbar(`Service specialist was saved.`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadSpecialistAvatar = async (id: string, file: File) => {
    if (!validateAvatarImage(file)) {
      enqueueSnackbar(VALIDATE_IMAGE_MESSAGE, {
        variant: 'error',
      });

      return;
    }

    const convertedFile = convertImageInFormData(file);
    setIsLoading(true);
    http
      .put(endpoints.people.uploadSpecialistImage(id), convertedFile, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        getSpecialistById(id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (specialistId) {
      getSpecialistById(specialistId);
    }
  }, []);

  return {
    specialist,
    uploadSpecialistAvatar,
    saveSpecialist,
    isLoading,
  };
};
