import styled from '@emotion/styled';

export const Container = styled.div`
  .MuiTableRow-root.MuiTableRow-hover {
    transition: all 0.15s ease;

    &:hover {
      background-color: ${(props: any) =>
        props.theme.palette.action.disabledBackground};
    }
  }
`;

export const BuildingList = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeWithStatus = styled.span`
  display: flex;
  align-items: center;

  img {
    margin-right: 15px;
  }
`;
