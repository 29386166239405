import React, { useEffect } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DashboardWrapper } from '../../components';
import { CustomPagination } from '../../components/CustomPagination/CustomPagination';
import { ColumnType } from '../../components/GridElements/GridCommonConfigs';
import { GridFilters } from '../../components/GridElements/GridFilters/GridFilters';
import { LIMIT } from '../../components/GridElements/GridFilters/GridFilters.constants';
import { FiltersParamsTypes } from '../../components/GridElements/GridFilters/GridFilters.types';
import { OverlayLoader } from '../../components/Loaders/OverlayLoader';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppSelector';
import { getMembers } from '../../store/members/members.actions';
import { Member } from '../../store/members/members.types';
import { RootState } from '../../store/rootReducer';
import { TABLE_CONTAINER_STYLES, TABLE_STYLES } from '../../utils/constants';
import { MEMBERS_LIST_CONFIG } from './Members.config';
import * as S from './Members.styled';

export const Members = () => {
  const dispatch = useAppDispatch();
  const {
    membersList: { params, list },
    loading: isLoading,
  } = useAppSelector((state: RootState) => state.members);

  useEffect(() => {
    dispatch(
      getMembers({
        page: 1,
        page_size: LIMIT,
      }),
    );
  }, []);

  const handleSearch = (value: string) => {
    dispatch(
      getMembers({
        ...params,
        page: 1,
        search: value,
      }),
    );
  };

  const handleFiltersChange = (filterParams: FiltersParamsTypes) => {
    dispatch(
      getMembers({
        ...params,
        ...filterParams,
      }),
    );
  };

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      <S.Container>
        <S.Header>Members</S.Header>
        <GridFilters
          handleSearch={handleSearch}
          searchPlaceholder="Search by name or email"
          handleFiltersChange={handleFiltersChange}
          config={{
            building: true,
          }}
        />
        <S.ListWrapper>
          <TableContainer component={Paper} sx={TABLE_CONTAINER_STYLES}>
            <Table sx={TABLE_STYLES} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {MEMBERS_LIST_CONFIG.map((item: ColumnType<Member>) => (
                    <React.Fragment key={item.label}>
                      {item.headerComponent(item)}
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(list?.result?.length) &&
                  list.result.map((member: Member) => (
                    <TableRow hover={true} key={member.member_id}>
                      {MEMBERS_LIST_CONFIG.map((item: ColumnType<Member>) => (
                        <React.Fragment key={item.label}>
                          {item.cellComponent(item, member)}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </S.ListWrapper>
        {Boolean(list?.count) && (
          <CustomPagination
            pages={Math.ceil(list?.count / LIMIT)}
            handleChange={(e: React.ChangeEvent<unknown>, value: number) => {
              dispatch(
                getMembers({
                  ...params,
                  page: value,
                }),
              );
            }}
            activePage={params.page as number}
          />
        )}
      </S.Container>
    </DashboardWrapper>
  );
};
