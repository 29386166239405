import React from 'react';

import { TableCell } from '@mui/material';

import { ColumnType } from '../GridCommonConfigs';

interface CellElementType<T> {
  column: ColumnType<T>;
  children: React.ReactNode;
  noBottomBorder?: boolean;
}

export const CellElement = <T extends unknown>({
  column,
  children,
  noBottomBorder,
}: CellElementType<T>): React.ReactElement => {
  return (
    <TableCell
      key={column.label}
      align={column.align}
      style={{
        width: `${column.widthPercentage}%`,
        maxWidth: `${column.widthPercentage}%`,
        ...(noBottomBorder ? { borderBottomColor: 'transparent' } : {}),
      }}
    >
      {children}
    </TableCell>
  );
};
