import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// tslint:disable:trailing-comma
import { ThemeProvider } from '@mui/material/styles';

import { NotFound } from 'modules/index';
import { BlogPostDetail } from 'modules/InfoPosts/BlogPosts/BlogPostDetail/BlogPostDetail';
import { BuildingCreate } from 'modules/InfoPosts/Buildings/BuildingCreate/BuildingCreate';
import { BuildingDetail } from 'modules/InfoPosts/Buildings/BuildingDetail/BuildingDetail';

import { ROLE } from 'constants/Roles';
import { darkTheme } from 'theme/theme';

import { PayrollDetailsWrapper } from '../modules/Billings/PayrollDetails/PayrollDetailsWrapper';
import { Contacts } from '../modules/Contacts/Contacts';
import EventDetails from '../modules/Events/Details/EventDetails';
import { EventEditorWrapper } from '../modules/Events/Editor/EventEditorWrapper';
import { Members } from '../modules/Members/Members';
import { EditScheduleFormWrapper } from '../modules/Schedule/Forms/EditScheduleForm/EditScheduleFormWrapper';
import { SCHEDULE_PAGES } from '../modules/Schedule/List/ScheduleList.constants';
import { ServiceDetails } from '../modules/Services/Details/ServiceDetails';
import { SpecialistSchedule } from '../modules/SpecialistSchedule/SpecialistSchedule';
import PrivateRoute from './PrivateRoutes';

const Signin = lazy(() => import('modules/Auth/Signin/Signin'));
const ForgotPassword = lazy(
  () => import('modules/Auth/ForgotPassword/ForgotPassword'),
);
const Invitation = lazy(() => import('modules/Auth/Invitation/Invitation'));
const RestorePassword = lazy(
  () => import('modules/Auth/RestorePassword/RestorePassword'),
);
const AccountManagement = lazy(
  () => import('modules/AccountManagement/AccountManagement'),
);
const PeopleManagement = lazy(
  () => import('modules/PeopleManagement/PeopleManagement'),
);

const SpecialistDetails = lazy(
  () =>
    import(
      '../modules/PeopleManagement/ServiceSpecialist/Details/SpecialistDetails'
    ),
);

const BookingRequests = lazy(
  () => import('modules/BookingRequests/BookingRequests'),
);
const ServicesList = lazy(() => import('modules/Services/List/ServicesList'));
const EventsList = lazy(() => import('modules/Events/List/EventsList'));
const Billings = lazy(() => import('modules/Billings/Billings'));
const InfoPosts = lazy(() => import('modules/InfoPosts/InfoPosts'));
const OnDemandVideoCreate = lazy(
  () => import('modules/InfoPosts/OnDemandVideo/OnDemandVideoCreate'),
);

const ServiceEditorWrapper = lazy(
  () => import('modules/Services/Editor/ServiceEditorWrapper'),
);

const ServiceBooking = lazy(
  () => import('modules/Bookings/ServiceBooking/ServiceBooking'),
);

const EventBooking = lazy(
  () => import('modules/Bookings/EventBooking/EventBooking'),
);

export const EliteRoute = () => {
  return useRoutes([
    {
      path: '/',
      element: (
        <ThemeProvider theme={darkTheme}>
          <Signin />
        </ThemeProvider>
      ),
    },
    {
      path: 'signin',
      element: (
        <ThemeProvider theme={darkTheme}>
          <Signin />
        </ThemeProvider>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <ThemeProvider theme={darkTheme}>
          <ForgotPassword />
        </ThemeProvider>
      ),
    },
    {
      path: 'restore-password/:reset_token',
      element: (
        <ThemeProvider theme={darkTheme}>
          <RestorePassword />
        </ThemeProvider>
      ),
    },

    {
      path: 'signup/:temporary_token',
      element: (
        <ThemeProvider theme={darkTheme}>
          <Invitation />
        </ThemeProvider>
      ),
    },
    {
      path: 'account',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.CONCIERGE,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <AccountManagement />
        </PrivateRoute>
      ),
    },
    {
      path: 'schedule',
      element: (
        <PrivateRoute roles={[ROLE.SERVICE_SPECIALIST]}>
          <SpecialistSchedule />
        </PrivateRoute>
      ),
    },
    {
      path: 'members',
      element: (
        <PrivateRoute roles={[ROLE.SERVICE_SPECIALIST]}>
          <Members />
        </PrivateRoute>
      ),
    },
    {
      path: 'management',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.CONCIERGE,
          ]}
        >
          <PeopleManagement />
        </PrivateRoute>
      ),
    },
    {
      path: 'management/specialist/:id',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <SpecialistDetails />
        </PrivateRoute>
      ),
    },
    {
      path: 'management/specialist/:id/:timeSlotId',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <EditScheduleFormWrapper
            schedulePageType={SCHEDULE_PAGES.SPECIALIST}
          />
        </PrivateRoute>
      ),
    },
    {
      path: 'info',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <InfoPosts />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/building/:id',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <BuildingDetail />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/new-building',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <BuildingCreate />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/blog-post/:id',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <BlogPostDetail />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/new-blog-post',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <BlogPostDetail />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/video-form',
      element: (
        <PrivateRoute roles={[ROLE.OWNER, ROLE.SUPER_ADMIN]}>
          <OnDemandVideoCreate />
        </PrivateRoute>
      ),
    },
    {
      path: 'info/video-form/:id',
      element: (
        <PrivateRoute roles={[ROLE.OWNER, ROLE.SUPER_ADMIN]}>
          <OnDemandVideoCreate />
        </PrivateRoute>
      ),
    },
    {
      path: 'booking-requests',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.CONCIERGE,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <BookingRequests />
        </PrivateRoute>
      ),
    },
    {
      path: 'services',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <ServicesList />
        </PrivateRoute>
      ),
    },
    {
      path: 'services/service-form',
      element: (
        <PrivateRoute roles={[ROLE.OWNER, ROLE.SUPER_ADMIN]}>
          <ServiceEditorWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'services/service-form/:id',
      element: (
        <PrivateRoute roles={[ROLE.OWNER, ROLE.SUPER_ADMIN]}>
          <ServiceEditorWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'services/:id',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <ServiceDetails />
        </PrivateRoute>
      ),
    },
    {
      path: 'services/:id/:timeSlotId',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <EditScheduleFormWrapper schedulePageType={SCHEDULE_PAGES.SERVICE} />
        </PrivateRoute>
      ),
    },
    {
      path: 'services/booking',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <ServiceBooking />
        </PrivateRoute>
      ),
    },
    {
      path: 'events',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <EventsList />
        </PrivateRoute>
      ),
    },
    {
      path: 'events/:id',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <EventDetails />
        </PrivateRoute>
      ),
    },
    {
      path: 'events/event-form',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <EventEditorWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'events/event-form/:id',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <EventEditorWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'events/event-form/:id/duplicate',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <EventEditorWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'events/booking',
      element: (
        <PrivateRoute
          roles={[ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.BUILDING_MANAGER]}
        >
          <EventBooking />
        </PrivateRoute>
      ),
    },
    {
      path: 'billings',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <Billings />
        </PrivateRoute>
      ),
    },
    {
      path: 'billings/payrolls/:id',
      element: (
        <PrivateRoute
          roles={[
            ROLE.OWNER,
            ROLE.SUPER_ADMIN,
            ROLE.BUILDING_MANAGER,
            ROLE.SERVICE_SPECIALIST,
          ]}
        >
          <PayrollDetailsWrapper />
        </PrivateRoute>
      ),
    },
    {
      path: 'contacts',
      element: (
        <PrivateRoute roles={[ROLE.SERVICE_SPECIALIST]}>
          <Contacts />
        </PrivateRoute>
      ),
    },

    { path: '*', element: <NotFound /> },
  ]);
};
