import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import DeleteIcon from '../../../assets/icons/delete.svg';
import { GridMoreDropdown } from '../../../components/GridElements/GridMoreDropdown/GridMoreDropdown';
import { useAppDispatch } from '../../../hooks/useAppSelector';
import { OpenModalParams } from '../../../hooks/useConfirmationModal';
import { RootState } from '../../../store/rootReducer';
import {
  deleteTimeSlot,
  getTimeSlots,
} from '../../../store/time-slots/time-slots.actions';
import { TimeSlot } from '../../../store/time-slots/time-slots.types';
import { theme } from '../../../theme/theme';

interface ScheduleListActionsProps {
  timeSlot: TimeSlot;
  handleOpenModal: (params: OpenModalParams) => void;
  serviceOrSpecialistId: string | undefined;
  isSpecialistPage: boolean;
}

export const ScheduleListActions = ({
  timeSlot,
  handleOpenModal,
  serviceOrSpecialistId,
  isSpecialistPage,
}: ScheduleListActionsProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    timeSlotsList: { params },
  } = useSelector((state: RootState) => state.timeSlots);

  const actions = [
    {
      label: '. . . Edit',
      color: theme.palette.statuses.pending,
      callback: () =>
        navigate(
          `/${isSpecialistPage ? 'management/specialist' : 'services'}/${
            serviceOrSpecialistId as string
          }/${timeSlot.id}`,
        ),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      color: theme.palette.error.main,
      callback: () => {
        if (handleOpenModal) {
          handleOpenModal({
            title: 'Delete time slot',
            question: 'Do you want to delete time slot?',
            callback: () =>
              dispatch(deleteTimeSlot(timeSlot.id))
                .unwrap()
                .then(() => {
                  enqueueSnackbar('Time slot was deleted successfully.', {
                    variant: 'success',
                  });
                  dispatch(getTimeSlots({ ...params }));
                })
                .catch((e) => {
                  enqueueSnackbar(e.message, {
                    variant: 'error',
                  });
                }),
          });
        }
      },
    },
  ];

  return (
    <>
      <GridMoreDropdown items={actions} />
    </>
  );
};
