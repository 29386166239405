import DeleteIcon from 'assets/icons/delete-button.svg';

import DefaultImage from '../../assets/empty-photo.png';
import { ImageType } from '../../types';
import { MAX_IMAGES_COUNT } from '../../utils/constants';
import * as S from './ImagesWrapper.styled';

interface ImagesWrapperProps {
  images: ImageType[];
  handleDeleteImage?: (id: string) => void;
}

export const ImagesWrapper = ({
  images,
  handleDeleteImage,
}: ImagesWrapperProps) => {
  const emptyImages = Array.from(
    { length: MAX_IMAGES_COUNT - images.length },
    (_, i) => i + 1,
  );

  return (
    <S.Images>
      {images.map((item) => (
        <S.Image key={item.id}>
          {handleDeleteImage && (
            <S.DeleteButton onClick={() => handleDeleteImage(item.id)}>
              <img src={DeleteIcon} alt="" />
            </S.DeleteButton>
          )}
          <img src={item.image} alt="" />
        </S.Image>
      ))}
      {emptyImages.map((item) => (
        <S.Image key={item}>
          <img src={DefaultImage} alt="" />
        </S.Image>
      ))}
    </S.Images>
  );
};
