import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

interface ILoginFCN {
  name: string;
  active: boolean;
  registration_id: string | null;
  user_id: string;
  type: string;
}

export const loginFCN = createAsyncThunk(
  'auth/loginFCN',
  async (data: ILoginFCN, thunkAPI: any) => {
    try {
      const url = endpoints.fcm.login;
      await http.post(url, data);
      return 0;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in loginFCN');
    }
  },
);
