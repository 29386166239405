import * as yup from 'yup';

const linkRule =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const FormSchemaAddRecording = yup.object().shape({
  link: yup
    .string()
    .test('empty-or-link-check', 'Enter the valid link!', (link: any) =>
      link.match(linkRule),
    ),
});
