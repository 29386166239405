import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

import http from 'utils/http';

import { AmountFormValues } from '../../modules/Billings/Refunds/AmountForm/AmountForm.types';
import { BaseError } from '../store.constants';
import {
  CurrentPayroll,
  GetBillingsParams,
  PayrollsList,
  RefundsList,
  TransactionsList,
} from './billings.types';

export const getTransactions = createAsyncThunk<
  TransactionsList,
  GetBillingsParams,
  { rejectValue: BaseError }
>('billings/getTransactions', async (params: GetBillingsParams) => {
  const response = await http.get(endpoints.billings.getTransactions, {
    params: {
      ...params,
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const getPayrolls = createAsyncThunk<
  PayrollsList,
  GetBillingsParams,
  { rejectValue: BaseError }
>('billings/getPayrolls', async (params: GetBillingsParams) => {
  const response = await http.get(endpoints.billings.getPayrolls, {
    params: {
      ...params,
      ordering: '-created',
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const getPayrollById = createAsyncThunk<
  CurrentPayroll,
  { id: string; params: GetBillingsParams },
  { rejectValue: BaseError }
>('billings/getPayrollById', async ({ id, params }) => {
  const response = await http.get(`${endpoints.billings.getPayrolls}${id}/`, {
    params: {
      ...params,
      ordering: '-created',
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const getRefunds = createAsyncThunk<
  RefundsList,
  GetBillingsParams,
  { rejectValue: BaseError }
>('billings/getRefunds', async (params: GetBillingsParams) => {
  const response = await http.get(endpoints.billings.getRefunds, {
    params: {
      ...params,
      ordering: '-created',
    },
  });

  return {
    list: response.data,
    params,
  };
});

export const submitRefund = createAsyncThunk<
  undefined,
  { id: string; data: AmountFormValues },
  { rejectValue: BaseError }
>('billings/submitRefund', async ({ id, data }) => {
  const response = await http.post(endpoints.billings.approveRefund(id), data);

  return response.data;
});

export const declineRefund = createAsyncThunk<
  undefined,
  string,
  { rejectValue: BaseError }
>('billings/declineRefund', async (id: string) => {
  const response = await http.post(endpoints.billings.declineRefund(id));
  return response.data;
});
