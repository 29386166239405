import styled from '@emotion/styled/macro';

export const pickerButtonOverride = () => {
  return `
  .MuiButtonBase-root {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &:hover {
      background-color: transparent;
    }

    .MuiTouchRipple-root {
      border-radius: 0;
      width: 100%;
    }
  }`;
};

export const TrancatedString = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
