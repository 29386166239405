import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import {
  declineRefund,
  getPayrollById,
  getPayrolls,
  getRefunds,
  getTransactions,
  submitRefund,
} from './billings.actions';
import { BillingsState } from './billings.types';

const BILLINGS_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

const CURRENT_PAYROLL_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
    sum_amenity_profit: 0,
    sum_specialist_profit: 0,
  },
  params: {},
};

export const initialState: BillingsState = Object.freeze({
  loading: false,
  error: null,
  transactions: BILLINGS_LIST_DEFAULT,
  payrolls: BILLINGS_LIST_DEFAULT,
  refunds: BILLINGS_LIST_DEFAULT,
  currentTransaction: null,
  currentPayroll: CURRENT_PAYROLL_LIST_DEFAULT,
  currentRefund: null,
  status: STORE_STATUSES.IDLE,
});

const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    setCurrentTransaction: (state, action) => {
      state.currentTransaction = action.payload;
    },
    setCurrentRefund: (state, action) => {
      state.currentRefund = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.transactions = action.payload;
      state.loading = false;
    });

    builder.addCase(getTransactions.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getPayrolls.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(getPayrolls.fulfilled, (state, action) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.payrolls = action.payload;
      state.loading = false;
    });

    builder.addCase(
      getPayrolls.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = STORE_STATUSES.REJECTED;
        state.loading = false;
        state.error = action.payload;
      },
    );

    builder.addCase(getPayrollById.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(getPayrollById.fulfilled, (state, action) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.currentPayroll = action.payload;
      state.loading = false;
    });

    builder.addCase(
      getPayrollById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = STORE_STATUSES.REJECTED;
        state.loading = false;
        state.error = action.payload;
      },
    );

    builder.addCase(getRefunds.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(getRefunds.fulfilled, (state, action) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.refunds = action.payload;
      state.error = null;
    });

    builder.addCase(getRefunds.rejected, (state) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
    });

    builder.addCase(submitRefund.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(submitRefund.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(submitRefund.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(declineRefund.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(declineRefund.fulfilled, (state) => {
      state.status = STORE_STATUSES.FULFILLED;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(declineRefund.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setCurrentTransaction, setCurrentRefund } =
  billingsSlice.actions;
export default billingsSlice.reducer;
