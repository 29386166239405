import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '../../CustomButton/CustomButton';
import CustomModal from '../CustomModal';

const useStyles = makeStyles({
  sendButton: {
    marginRight: '16px',
  },
});

interface YesNoModalProps {
  isOpen: boolean;
  title: string;
  question?: string;
  closeModal: () => void;
  handleAction: () => void;
}

export const YesNoModal = ({
  isOpen,
  title,
  question,
  closeModal,
  handleAction,
}: YesNoModalProps) => {
  const classes = useStyles();

  return (
    <CustomModal title={title} open={isOpen} closeModal={closeModal}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {question && <Typography variant={'body1'}>{question}</Typography>}
      </Box>

      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={'Yes'}
          variant={'buttonMedium'}
          additionalClass={classes.sendButton}
          handleClick={handleAction}
        />
        <CustomButton
          title={'No'}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={closeModal}
        />
      </Box>
    </CustomModal>
  );
};
