import { useNavigate } from 'react-router-dom';

import { ImagesWrapper } from 'components/ImagesWrapper/ImagesWrapper';
import * as SInfoPage from 'components/InfoPage/InfoPage.styled';

import CheckmarkIcon from '../../../../assets/icons/checkmark-black.svg';
import DurationForUserIcon from '../../../../assets/icons/duration-for-user.svg';
import EditInfo from '../../../../assets/icons/edit.svg';
import FitnessIcon from '../../../../assets/icons/fitness.svg';
import HomeIcon from '../../../../assets/icons/home.svg';
import PriceIcon from '../../../../assets/icons/price.svg';
import TimeIcon from '../../../../assets/icons/time.svg';
import { CustomButton } from '../../../../components';
import GoldLabel from '../../../../components/GoldLabel/GoldLabel';
import { PERMISSIONS } from '../../../../constants/Roles';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useHasPermissions } from '../../../../hooks/useHasPermissions';
import { RootState } from '../../../../store/rootReducer';
import { shapePrice } from '../../List/ServicesList.helpers';
import * as S from './ServiceInfo.styled';

interface ServiceInfoProps {
  isConciergeService: boolean;
}

export const ServiceInfo = ({ isConciergeService }: ServiceInfoProps) => {
  const navigate = useNavigate();
  const { currentService } = useAppSelector(
    (state: RootState) => state.services,
  );
  const canEditService = useHasPermissions(PERMISSIONS.CAN_EDIT_SERVICE);

  return (
    <S.Container>
      <S.ImagesAndInfo>
        <ImagesWrapper images={currentService?.images || []} />
        <S.Info>
          {canEditService && (
            <CustomButton
              title="Edit info"
              variant="buttonMedium"
              color="primary"
              afterIcon={EditInfo}
              handleClick={() =>
                navigate(`/services/service-form/${currentService?.id}`)
              }
            />
          )}
          <SInfoPage.EntityName>{currentService?.name}</SInfoPage.EntityName>
          {Boolean(currentService?.buildings.length) && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={HomeIcon} alt="" />
              <SInfoPage.InfoItemName>
                {currentService?.buildings
                  .map(
                    (building) =>
                      `${building.building_code} ${building.building_name}`,
                  )
                  .join(', ')}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {currentService?.category.name && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={FitnessIcon} alt="" />
              <SInfoPage.InfoItemName>
                {currentService?.category.name}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          {currentService?.service_type.name && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={CheckmarkIcon} alt="" />
              <SInfoPage.InfoItemName>
                {currentService?.service_type.name}
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
          <SInfoPage.InfoItem>
            <SInfoPage.InfoItemIcon src={PriceIcon} alt="" />
            <SInfoPage.InfoItemName>
              <GoldLabel
                text={shapePrice(
                  currentService?.price,
                  currentService?.price_from,
                  currentService?.price_to,
                )}
                variant={'h5'}
              />
            </SInfoPage.InfoItemName>
          </SInfoPage.InfoItem>
          {currentService?.time_slot_duration_internal &&
            !isConciergeService && (
              <SInfoPage.InfoItem>
                <SInfoPage.InfoItemIcon src={TimeIcon} alt="" />
                <SInfoPage.InfoItemName>
                  {currentService?.time_slot_duration_internal}h duration
                  (internal)
                </SInfoPage.InfoItemName>
              </SInfoPage.InfoItem>
            )}
          {currentService?.time_slot_duration_user && !isConciergeService && (
            <SInfoPage.InfoItem>
              <SInfoPage.InfoItemIcon src={DurationForUserIcon} alt="" />
              <SInfoPage.InfoItemName>
                {currentService?.time_slot_duration_user}h duration (for user)
              </SInfoPage.InfoItemName>
            </SInfoPage.InfoItem>
          )}
        </S.Info>
      </S.ImagesAndInfo>
      <S.DescriptionWrapper>
        <S.DescriptionHeader>Service description</S.DescriptionHeader>
        {currentService?.description && (
          <S.Description>{currentService.description}</S.Description>
        )}
      </S.DescriptionWrapper>
      {currentService?.service_type.need_specialist && canEditService && (
        <S.SpecialistsWrapper>
          <S.SpecialistsListHeader>Service specialists</S.SpecialistsListHeader>
          <S.SpecialistsList>
            {currentService?.service_specialists.map((specialist) => (
              <S.SpecialistCard
                key={specialist.id}
                title={specialist.full_name}
              >
                {specialist.full_name}
                <div>Split {specialist.split}%</div>
              </S.SpecialistCard>
            ))}
          </S.SpecialistsList>
          <CustomButton
            title="Edit specialists"
            variant="buttonMedium"
            color="primary"
            afterIcon={EditInfo}
            handleClick={() =>
              navigate(`/services/service-form/${currentService?.id}`, {
                state: {
                  activeStep: 1,
                },
              })
            }
          />
        </S.SpecialistsWrapper>
      )}
    </S.Container>
  );
};
