import { ChangeEvent, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DashboardWrapper } from 'components';
import { BackButton } from 'components/BackButton/BackButton';

import DefaultImage from '../../../assets/empty-photo.png';
import UploadIcon from '../../../assets/icons/upload-black.svg';
import { OverlayLoader } from '../../../components/Loaders/OverlayLoader';
import { UploadButton } from '../../../components/UploadButton/UploadButton';
import { useAppDispatch } from '../../../hooks/useAppSelector';
import { getBuildings } from '../../../store/buildings/buildings.actions';
import { getCategories } from '../../../store/categories/categories.actions';
import { useServiceSpecialistData } from '../../PeopleManagement/ServiceSpecialist/useServiceSpecialistData';
import { useEventDetailsData } from '../useEventDetailsData';
import { EventEditor } from './EventEditor';
import * as S from './EventEditor.styled';

export const EventEditorWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id: eventId } = useParams();
  const { serviceSpecialists, getSpecialistsList, clearSpecialists } =
    useServiceSpecialistData(false);
  const {
    event,
    createEvent,
    isLoading,
    processEventImage,
    eventImage,
    saveEvent,
  } = useEventDetailsData(eventId);
  const isDuplicate = location.pathname.includes('duplicate');
  const isEdit = Boolean(eventId && !isDuplicate);

  const getBackButtonLabel = () => {
    if (isDuplicate) {
      return event?.name ? `${event?.name} (COPY)` : '';
    }

    return eventId ? event?.name || '' : 'Create event';
  };

  const handleUploadEventImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      processEventImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(
      getBuildings({
        page_size: 10000,
      }),
    );
  }, []);

  useEffect(() => {
    if (event?.buildings.length) {
      getSpecialistsList({
        building: event?.buildings.map((item) => item.id).join(','),
        search: '',
        page: 1,
        limit: 10000,
      });
    }
  }, [event?.buildings]);

  return (
    <DashboardWrapper>
      {isLoading && <OverlayLoader />}
      <BackButton
        name={getBackButtonLabel()}
        handleClickBack={() =>
          isEdit ? navigate(`/events/${event?.id}`) : navigate('/events')
        }
      />
      <S.Container>
        <S.UploadSection>
          <UploadButton
            title="Upload photo"
            onChange={handleUploadEventImage}
            additionalClasses="upload-button"
            variant={'buttonMedium'}
            icon={UploadIcon}
            name="specialist-avatar"
          />
          <S.Picture src={eventImage.imgSrc || event?.image || DefaultImage} />
        </S.UploadSection>
        <EventEditor
          event={event}
          createEvent={createEvent}
          saveEvent={saveEvent}
          eventImage={eventImage}
          isDuplicate={isDuplicate}
          isEdit={isEdit}
          serviceSpecialists={serviceSpecialists}
          getSpecialistsList={getSpecialistsList}
          clearSpecialists={clearSpecialists}
        />
      </S.Container>
    </DashboardWrapper>
  );
};
