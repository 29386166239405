export interface BaseError {
  message: string;
}

export enum STORE_STATUSES {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}
