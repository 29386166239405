import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { Avatar } from 'components/Avatar/Avatar';
import { CategoryView } from 'components/CategoryView/CategoryView';

const useStyles = makeStyles({
  providerHead: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
  },

  providerSubtitle: {
    color: '#757575',
  },
  providerSubName: {
    color: '#999999',
    textTransform: 'capitalize',
  },
  providerBlock: {
    marginBottom: '24px',
  },
  category: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface DetailsBuildingManagerModalProps {
  userInfo: any;
}

export const DetailsBuildingManagerModal = ({
  userInfo: {
    first_name,
    last_name,
    email,
    building_names,
    note,
    account_icon,
    building_descriptions,
    category,
    role,
    type,
  },
}: DetailsBuildingManagerModalProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.providerHead} py={3} mt={-5}>
        <Avatar img={account_icon} alt="building_manager" />
        <Box ml={2}>
          <Typography variant={'subtitle1'}>
            {first_name} {last_name}
          </Typography>
          <Typography variant={'body1'} className={classes.providerSubName}>
            Building Manager
          </Typography>
        </Box>
      </Box>
      <Grid container={true} columnSpacing={5} mt={3}>
        <Grid item={true} xs={6}>
          <Box className={classes.providerBlock}>
            <Typography
              variant={'subtitle2'}
              className={classes.providerSubtitle}
              mb={1}
            >
              Email
            </Typography>
            <Typography variant={'h5'}>
              {email === '' ? 'No Email' : email}
            </Typography>
          </Box>
          <Box className={classes.providerBlock}>
            <Typography
              variant={'subtitle2'}
              className={classes.providerSubtitle}
              mb={1}
            >
              Building name(s)
            </Typography>

            {building_names?.length ? (
              building_names.map((elem: any, index: number) => (
                <Box key={index}>
                  <Typography variant={'h5'}>
                    {elem.building_code} {elem.building_name}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant={'h5'}>No building names</Typography>
            )}
          </Box>
        </Grid>
        <Grid item={true} xs={6}>
          <Box className={classes.providerBlock}>
            <Typography
              variant={'subtitle2'}
              className={classes.providerSubtitle}
              mb={1}
            >
              Category
            </Typography>
            {category?.length ? (
              category.map((elem: any, index: number) => (
                <Box className={classes.category} key={index}>
                  <CategoryView category={elem} />
                </Box>
              ))
            ) : (
              <Typography variant={'h5'}>No Category</Typography>
            )}
          </Box>
          <Box className={classes.providerBlock}>
            <Typography
              variant={'subtitle2'}
              className={classes.providerSubtitle}
              mb={1}
            >
              Type
            </Typography>
            {type?.length ? (
              type.map((elem: any, index: number) => (
                <Box key={index}>
                  <Typography variant={'h5'}>{elem}</Typography>
                </Box>
              ))
            ) : (
              <Typography variant={'h5'}>No Type</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.providerBlock}>
        <Typography
          variant={'subtitle2'}
          mb={1}
          className={classes.providerSubtitle}
        >
          Building description
        </Typography>
        {building_descriptions?.length ? (
          building_descriptions.map((building: any, index: number) => (
            <Box key={index}>
              <Typography variant={'h5'}>
                {building.building_description}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant={'h5'}>'No Description'</Typography>
        )}
      </Box>
    </>
  );
};
