import { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter } from 'date-fns';
import { useSnackbar } from 'notistack';

import CloseIcon from 'assets/icons/cross.svg';

import { CustomButton } from 'components';
import { FormCheckbox } from 'components/HookFormWrappers/FormCheckbox/FormCheckbox';
import { FormDatePicker } from 'components/HookFormWrappers/FormDatePicker/FormDatePicker';
import { FormMultiSelect } from 'components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { FormSelect } from 'components/HookFormWrappers/FormSelect/FormSelect';
import { useAppDispatch } from 'hooks/useAppSelector';

import { RootState } from 'store/rootReducer';
import {
  addTimeSlots,
  getTimeSlots,
} from 'store/time-slots/time-slots.actions';

import {
  REPEATS_EVERY_OPTIONS,
  REPEATS_TYPES_OPTIONS,
  TIME_SLOTS_EDITOR_TYPES,
} from '../ScheduleForms.constants';
import * as SharedS from '../ScheduleForms.styled';
import {
  getTimeSlotsEditorValues,
  mapBuildingsForSelect,
  prepareDataForRequest,
} from './AddScheduleForm.helpers';
import { getAddScheduleFormSchema } from './AddScheduleForm.schema';
import * as S from './AddScheduleForm.styled';
import { AddTimeSlotsEditorValues } from './AddScheduleForm.types';
import { DaysSelector } from './DaysSelector/DaysSelector';
import { IntervalsSelector } from './IntervalsSelector/IntervalsSelector';
import { LocationServiceCombined } from './LocationServiceCombined/LocationServiceCombined';

interface TimeSlotsEditorProps {
  editorType: TIME_SLOTS_EDITOR_TYPES;
  handleCloseTimeSlotsModal: () => void;
  serviceOrSpecialistId: string;
  isSpecialistPage?: boolean;
}

export const AddScheduleForm = ({
  editorType,
  handleCloseTimeSlotsModal,
  serviceOrSpecialistId,
  isSpecialistPage,
}: TimeSlotsEditorProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const {
    timeSlotsList: { params },
  } = useSelector((state: RootState) => state.timeSlots);

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getAddScheduleFormSchema(isSpecialistPage)),
    values: getTimeSlotsEditorValues(),
  });

  const { watch, setValue } = methods;
  const watchedStartDate = watch('start_date');
  const watchedEndDate = watch('end_date');

  const handleSubmitForm = (values: AddTimeSlotsEditorValues) => {
    dispatch(
      addTimeSlots(
        prepareDataForRequest(
          values,
          editorType,
          serviceOrSpecialistId,
          isSpecialistPage,
        ),
      ),
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar('Time slots were added successfully', {
          variant: 'success',
        });
        handleCloseTimeSlotsModal();
        dispatch(getTimeSlots({ ...params }));
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      });
  };

  const isDaysSelectorVisible = () => {
    return watch('no_end_date')
      ? Boolean(watchedStartDate)
      : Boolean(watchedStartDate && watchedEndDate);
  };

  const cleanWeekdays = () => setValue('weekdays', []);

  return (
    <S.Container>
      <S.Close onClick={handleCloseTimeSlotsModal}>
        <img src={CloseIcon} alt="" />
      </S.Close>
      {editorType === TIME_SLOTS_EDITOR_TYPES.AVAILABLE_TIME && (
        <S.Header>Add Working Time</S.Header>
      )}
      {editorType === TIME_SLOTS_EDITOR_TYPES.UNAVAILABLE_TIME && (
        <S.Header>Add Time-off</S.Header>
      )}
      <FormProvider {...methods}>
        <S.ScrollableContainer>
          <SharedS.LocationWrapper>
            {Boolean(buildings?.result?.length) && !isSpecialistPage && (
              <Fragment>
                <SharedS.FormItemHeader>Location</SharedS.FormItemHeader>
                <FormMultiSelect
                  name="services_buildings"
                  label="Buildings"
                  items={mapBuildingsForSelect(buildings)}
                  hasSelectAllOption={true}
                  disabled={!buildings.result.length}
                />
              </Fragment>
            )}
            {Boolean(buildings?.result?.length) && isSpecialistPage && (
              <Fragment>
                <SharedS.FormItemHeader>
                  Location and Service
                </SharedS.FormItemHeader>
                <LocationServiceCombined
                  name="services_buildings"
                  buildings={buildings}
                  specialist_id={serviceOrSpecialistId}
                />
              </Fragment>
            )}
          </SharedS.LocationWrapper>
          <SharedS.DateAndTimeWrapper>
            <SharedS.FormItemHeader>Date and Time</SharedS.FormItemHeader>
            <SharedS.DoubleFormItem>
              <SharedS.DatepickerWrapper>
                <FormDatePicker
                  name="start_date"
                  label="Start date"
                  onChange={(value) => {
                    if (watchedEndDate && isAfter(value, watchedEndDate)) {
                      setValue('end_date', null);
                    }
                    cleanWeekdays();
                  }}
                />
              </SharedS.DatepickerWrapper>
              <SharedS.DatepickerWrapper>
                <FormDatePicker
                  name="end_date"
                  label="End date"
                  minData={watchedStartDate as Date}
                  disabled={!(watchedStartDate as Date) || watch('no_end_date')}
                  onChange={() => {
                    cleanWeekdays();
                  }}
                />
                <FormCheckbox
                  name="no_end_date"
                  label="No end date"
                  size={20}
                  onChange={() => {
                    setValue('end_date', null);
                    cleanWeekdays();
                  }}
                />
              </SharedS.DatepickerWrapper>
            </SharedS.DoubleFormItem>
            {isDaysSelectorVisible() && (
              <S.SelectDayWrapper>
                <DaysSelector name="weekdays" />
              </S.SelectDayWrapper>
            )}
            <IntervalsSelector name="timeslots" />
            <SharedS.RepeatEveryWrapper>
              <SharedS.FormItemHeader>Repeats every</SharedS.FormItemHeader>
              <SharedS.DoubleFormItem>
                <FormSelect
                  name="repeat_every"
                  label="Count"
                  items={REPEATS_EVERY_OPTIONS}
                />
                <FormSelect
                  name="repeat_type"
                  label="Period"
                  disabled={true}
                  items={REPEATS_TYPES_OPTIONS}
                />
              </SharedS.DoubleFormItem>
            </SharedS.RepeatEveryWrapper>
          </SharedS.DateAndTimeWrapper>
        </S.ScrollableContainer>
        <S.ButtonsWrapper>
          <CustomButton
            title="Cancel"
            color="secondary"
            variant="buttonMedium"
            size="sm"
            handleClick={handleCloseTimeSlotsModal}
          />
          <CustomButton
            title="Add time"
            color="primary"
            variant="buttonMedium"
            size="sm"
            handleClick={methods.handleSubmit(handleSubmitForm)}
          />
        </S.ButtonsWrapper>
      </FormProvider>
    </S.Container>
  );
};
