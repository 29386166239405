import styled from '@emotion/styled/macro';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const SelectedCombination = styled.div`
  height: 53px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 8px;
  background-color: ${(props: any) => props.theme.palette.grey.greyF3};
`;

export const Info = styled.div`
  padding: 0 16px;
  max-width: 82%;
`;

export const Building = styled.div`
  font-size: 16px;
  font-family: 'GraphikMedium', sans-serif;
`;

export const Services = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  margin-top: 2px;
  cursor: pointer;
`;
