import styled from '@emotion/styled/macro';

export const EntityName = styled.div`
  font-size: 20px;
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 24px;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const InfoItemIcon = styled.img`
  margin-right: 16px;
`;

export const InfoItemName = styled.div<{ isCapitalized?: boolean }>`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 16px;
  color: black;
  text-transform: ${(props) => (props.isCapitalized ? 'capitalize' : 'none')};
`;

export const Description = styled.div`
  padding-top: 32px;
`;

export const DescriptionHeader = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 20px;
`;

export const DescriptionInfo = styled.div`
  margin-top: 24px;
`;
