import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useAppDispatch } from '../../../../hooks/useAppSelector';
import { getCategories } from '../../../../store/categories/categories.actions';
import { RootState } from '../../../../store/rootReducer';
import {
  mapCategoriesForSelect,
  mapServiceTypesForSelect,
} from '../../../../utils/form.helpers';
import { ALL_OPTION, BaseSelectItem } from '../GridFilters.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';
import * as S from './ServiceCategoryAndTypeFilter.styled';

interface ServiceCategoryAndTypeFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ServiceCategoryAndTypeFilter = ({
  onFiltersChange,
}: ServiceCategoryAndTypeFilterProps) => {
  const dispatch = useAppDispatch();
  const [selectedCategory, setSelectedCategory] = useState<string>(
    ALL_OPTION.value,
  );
  const [selectedType, setSelectedType] = useState<string>(ALL_OPTION.value);
  const { categories } = useSelector((state: RootState) => state.categories);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const selectCategories = useMemo(() => {
    if (categories.length) {
      return [ALL_OPTION, ...mapCategoriesForSelect(categories)];
    }

    return [];
  }, [categories]);

  const selectTypes = useMemo(() => {
    if (
      selectedCategory &&
      selectedCategory !== ALL_OPTION.value &&
      categories.length
    ) {
      return [
        ALL_OPTION,
        ...mapServiceTypesForSelect(
          categories.find((item) => item.id === selectedCategory)
            ?.service_types || [],
        ),
      ];
    }

    return [ALL_OPTION];
  }, [selectedCategory]);

  return (
    <S.Container>
      <FormControl fullWidth={true}>
        <InputLabel id="category-filter">Category</InputLabel>
        <Select
          labelId="category-filter"
          value={selectedCategory}
          disabled={!selectCategories.length}
          label="Category"
          onChange={(e: SelectChangeEvent) => {
            setSelectedCategory(e.target.value);
            setSelectedType(ALL_OPTION.value);

            onFiltersChange({
              service_category_id: normalizeFilterValue(e.target.value),
              service_type_id: '',
              page: 1,
            });
          }}
        >
          {selectCategories.length
            ? selectCategories.map(({ value, label }: BaseSelectItem) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))
            : []}
        </Select>
      </FormControl>
      <FormControl fullWidth={true}>
        <InputLabel id="type-filter">Type</InputLabel>
        <Select
          labelId="type-filter"
          value={selectedType}
          label="Type"
          disabled={!selectedCategory || selectedCategory === ALL_OPTION.value}
          onChange={(e: SelectChangeEvent) => {
            setSelectedType(e.target.value);

            onFiltersChange({
              service_type_id: normalizeFilterValue(e.target.value),
              page: 1,
            });
          }}
        >
          {selectTypes.length
            ? selectTypes.map(({ value, label }: BaseSelectItem) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))
            : []}
        </Select>
      </FormControl>
    </S.Container>
  );
};
