import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { Event } from 'store/events/events.types';

import { endpoints } from '../../config';
import http from '../../utils/http';
import { LIMIT } from '../PeopleManagement/PeopleManagement';
import { ListRequestParams } from '../PeopleManagement/ServiceSpecialist/useServiceSpecialistData';
import { DEFAULT_PARAMS } from './Events.constants';

export interface UseEventsDataParams {
  isLoading: boolean;
  listCount: number;
  events: Event[];
  getEventsList: (params: ListRequestParams) => void;
  deleteEvent: (id: string, navigateToList?: () => void) => Promise<void>;
  listRequestParams: ListRequestParams;
}

export const useEventsData = (initialLoad = true): UseEventsDataParams => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [listRequestParams, setListRequestParams] =
    useState<ListRequestParams>(DEFAULT_PARAMS);

  const getEventsList = (params: ListRequestParams) => {
    setIsLoading(true);
    http
      .get(endpoints.events.getEvents, {
        params: {
          ...params,
          ordering: '-created',
          page_size: LIMIT,
          page: params.page,
        },
      })
      .then((res: AxiosResponse) => {
        setEvents(res.data.result);
        setListCount(res.data.count);
        setListRequestParams(params);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteEvent = (id: string, navigateToList?: () => void) => {
    setIsLoading(true);
    return http
      .delete(endpoints.events.eventById(id))
      .then((res: AxiosResponse) => {
        getEventsList({
          ...listRequestParams,
          page: 1,
        });
        enqueueSnackbar(res.data.message, {
          variant: 'success',
        });

        if (navigateToList) {
          navigateToList();
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (initialLoad) {
      getEventsList(DEFAULT_PARAMS);
    }
  }, []);

  return {
    isLoading,
    listCount,
    events,
    getEventsList,
    listRequestParams,
    deleteEvent,
  };
};
