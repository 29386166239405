export const fcmConfig = {
  apiKey: 'AIzaSyAwZLEuocJLLYBsTPFtBIwuC_QTDd2BQYs',
  authDomain: 'elite-amenity.firebaseapp.com',
  projectId: 'elite-amenity',
  storageBucket: 'elite-amenity.appspot.com',
  messagingSenderId: '984552560113',
  appId: '1:984552560113:web:f1026728d0a823e9c32050',
  measurementId: 'G-SC4KT6BP0T',
};

export const vapidKeyPublic =
  'BEFcq_rqZ0KxlrXaXNqt5b6aupGGUnPPS2A9XN4iQu6LccT1J_nsQFME6XUCyfCVzQD6L6pRb7tZgAoP5QBqBwo';
