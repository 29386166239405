import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { endpoints } from 'config';

import ArrowBack from 'assets/icons/back-arrow.svg';

import { DashboardWrapper } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks/useAppSelector';
import { BuildingInvitation } from 'modules/InfoPosts/Buildings/BuildingInvitation/BuildingInvitation';
import { BuildingMainInfo } from 'modules/InfoPosts/Buildings/BuildingMainInfo/BuildingMainInfo';
import { BuildingRules } from 'modules/InfoPosts/Buildings/BuildingRules/BuildingRules';
import { BuildingWorkingHours } from 'modules/InfoPosts/Buildings/BuildingWorkingHours/BuildingWorkingHours';

import {
  createBuildingMainInfoSchema,
  createBuildingRulesSchema,
  createBuildingWorkingHoursSchema,
} from 'constants/schema';
import { imageBuildingUploader } from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import {
  convertImageInFormData,
  decodeFromBase64,
} from 'utils/HelperFunctions';

const useStyles = makeStyles({
  mainTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowBack: {
    marginRight: '8px',
  },
  declineIcon: {
    marginRight: '8px',
    height: '20px',
  },
  deleteBtn: {
    marginRight: '10px',
  },
});

export const BuildingDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<number | string | null>('info');
  const [notUploadedImage, setNotUploadedImage] = useState('');
  const { currentBuilding } = useAppSelector(
    (state: RootState) => state.buildings,
  );

  useEffect(() => {
    if (currentBuilding?.id && notUploadedImage) {
      uploadImage(notUploadedImage);
      setNotUploadedImage('');
    }
  }, [notUploadedImage]);

  const uploadImage = (image: string) => {
    const file = decodeFromBase64(image, new Date().getTime().toString());
    const imageFormData = convertImageInFormData(file);

    if (activeTab === 'desk') {
      dispatch(
        imageBuildingUploader({
          image: imageFormData,
          url: endpoints.images.imageBuildingPostUpload(
            currentBuilding?.id,
            currentBuilding?.building_rules?.id,
          ),
          building_id: currentBuilding?.id,
        }),
      );
    } else {
      dispatch(
        imageBuildingUploader({
          image: imageFormData,
          url: endpoints.images.imageBuildingUpload(currentBuilding?.id),
          building_id: currentBuilding?.id,
        }),
      );
    }
  };

  const methodsMainInfo = useForm({
    mode: 'onChange',
    resolver: yupResolver(createBuildingMainInfoSchema),
  });

  const methodsWorkingHours = useForm({
    mode: 'onChange',
    resolver: yupResolver(createBuildingWorkingHoursSchema),
  });

  const methodsBuildingRules = useForm({
    mode: 'onChange',
    resolver: yupResolver(createBuildingRulesSchema),
  });

  const handleBack = () => {
    navigate('/info', { state: { activeInfoTab: 'buildings' } });
  };

  const handleTabChange = (
    event: React.SyntheticEvent | null,
    value: number | string | null,
  ) => {
    setActiveTab(value);
  };

  return (
    <DashboardWrapper>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography
          variant={'h2'}
          className={classes.mainTitle}
          mb={4}
          onClick={handleBack}
        >
          <img src={ArrowBack} alt="arrow back" className={classes.arrowBack} />
          {currentBuilding?.building_code} {currentBuilding?.building_name}
        </Typography>
      </Box>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <TabsList>
          <Tab value="info">Info</Tab>
          <Tab value="hours">Business hours</Tab>
          <Tab value="desk">Information Desk</Tab>
          <Tab value="managers">Building Managers</Tab>
        </TabsList>
        <TabPanel value={'info'}>
          <FormProvider {...methodsMainInfo}>
            <BuildingMainInfo
              canUpdate={true}
              setActiveTab={setActiveTab}
              validationFormState={methodsMainInfo}
              notUploadedImage={notUploadedImage}
              setNotUploadedImage={setNotUploadedImage}
            />
          </FormProvider>
        </TabPanel>
        <TabPanel value={'hours'}>
          <FormProvider {...methodsWorkingHours}>
            <BuildingWorkingHours
              canUpdate={true}
              validationFormState={methodsWorkingHours}
            />
          </FormProvider>
        </TabPanel>
        <TabPanel value={'desk'}>
          <FormProvider {...methodsBuildingRules}>
            <BuildingRules
              canUpdate={true}
              validationFormState={methodsBuildingRules}
              notUploadedImage={notUploadedImage}
              setNotUploadedImage={setNotUploadedImage}
            />
          </FormProvider>
        </TabPanel>
        <TabPanel value={'managers'}>
          <BuildingInvitation canUpdate={true} />
        </TabPanel>
      </Tabs>
    </DashboardWrapper>
  );
};
