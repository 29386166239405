export const CONCIERGE_SERVICE_CATEGORY = 'Concierge services';
export const HOME_SERVICES_CATEGORY = 'Home services';
export const FITNESS_AND_WELLNESS_CATEGORY = 'Fitness & wellness';

export const SERVICE_TYPE_POOL = 'Pool';
export const SERVICE_TYPE_GYM = 'Gym';

export enum EDITOR_FLOWS {
  BY_SPECIALIST = 'BY_SPECIALIST',
  BY_ELITE_AMENITY = 'BY_ELITE_AMENITY',
  BY_CONCIERGE = 'BY_CONCIERGE',
}

export const STEPS = {
  [EDITOR_FLOWS.BY_SPECIALIST]: ['Add basic info', 'Assign service specialist'],
  [EDITOR_FLOWS.BY_ELITE_AMENITY]: ['Add basic info'],
  [EDITOR_FLOWS.BY_CONCIERGE]: ['Add basic info'],
};
