import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Send from 'assets/icons/send.svg';

import { CustomButton, InvitationModal } from 'components';
import CustomModal from 'components/CustomModal/CustomModal';
import { CustomPagination } from 'components/CustomPagination/CustomPagination';
import { Toast } from 'components/Toast/Toast';
import { BuildingManagerList } from 'modules/InfoPosts/Buildings/BuildingMangerList/BuildingManagerList';
import { DetailsBuildingManagerModal } from 'modules/PeopleManagement/components/DetailsBuildingManagerModal/DetailsBuildingManagerModal';

import { addManagerToBuilding } from 'store/building-managers/building-managers.actions';
import { RootState } from 'store/rootReducer';
import { MessageInfo } from 'types';

import { useAppDispatch } from '../../../../hooks/useAppSelector';

const useStyles = makeStyles({
  saveButton: {
    width: '100%',
    maxWidth: '367px',
    marginTop: '32px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface BuildingInvitationProps {
  canUpdate?: boolean;
  selectManager?: string;
  handleSelectManager?: (id: string) => void;
}

const limit = 10;

export const BuildingInvitation = ({
  canUpdate,
  selectManager,
  handleSelectManager,
}: BuildingInvitationProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { message, status, loading } = useSelector(
    (state: RootState) => state.people,
  );
  const { allUsers, count } = useSelector((state: RootState) => state.people);
  const { currentBuilding, loading: buildingLoading } = useSelector(
    (state: RootState) => state.buildings,
  );

  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastStyle, setToastStyle] = useState<MessageInfo>('info');
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [isReloadPage, setIsReloadPage] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(1);
  const [user, setUser] = useState();
  const [isInvitationModalOpen, setIsInvitationModalOpen] =
    useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState(
    currentBuilding?.building_manager?.id || '',
  );

  const handlePageActive = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setActivePage(value);
  };

  const handleSelect = (id: string) => {
    if (handleSelectManager) {
      handleSelectManager(id);
    } else {
      setSelectedManager(id);
    }
  };

  const handleOpenInvitationModal = () => {
    setIsToastOpen(false);
    setIsInvitationModalOpen(true);
  };

  const handleOpenInfoModal = (id: string) => {
    setOpenInfoModal(true);

    const userInfo = allUsers.filter((resident: any) => {
      return resident?.user_id === id;
    })[0];

    if (userInfo) {
      setUser(userInfo);
    }
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleCloseInvitationModal = () => {
    setIsInvitationModalOpen(false);
  };

  const handleAddBuildingManager = () => {
    dispatch(
      addManagerToBuilding({
        id: selectedManager,
        buildingId: { building_id: currentBuilding?.id },
      }),
    );
  };

  useEffect(() => {
    if (isReloadPage) {
      setIsReloadPage(false);
    } else {
      if (!loading) {
        if (message) {
          setIsReloadPage(false);
          setToastMessage(message);
          setIsToastOpen(true);
          status === 'rejected'
            ? setToastStyle('error')
            : setToastStyle('success');
        } else {
          setIsReloadPage(false);
        }
      }
    }
  }, [message, loading]);

  if (buildingLoading) {
    return (
      <Box textAlign={'center'} mt={2}>
        Loading...
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={3}>
        {!canUpdate && (
          <CustomButton
            handleClick={handleOpenInvitationModal}
            title={'Send invitation'}
            variant={'buttonMedium'}
            color={'primary'}
            afterIcon={Send}
          />
        )}

        <Typography variant={'body1'} mt={4}>
          {canUpdate
            ? 'Choose new manager for change'
            : 'You can choose already existing person in system'}
        </Typography>
      </Box>
      <BuildingManagerList
        activePage={activePage}
        selectedManager={selectManager ? selectManager : selectedManager}
        handleSelect={handleSelect}
        handleOpenModal={handleOpenInfoModal}
      />
      {!!limit && (
        <CustomPagination
          pages={Math.ceil(+count / limit)}
          handleChange={handlePageActive}
          activePage={activePage}
        />
      )}
      {canUpdate && (
        <Box className={classes.btnContainer}>
          <CustomButton
            title={'Save'}
            color={'primary'}
            variant={'buttonMedium'}
            handleClick={() => handleAddBuildingManager()}
            additionalClass={classes.saveButton}
          />
        </Box>
      )}
      {!canUpdate && (
        <InvitationModal
          closeModal={handleCloseInvitationModal}
          isOpen={isInvitationModalOpen}
          isBuildingManager={true}
        />
      )}
      <CustomModal
        title="Details"
        open={openInfoModal}
        sizeModal={'medium'}
        closeModal={handleCloseInfoModal}
      >
        <DetailsBuildingManagerModal userInfo={user} />
      </CustomModal>
      <Toast
        duration={5000}
        isOpen={isToastOpen}
        message={toastMessage}
        toastStyle={toastStyle}
      />
    </Box>
  );
};
