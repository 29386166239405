export interface Interval {
  start_time: Date | null;
  end_time: Date | null;
}

export interface SelectedInterval {
  start_time: Date;
  end_time: Date;
}

export const INTERVAL_DEFAULT_VALUE: Interval = {
  start_time: null,
  end_time: null,
};
