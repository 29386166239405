import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const ImageAndData = styled.div`
  margin-top: 20px;
  display: flex;
  padding-bottom: 42px;
  border-bottom: 1px solid ${(props: any) => props.theme.palette.grey['300']};
`;

export const EventImage = styled.img`
  width: 376px;
  height: 286px;
  object-fit: cover;
  border-radius: 10px;
`;

export const EventData = styled.div`
  margin-left: 30px;
  position: relative;
  padding-right: 120px;
  flex: 1;
`;

export const EventActions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

export const LinkWrapper = styled.div`
  padding-left: 41px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }
`;

export const EventLink = styled.a`
  color: ${(props: any) => props.theme.palette.info.main};
  margin-right: 10px;
`;
