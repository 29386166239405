import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Checkbox, FormControlLabel, Modal } from '@mui/material';
import { useSnackbar } from 'notistack';

import { YesNoModal } from 'components/CustomModal/YesNoModal/YesNoModal';
import { CustomPagination } from 'components/CustomPagination/CustomPagination';
import { ServiceTypeFilter } from 'components/GridElements/GridFilters/AvailableFilters/ServiceTypeFilter';
import { GridFilters } from 'components/GridElements/GridFilters/GridFilters';
import { FiltersParamsTypes } from 'components/GridElements/GridFilters/GridFilters.types';
import { OverlayLoader } from 'components/Loaders/OverlayLoader';
import { useAppDispatch, useAppSelector } from 'hooks/useAppSelector';
import { useConfirmationModal } from 'hooks/useConfirmationModal';

import { RootState } from 'store/rootReducer';
import {
  getSpecialistsServices,
  unassignServiceFromSpecialist,
} from 'store/service-specialists/service-specialists.actions';
import { clearSpecialistsServices } from 'store/service-specialists/service-specialists.slice';
import { Service } from 'store/services/services.types';

import { EmptyState } from './EmptyState/EmptyState';
import { ServiceTile } from './ServiceTile/ServiceTile';
import {
  DEFAULT_PARAMS,
  SPECIALIST_SERVICES_LIMIT,
} from './SpecialistServices.constants';
import * as S from './SpecialistServices.styled';
import { SplitForm } from './SplitForm/SplitForm';
import { useSplitModal } from './useSplitModal';

interface SpecialistServicesProps {
  specialistName: string;
}

export const SpecialistServices = ({
  specialistName,
}: SpecialistServicesProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSplitModalOpened,
    splitModalMode,
    handleCloseSplitModal,
    handleOpenSplitModal,
    currentService,
  } = useSplitModal();
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();
  const {
    specialistServices: { list, params },
    loading: isLoading,
  } = useAppSelector((state: RootState) => state.serviceSpecialists);
  const { serviceTypes } = useAppSelector(
    (state: RootState) => state.serviceTypes,
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getSpecialistsServices({
          params: DEFAULT_PARAMS,
          specialistId: id,
        }),
      );
    }

    return () => {
      dispatch(clearSpecialistsServices());
    };
  }, []);

  const onFilterChange = (filterParams: FiltersParamsTypes) => {
    dispatch(
      getSpecialistsServices({
        params: {
          ...params,
          ...filterParams,
          page: 1,
        },
        specialistId: id as string,
      }),
    );
  };

  const handleServiceSearch = (searchValue: string) => {
    dispatch(
      getSpecialistsServices({
        params: {
          ...params,
          search: searchValue,
          page: 1,
        },
        specialistId: id as string,
      }),
    );
  };

  const handleUnassignSpecialist = (service: Service) => {
    handleOpenModal({
      title: 'Unassign service',
      question: `Do you want to unassign the service from ${specialistName}?`,
      callback: () => {
        dispatch(
          unassignServiceFromSpecialist({
            specialistId: id as string,
            serviceId: service.id,
          }),
        )
          .unwrap()
          .then(() => {
            enqueueSnackbar(
              `The service is successfully removed. ${specialistName} will no longer provide ${service.name}`,
              {
                variant: 'success',
              },
            );
            dispatch(
              getSpecialistsServices({
                params: { ...params, page: 1 },
                specialistId: id as string,
              }),
            );
            handleCloseModal();
          })
          .catch((e) => {
            enqueueSnackbar(e.message, {
              variant: 'error',
            });
          });
      },
    });
  };

  return (
    <S.Container>
      {isLoading && <OverlayLoader />}
      <S.Filters>
        <S.LeftSection>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!list.assigned_count || !list.count}
                onChange={(event) => {
                  onFilterChange({
                    is_assigned: event.target.checked || '',
                    page: 1,
                  });
                }}
              />
            }
            label={`Show Assigned (${list?.assigned_count || 0})`}
          />
          <S.FilterWrapper>
            <ServiceTypeFilter
              serviceTypes={serviceTypes.list}
              onFiltersChange={onFilterChange}
            />
          </S.FilterWrapper>
        </S.LeftSection>
        <GridFilters
          handleSearch={handleServiceSearch}
          searchPlaceholder="Search by name of service"
        />
      </S.Filters>
      {list.assigned_count_before_filters === 0 && !isLoading && (
        <EmptyState
          text="There are no assigned services for service specialist yet."
          subText="To assign service click “Assign” button on service card."
        />
      )}
      {list.count === 0 && !isLoading && (
        <EmptyState
          text="No results found."
          subText="Adjust your filters or try a different search term."
        />
      )}
      <S.Tiles>
        {list?.result.map((service: Service) => (
          <Fragment key={service.id}>
            <ServiceTile
              service={service}
              handleUnassignSpecialist={handleUnassignSpecialist}
              handleOpenSplitModal={handleOpenSplitModal}
            />
          </Fragment>
        ))}
      </S.Tiles>
      {Boolean(list?.count) && (
        <CustomPagination
          pages={Math.ceil(list?.count / SPECIALIST_SERVICES_LIMIT)}
          handleChange={(e: React.ChangeEvent<unknown>, value: number) => {
            dispatch(
              getSpecialistsServices({
                params: {
                  ...params,
                  page: value,
                },
                specialistId: id as string,
              }),
            );
          }}
          activePage={params.page as number}
        />
      )}
      <Modal open={isSplitModalOpened} onClose={handleCloseSplitModal}>
        <Fragment>
          <SplitForm
            mode={splitModalMode}
            specialistName={specialistName}
            specialistId={id as string}
            handleCloseSplitModal={handleCloseSplitModal}
            service={currentService}
            params={params}
          />
        </Fragment>
      </Modal>
      <YesNoModal
        isOpen={isConfirmationModalOpen}
        title={modalInfo.title}
        question={modalInfo.question}
        closeModal={handleCloseModal}
        handleAction={() => {
          if (modalInfo.callback) {
            modalInfo.callback();
          }
          handleCloseModal();
        }}
      />
    </S.Container>
  );
};
