import { format, startOfToday } from 'date-fns';

import { Service } from '../../../../store/services/services.types';
import { Building } from '../../../../types/buildings';
import { DATE_FORMAT_BE, TIME_FORMAT_BE } from '../../../../utils/constants';
import { TIME_SLOTS_EDITOR_TYPES } from '../ScheduleForms.constants';
import {
  AddTimeSlotsEditorValues,
  AddTimeSlotsRequestValues,
  LocationServices,
  RepeatType,
} from './AddScheduleForm.types';

export const getTimeSlotsEditorValues = (): AddTimeSlotsEditorValues => {
  return {
    services_buildings: [],
    start_date: startOfToday(),
    end_date: null,
    no_end_date: false,
    weekdays: [],
    timeslots: [],
    repeat_every: 1,
    repeat_type: RepeatType.WEEK,
  };
};

export const mapBuildingsForSelect = (buildings: { result: Building[] }) => {
  return [...buildings.result]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((building: Building) => ({
      label: `${building.code} ${building.name}`,
      value: building.id,
    }));
};

export const mapServicesForSelect = (services: { result: Service[] }) => {
  return [...services.result]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((service: Service) => ({
      label: service.name,
      value: service.id,
    }));
};

export const prepareDataForRequest = (
  {
    services_buildings,
    start_date,
    end_date,
    weekdays,
    timeslots,
    repeat_every,
    repeat_type,
  }: AddTimeSlotsEditorValues,
  editorType: TIME_SLOTS_EDITOR_TYPES,
  serviceOrSpecialistId: string,
  isSpecialistPage: boolean | undefined,
): AddTimeSlotsRequestValues => {
  return {
    services_buildings: isSpecialistPage
      ? (services_buildings as LocationServices[])
      : (services_buildings as string[]).map((building) => ({
          building,
          services: [serviceOrSpecialistId],
        })),
    start_date: format(start_date as Date, DATE_FORMAT_BE),
    ...(end_date ? { end_date: format(end_date as Date, DATE_FORMAT_BE) } : {}),
    weekdays,
    timeslots: timeslots.map((item) => ({
      start_time: format(item.start_time as Date, TIME_FORMAT_BE),
      end_time: format(item.end_time as Date, TIME_FORMAT_BE),
    })),
    repeat_every,
    repeat_type,
    status: editorType,
    ...(isSpecialistPage ? { specialist_id: serviceOrSpecialistId } : {}),
  };
};
