import styled from '@emotion/styled/macro';

export const Container = styled.div``;
export const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LeftSection = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-bottom: 22px;

  & > label {
    min-width: 200px;
  }
`;

export const FilterWrapper = styled.div`
  width: 200px;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`;
