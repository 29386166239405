/**
 * Application wide configuration.
 * The object are nested on basis of redux store
 */

export const dev = {
  env: process.env.NODE_ENV,
  baseURI: 'https://api.dev.eliteamenitydashboard.com/api',
};

export const stage = {
  env: process.env.NODE_ENV,
  baseURI: 'https://api.stage.eliteamenitydashboard.com/api',
};

export const prod = {
  env: process.env.NODE_ENV,
  baseURI: 'https://api.eliteamenitydashboard.com/api',
};

let config: any;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'staging':
    config = stage;
    break;
  default:
    config = dev;
    break;
}

export const main = {
  auth: {
    root: `${config.baseURI}/users/`,
  },
  token: {
    root: `${config.baseURI}/token/`,
  },
  services: {
    root: `${config.baseURI}/services/`,
  },
  serviceTypes: {
    root: `${config.baseURI}/service-types/`,
  },
  events: {
    root: `${config.baseURI}/events/`,
  },
  buildings: {
    root: `${config.baseURI}/buildings/`,
  },
  billings: {
    root: `${config.baseURI}/payments/`,
  },
  notifications: {
    root: `${config.baseURI}/notifications/`,
  },
  export: {
    root: `${config.baseURI}/report/`,
  },
  posts: {
    root: `${config.baseURI}/building-posts/`,
  },
  categories: {
    root: `${config.baseURI}/service-categories/`,
  },
  serviceProviders: {
    root: `${config.baseURI}/service-providers/`,
  },
  serviceSpecialists: {
    root: `${config.baseURI}/service-specialists/`,
  },
  buildingManagers: {
    root: `${config.baseURI}/building-managers/`,
  },
  serviceRequests: {
    root: `${config.baseURI}/booking/service/`,
  },
  eventRequests: {
    root: `${config.baseURI}/booking/events/`,
  },
  images: {
    root: `${config.baseURI}/service-image/`,
  },
  fcm: {
    root: `${config.baseURI}/fcm-devices`,
  },
  scheduling: {
    root: `${config.baseURI}/scheduling/`,
  },
  timeSlots: {
    root: `${config.baseURI}/time-slots/`,
  },
};
export const endpoints = {
  auth: {
    login: `${main.auth.root}login/`,
    logout: `${main.auth.root}logout/`,
    restorePassword: `${main.auth.root}restore-password/`,
    resetPassword: `${main.auth.root}reset-password/`,
    savePassword: `${main.auth.root}admins/save-password/`,
    signup: `${main.auth.root}admins/sign-up/`,
    temporaryToken: (token: string) =>
      `${main.auth.root}admins/user/?temporary_token=${token}`,
    sendInvite: `${main.auth.root}admins/invite/`,
    canCurrentUserRolesInvite: `${main.auth.root}admins/can-invite-roles/`,
    sendVerificationCode: `${main.auth.root}emails/change/request`,
    sendToVerifyCode: `${main.auth.root}emails/verify/`,
    sendToAcceptEmail: `${main.auth.root}emails/change/accept`,
    sendToChangePassword: `${main.auth.root}change-password/`,
    updateAccountInfo: `${main.auth.root}me/`,
    deleteAccount: (id: string) => `${main.auth.root}${id}/delete/`,
    getContacts: `${main.auth.root}contacts/`,
  },
  fcm: {
    login: `${main.fcm.root}/`,
  },
  token: {
    updateAccessToken: `${main.token.root}refresh/`,
  },
  buildings: {
    getBuildingById: (id: string) => `${main.buildings.root}${id}/`,
    updateBuilding: (id: string) => `${main.buildings.root}${id}/`,
    deleteBuildingById: (id: string) => `${main.buildings.root}${id}/`,
    createBuildingWorkingHours: (building_id: string) =>
      `${main.buildings.root}${building_id}/working-hours/`,
    updateBuildingWorkingHoursById: (
      building_id: string,
      working_hours_id: string,
    ) =>
      `${main.buildings.root}${building_id}/working-hours/${working_hours_id}/`,
    createBuildingRules: (building_id: string) =>
      `${main.buildings.root}${building_id}/building-posts/?post_type=building_rules`,
    updateBuildingRulesById: (building_id: string, post_id: string) =>
      `${main.buildings.root}building-posts/${post_id}/`,
    updateBuildingPostImage: (building_id: string, post_id: string) =>
      `${main.buildings.root}${building_id}/building-posts/${post_id}/images/`,
    videosCreate: (building_id: string) =>
      `${main.buildings.root}${building_id}/videos/`,
    getVideoById: (video_id: string) =>
      `${main.buildings.root}0d1177ca-b088-47f5-8d5b-c00b542dd2f1/videos/${video_id}/`,
  },
  billings: {
    getTransactions: `${main.billings.root}transactions/`,
    getPayrolls: `${main.billings.root}payrolls/`,
    getRefunds: `${main.billings.root}refunds/`,
    approveRefund: (id: string) =>
      `${main.billings.root}refunds/${id}/approve/`,
    declineRefund: (id: string) =>
      `${main.billings.root}refunds/${id}/decline/`,
  },
  export: {
    exportTransaction: `${main.export.root}transaction/`,
    exportPayroll: `${main.export.root}payroll/`,
    exportTransactionsExcel: `${main.export.root}transactions/`,
    exportPayrollsExcel: `${main.export.root}payrolls/`,
    exportRefundsExcel: `${main.export.root}refunds/`,
  },
  notifications: {
    getUnreadCountNotifications: `${main.notifications.root}unread-count/`,
    getAllPastNotifications: `${main.notifications.root}past/`,
    getAllTodayNotifications: `${main.notifications.root}today/`,
    markReadAll: `${main.notifications.root}mark-all/`,
    markReadById: (id: string) => `${main.notifications.root}${id}/mark/`,
  },
  posts: {
    createPost: () => `${main.buildings.root}building-posts/post-create/`,
    updatePost: (post_id: string) =>
      `${main.buildings.root}building-posts/${post_id}/`,
    infoPostsByBuilding: (id: string) =>
      `${main.buildings.root}${id}/building-posts/?post_type=info_post`,
    buildingPostById: (building_id: string, post_id: string) =>
      `${main.buildings.root}${building_id}/building-posts/${post_id}/`,
  },
  people: {
    getAllPeople: `${main.auth.root}people-management/`,
    getAllSpecialists: `${main.serviceSpecialists.root}`,
    residentNoteById: (id: string) =>
      `${config.baseURI}/residents/${id}/admin_note/`,
    changeStatus: (id: string, status: string) =>
      `${main.auth.root}${id}/change-status/?status=${status}`,
    deleteUser: (id: string) => `${main.auth.root}${id}/delete/`,
    specialistById: (id: string) => `${main.serviceSpecialists.root}${id}/`,
    specialistByProviderId: (id: string, free: boolean, size: number) =>
      `${
        main.serviceSpecialists.root
      }?provider=${id}&free=${true}&page_size=${size}`,
    getSpecialistTimeSlots: (
      specialist_id: string,
      service_id: string,
      date: string,
      tz: string,
    ) =>
      `${main.serviceSpecialists.root}${specialist_id}/time-slots/?service_id=${service_id}&date=${date}&tz=${tz}`,
    createTimeslots: (specialist_id: string) =>
      `${main.serviceSpecialists.root}${specialist_id}/time-slots/`,
    updateTimeslots: (specialist_id: string, service_id: string) =>
      `${main.serviceSpecialists.root}${specialist_id}/time-slots/${service_id}/`,
    uploadSpecialistImage: (specialist_id: string) =>
      `${main.serviceSpecialists.root}${specialist_id}/images/`,
    rescheduleTimeSlotBySpecialist: (specialist_id: string) =>
      `${main.serviceSpecialists.root}${specialist_id}/time-slots/reschedule/`,
    copyScheduleBySpecialist: (id: string) =>
      `${main.serviceSpecialists.root}${id}/time-slots/copy/`,
    getMembers: `${config.baseURI}/users/residents/`,
  },
  services: {
    getServicesByBuildings: `${main.services.root}by-buildings/`,
    createServiceTimeSlot: `${main.services.root}/time-slots/`,
    serviceById: (id: string) => `${main.services.root}${id}/`,
    servicePackagesById: (id: string) => `${main.services.root}${id}/packages/`,
    deleteServicePackagesById: (service_id: string, package_id: string) =>
      `${main.services.root}${service_id}/packages/${package_id}`,
    updateServiceStatus: (id: string) =>
      `${main.services.root}${id}/set_active_state/`,
    uploadServiceImages: `${main.images.root}`,
    deleteServiceImage: (id: string) => `${main.images.root}${id}`,
    serviceToSpecialist: (specialistId: string, serviceId: string) =>
      `${main.serviceSpecialists.root}${specialistId}/services/${serviceId}/`,
  },
  serviceRequests: {
    getServiceRequests: `${main.serviceRequests.root}details/`,
    getConciergeRequests: `${main.services.root}concierge-requests/`,
    createBookingConciergeServices: (id: string) =>
      `${main.services.root}${id}/concierge-requests/`,
    confirmDetailsConciergeService: (id: string) =>
      `${main.services.root}concierge-requests/${id}/confirm/`,
    approveServiceRequests: (id: string) =>
      `${main.serviceRequests.root}${id}/approve/`,
    declineServiceRequests: (id: string) =>
      `${main.serviceRequests.root}${id}/decline/`,
    declineConciergeServiceRequests: (id: string) =>
      `${main.services.root}concierge-requests/${id}/decline/`,
  },
  eventRequests: {
    getEventRequests: `${main.eventRequests.root}details/`,
    approveEventRequests: (id: string) =>
      `${main.eventRequests.root}${id}/approve/`,
    declineEventRequests: (id: string) =>
      `${main.eventRequests.root}${id}/decline/`,
  },
  providers: {
    getServiceProviders: `${main.serviceProviders.root}related-data/`,
    saveSplit: (id: string) => `${main.serviceProviders.root}${id}/split-ea/`,
  },
  events: {
    getEvents: main.events.root,
    uploadEventPhoto: (id: string) => `${main.events.root}${id}/photo/`,
    eventById: (id: string) => `${main.events.root}${id}/`,
    changeEventStatus: (id: string) =>
      `${main.events.root}${id}/set_active_state/`,
  },
  buildingManagers: {
    addManagerToBuilding: (id: string) =>
      `${main.buildingManagers.root}${id}/assign/`,
  },
  images: {
    imageById: (id: string) => `${main.images.root}${id}`,
    imageBuildingUpload: (id: string) => `${main.buildings.root}${id}/images/`,
    imageBuildingPostUpload: (building_id: string, post_id: string) =>
      `${main.buildings.root}${building_id}/building-posts/${post_id}/images/`,
  },
};
