import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { normalizePhone } from 'utils/HelperFunctions';

const useStyles = makeStyles({
  input: { position: 'relative', paddingTop: '0.5rem' },
  phoneMask: {},
  phoneInput: {
    display: 'flex!important',
    flexDirection: 'row',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    top: '55px',
    marginTop: '10px!important',
  },
});

interface PhoneInputProps {
  name: string;
  value: string;
  label: string;
  color?: 'white' | 'black';
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneInput = ({
  name,
  label,
  value,
  color,
  handleChange,
}: PhoneInputProps) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        render={({ field }: any) => (
          <FormControl
            // className={classes.phoneInput}
            sx={{
              m: 0,
              width: '100%',
              display: 'flex!important',
              flexDirection: 'row',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${
                  color === 'white' ? '#000000' : '#ffffff'
                } inset`,
              },
            }}
            variant="outlined"
          >
            <Typography
              variant="h5"
              className={classes.phoneMask}
              mt={2}
              mr={2}
            >
              +1
            </Typography>
            <Box>
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={{
                  paddingLeft: '30px',

                  color: `${color === 'white' ? '#ffffff' : '#000000'}`,
                }}
              >
                {label}
              </InputLabel>
            </Box>
            <OutlinedInput
              sx={{
                width: '100%',
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                    },
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${
                    color === 'white' ? '#000000' : '#F3F3F3'
                  } inset`,
                },
              }}
              {...field}
              name={name}
              value={normalizePhone(value)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                handleChange(e);
              }}
              label={label}
              // inputComponent={!value && (TextMaskCustom as any)}
            />
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
