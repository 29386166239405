import { Typography } from '@mui/material';

import { CellElement } from '../../components/GridElements/CellElement/CellElement';
import {
  ColumnType,
  GRID_HEADER_ALIGNMENTS,
} from '../../components/GridElements/GridCommonConfigs';
import { HeaderElement } from '../../components/GridElements/HeaderElement/HeaderElement';
import { TextWithAvatar } from '../../components/GridElements/TextWithAvatar/TextWithAvatar';
import { Member } from '../../store/members/members.types';

export const MEMBERS_LIST_CONFIG: ColumnType<Member>[] = [
  {
    label: 'Member name',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Member>) => (
      <HeaderElement<Member> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Member>, member: Member) => (
      <CellElement<Member> column={item}>
        <TextWithAvatar
          imgLink={member.image}
          text={`${member.first_name} ${member.last_name}`}
        />
      </CellElement>
    ),
  },
  {
    label: 'Email',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Member>) => (
      <HeaderElement<Member> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Member>, member: Member) => (
      <CellElement<Member> column={item}>
        <Typography variant={'body2'}>{member.email}</Typography>
      </CellElement>
    ),
  },
  {
    label: 'Phone number',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Member>) => (
      <HeaderElement<Member> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Member>, member: Member) => (
      <CellElement<Member> column={item}>
        <Typography variant={'body2'}>{member.phone_number}</Typography>
      </CellElement>
    ),
  },
  {
    label: 'Building',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Member>) => (
      <HeaderElement<Member> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Member>, member: Member) => (
      <CellElement<Member> column={item}>
        <Typography variant={'body2'}>
          {member.building.building_code} {member.building.building_name}
        </Typography>
      </CellElement>
    ),
  },
  {
    label: 'Apartment #',
    widthPercentage: 20,
    align: GRID_HEADER_ALIGNMENTS.LEFT,
    headerComponent: (column: ColumnType<Member>) => (
      <HeaderElement<Member> column={column}>
        <Typography variant={'tableHeader'}>{column.label}</Typography>
      </HeaderElement>
    ),
    cellComponent: (item: ColumnType<Member>, member: Member) => (
      <CellElement<Member> column={item}>
        <Typography variant={'body2'}>{member.apartment_number}</Typography>
      </CellElement>
    ),
  },
];
