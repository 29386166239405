import { Dispatch, Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@mui/material';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { CustomButton } from '../../../../../../components';
import { BaseFormInput } from '../../../../../../components/HookFormWrappers/BaseFormInput/BaseFormInput';
import { BaseFormTextArea } from '../../../../../../components/HookFormWrappers/BaseFormTextArea/BaseFormTextArea';
import { FormMultiSelect } from '../../../../../../components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { PhoneNumberInput } from '../../../../../../components/HookFormWrappers/PhoneNumberInput/PhoneNumberInput';
import { RootState } from '../../../../../../store/rootReducer';
import { Building, ResponseBuilding } from '../../../../../../types/buildings';
import { SpecialistEditorSchema } from './SpecialistEditor.schema';
import * as S from './SpecialistEditor.styled';
import { SpecialistEditorValues } from './SpecialistEditor.types';

interface SpecialistEditorProps {
  specialist: ServiceSpecialist;
  saveSpecialist: (values: SpecialistEditorValues) => Promise<void>;
  setIsEditMode: Dispatch<boolean>;
}

export const SpecialistEditor = ({
  specialist,
  saveSpecialist,
  setIsEditMode,
}: SpecialistEditorProps) => {
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(SpecialistEditorSchema),
    defaultValues: {
      first_name: specialist.first_name || '',
      last_name: specialist.last_name || '',
      bio_info: specialist.bio_info || '',
      profession: specialist.profession || '',
      email: specialist.email || '',
      phone_number: specialist.phone_number || '',
      building_codes:
        specialist.buildings.map(
          (building: ResponseBuilding) => building.building_code,
        ) || [],
    } as SpecialistEditorValues,
  });

  const mapBuildingsForSelect = () =>
    buildings.result.map((building: Building) => ({
      label: `${building.code} ${building.name}`,
      value: building.code,
      disabled: specialist.buildings.find(
        (item: ResponseBuilding) => item.id === building.id,
      )?.has_bookings,
    }));

  const handleSubmitForm = (values: SpecialistEditorValues) =>
    saveSpecialist(values).then(() => {
      setIsEditMode(false);
    });

  const haveUpcomingBookings = () =>
    specialist?.buildings.some((item) => item.has_bookings);

  return (
    <S.Container>
      <FormProvider {...methods}>
        <S.FormWrapper>
          <S.FormItem>
            <BaseFormInput name="first_name" label="First name" />
          </S.FormItem>
          <S.FormItem>
            <BaseFormInput name="last_name" label="Last name" />
          </S.FormItem>
          <S.FormItem>
            <BaseFormTextArea name="bio_info" label="Bio info" />
          </S.FormItem>
          <S.FormItem>
            <BaseFormInput name="profession" label="Position" />
          </S.FormItem>
          <S.FormItem>
            <BaseFormInput name="email" label="Email" />
          </S.FormItem>
          <S.FormItem>
            <PhoneNumberInput name="phone_number" label="Phone number" />
          </S.FormItem>
          <S.FormItem>
            {Boolean(buildings.result.length) && (
              <Fragment>
                <FormMultiSelect
                  name="building_codes"
                  label="Building names"
                  items={mapBuildingsForSelect()}
                />
                {haveUpcomingBookings() && (
                  <Alert severity="warning">
                    The buildings with upcoming bookings are disabled in the
                    list and cannot be deleted
                  </Alert>
                )}
              </Fragment>
            )}
          </S.FormItem>
        </S.FormWrapper>
        <S.ButtonsWrapper>
          <CustomButton
            title="Save"
            color="primary"
            variant="buttonMedium"
            size="sm"
            handleClick={methods.handleSubmit(handleSubmitForm)}
          />
          <CustomButton
            title="Cancel"
            color="secondary"
            variant="buttonMedium"
            size="sm"
            handleClick={() => setIsEditMode(false)}
          />
        </S.ButtonsWrapper>
      </FormProvider>
    </S.Container>
  );
};
