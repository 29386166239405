import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_STATUSES } from '../store.constants';
import { getMembers } from './members.actions';
import { MembersList, MembersStateType } from './members.types';

const MEMBERS_LIST_DEFAULT = {
  list: {
    result: [],
    count: 0,
  },
  params: {},
};

export const initialState: MembersStateType = Object.freeze({
  loading: false,
  error: null,
  status: STORE_STATUSES.IDLE,
  membersList: MEMBERS_LIST_DEFAULT,
});

const membersSlice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    clearMembers: (state) => {
      state.membersList = MEMBERS_LIST_DEFAULT;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembers.pending, (state) => {
      state.status = STORE_STATUSES.PENDING;
      state.loading = true;
    });

    builder.addCase(
      getMembers.fulfilled,
      (state, action: PayloadAction<MembersList>) => {
        state.status = STORE_STATUSES.FULFILLED;
        state.membersList = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(getMembers.rejected, (state, action) => {
      state.status = STORE_STATUSES.REJECTED;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearMembers } = membersSlice.actions;

export default membersSlice.reducer;
