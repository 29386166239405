import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config';

// import { loginFCN } from 'store/fcm/fcm.actions';ss
// import authHeader from 'utils/auth-header';
import { RequestError } from 'types/errors';
import {
  CompanyInfo,
  IRestorePassword,
  LoginData,
  SavePassword,
  User,
} from 'types/index';
import { removeToken, setToken } from 'utils/HelperFunctions';
import http from 'utils/http';

// import { AppDispatch } from './../index';

// type ThunkApi = {
//   rejectValue: ValidationError;
// };

export const logIn = createAsyncThunk<
  User, // return type of the payload creator
  LoginData, // First argument to the payload creator
  {
    // Optional fields for defining thunkApi field types
    // dispatch: AppDispatch;
    // state: AuthState;
    // rejectValue: string;
    rejectValue: RequestError;
  }
  // AsyncThunkConfig
>('auth/logIn', async (loginData: LoginData, thunkAPI: any) => {
  const {
    email,
    password,
    // fcmToken
  } = loginData;
  try {
    const url = endpoints.auth.login;
    const response = await http.post(url, { email, password });
    const access = response.data.access;
    setToken(access);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const logOut = createAsyncThunk(
  'auth/logOut',
  async (refresh: string | undefined, thunkAPI: any) => {
    try {
      const url = endpoints.auth.logout;
      removeToken();
      await http.post(url, { refresh });
      return 0;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in logout');
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string) => {
    return http.post(`${endpoints.auth.resetPassword}`, email);
  },
);

export const restorePassword = createAsyncThunk(
  'auth/restorePassword',
  async (restorePass: IRestorePassword, thunkAPI: any) => {
    try {
      const url = endpoints.auth.restorePassword;
      await http.post<{ user: User }>(url, restorePass);
      // TODO
      return 0;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in restorePassword');
    }
  },
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (companyInfo: CompanyInfo, thunkAPI: any) => {
    try {
      const url = endpoints.auth.signup;
      const response = await http.post<{ user: User }>(url, companyInfo, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error on save Company info');
    }
  },
);

export const savePassword = createAsyncThunk(
  'auth/savePassword',
  async (password: SavePassword, thunkAPI: any) => {
    try {
      const url = endpoints.auth.savePassword;
      await http.post<{ user: User }>(url, password);
      // TODO
      return 0;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error on save Password');
    }
  },
);

export const updateAccessToken = createAsyncThunk(
  'auth/updateAccessToken',
  async (refresh: string, thunkAPI: any) => {
    try {
      const url = endpoints.token.updateAccessToken;
      const req = {
        refresh,
      };
      const response = await http.post<{ access: string }>(url, req);
      const access = response.data.access;
      setToken(access);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error on save Password');
    }
  },
);

export const checkTemporaryToken = createAsyncThunk(
  'auth/checkTemporaryToken',
  async (temporary: string, thunkAPI: any) => {
    try {
      const url = endpoints.auth.temporaryToken(temporary);
      const response = await http.get(url);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error on save Password');
    }
  },
);
