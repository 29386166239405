import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { SERVICE_REQUEST_STATUSES_OPTIONS } from '../../../../modules/BookingRequests/ServiceRequests/ServiceRequestsList.constants';
import { normalizeFilterValue } from '../GridFilters.helpers';
import { FiltersParamsTypes } from '../GridFilters.types';

interface ServiceRequestStatusFilterProps {
  onFiltersChange: (filterParams: FiltersParamsTypes) => void;
}

export const ServiceRequestStatusFilter = ({
  onFiltersChange,
}: ServiceRequestStatusFilterProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="event-format-filter">Status</InputLabel>
      <Select
        labelId="service-request-status-filter"
        defaultValue={SERVICE_REQUEST_STATUSES_OPTIONS[0].value as string}
        label="Status"
        onChange={(e: SelectChangeEvent) =>
          onFiltersChange({
            status: normalizeFilterValue(e.target.value),
            page: 1,
          })
        }
      >
        {SERVICE_REQUEST_STATUSES_OPTIONS.map((format) => (
          <MenuItem key={format.value} value={format.value}>
            {format.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
