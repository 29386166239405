import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { ServiceSpecialist } from 'store/service-specialists/service-specialists.types';

import { ENTITY_CHANGE_STATUSES } from '../../../components/EntityStatus/EntityStatus.constants';
import { ALL_BUILDINGS_OPTION } from '../../../components/GridElements/GridFilters/FiltersList/FiltersList.constants';
import { FiltersParamsTypes } from '../../../components/GridElements/GridFilters/GridFilters.types';
import { endpoints } from '../../../config';
import http from '../../../utils/http';
import { LIMIT } from '../PeopleManagement';

export interface ListRequestParams extends FiltersParamsTypes {
  page: number;
  search: string;
  limit?: number;
}

interface UseServiceSpecialistDataParams {
  serviceSpecialists: ServiceSpecialist[];
  getSpecialistsList: (params: ListRequestParams) => void;
  deleteSpecialist: (id: string) => void;
  changeSpecialistStatus: (id: string, status: ENTITY_CHANGE_STATUSES) => void;
  listRequestParams: ListRequestParams;
  listCount: number;
  isLoading: boolean;
  clearSpecialists: () => void;
}

export const DEFAULT_PARAMS = {
  page: 1,
  search: '',
  building: ALL_BUILDINGS_OPTION.value,
};

export const useServiceSpecialistData = (
  initialLoad = true,
): UseServiceSpecialistDataParams => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceSpecialists, setServiceSpecialists] = useState<
    ServiceSpecialist[]
  >([]);

  const [listCount, setListCount] = useState(0);
  const [listRequestParams, setListRequestParams] =
    useState<ListRequestParams>(DEFAULT_PARAMS);

  const getSpecialistsList = (params: ListRequestParams) => {
    setIsLoading(true);
    return http
      .get(endpoints.people.getAllSpecialists, {
        params: {
          ...params,
          ordering: '-created',
          page_size: params.limit || LIMIT,
        },
      })
      .then((res: AxiosResponse) => {
        setServiceSpecialists(res.data.result);
        setListCount(res.data.count);
        setListRequestParams({
          ...params,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearSpecialists = () => {
    setServiceSpecialists([]);
  };

  const changeSpecialistStatus = (
    id: string,
    status: ENTITY_CHANGE_STATUSES,
  ) => {
    setIsLoading(true);
    http
      .put(endpoints.people.changeStatus(id, status))
      .then((res: AxiosResponse) => {
        getSpecialistsList({
          ...listRequestParams,
        });
        enqueueSnackbar(res.data.message, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSpecialist = (id: string) => {
    setIsLoading(true);
    http
      .delete(endpoints.people.deleteUser(id))
      .then((res: AxiosResponse) => {
        getSpecialistsList({
          ...listRequestParams,
          page: 1,
        });
        enqueueSnackbar(res.data.message, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (initialLoad) {
      getSpecialistsList(DEFAULT_PARAMS);
    }
  }, []);

  return {
    serviceSpecialists,
    getSpecialistsList,
    clearSpecialists,
    deleteSpecialist,
    changeSpecialistStatus,
    listRequestParams,
    listCount,
    isLoading,
  };
};
