import { makeStyles } from '@mui/styles';

import defaultAvatar from 'assets/empty-user.jpg';

// import defaultAvatar from 'assets/s_avatar.png';

const useStyles = makeStyles({
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    marginRight: '1rem',
  },
});

interface AvatarProps {
  img: string | null;
  alt?: string;
}

export const Avatar = ({ img, alt }: AvatarProps) => {
  const classes = useStyles();

  return (
    <img
      className={classes.avatar}
      src={
        img === null || img === undefined || img === '' ? defaultAvatar : img
      }
      alt={alt}
      width={40}
      height={40}
    />
  );
};
