import { ReactElement } from 'react';

import * as S from './FormControlsPanel.styled';

interface FormControlsPanelProps {
  children: ReactElement;
}

export const FormControlsPanel = ({ children }: FormControlsPanelProps) => {
  return <S.Container>{children}</S.Container>;
};
