/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CategoriesState, ICategory } from 'types';

import * as categories from './categories.actions';

export const initialState: CategoriesState = Object.freeze({
  loading: false,
  error: null,
  status: 'idle',
  categories: [],
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearBuildingsError: (state) => {
      state.error = null;
    },
    clearCategories: (state) => {
      state.categories = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(categories.getCategories.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.categories = [];
    });

    builder.addCase(
      categories.getCategories.fulfilled,
      (state, action: PayloadAction<ICategory[]>) => {
        state.status = 'resolved';
        state.loading = false;
        state.categories = action.payload;
        state.error = null;
      },
    );

    builder.addCase(categories.getCategories.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// actions from slice
export const { clearBuildingsError, clearCategories } = categoriesSlice.actions;

// The reducer
export default categoriesSlice.reducer;
