import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import balloons from 'assets/icons/balloons.svg';
import fitness from 'assets/icons/fitness.svg';
import key from 'assets/icons/key.svg';

const useStyles = makeStyles({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface CategoryViewProps {
  category: string;
}

export const CategoryView = ({ category }: CategoryViewProps) => {
  const classes = useStyles();

  const serviceIcon = (serviceName: string) => {
    switch (serviceName) {
      case 'Concierge services':
        return balloons;
      case 'Fitness & wellness':
        return fitness;
      default:
        return key;
    }
  };

  return (
    <Box className={classes.textWrapper}>
      <img src={serviceIcon(category)} alt={'category'} />
      <Typography variant={'h5'} ml={1}>
        {category}
      </Typography>
    </Box>
  );
};
