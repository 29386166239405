import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import accountReducer from './account-management/account-management.slice';
import authReducer from './auth/auth.slice';
import billingsReducer from './billings/billings.slice';
import blogPostsReducer from './blog-posts/blog-posts.slice';
import buildingManagerReducer from './building-managers/building-managers.slice';
import buildingsReducer from './buildings/buildings.slice';
import categoriesReducer from './categories/categories.slice';
import contactsReducer from './contacts/contacts.slice';
import eventRequestsReducer from './events-requests/eventsRequests.slice';
import eventReducer from './events/events.slice';
import fcmReducer from './fcm/fcm.slice';
import membersReducer from './members/members.slice';
import notificationsReducer from './notifications/notifications.slice';
import packagesReducer from './packages/packages.slice';
import peopleReducer from './people/people.slice';
import invitationInfoReducer from './people/peopleInvitation.slice';
import serviceRequestsReducer from './service-requests/serviceRequests.slice';
import serviceSpecialistsReducer from './service-specialists/service-specialists.slice';
import serviceTypesReducer from './service-types/service-types.slice';
import servicesReducer from './services/services.slice';
import timeSlotsReducer from './time-slots/time-slots.slice';

const combinedReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  people: peopleReducer,
  services: servicesReducer,
  events: eventReducer,
  buildings: buildingsReducer,
  posts: blogPostsReducer,
  categories: categoriesReducer,
  buildingManager: buildingManagerReducer,
  invitation: invitationInfoReducer,
  packages: packagesReducer,
  serviceRequests: serviceRequestsReducer,
  eventRequests: eventRequestsReducer,
  billings: billingsReducer,
  notifications: notificationsReducer,
  fcm: fcmReducer,
  timeSlots: timeSlotsReducer,
  serviceTypes: serviceTypesReducer,
  serviceSpecialists: serviceSpecialistsReducer,
  members: membersReducer,
  contacts: contactsReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer = (
  state: RootState | undefined,
  action: AnyAction,
) => {
  const actionsWithReset = ['auth/logOut/pending'];

  if (actionsWithReset.includes(action.type)) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
