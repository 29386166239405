import styled from '@emotion/styled/macro';

export const LocationWrapper = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props: any) => props.theme.palette.grey[300]};

  .MuiAlert-root {
    margin-top: 10px;
  }
`;

export const FormItem = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 24px;
`;

export const FormItemHeader = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 20px;
  margin-bottom: 24px;
`;

export const DateAndTimeWrapper = styled.div``;

export const DoubleFormItem = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  & > div {
    width: 240px;
  }

  & > div:first-of-type {
    margin-right: 20px;
  }
`;

export const DatepickerWrapper = styled.div`
  width: 240px;

  & > div:nth-of-type(2) {
    margin-top: 10px;
  }
`;

export const RepeatEveryWrapper = styled.div`
  margin-bottom: 24px;
`;
