import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  stepCircle: {
    '& .MuiSvgIcon-root circle': {
      color: '#FFFFFF',
    },
    '& .MuiStepIcon-text ': {
      fill: '#000000',
    },
  },
  activeStep: {
    '& .MuiSvgIcon-root circle': {
      color: '#D5A419',
    },

    '& .MuiSvgIcon-root.Mui-completed': {
      color: '#D5A419',
    },
  },
});

interface CustomStepperProps {
  stepList: string[];
  activeStep: number;
}

export const CustomStepper = ({ stepList, activeStep }: CustomStepperProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {stepList.map((label: string, index: number) => {
          return (
            <Step
              key={index}
              className={classNames(classes.stepCircle, {
                [classes.activeStep]: activeStep >= index,
              })}
            >
              <StepLabel>
                <Typography
                  variant={activeStep >= index ? 'subtitle2' : 'body1'}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
