import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { SelectItem } from '../../MultiSelectWrapper/MultiSelectWrapper';
import * as S from './FormMultiSelect.styled';

interface FormMultiSelectProps {
  name: string;
  label: string;
  items: SelectItem[];
  onChange?: (event: SelectChangeEvent<string[]>) => void;
  disabled?: boolean;
  hasSelectAllOption?: boolean;
}

export const FormMultiSelect = ({
  name,
  label,
  items,
  onChange,
  disabled,
  hasSelectAllOption,
}: FormMultiSelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleRenderSelectedValue = (selected: string[]) =>
    selected.length
      ? items
          .filter((item) =>
            selected.some((selectItem: string) => item.value === selectItem),
          )
          .map((item) => item.label)
          .join(', ')
      : '';

  const handleChangeSelect = (
    event: SelectChangeEvent<string[]>,
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    if (onChange) {
      onChange(event);
    }

    if (event.target.value.includes('all')) {
      return;
    }

    field.onChange(event);
  };

  const handleSelectAll = (
    onFieldChange: (value: string[]) => void,
    selectedValue: string[],
  ) => {
    if (selectedValue.length === items.length) {
      onFieldChange([]);
    } else {
      onFieldChange(items.map((item) => item.value as string));
    }
  };

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl>
            <InputLabel id={`form-multiselect${name}`}>{label}</InputLabel>
            <Select
              labelId={`form-multiselect${name}`}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
                autoFocus: false,
              }}
              multiple={true}
              disabled={disabled}
              value={field.value}
              input={<OutlinedInput label={label} />}
              renderValue={handleRenderSelectedValue}
              onChange={(e) => handleChangeSelect(e, field)}
            >
              {hasSelectAllOption && (
                <MenuItem
                  value="all"
                  onClick={() => handleSelectAll(field.onChange, field.value)}
                >
                  <Checkbox
                    indeterminate={
                      field.value.length > 0 &&
                      field.value.length < items.length
                    }
                    checked={field.value.length === items.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
              )}
              {items.map((selectItem: SelectItem) => (
                <MenuItem
                  disabled={selectItem.disabled}
                  key={selectItem.value}
                  value={selectItem.value}
                >
                  <Checkbox checked={field.value.includes(selectItem.value)} />
                  <ListItemText primary={selectItem.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
