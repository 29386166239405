import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints, main } from 'config';
import { stringify } from 'querystring-ts';

import {
  IBuilding,
  IBuildingPost,
  IBuildingRules,
  IWorkingHoursList,
} from 'types/index';
import { IOnDemandVideoForm } from 'types/onDemandVideo';
import http from 'utils/http';

export interface GetBuildings {
  page?: number;
  page_size?: number;
}

export const getBuildings = createAsyncThunk(
  'buildings/getBuildings',
  async (params: object, thunkAPI: any) => {
    try {
      const paramsString = stringify(params);
      const url = `${main.buildings.root}?${paramsString}`;
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in getting buildings');
    }
  },
);

export const getBuildingById = createAsyncThunk(
  'buildings/getBuildingById',
  async (id: string, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.getBuildingById(id);
      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in getting buildings');
    }
  },
);

export const createBuilding = createAsyncThunk(
  'buildings/createBuilding',
  async (building: IBuilding, thunkAPI: any) => {
    try {
      const url = main.buildings.root;
      const response = await http.post(url, building);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error this email already exist');
    }
  },
);

interface UpdateBuilding {
  building: IBuilding;
  id: string;
}

export const updateBuildingById = createAsyncThunk(
  'buildings/updateBuildingById',
  async ({ building, id }: UpdateBuilding, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.updateBuilding(id);
      const response = await http.put(url, building);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

interface ICreateWorkingHours {
  building_id: string;
  workingHours: IWorkingHoursList;
}

export const createBuildingWorkingHours = createAsyncThunk(
  'buildings/createBuildingWorkingHours',
  async ({ workingHours, building_id }: ICreateWorkingHours, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.createBuildingWorkingHours(building_id);
      const response = await http.post(url, workingHours);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

interface IUpdateWorkingHours {
  working_hours_id: string;
  workingHours: IWorkingHoursList;
  building_id: string;
}

export const updateBuildingWorkingHoursById = createAsyncThunk(
  'buildings/updateBuildingWorkingHoursById',
  async (
    { workingHours, working_hours_id, building_id }: IUpdateWorkingHours,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.buildings.updateBuildingWorkingHoursById(
        building_id,
        working_hours_id,
      );
      const response = await http.put(url, workingHours);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

interface ICreateBuildingRules {
  building_id: string;
  buildingRules: IBuildingRules;
}

export const createBuildingRules = createAsyncThunk(
  'buildings/createBuildingRules',
  async (
    { buildingRules, building_id }: ICreateBuildingRules,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.buildings.createBuildingRules(building_id);
      const response = await http.post(url, buildingRules);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

interface IUpdateBuildingRules {
  building_id: string;
  post_id: string;
  buildingRules: IBuildingRules;
}

export const updateBuildingRulesById = createAsyncThunk(
  'buildings/updateBuildingRulesById',
  async (
    { buildingRules, building_id, post_id }: IUpdateBuildingRules,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.buildings.updateBuildingRulesById(
        building_id,
        post_id,
      );
      const response = await http.put(url, {
        ...buildingRules,
        building_ids: [building_id],
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

interface IUpdateBuildingPost {
  building_id: string;
  post_id: string;
  post: IBuildingPost;
}

export const updateBuildingPost = createAsyncThunk(
  'buildings/updateBuildingPost',
  async (
    { post, building_id, post_id }: IUpdateBuildingPost,
    thunkAPI: any,
  ) => {
    try {
      const url = endpoints.buildings.updateBuildingPostImage(
        building_id,
        post_id,
      );
      const response = await http.put(url, post);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export interface ImageBuildingUploader {
  image: FormData;
  url: string;
  building_id: string;
}

export const imageBuildingUploader = createAsyncThunk(
  'buildings/ImageBuildingUploader',
  async ({ image, url, building_id }: ImageBuildingUploader, thunkAPI: any) => {
    try {
      const response = await http.put(url, image, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      thunkAPI.dispatch(getBuildingById(building_id));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export interface OnDemandVideo {
  form: IOnDemandVideoForm;
  videoId?: string;
}

export const createVideoOnDemand = createAsyncThunk(
  'buildings/createVideoOnDemand',
  async ({ form }: OnDemandVideo, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.videosCreate(
        '0d1177ca-b088-47f5-8d5b-c00b542dd2f1',
      );
      const response = await http.post(url, form);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export const updateVideoOnDemand = createAsyncThunk(
  'buildings/updateVideoOnDemand',
  async ({ form, videoId }: OnDemandVideo, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.getVideoById(videoId as string);
      const response = await http.put(url, form);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export const getVideoOnDemand = createAsyncThunk(
  'buildings/getVideoOnDemand',
  async (params: object, thunkAPI: any) => {
    try {
      const paramsString = stringify(params);
      const url = `${endpoints.buildings.videosCreate(
        '0d1177ca-b088-47f5-8d5b-c00b542dd2f1',
      )}?${paramsString}`;

      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export const getVideoOnDemandById = createAsyncThunk(
  'buildings/getVideoOnDemandById',
  async (videoId: string, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.getVideoById(videoId);

      const response = await http.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export const deleteVideoOnDemand = createAsyncThunk(
  'buildings/deleteVideoOnDemand',
  async (videoId: string, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.getVideoById(videoId);

      const response = await http.delete(url);
      thunkAPI.dispatch(getVideoOnDemand({ page_size: 10, page: 1 }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);

export interface DeleteBuildingById {
  id: string;
  page?: number;
}

export const deleteBuildingById = createAsyncThunk(
  'buildings/deleteBuildingById',
  async ({ id, page }: DeleteBuildingById, thunkAPI: any) => {
    try {
      const url = endpoints.buildings.deleteBuildingById(id);
      const response = await http.delete(url);
      thunkAPI.dispatch(getBuildings({ page: page ? page : 1, page_size: 6 }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Error in create building');
    }
  },
);
