import { isAfter } from 'date-fns';
import * as yup from 'yup';

export const getEditScheduleFormSchema = (isSpecialistPage: boolean) => {
  return yup.object({
    matching_schedules: yup.array(),
    buildings: yup
      .array()
      .when('start_date', {
        is: () => isSpecialistPage,
        then: yup.array(),
      })
      .when('start_date', {
        is: () => !isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(1, 'At least one building should be chosen'),
      }),
    building: yup
      .string()
      .when('start_date', {
        is: () => isSpecialistPage,
        then: yup.string().required('Building is required'),
      })
      .when('start_date', {
        is: () => !isSpecialistPage,
        then: yup.string(),
      }),
    services: yup
      .array()
      .when('start_date', {
        is: () => isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(1, 'At least one building should be chosen'),
      })
      .when('start_date', {
        is: () => !isSpecialistPage,
        then: yup.array(),
      }),
    start_date: yup.date().nullable().required('Start date is required!'),
    end_date: yup
      .date()
      .nullable()
      .when('no_end_date', {
        is: false,
        then: yup.date().nullable().required('End date is required!'),
      }),
    start_time: yup.date().nullable().required('Start time is required!'),
    end_time: yup
      .date()
      .test(
        'is15MinAfter',
        'End time should be at least 15 min after start time.',
        (value: any, context: any) => {
          return isAfter(value, context.parent.start_time);
        },
      )
      .nullable()
      .required('End time is required!'),
  });
};
