import * as yup from 'yup';

export const getAddScheduleFormSchema = (
  isSpecialistPage: boolean | undefined,
) => {
  return yup.object({
    services_buildings: yup
      .array()
      .when('service_buildings', {
        is: () => !isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(1, 'At least one building should be chosen'),
      })
      .when('service_buildings', {
        is: () => isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(
            1,
            'At least one building-services combination should be selected.',
          ),
      }),
    specialist_services_buildings: yup.array(),
    start_date: yup.date().nullable().required('Start date is required!'),
    end_date: yup
      .date()
      .nullable()
      .when('no_end_date', {
        is: false,
        then: yup.date().nullable().required('End date is required!'),
      }),
    weekdays: yup
      .array()
      .required()
      .min(1, 'At least one day should be chosen'),
    timeslots: yup
      .array()
      .required()
      .min(1, 'At least one timeslot should be chosen'),
  });
};
