import { BaseTabConfig } from '../../../components/BaseTabs/BaseTabs';
import { Service } from '../../../store/services/services.types';
import { PackageList } from '../../Packages/List/PackageList';
import { ScheduleList } from '../../Schedule/List/ScheduleList';
import { SCHEDULE_PAGES } from '../../Schedule/List/ScheduleList.constants';
import {
  CONCIERGE_SERVICE_CATEGORY,
  FITNESS_AND_WELLNESS_CATEGORY,
  SERVICE_TYPE_GYM,
  SERVICE_TYPE_POOL,
} from '../Editor/ServiceEditor.constant';
import { ServiceInfo } from './ServiceInfo/ServiceInfo';

export enum SERVICE_DETAILS_TABS {
  SERVICE_INFO = 'service_info',
  PACKAGES = 'packages',
  SERVICES_SCHEDULE = 'services_schedule',
}

export const getServiceDetailsTabsConfig = (
  currentService: Service,
): BaseTabConfig[] => {
  const shouldHidePackages =
    currentService.category.name === FITNESS_AND_WELLNESS_CATEGORY &&
    currentService.service_type.name !== SERVICE_TYPE_POOL &&
    currentService.service_type.name !== SERVICE_TYPE_GYM;
  const needSpecialist = currentService?.service_type.need_specialist;
  const isConciergeService =
    currentService.category.name === CONCIERGE_SERVICE_CATEGORY;

  return [
    {
      value: SERVICE_DETAILS_TABS.SERVICE_INFO,
      label: 'Service info',
      component: () => <ServiceInfo isConciergeService={isConciergeService} />,
    },
    ...(shouldHidePackages
      ? [
          {
            value: SERVICE_DETAILS_TABS.PACKAGES,
            label: 'Packages',
            component: () => <PackageList />,
          },
        ]
      : []),
    ...(!needSpecialist && !isConciergeService
      ? [
          {
            value: SERVICE_DETAILS_TABS.SERVICES_SCHEDULE,
            label: 'Services schedule',
            component: () => (
              <ScheduleList
                schedulePageType={SCHEDULE_PAGES.SERVICE}
                id={currentService.id}
              />
            ),
          },
        ]
      : []),
  ];
};
