import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Box, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from 'components/index';

import { ROLE_NAMES } from 'constants/Roles';
import {
  invitationBuildingManagerSchema,
  invitationSchema,
} from 'constants/schema';
import { AppDispatch } from 'store';
import {
  getCanCurrentUserInviteRoles,
  sendInvite,
} from 'store/people/people.actions';
import { RootState } from 'store/rootReducer';
import { Invite } from 'types';

import CustomModal from '../CustomModal/CustomModal';
import { CheckmarksInput } from '../Inputs/CheckmarkInput/CheckmarkInput';
import { SelectInput } from '../Inputs/SelectInput/SelectInput';
import { TextInput } from '../Inputs/TextInput/TextInput';

const useStyles = makeStyles({
  sendButton: {
    marginRight: '16px',
  },
});

interface InvitationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  isBuildingManager?: boolean;
}

export const InvitationModal = ({
  isOpen,
  closeModal,
  isBuildingManager,
}: InvitationModalProps) => {
  const classes = useStyles();

  const dispatch = useDispatch<AppDispatch>();
  const { invitationData } = useSelector(
    (state: RootState) => state.invitation,
  );

  const [alertError, setAlertError] = useState(null);
  const [initData, setInvitationData] = useState(invitationData);
  const [codes, setCodes] = useState<any[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<any[]>([]);
  const [selectedCodes, setSelectedCodes] = useState<any[]>([]);

  const { buildings, loading } = useSelector(
    (state: RootState) => state.buildings,
  );
  const { canUserInviteRoles } = useSelector(
    (state: RootState) => state.people,
  );

  const roles = useMemo(
    () =>
      canUserInviteRoles?.map((item: string) => ({
        id: item,
        name: ROLE_NAMES[item],
      })),
    [canUserInviteRoles],
  );

  useEffect(() => {
    dispatch(getCanCurrentUserInviteRoles());
  }, []);

  useEffect(() => {
    if (buildings && buildings.result) {
      const code = buildings?.result?.map((building: any) => {
        return {
          id: building.code,
          name: building.name,
        };
      });
      setCodes(code);
    }
  }, [loading, buildings]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isBuildingManager ? invitationBuildingManagerSchema : invitationSchema,
    ),
  });

  const handleChangeRole = (e: SelectChangeEvent) => {
    e.preventDefault();
    const r = roles.filter((elem: any) => {
      return elem.id === e.target.value;
    });

    setInvitationData((prevState: any) => ({
      ...prevState,
      ...{ role: r[0].id },
    }));
  };

  const handleChangeBuildingCode = (e: string[]) => {
    const selectCodes = e.map((item: string) => item.split(' ')[0]);
    setSelectedBuildings(e);
    setSelectedCodes(selectCodes);
  };

  useEffect(() => {
    if (isOpen) {
      setAlertError(null);
    }
  }, [isOpen]);

  useEffect(() => {
    setInvitationData((prevState: any) => ({
      ...prevState,
      ...{ building_codes: selectedCodes },
    }));
  }, [selectedCodes]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInvitationData((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleModal = () => {
    methods.reset();
    setInvitationData(invitationData as Invite);
    closeModal();
  };

  const onSubmit = () => {
    const data = isBuildingManager
      ? {
          ...initData,
          role: 'building_manager',
          owner_note: 'New Building Manager',
        }
      : {
          ...initData,
          building_codes: selectedCodes,
        };

    setAlertError(null);
    dispatch(sendInvite(data))
      .unwrap()
      .then(() => {
        methods.reset();
        setSelectedCodes([]);
        setSelectedBuildings([]);
        setInvitationData({ ...invitationData });
        closeModal();
      })
      .catch((e) => {
        setAlertError(e.message);
      });
  };

  return (
    <CustomModal
      title={'Send Invitation'}
      open={isOpen}
      closeModal={closeModal}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FormProvider {...methods}>
          <Box sx={{ mb: 4 }}>
            <TextInput
              name="first_name"
              label={'First name'}
              color={'black'}
              value={initData.first_name}
              handleChange={handleChange}
            />
          </Box>

          <Box sx={{ mb: 4 }}>
            <TextInput
              name="last_name"
              label={'Last name'}
              color={'black'}
              value={initData.last_name}
              handleChange={handleChange}
            />
          </Box>

          <Box sx={{ mb: 4 }}>
            <TextInput
              name="email"
              label={'Email'}
              color={'black'}
              value={initData.email}
              handleChange={handleChange}
            />
          </Box>

          {!isBuildingManager && (
            <>
              {' '}
              <Box sx={{ mb: 4, maxwidth: '100%' }}>
                <SelectInput
                  value={initData.role}
                  returnedValue={'id'}
                  label={'Role'}
                  name={'role'}
                  color={'black'}
                  itemValue={'name'}
                  handleChange={handleChangeRole}
                  items={roles}
                />
              </Box>
              <Box sx={{ mb: 4, maxwidth: '100%' }}>
                <CheckmarksInput
                  value={selectedBuildings}
                  color={'black'}
                  name={'code'}
                  label={'Building name'}
                  selectedId={selectedCodes}
                  handleChange={handleChangeBuildingCode}
                  items={codes}
                  disabled={!initData.role || initData.role === 'super_admin'}
                />
              </Box>
              <Box sx={{ mb: 4 }}>
                <TextInput
                  name="owner_note"
                  label={'Owner Note'}
                  color={'black'}
                  value={initData.owner_note}
                  handleChange={handleChange}
                />
              </Box>
            </>
          )}
        </FormProvider>
      </Box>

      {alertError && (
        <Box>
          <Alert severity="error">{alertError}</Alert>
        </Box>
      )}

      <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
        <CustomButton
          title={'Send'}
          variant={'buttonMedium'}
          additionalClass={classes.sendButton}
          handleClick={methods.handleSubmit(onSubmit)}
        />

        <CustomButton
          title={'Cancel'}
          color={'gray'}
          variant={'buttonMedium'}
          handleClick={handleModal}
        />
      </Box>
    </CustomModal>
  );
};
