import styled from '@emotion/styled/macro';

export const Images = styled.div`
  width: 380px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-bottom: 20px;
`;

export const Image = styled.div`
  flex: 0 1 31%;
  height: 89px;
  position: relative;

  &:nth-of-type(2),
  &:nth-of-type(3) {
    margin-right: 13px;
  }

  &:first-of-type {
    flex: 1 1 100%;
    margin-bottom: 12px;
    height: 286px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;
