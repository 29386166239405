import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: fixed;
  height: 98px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 80px;
  z-index: 2;

  & > button:first-of-type {
    margin-right: 20px;
  }
`;
