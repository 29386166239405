/* tslint:disable typedef */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountState, User } from 'types/index';

import {
  deleteAccount,
  getVerificationCode,
  sendToAcceptEmail,
  sendToChangePassword,
  sendToVerifyCode,
} from './account-management.actions';

// import { RequestError } from 'types/errors';

export const initialState: AccountState = Object.freeze({
  error: [],
  loading: false,
  status: 'idle',
  account: null,
});

const accountManagementSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccountError: (state) => {
      state.error = '';
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getVerificationCode.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(getVerificationCode.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(getVerificationCode.rejected, (state) => {
      state.status = 'rejected';
      state.loading = false;
    });

    // -------------------------------------------------------------

    builder.addCase(sendToVerifyCode.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(
      sendToVerifyCode.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.status = 'resolved';
        state.account = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(sendToVerifyCode.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
      state.loading = false;
    });

    // -------------------------------------------------------------

    builder.addCase(sendToChangePassword.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(sendToChangePassword.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(sendToChangePassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
      state.loading = false;
    });

    // -------------------------------------------------------------

    builder.addCase(deleteAccount.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(deleteAccount.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
      state.loading = false;
    });

    // -------------------------------------------------------------

    builder.addCase(sendToAcceptEmail.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(sendToAcceptEmail.fulfilled, (state) => {
      state.status = 'resolved';
      state.loading = false;
    });

    builder.addCase(
      sendToAcceptEmail.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'rejected';
        state.error = action.payload;
        state.loading = false;
      },
    );
  },
});

export const { clearAccountError } = accountManagementSlice.actions;

// The reducer
export default accountManagementSlice.reducer;
