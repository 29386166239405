import { useState } from 'react';

import { Service } from '../../../../../store/services/services.types';

export enum SPLIT_MODAL_MODES {
  ASSIGN = 'ASSIGN',
  EDIT = 'EDIT',
}

interface UseAddTimeSlotsModalReturn {
  isSplitModalOpened: boolean;
  splitModalMode: SPLIT_MODAL_MODES;
  handleOpenSplitModal: (
    modalMode: SPLIT_MODAL_MODES,
    service: Service,
  ) => void;
  handleCloseSplitModal: () => void;
  currentService: Service | null;
}

export const useSplitModal = (): UseAddTimeSlotsModalReturn => {
  const [isSplitModalOpened, setIsSplitModalOpened] = useState(false);
  const [splitModalMode, setSplitModalMode] = useState<SPLIT_MODAL_MODES>(
    SPLIT_MODAL_MODES.ASSIGN,
  );
  const [currentService, setCurrentService] = useState<Service | null>(null);

  const handleOpenSplitModal = (
    modalMode: SPLIT_MODAL_MODES,
    service: Service,
  ) => {
    setIsSplitModalOpened(true);
    setCurrentService(service);
    setSplitModalMode(modalMode);
  };

  const handleCloseSplitModal = () => {
    setIsSplitModalOpened(false);
  };

  return {
    isSplitModalOpened,
    splitModalMode,
    handleOpenSplitModal,
    handleCloseSplitModal,
    currentService,
  };
};
