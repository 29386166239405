import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DashboardWrapper } from 'components/index';

const useStyles = makeStyles({
  error: {
    color: 'red',
    textAlign: 'center',
  },
});

export const NoAccess = () => {
  const classes = useStyles();

  return (
    <DashboardWrapper>
      <Typography variant="h2" component="h2" className={classes.error}>
        You don't have permissions for access to this page
      </Typography>
    </DashboardWrapper>
  );
};
