import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  input: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    left: 0,
    top: '55px',
  },
});

interface TextInputProps {
  name: string;
  type?: 'text' | 'number';
  value?: string | number;
  label: string;
  isDisabled?: boolean;
  color?: 'white' | 'black';
  handleChange?: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = ({
  name,
  type = 'text',
  label,
  value,
  isDisabled = false,
  color = 'white',
  handleChange,
}: TextInputProps) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box className={classes.input}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }: any) => (
          <FormControl
            sx={{
              m: 0,
              width: '100%',
              '& .Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: `${color === 'white' ? '#ffffff' : '#000000'}`,
              },
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${
                  color === 'white' ? '#000000' : '#ffffff'
                } inset`,
              },
            }}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              disabled={isDisabled}
              style={{
                color: `${
                  isDisabled
                    ? '#999999'
                    : color === 'white'
                    ? '#ffffff'
                    : '#000000'
                }`,
              }}
            >
              {label}
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${color === 'white' ? '#ffffff' : '#000000'}`,
                },
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                    },
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${
                    color === 'white' ? '#000000' : '#F3F3F3'
                  } inset`,
                },
              }}
              id="outlined-adornment-password"
              {...field}
              disabled={isDisabled}
              name={name}
              type={type}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                if (handleChange) {
                  handleChange(e);
                }
              }}
              label={label}
            />
          </FormControl>
        )}
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className={classes.errorText}
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </Box>
  );
};
